import axios from './../utils/axios';
import config  from "../config";


function getProfile() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/users/profile`,
  }

  return axios(request);
}

function checkEmailDuplicate(value) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/companies/register/check-email`,
    params : {
      email : value
    }
  }

  return axios(request);
}








export {getProfile, checkEmailDuplicate};


