import React, { useState, useEffect, Component, useRef } from 'react';
import Text from '../../common/components/text';
import Button from '../../common/components/button';
import Price from '../../common/components/price';
import TextButton from '../../common/components/button/TextButton';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertDeleteComponent from './../../common/components/AlertDeleteComponent';
import defaultImage from '../../config/images/product_default_image.png';
import CustomButtom from '../../common/components/button';
import MoveIcon from '../../utils/components/MoveIcon';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TextField,
  IconButton,
  MenuItem,
  TableContainer,
  Paper
} from '@material-ui/core';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { notiFailed } from './../../utils/helpers';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import CustomLoading from './../../common/components/CustomLoading';
import { ExcelCustomComponent } from '../../utils/components';
import { convertHTMLEntity } from './../../utils/helpers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  categoryContainer: {
    justifyContent: 'flex-start',
    paddingLeft: 30,
    paddingRight: 30,
    borderBottom: 'solid #E6E6E6 1px',
    maxWidth: '100vw',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  totalInfoItem: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingBottom: '0px !important',
      '& p': {
        marginBottom: 0
      }
    }
  },
  categoryItem: {
    paddingBottom: '4px !important',
    [theme.breakpoints.down('xs')]: {
      width: '45%'
    }
  },
  categoryItemFillWidth: {
    paddingBottom: '4px !important',
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  tableContainer: {
    boxShadow: 'none'
  },
  tableHeaderCell: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1.0625rem'
  },
  tableHeaderRow: {
    height: '45px'
  },
  tableCellPlaceholder: {
    width: '1vw'
  },
  totalSection: {
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0
    },
    "& .MuiGrid-item p": {
      marginTop: '15px',
      marginBottom: '15px'
    }
  },
  tableHeaderPriority: {
    width: '96px',
    textAlign: 'center'
  },
  tableHeaderProductImage: {
    width: '72.64px'
  },
  tableProductColumn: {
    maxWidth: '200px',
    width: '25%'
  },
  productCategory: {
    paddingTop: '1px',
    paddingBottom: '1px',
    paddingRight: '4px',
    paddingLeft: '4px'
  },
  productContainer: {
    backgroundColor: '#ffffff',
    border: 'solid #E6E6E6 1px',
    borderBottom: 0
  },
  tableHeaderInventory: {
    width: '15%'
  },
  tableHeaderPrice: {
    width: '15%'
  },
  tableHeaderStatus: {
    width: '13%'
  }
}));

const CustomCircularProgress2 = withStyles(theme => ({
  root: {
    '&.MuiCircularProgress-colorPrimary': {
      color: '#00ADEF'
    }
  }
}))(CircularProgress);

class TableCellCustom extends Component {
  constructor(props) {
    super(props);
    this.ref = null;
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (!this.ref) {
      return null;
    }

    const isDragStarting = this.props.isDragOccurring && !prevProps.isDragOccurring;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    const snapshot = {
      width,
      height
    };

    return snapshot;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragOccurring) {
      return;
    }

    if (ref.style.width == null) {
      return;
    }

    ref.style.removeProperty('height');
    ref.style.removeProperty('width');
  }

  setRef = ref => {
    this.ref = ref;
  };

  render() {
    return (
      <TableCell
        ref={this.setRef}
        style={{
          color: `${this.props.isDragOccurring ? '#00adef' : ''}`,
          backgroundColor: `${
            this.props.isDragOccurring ? 'rgb(229, 243, 251)' : ''
          }`
        }}
        {...this.props}
      >
        {this.props.children}
      </TableCell>
    );
  }
}

const ALL_CODE = 'all';

export default function Products() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [productsClone, setProductsClone] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchName, setSearchName] = useState('');
  const [stats, setStats] = useState({});
  const [report, setReport] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [status, setStatus] = useState(ALL_CODE);
  const typingTimeoutRef = useRef(null);
  const [countProducts, setCountProducts] = useState(0);
  const [offset, setOffset] = useState(0);
  const [productList, setProductList] = useState([]);
  const [filters, setFilters] = useState({ sort: 'priority', order: 'asc' });
  const [postsPerPage] = useState(25);
  const [productsReportDataSheet, setProductsReportDataSheet] = useState([]);

  const handleOpen = id => {
    setOpen(true);
    setSelectedProduct(id);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const getStats = products => {
    let sale = 0;
    let hidden = 0;
    let soldOut = 0;

    products.forEach(p => {
      if (p.active) {
        if (p.variants.length > 0 && p.variants[0]) {
          const stock = p.variants.reduce((acc, v) => acc + v.stock, 0);
          if (stock === 0) {
            soldOut += 1;
          } else {
            sale += 1;
          }
        } else {
          soldOut += 1;
        }
      } else {
        hidden += 1;
      }
    });

    setStats({ sale, hidden, soldOut });
  };

  const getProducts = async (filters = {}, resetList = false) => {
    try {
      setLoadingProduct(true);
      let offset1;
      if (resetList) {
        offset1 = 0;
      } else {
        offset1 = offset;
      }
      setOffset(offset1);
      let aaa = {
        params: {
          offset: offset1,
          limit: 25,
          ...filters
        }
      };
      const { data } = await axios.get(`${baseUrl}/products/all`, aaa);
      setLoadingProduct(false);
      setProductsClone([...productsClone, ...data.results]);

      let target = [];
      if (resetList) {
        target = data.results;
      } else {
        target = _.unionWith(productList, data.results, function(o1, o2) {
          return o1.id == o2.id;
        });
      }

      setProductList(target);
      getStats(target);
      setReport(target);
      setCountProducts(data.total[0].count);
    } catch (err) {
      setLoadingProduct(false);
      notiFailed(`There was a problem getting the products ${err.message}`);
    }
  };

  const getCatergories = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/categories`);
      setCategories(data);
    } catch (err) {
      notiFailed(`There was a problem getting the categories ${err.message}`);
    }
  };

  useEffect(() => {
    getProducts(filters);
    getCatergories();
  }, []);

  useEffect(() => {
    if (offset === 0) return;
    getProducts(filters, false);
  }, [offset]);

  const handleDelete = async selectedProduct => {
    try {
      await axios.delete(`${baseUrl}/products/${selectedProduct}`);
      setOpen(false);
      getProducts(filters, true);
    } catch (err) {
      notiFailed(`There was a deleting the selected product ${err.message}`);
    }
  };

  const handleCategorySelection = categoryId => () => {
    setSelectedCategory(categoryId);
    const categories =
      categoryId !== 'all'
        ? {
            categories: categoryId
          }
        : {};
    let filter = {
      ...filters,
      ...categories
    };

    if (categoryId === 'all') {
      delete filter.categories;
    }

    setFilters(filter);
    getProducts(filter, true);
  };

  const handleFilterSearch = e => {
    const value = e.target.value;
    let filter = {
      ...filters,
      searchName: value
    };
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setSearchName(value);
      setFilters(filter);
      getProducts(filter, true);
    }, 300);
  };

  const exportReport = async () => {
    const result = [];
    await report.forEach(reportItem => {
      [...reportItem.variants].forEach(variant => {
        result.push({
          sku: variant.sku,
          category: reportItem.categories[0].name,
          productName: reportItem.name,
          productDescription: convertHTMLEntity(reportItem.description.replace( /(<([^>]+)>)/ig, '')),
          color: variant.color ? variant.color : '',
          size: variant.size ? variant.size : '',
          custom: variant.custom ? variant.custom : '',
          inventoryQuantity: variant.unlimited ? 'Unlimited' : variant.stock,
          itemStatus: reportItem.active ? 'Visible' : 'Hidden'
        });
      });
    });
    setProductsReportDataSheet(result);
    document.getElementById('products-report-data-sheet').click();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const viewMore2 = async () => {
    setOffset(offset + 25);
  };

  const onDragEnd = async result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const priority = [];
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    if (sourceIndex < destinationIndex) {
      for (let i = sourceIndex; i <= destinationIndex; i++) {
        priority.push(productList[i].priority);
      }
    } else {
      for (let i = destinationIndex; i <= sourceIndex; i++) {
        priority.push(productList[i].priority);
      }
    }

    const newProducts = reorder(
      productList,
      result.source.index,
      result.destination.index
    );

    if (sourceIndex < destinationIndex) {
      let j = 0;
      for (let i = sourceIndex; i <= destinationIndex; i++) {
        newProducts[i].priority = priority[j];
        j++;
      }
    } else {
      let j = 0;
      for (let i = destinationIndex; i <= sourceIndex; i++) {
        newProducts[i].priority = priority[j];
        j++;
      }
    }

    try {
      setProductList(newProducts);

      const newProducts2 = newProducts.map(newProduct => {
        let target = {};
        target.id = _.get(newProduct, 'id');
        target.priority = _.get(newProduct, 'priority');
        return target;
      });

      await axios.patch(`${baseUrl}/products/update/updatePriority`, {
        batchUpdate: newProducts2
      });
    } catch (err) {
      notiFailed(`There was a problem changing priority of the product.`);
    }
  };

  const handleFilterSearchStatus = e => {
    setStatus(e.target.value);
    let filter;
    if (e.target.value !== 'all') {
      filter = {
        ...filters,
        active: e.target.value
      };
    } else {
      delete filters.active;
      filter = {
        ...filters
      };
    }
    if (searchName) {
      filter.searchName = searchName;
    }
    setFilters(filter);
    getProducts(filter, true);
  };

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: '20px' }}
      >
        <Text size="h2" style={{ lineHeight: '0px' }}>
          Products
        </Text>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                style={{ height: '40px' }}
                color="secondary"
                component={Link}
                to="/marketing-shop/categories"
              >
                Categories
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ height: '40px' }}
                component={Link}
                to="/marketing-shop/add-products"
              >
                Add a Product
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextButton onClick={exportReport}>Download Report</TextButton>
          <ExcelCustomComponent
            filename={`products-report-${new Date()}.csv`}
            id="products-report-data-sheet"
            data={productsReportDataSheet}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.productContainer}>
        <Grid container className={classes.categoryContainer} spacing={2}>
          <Grid
            item
            className={classes.categoryItemFillWidth}
            onClick={handleCategorySelection('all')}
          >
            <Text
              color={selectedCategory === 'all' ? 'primaryLight' : 'dark'}
              size="h4"
              weight="regular"
              style={{
                width: 'fit-content',
                cursor: 'pointer',
                margin: '5px',
                borderBottom: selectedCategory === 'all' ? 'solid' : '',
                borderColor: selectedCategory === 'all' ? '#00ADEF' : '',
                height: '100%'
              }}
              className={classes.productCategory}
            >
              All
            </Text>
          </Grid>

          {categories
            ? categories.map(category => (
                <Grid
                  className={classes.categoryItem}
                  key={category.id}
                  item
                  onClick={handleCategorySelection(category.id)}
                >
                  <Text
                    color={
                      selectedCategory === category.id ? 'primaryLight' : 'dark'
                    }
                    size="h4"
                    weight="regular"
                    style={{
                      width: 'fit-content',
                      cursor: 'pointer',
                      margin: '5px',
                      borderBottom: selectedCategory === category.id ? 'solid' : '',
                      borderColor: selectedCategory === category.id ? '#00ADEF' : '',
                      height: '100%'
                    }}
                    className={classes.productCategory}
                  >
                    {category.name.toUpperCase()}
                  </Text>
                </Grid>
              ))
            : false}
          {!(categories % 2) && <Grid className={classes.categoryItem} item></Grid>}
        </Grid>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6',
            width: '100%'
          }}
        >
          <Grid
            container
            spacing={1}
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              paddingTop: '20px',
              paddingBottom: '20px'
            }}
          >
            <Grid item md={11} xs={8}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Search"
                onChange={handleFilterSearch}
              />
            </Grid>
            <Grid item md={1} xs={4}>
              <TextField
                fullWidth
                select
                size="small"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                label="Status"
                value={status}
                onChange={handleFilterSearchStatus}
              >
                <MenuItem value={ALL_CODE}>All</MenuItem>
                <MenuItem value="true">Visible</MenuItem>
                <MenuItem value="false">Hidden</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6',
            width: '100%'
          }}
        >
          <Grid
            container
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto'
            }}
            spacing={2}
            className={classes.totalSection}
          >
            <Grid item className={classes.totalInfoItem}>
              <Text size="h4" weight="medium">
                Total {productList ? productList.length : 0}
              </Text>
            </Grid>
            <Grid item>
              <Text size="h4" weight="light">
                <span style={{ color: '#00ADEF' }}>{stats.sale}</span> on sale
              </Text>
            </Grid>
            <Grid item>
              <Text size="h4" weight="light">
                <span style={{ color: '#00ADEF' }}>{stats.soldOut}</span> sold out
              </Text>
            </Grid>
            <Grid item>
              <Text size="h4" weight="light">
                <span style={{ color: '#00ADEF' }}>{stats.hidden}</span> hidden
              </Text>
            </Grid>
          </Grid>
        </div>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            size="small"
            style={{
              borderBottom: 'solid',
              borderWidth: '1px',
              borderColor: '#8C8C8C',
              margin: 'auto'
            }}
          >
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>}
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderPriority}`}>
                  Priority
                </TableCell>
                <TableCell className={classes.tableHeaderProductImage}></TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableProductColumn}`}>
                  Product
                </TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderInventory}`}>
                  Inventory
                </TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderPrice}`}>
                  Price
                </TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderStatus}`}>
                  Status
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="table">
                {(provided, snapshot) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {productList.map(
                      (
                        {
                          id,
                          name,
                          active,
                          variants,
                          categories = [],
                          prices,
                          media
                        },
                        index
                      ) => {
                        return (
                          <Draggable key={id} draggableId={String(id)} index={index}>
                            {(provided, snapshot) => (
                              <TableRow
                                hover
                                key={id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>}
                                <TableCellCustom
                                  className={classes.tableHeaderPriority}
                                  isDragOccurring={snapshot.isDragging}
                                >
                                  <span {...provided.dragHandleProps}>
                                    <MoveIcon />
                                  </span>{' '}
                                </TableCellCustom>
                                <TableCellCustom
                                  isDragOccurring={snapshot.isDragging}
                                >
                                  {media &&
                                  media.length > 0 &&
                                  media[0] !== null &&
                                  media[0].assetUrl ? (
                                    <img
                                      src={
                                        media[0].assetUrl.indexOf('http') === 0
                                          ? media[0].assetUrl
                                          : `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${media[0].assetUrl}`
                                      }
                                      alt={media[0].assetUrl}
                                      style={{
                                        width: '72.64px',
                                        height: '72.64px',
                                        margin: 'auto'
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={defaultImage}
                                      width="72.64px"
                                      height="72.64px"
                                      alt=""
                                    />
                                  )}
                                </TableCellCustom>
                                <TableCellCustom
                                  isDragOccurring={snapshot.isDragging}
                                  className={classes.tableProductColumn}
                                >
                                  <Text
                                    weight="medium"
                                    style={{ marginBottom: '-15px' }}
                                  >
                                    {name}
                                  </Text>
                                  {categories.map(c => {
                                    if (c) {
                                      return (
                                        <Text
                                          key={c.id}
                                          size="h6"
                                          weight="light"
                                          color="secondaryDark"
                                        >
                                          {c.name.toUpperCase()}
                                        </Text>
                                      );
                                    }
                                  })}
                                </TableCellCustom>
                                <TableCellCustom
                                  isDragOccurring={snapshot.isDragging}
                                  align="left"
                                >
                                  <Text weight="regular">
                                    {variants[0] && variants[0].unlimited
                                      ? 'Unlimited'
                                      : variants.reduce((a, b) => a + b.stock, 0)}
                                  </Text>
                                </TableCellCustom>
                                <TableCellCustom
                                  isDragOccurring={snapshot.isDragging}
                                  align="left"
                                >
                                  {variants.length > 0 && variants[0] ? (
                                    <>
                                      {variants.length > 1 ? (
                                        <Text>Multiple</Text>
                                      ) : (
                                        <Price color="dark">
                                          {variants[0].qpoints}
                                        </Price>
                                      )}
                                    </>
                                  ) : (
                                    <Price color="dark">{prices}</Price>
                                  )}
                                </TableCellCustom>
                                <TableCellCustom
                                  isDragOccurring={snapshot.isDragging}
                                >
                                  <Text>{active ? 'Visible' : 'Hidden'}</Text>
                                </TableCellCustom>
                                <TableCellCustom
                                  isDragOccurring={snapshot.isDragging}
                                  align="left"
                                >
                                  <IconButton
                                    size="small"
                                    style={{ marginRight: '20px' }}
                                    component={Link}
                                    to={`/marketing-shop/add-products/${id}`}
                                  >
                                    <CreateOutlinedIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => handleOpen(id)}
                                    size="small"
                                  >
                                    <DeleteOutlineOutlinedIcon />
                                  </IconButton>
                                </TableCellCustom>
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
        {loadingProduct &&
          (productList.length < postsPerPage ||
            productList.length >= countProducts) && (
            <Grid item xs={12}>
              <div className="wr_loading_product">
                <CustomLoading size={40} color="#00adef"></CustomLoading>
              </div>
            </Grid>
          )}
      </Grid>
      {productList.length < postsPerPage || productList.length >= countProducts ? (
        false
      ) : (
        <Grid
          container
          justify="center"
          style={{ marginTop: '50px', marginBottom: '50px' }}
        >
          <Grid item>
            <CustomButtom
              disabled={loadingProduct}
              color="secondary"
              onClick={viewMore2}
              className="RewardHistory_ViewMoreButton"
            >
              {loadingProduct ? <CustomCircularProgress2 size={16} /> : 'View More'}
            </CustomButtom>
          </Grid>
        </Grid>
      )}

      <AlertDeleteComponent
        title="Delete this product?"
        content="Once you do this, it can't be undone."
        handleClose={() => {
          handleClose();
        }}
        handleDelete={() => {
          handleDelete(selectedProduct);
        }}
        open={open}
      />
    </>
  );
}
