import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClaimManagement from './pages/ClaimManagement';

export default function AdminManagement() {
  return (
    <Switch>
      <Route
        exact
        path="/supportcenter/claim-management"
        component={ClaimManagement}
      />
    </Switch>
  );
}
