import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NewPage from '../../pages/news';
import FormAddNew from '../../pages/news/components/FormAddNew';
import FormEditNew from '../../pages/news/components/FormEditNew';

export default function News() {
  return(
    <Switch>
      <Route
        exact
        path="/admincontrols/news"
        component={NewPage}
      />
      <Route
        exact
        path="/admincontrols/news/add"
        component={FormAddNew}
      />
      <Route
        exact
        path="/admincontrols/news/:id"
        component={FormEditNew}
      />
    </Switch>
  )
}
