import React, { useState, useEffect, Component } from 'react';
import Text from '../../common/components/text';
import BackButton from '../../common/components/button/BackButton';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import AlertDeleteComponent from './../../common/components/AlertDeleteComponent';
import EditCategoryModal from './EditCategoryModal';
import {
  Paper,
  TextField,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  TableContainer
} from '@material-ui/core';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DoneIcon from '@material-ui/icons/Done';
import HeightIcon from '@material-ui/icons/Height';
import { notiSuccess, notiFailed } from './../../utils/helpers';
import CustomLoading from './../../common/components/CustomLoading';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class TableCellCustom extends Component {
  constructor(props) {
    super(props);
    this.ref = null;
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (!this.ref) {
      return null;
    }

    const isDragStarting = this.props.isDragOccurring && !prevProps.isDragOccurring;

    if (!isDragStarting) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    const snapshot = {
      width,
      height,
    };

    return snapshot;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragOccurring) {
      return;
    }

    if (ref.style.width == null) {
      return;
    }

    ref.style.removeProperty('height');
    ref.style.removeProperty('width');
  }

  setRef = ref => {
    this.ref = ref;
  };

  render() {
    return (
      <TableCell
        ref={this.setRef}
        style={{ color: `${this.props.isDragOccurring ? '#00adef' : ''}` }}
      >
        {this.props.children}
      </TableCell>
    );
  }
}

export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [changeCategory, setChangeCategory] = useState('');
  const [originalCategory, setOriginalCategory] = useState('');
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [selectedDeleteCategory, setSelectedDeleteCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpen = id => {
    setOpen(true);
    setSelectedDeleteCategory(id);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDeleteCategory(null);
  };

  const getCatergories = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/categories`);
      setCategories(data);
    } catch (err) {
      notiFailed(`There was a problem getting the promotions ${err.message}`);
    }
  };

  const submitNewCategory = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseUrl}/categories`, {
        name: newCategory
      });
      setCategories([...categories, { ...data, count: 0 }]);
      notiSuccess(`You have successfully create the category ${newCategory}`);
      setNewCategory('');
    } catch (err) {
      if (err.data && err.data.statusCode === 500) {
        notiFailed(err.data.message);
      } else {
        notiFailed(`There was a problem creating a new category ${err.message}`);
      }
      setNewCategory('');
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = async categoryId => {
    try {
      const { data } = await axios.delete(`${baseUrl}/categories/${categoryId}`);
      setOpen(false);
      getCatergories();
      notiSuccess(`You have successfully deleted the category.`);
    } catch (err) {
      setOpen(false);
      if (err.data && err.data.statusCode === 500) {
        notiFailed(err.data.message);
      } else {
        notiFailed(`There was a problem deleting the category.`);
      }
    }
  };

  const editCategory = (id, name) => {
    setSelectedProduct(id);
    setChangeCategory(name);
    setOriginalCategory(name);
  };

  const handleEditCategory = async () => {
    try {
      await axios.patch(`${baseUrl}/categories/${selectedProduct}`, {
        id: selectedProduct,
        name: changeCategory
      });
      getCatergories();
      setSelectedProduct(null);
      setChangeCategory('');
      setOriginalCategory('');
      setOpenEditCategoryModal(false);
      notiSuccess(`You have successfully edited the category.`);
    } catch (err) {
      notiFailed(`There was a problem editing the category.`);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const originalCategories = [...categories];
    const priority = [];
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    if (sourceIndex < destinationIndex) {
      for (let i = sourceIndex; i <= destinationIndex; i++) {
        priority.push(categories[i].priority);
      }
    } else {
      for (let i = destinationIndex; i <= sourceIndex; i++) {
        priority.push(categories[i].priority);
      }
    }

    const newCategories = reorder(
      categories,
      result.source.index,
      result.destination.index,
    );

    if (sourceIndex < destinationIndex) {
      let j = 0;
      for (let i = sourceIndex; i <= destinationIndex; i++) {
        newCategories[i].priority = priority[j];
        j++;
      }
    } else {
      let j = 0;
      for (let i = destinationIndex; i <= sourceIndex; i++) {
        newCategories[i].priority = priority[j];
        j++;
      }
    }

    try {
      setCategories(newCategories);
      await axios.patch(`${baseUrl}/categories/update/updatePriority`, {
        batchUpdate: newCategories
      });
    } catch (err) {
      setCategories(originalCategories);
      notiFailed(`There was a problem changing priority of the category.`);
    }
  };

  useEffect(() => {
    getCatergories();
  }, []);

  return (
    <>
      <BackButton component={Link} to="/marketing-shop/products">
        Products
      </BackButton>
      <Text size="h1">Categories</Text>

      <Paper elevation={0}>
        <Grid container style={{ maxWidth: '75vw', margin: 'auto' }}>
          <Text size="h2">Add a New Category</Text>
        </Grid>
        <Grid
          container
          style={{ maxWidth: '75vw', margin: 'auto', paddingBottom: '10px' }}
        >
          <Grid item md={10} xs={8}>
            <TextField
              fullWidth
              size="small"
              label="Category Title"
              variant="outlined"
              value={newCategory}
              onChange={e => setNewCategory(e.target.value)}
            />
          </Grid>
          <Grid item container justify="flex-end" md={2} xs={4}>
            <Button
              size="large"
              variant="outlined"
              style={{
                backgroundColor: '#002D56',
                color: '#ffffff',
                padding: '6px 5px'
              }}
              disabled={newCategory === '' || loading}
              onClick={submitNewCategory}
            >
              {loading ? <CustomLoading size={18} color="#fff" /> : '+ Add'}
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table
            style={{
              borderBottom: 'solid',
              borderWidth: '1px',
              borderColor: '#8C8C8C',
              maxWidth: '75vw',
              margin: 'auto'
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text size="h5" weight="light">
                    Priority
                  </Text>
                </TableCell>
                <TableCell>
                  <Text size="h5" weight="light">
                    Category
                  </Text>
                </TableCell>
                <TableCell>
                  <Text size="h5" weight="light">
                    # of items
                  </Text>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="table">
                {(provided, snapshot) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {categories.map((category, index) => (
                      <Draggable
                        key={category.id}
                        draggableId={String(category.id)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              position: 'relative',
                              background: `${snapshot.isDragging ? '#e5f3fb' : ''}`,
                              ...provided.draggableProps.style
                            }}
                          >
                            <TableCellCustom isDragOccurring={snapshot.isDragging}>
                              <span {...provided.dragHandleProps}>
                                <HeightIcon />
                              </span>
                            </TableCellCustom>
                            <TableCellCustom isDragOccurring={snapshot.isDragging}>
                              {selectedProduct === category.id ? (
                                <TextField
                                  autoFocus
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  value={changeCategory}
                                  onChange={e => setChangeCategory(e.target.value)}
                                  onBlur={() => {
                                    if (originalCategory !== changeCategory) {
                                      setOpenEditCategoryModal(true);
                                    } else {
                                      setSelectedProduct(null);
                                      setChangeCategory('');
                                      setOriginalCategory('');
                                    }
                                  }}
                                ></TextField>
                              ) : (
                                <Text size="h4" weight="medium">
                                  {category.name.toUpperCase()}
                                </Text>
                              )}
                            </TableCellCustom>
                            <TableCellCustom isDragOccurring={snapshot.isDragging}>
                              <Text size="h4" weight="medium">
                                {category.count}
                              </Text>
                            </TableCellCustom>
                            <TableCellCustom
                              align="right"
                              isDragOccurring={snapshot.isDragging}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: '20px',
                                  color: `${snapshot.isDragging ? '#00adef' : ''}`
                                }}
                              >
                                {selectedProduct === category.id ? (
                                  <DoneIcon />
                                ) : (
                                  <CreateOutlinedIcon
                                    onClick={() =>
                                      editCategory(category.id, category.name)
                                    }
                                  />
                                )}
                              </IconButton>
                              <IconButton
                                size="small"
                                style={{
                                  color: `${snapshot.isDragging ? '#00adef' : ''}`
                                }}
                              >
                                <DeleteOutlineOutlinedIcon
                                  onClick={() => handleOpen(category.id)}
                                />
                              </IconButton>
                            </TableCellCustom>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </Paper>

      <AlertDeleteComponent
        open={open}
        title="Delete this category?"
        content="Once you do this, it can't be undone."
        handleClose={() => {
          handleClose();
        }}
        handleDelete={() => {
          deleteCategory(selectedDeleteCategory);
        }}
      />

      <EditCategoryModal
        open={openEditCategoryModal}
        handleClose={() => {
          setOpenEditCategoryModal(false);
          setSelectedProduct(null);
          setChangeCategory('');
          setOriginalCategory('');
        }}
        handleEdit={handleEditCategory}
      />
    </>
  );
}
