import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, Paper, Grid } from '@material-ui/core';
import BackButton from '../../common/components/button/BackButton';
import { Link, useParams } from 'react-router-dom';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import { notiFailed } from './../../utils/helpers';
import _ from 'lodash';
import Moment from 'react-moment';
import MessageReview from './MessageReview';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import OpenImageIcon from '../../config/images/open_image_icon.svg'
import ImageModal from './ImageModal';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  // reviewInfoContainer: {
  //   width: '100%',
  //   margin: '70px auto 51px auto',
  // },
  reviewSectionsContainerDetailProject : {
    marginTop: '30px',
    // marginTop: 36,
    wordBreak: 'break-word',
    width: '100%',
    margin: 'auto',
  },
  reviewTitleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    paddingBottom: '5px',   
    alignItems: 'center',
    height: 70,
  },
  images: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    margin: '-15px 0 0 -15px',
    width: 'calc(100% - 20px)',
    '& > div': {
        margin: '15px 0 0 15px',
        position: 'relative'
    },
    '& > div:hover div[role=overlay]': {
        opacity: 1
    },
    [theme.breakpoints.down(700)]: {
        width: '100%'
    }
  },
  image: {
    height: '70px',
    width: '70px',
    borderRadius: '5px'
  },
  reviewSectionContainer: {
    '& h5': {
      fontFamily: 'Actual',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
    },
    [theme.breakpoints.down(1245)]: {
      gridTemplateColumns: '1fr'
    }
  },
  reviewSectionContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    minHeight: '50px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    gridColumn: 2,
    gridGap: '88px',
    paddingLeft: '20px',
    fontFamily: 'Actual',
    '& h6': {
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '15px',
      color: '#4F4F4F',
      margin: '15px 0'
    //   marginLeft: '15px'
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      color: '#000000'
    },
    [theme.breakpoints.down(1245)]: {
      gridColumn: 1,
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.down(700)]: {
        gridTemplateColumns: 'auto',
        gridGap: '5px',
        '& h6': {
            marginLeft: 0
        },
        '& p': {
            fontSize: '15px !important'
        },
        paddingTop: '10px',
        paddingBottom: '10px'
    }
  },
  container: {
    padding: '40px',
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 0,
    boxShadow: 'none',
    // marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  reviewNoteSection: {
    padding: '20px 25px',
    minHeight: '200px',
    background: '#F6F6F6',
    border: '1px solid #E6E6E6',
    boxSizing: 'border-box',
    fontSize: '15px'
  },
  overlay: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.5s ease',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '5px'
  },
}));

export default function ProjectDetail() {
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filesProject, setFilesProject] = useState([]);
  const [filesProof, setFilesProof] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [modalUrl, setModalUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const { errors, watch, setValue, getValues } = useForm({});

  const dataPro = watch();
  const moduleSerialList = watch('moduleSerialNumber');
  const essSerial = watch('essSerialNumber');
  const isSerialNumber = watch('isSerialNumber');
  const isEssSerialNumber = watch('isEssSerialNumber');
  const isManualSerialPanelNotMatchDb = watch('isManualSerialPanelNotMatchDb');
  const projectAddress = getValues();
  const panelQualified = watch('panelQualified');
  const panelNonQcells = watch('panelNonQcells');
  const batteryNonQcells = watch('batteryNonQcells');
  const batteryQualified = watch('batteryQualified');

  const numberOfModules = watch('numberOfModules');

  const moduleSerial = useMemo(() => {
    const uniqModuleSerialNumber = _.remove(
      _.uniqBy(moduleSerialList, 'productName'),
      p => p.productName
    );

    return uniqModuleSerialNumber;
  }, [moduleSerialList]);

  useEffect(() => {
    setValue('projectName', data.projectName);
    setValue('projectAddress', {
      label: data.projectAddress?.label,
      value: data.projectAddress?.value
    });
    setValue('projectAddress2', data.projectAddress2);
    setValue('projectCity', data.projectCity);
    setValue('projectState', data.projectState ? data.projectState : 'select');
    setValue('projectZipCode', data.projectZipCode);
    if (_.trim(_.get(data, 'projectImages'))) {
      setFilesProject(
        _.map(_.split(_.get(data, 'projectImages'), '|'), p => ({ url: p }))
      );
    }

    if (_.trim(_.get(data, 'proofOfOperateDate'))) {
      setFilesProof(
        _.map(_.split(_.get(data, 'proofOfOperateDate'), '|'), p => ({ url: p }))
      );
    }

    setValue('installationDate', data.installationDate);
    setValue('operateDate', data.operateDate);
    setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');

    /**
     * system information
     */

    setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
    const isManualSerialNumber = data.manualSerialNumber;
    setValue('isManualSerialNumber', !!isManualSerialNumber);
    const moduleSN = _.filter(
      _.get(data, 'serialNumbers'),
      s => s.type === 'modules'
    );
    setValue(
      'isManualSerialPanelNotMatchDb',
      _.get(data, 'isManualSerialPanelNotMatchDb', false)
    );
    setValue(
      'isManualSerialBatteryNotMatchDb',
      _.get(data, 'isManualSerialBatteryNotMatchDb', false)
    );
    if (moduleSN && moduleSN.length > 0) {
      setValue('moduleSerialNumber', moduleSN);
      setValue('isSerialNumber', true);
      if (_.get(data, 'isManualSerialPanelNotMatchDb', false)) {
        setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
        setValue(
          'modulePowerClass',
          data.modulePowerClass ? data.modulePowerClass : ''
        );
        setValue('moduleModel', data.moduleModel ? data.moduleModel : 'select');
        setValue(
          'numberOfModules',
          data.numberOfModules ? data.numberOfModules : ''
        );
        setValue('productName', data.productName);
        //setValue('volume', data.volume);
        setValue('numberOfModules', data.numberOfModules);
      }
    } else {
      setValue('moduleSerialNumber', []);
      setValue('isSerialNumber', false);
      setValue('moduleBrand', data.moduleBrand ? data.moduleBrand : 'select');
      setValue(
        'modulePowerClass',
        data.modulePowerClass ? data.modulePowerClass : ''
      );
      setValue(
        'moduleModel',
        data.moduleModel
          ? data.moduleModel
          : data.moduleBrand === 'Q CELLS'
          ? 'select'
          : ''
      );
      setValue('numberOfModules', data.numberOfModules ? data.numberOfModules : '');
      setValue('productName', data.productName);
      //setValue('volume', data.volume);
      setValue('numberOfModules', data.numberOfModules);
    }

    if (data.moduleBrand === 'Other') {
      setValue('nameOfModuleBrandPanelOther', data.nameOfModuleBrandPanelOther);
    }
    if (data.batteryBrand === 'Other') {
      setValue('nameOfModuleBrandBatteryOther', data.nameOfModuleBrandBatteryOther);
    }

    setValue('isEssSerialNumber', data.isEssSerialNumber);
    setValue('battery', !!data.battery);
    setValue('systemType', data.systemType ? data.systemType : 'select');
    setValue('batteryBrand', data.batteryBrand ? data.batteryBrand : 'select');
    setValue('batteryModel', data.batteryModel ? data.batteryModel : 'select');
    setValue('batteryEnergy', data.batteryEnergy ? data.batteryEnergy : 'select');
    setValue(
      'batteryInverterPower',
      data.batteryInverterPower ? data.batteryInverterPower : 'select'
    );

    const isSelectBatteryCapacity =
      ['AC', 'DC'].includes(data.systemType) && data.batteryBrand === 'Q CELLS';
    if (data.battery && isSelectBatteryCapacity) {
      setValue(
        'batteryCapacity1',
        data.batteryCapacity ? data.batteryCapacity : 'select'
      );
    }
    if (data.battery && !isSelectBatteryCapacity) {
      setValue('batteryCapacity2', data.batteryCapacity);
      setValue('batteryProductName1', data.batteryProductName);
    }
    const inverterSN = _.filter(
      _.get(data, 'serialNumbers'),
      s => s.type === 'inverter'
    );
    const isManualEssSerialNumber = data.manualInverter;
    setValue('isManualEssSerialNumber', !!isManualEssSerialNumber);
    if (
      data.battery &&
      ['DC', 'AC'].includes(data.systemType) &&
      data.batteryBrand === 'Q CELLS' &&
      inverterSN &&
      inverterSN.length > 0
    ) {
      setValue('essSerialNumber', inverterSN);
      setValue('isEssSerialNumber', true);
    } else {
      setValue('essSerialNumber', []);
      setValue('isEssSerialNumber', false);
    }

    if (!data.battery || (data.battery && data.systemType === 'select')) {
      if (data.inverterProductType) {
        setValue('inverterProductBrand', data.inverterProductType);
      }
      if (data.inverterProductName) {
        setValue('inverterProductName', data.inverterProductName);
      }
    }

    if (data.battery && data.systemType === 'AC') {
      if (data.pvProductType) {
        setValue('pvInverterProductBrand', data.pvProductType);
      }
      if (data.pvProductName) {
        setValue('pvInverterProductName', data.pvProductName);
      }
    }

    setValue(
      'pvInverterBrand',
      data.pvInverterBrand ? data.pvInverterBrand : 'select'
    );
    setValue(
      'pvInverterModel',
      data.pvInverterModel ? data.pvInverterModel : 'select'
    );
    setValue('rackingBrand', data.rackingBrand ? data.rackingBrand : 'select');
    setValue('rackingModel', data.rackingModel ? data.rackingModel : '');

    setValue(
      'purchasingChannel',
      data.purchasingChannel ? data.purchasingChannel : 'select'
    );
    if (data.purchasingChannel === 'distributor') {
      setValue(
        'nameOfPurchasingChannelDistributor',
        distributors.find(d => d.name === data.nameOfPurchasingChannel)
      );
    } else if (data.purchasingChannel === 'other') {
      setValue('nameOfPurchasingChannelOther', data.nameOfPurchasingChannel);
    }

    setValue('panelQualified', data.panelQualified);
    setValue('batteryQualified', data.batteryQualified);
    setValue('panelNonQcells', data.panelNonQcells);
    setValue('batteryNonQcells', data.batteryNonQcells);

    /**
     * define
     * countSubmit : 0,
     * countDraft : 0,
     *
     * */

    /**
     * case 1 : submited to claim countSubmit = 0 && countDraft = 0
     * case 2 : countiene complte countDraft > 0 @@ countSubmit = 0
     * case 3 : countSubmit > 0 && countDraft > 0
     *
     * */
    setValue('systemSize', data.systemSize);
    setValue('firstName', data.firstName);
    setValue('lastName', data.lastName);
    setValue('email', data.email);
    setValue('phoneNumber', data.phoneNumber);
    setValue('selectedContactNumber', data.isMobileNumber ? '2' : '1');
    setValue('note', data.note);
    setValue('createdAt', data.createdAt);
  }, [data, distributors, setValue]);

  useEffect(() => {
    getProject();
    getDistributor();
  }, []);

  const getProject = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseUrl}/projects/detail`, {
        params: { id }
      });
      setData(data);
    } catch (err) {
      notiFailed(`Something happened getting project`);
    } finally {
      setLoading(false);
    }
  };

  const getDistributor = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/distributors`);
      setDistributors(data);
    } catch (err) {
      console.log(err);
    }
  };

  const renderProjectName = () => {
    if (_.trim(data.projectName)) {
      return _.trim(data.projectName);
    }

    const firstName = data.firstName;
    const lastName = data.lastName;
    const city = data.projectCity;
    let systemSize = null;

    return _.join(_.remove([_.trim(`${firstName || ''} ${lastName || ''}`), systemSize, city], value => !!value), ', ');
  };

  const renderField = fieldName => {
    const fieldValue = data[fieldName];
    if (!!fieldValue && fieldValue !== 'select') {
      return fieldValue;
    }

    return null;
  };

  const renderBatteryModel = (model) => {
    return (
      <>{model === 'select' ? '' : model} <br/> {!data.batteryQualified && model !== 'select' && <MessageReview text={'*This product does not qualify for labor cost reimbursement'}/> }</>
    )
  }

  const renderAddress = (array = []) => {
    const newArray = _.remove(array, value => !!value && value !== 'select');
    return newArray.length > 0 ? _.join(newArray, ', ') : '';
  };

  const renderPurchasingChannel = purchasingChannelValue => {
    switch (purchasingChannelValue) {
    case 'qcells':
      return 'Q CELLS';
    case 'Q CELLS':
      return 'Q CELLS';
    case 'Distributor':
      return 'Distributor';
    case 'distributor':
      return 'Distributor';
    case 'other':
      return 'Other';
    case 'Other':
      return 'Other';
    default:
      return 'Q CELLS';
    }
  };

  const renderNameOfPurchasingChannel = () => {
    const purchaseChannel = data.purchasingChannel;
    switch (purchaseChannel) {
    case 'qcells':
      return 'Q CELLS';
    case 'Q CELLS':
      return 'Q CELLS';
    case 'distributor':
      return _.get(data, 'nameOfPurchasingChannel') + '' + (_.get(data, 'nameOfPurchasingChannel') === 'Other' ? `-${data.nameOfChanelOtherDistributor}` : '');
    case 'Distributor':
      return _.get(data, 'nameOfPurchasingChannel') + '' + (_.get(data, 'nameOfPurchasingChannel') === 'Other' ? `-${data.nameOfChanelOtherDistributor}` : '');  
    case 'other':
      return data.nameOfPurchasingChannel;
    case 'Other':
      return data.nameOfPurchasingChannel;
    default:
      return '';
    }
  }

  function renderSystemSize() {
    if(data.systemSize > 25) {
      return (
        <p>{`${data.systemSize} kW`} <br/> <MessageReview text={'*System size should be less than or equal to 25kW to qualify for labor reimbursement'}/></p>
      )
    }

    return <p>{data.systemSize} kW</p>
  }

  function renderModuleBrand(){
    if(!errors.moduleBrand) {
      return <p>{`${data.moduleBrand} ${data.moduleBrand === 'Other' ? `-${data.nameOfModuleBrandPanelOther}` : ''}`} <br/> {data.panelNonQcells && <MessageReview text={'*Project should use Q CELLS residential products only to qualify for labor reimbursement'}/>}</p>
    }
  }

  function renderModelPanel() {

    return(
      <>{(data?.moduleBrand !== 'Q CELLS') ? ((data.moduleModel === '' || data.moduleModel === null )? 'N/A'  : data.moduleModel ) : (moduleSerial.length === 0 ?  data.moduleModel : _.get(moduleSerial, '[0].productName', data.moduleModel))} <br/> {!data.panelQualified && <MessageReview text={'*This product does not qualify for labor cost reimbursement'}/>}</>
    )
  }

  function renderBatteryBrand() {
    if(!errors.batteryBrand) {

      return <p>{`${data.batteryBrand} ${data.batteryBrand === 'Other' ? `-${data.nameOfModuleBrandBatteryOther}` : ''}`} <br/> {data.batteryNonQcells && <MessageReview text={'*Project should use Q CELLS residential products only to qualify for labor reimbursement'}/>}</p>

    }
  }

  function renderOperaDateError() {
    const perateDateI = moment(_.get(data, 'operateDate')).add(3, 'M').format('YYYY-MM-DD');
    const dateNow = moment(_.get(data, 'createdAt')).format('YYYY-MM-DD')
    const isOpe = moment(perateDateI).isSameOrAfter(dateNow)
    return (
      <>
        <Moment format="L">{data.operateDate}</Moment>
        <br/>
        {!isOpe && <MessageReview text={'*This project will not qualify for labor reimbursement as the PTO date is not within the past 3 months'}/>}
      </>
    )
  }

  // const renderProductType = () => {
  //   switch (_.get(data, 'moduleProductType')) {
  //     case 'qcells':
  //       return 'Q CELLS';
  //     case 'other':
  //       return 'Other';
  //     default:
  //       return 'Q CELLS';
  //   }
  // };

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 30, marginTop: 20 }}
      >
        <BackButton component={Link} to="/projects/projects-management">
          List
        </BackButton>
      </Grid>
      <Paper className={classes.container}>
        <div className={classes.reviewInfoContainer}>
          <div className={classes.reviewSectionsContainerDetailProject} style={{marginTop: 0}}>
            <div
              className={classNames(
                [classes.reviewSectionContainer],
                [classes.reviewTitleSection]
              )}
            >
              <h5>Project Information</h5>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Project Name</h6>
                <p>{renderProjectName()}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Project Address</h6>
                <p>
                  {renderAddress([
                    _.get(data, 'projectAddress'),
                    _.get(data, 'projectAddress2'),
                    _.get(data, 'projectCity'),
                    _.get(data, 'projectState'),
                    _.get(data, 'projectZipCode')
                  ])}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div
                style={{
                  paddingTop: '13px',
                  paddingBottom: '13px'
                }}
                className={classes.reviewSectionContent}
              >
                <h6>Project Picture</h6>
                <div className={classes.images}>
                  {filesProject &&
                    filesProject?.map(p => (
                      <div key={p.url}>
                        <img src={p.url} className={classes.image} />
                        <div className={classes.overlay} role="overlay">
                          <img
                            src={OpenImageIcon}
                            onClick={() => {
                              setModalUrl(p.url);
                              setOpenModal(true);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Installation Date</h6>
                <p>
                  {data.installationDate && (
                    <Moment format="L">{data.installationDate}</Moment>
                  )}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Permission-to-Operate Date</h6>
                <p>{data.operateDate && renderOperaDateError()}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div
                style={{
                  paddingTop: '13px',
                  paddingBottom: '13px'
                }}
                className={classes.reviewSectionContent}
              >
                <h6>Proof of Permission-to-Operate Date</h6>
                <div className={classes.images}>
                  {filesProof?.length > 0 &&
                    filesProof?.map(p => (
                      <div key={p.url}>
                        <img src={p.url} className={classes.image} />
                        <div className={classes.overlay} role="overlay">
                          <img
                            src={OpenImageIcon}
                            onClick={() => {
                              setModalUrl(p.url);
                              setOpenModal(true);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.reviewSectionsContainerDetailProject}>
            <div
              className={classNames(
                [classes.reviewSectionContainer],
                [classes.reviewTitleSection]
              )}
            >
              <h5>System Information</h5>
            </div>
            <div className={classes.reviewSectionContent} style={{ paddingLeft: 0 }}>
              <p>Panel</p>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Brand</h6>
                {renderModuleBrand()}
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Power Class (W)</h6>
                <p>
                  {data?.moduleBrand !== 'Q CELLS'
                    ? data.modulePowerClass
                    : !isSerialNumber
                    ? data.modulePowerClass
                    : _.get(moduleSerial, '[0].volume', data.modulePowerClass)}{' '}
                  {data.modulePowerClass ? 'W' : ''}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Model</h6>
                <p>{renderModelPanel()}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Number of Modules</h6>
                <p>
                  {data?.moduleBrand !== 'Q CELLS'
                    ? data.numberOfModules
                    : isManualSerialPanelNotMatchDb
                    ? data.numberOfModules
                    : isSerialNumber
                    ? moduleSerialList?.length
                    : data.numberOfModules}{' '}
                  {data.numberOfModules ? 'pcs' : ''}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>System Size </h6>
                {renderSystemSize()}
              </div>
            </div>
            <div className={classes.reviewSectionContent} style={{ paddingLeft: 0 }}>
              <p>Battery</p>
              <p style={{ padding: 10 }}>{data?.battery ? 'Yes' : 'No'}</p>
            </div>
            {data?.battery && (
              <>
                <div className={classes.reviewSectionContainer}>
                  <div className={classes.reviewSectionContent}>
                    <h6>System Type</h6>
                    <p>{renderField('systemType')}</p>
                  </div>
                </div>
                <div className={classes.reviewSectionContainer}>
                  <div className={classes.reviewSectionContent}>
                    <h6>Brand</h6>
                    {renderBatteryBrand()}
                  </div>
                </div>

                <div className={classes.reviewSectionContainer}>
                  <div className={classes.reviewSectionContent}>
                    <h6>Model</h6>
                    <p>
                      {renderBatteryModel(
                        data?.batteryBrand === 'Q CELLS'
                          ? isEssSerialNumber &&
                            !data.isManualSerialBatteryNotMatchDb
                            ? _.get(essSerial, '[0].productName')
                            : data.batteryModel
                          : data.batteryModel
                      )}
                    </p>
                  </div>
                </div>
                {['select', 'Q CELLS'].includes(data.batteryBrand) && (
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Battery Inverter Power(kW)</h6>
                      <p>
                        {data?.batteryBrand === 'Q CELLS'
                          ? `${
                              isEssSerialNumber &&
                              !data.isManualSerialBatteryNotMatchDb
                                ? _.get(essSerial, '[0].volume') / 1000
                                : data.batteryInverterPower
                            } kW`
                          : ''}
                      </p>
                    </div>
                  </div>
                )}
                {['select', 'Q CELLS'].includes(data.batteryBrand) && (
                  <div className={classes.reviewSectionContainer}>
                    <div className={classes.reviewSectionContent}>
                      <h6>Battery Energy(kWh)</h6>
                      <p>
                        {data?.batteryBrand === 'Q CELLS' ? `${data.batteryEnergy} kWh` : ''}
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className={classes.reviewSectionContent} style={{ paddingLeft: 0 }}>
              <p>PV Inverter</p>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Brand</h6>
                <p>
                  {data.pvInverterBrand === 'select' ? '' : data.pvInverterBrand}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Model</h6>
                <p>
                  {data.pvInverterModel === 'select' ? '' : data.pvInverterModel}
                </p>
              </div>
            </div>
            <div className={classes.reviewSectionContent} style={{ paddingLeft: 0 }}>
              <p>Racking</p>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Brand</h6>
                <p>{data.rackingBrand === 'select' ? '' : data.rackingBrand}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Model</h6>
                <p>{data.rackingModel === '' ? '' : data.rackingModel}</p>
              </div>
            </div>
          </div>
          <div className={classes.reviewSectionsContainerDetailProject}>
            <div
              className={classNames(
                [classes.reviewSectionContainer],
                [classes.reviewTitleSection]
              )}
            >
              <h5>Purchasing Information</h5>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Purchasing Channel</h6>
                <p>{renderPurchasingChannel(data.purchasingChannel)}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Name of Purchasing Channel</h6>
                <p>{renderNameOfPurchasingChannel()}</p>
                {/* <p>{data.nameOfPurchasingChannel}</p> */}
              </div>
            </div>
          </div>

          <div className={classes.reviewSectionsContainerDetailProject}>
            <div
              className={classNames(
                [classes.reviewSectionContainer],
                [classes.reviewTitleSection]
              )}
            >
              <h5>Project Owner Information</h5>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Project Owner</h6>
                <p>{`${data.firstName} ${data.lastName}`}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>E-mail</h6>
                <p>{data.email}</p>
              </div>
            </div>
            <div className={classes.reviewSectionContainer}>
              <div className={classes.reviewSectionContent}>
                <h6>Contact Number</h6>
                <p>{data.phoneNumber}</p>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              [classes.reviewSectionsContainerDetailProject],
              [classes.reviewNoteSection]
            )}
          >
            <span style={{ fontWeight: '500' }}>Notes: </span> {data.note}
          </div>
          <ImageModal
            open={openModal}
            url={modalUrl}
            name={modalUrl ? modalUrl.substring(modalUrl.lastIndexOf('/') + 1) : ''}
            handleClose={() => {
              setOpenModal(false);
              setModalUrl('');
            }}
          />
        </div>
      </Paper>
    </>
  );
}
