import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from './../../common/notfound';
import Projects from './Projects';
import ProjectDetails from './ProjectDetails';

export default function ProjectsRoute() {
    return (
      <Switch>
        <Route exact path="/projects/projects-management" component={Projects} />
        <Route exact path="/projects/:id" component={ProjectDetails} />
        <Route component={NotFound} />
      </Switch>
    );
}