import axios from 'axios';
const axiosInstance = axios;
axiosInstance.interceptors.request.use(request => {
  const user = JSON.parse(localStorage.getItem('qpartner_user'));
  if (user) {
    const { token } = user;
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  ({ response }) => {
    if (response.status === 401) {
      localStorage.removeItem('qpartner_user');
      if(window.location.pathname !== '/'){
        window.location.href = '/';
      }
    }
    if (response.status === 422) {
      return Promise.reject(response);
    }
    if (response.status === 500) {
      return Promise.reject(response);
    }
    return response;
  }
);

export default axiosInstance;
