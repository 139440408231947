import React from 'react';
import { Modal, Paper, makeStyles } from '@material-ui/core';
import ModalCloseIcon from '../../config/images/modal_close_icon.svg';

const useStyles = makeStyles(theme => ({
    modal: {
        width: '610px',
        top: '50px !important',
        margin: 'auto',
        "& .MuiPaper-root": {
            overflowX: 'hidden'
        },
        "& .MuiPaper-rounded": {
            borderRadius: 0
        },
        '& span': {
            position: 'absolute',
            right: '15px',
            top: '15px'
        },
        '& span:hover': {
            cursor: 'pointer'
        }
    },
    modalContent: {
        position: 'relative',
        margin: '22px 30px 30px 30px',
        '& p': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '15px',
            lineHeight: '19px',
            color: '#000000',
            marginBottom: '20px'
        },
        '& div img': {
            width: '100%',
            height: 'auto'
        }
    }
}));

export default function ImageModal({ open, name, url, handleClose }) {
    const classes = useStyles();

    return (
        <Modal onClose={handleClose} open={open} className={classes.modal}>
            <Paper>
                <span onClick={handleClose}>
                    <img src={ModalCloseIcon} />
                </span>
                <div className={classes.modalContent}>
                    <p>{name}</p>
                    <div>
                        <img src={url} />
                    </div>
                </div>
            </Paper>
        </Modal>
    )
}
