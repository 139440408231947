import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

const StyledButton = styled(ButtonBase)(
  ({ theme: { colors } }, color) => `
  && {
    font-size: ${({ theme, size }) => theme.fontSizes[size]};
    color: ${color === 'primary' ? colors.primaryLight : colors.secondaryDark};
    text-decoration: underline;
    font-family: 'Actual';

    &:hover {
      color: ${color === 'primary' ? colors.secondaryDark : colors.primaryLight};
    }
  }
`
);

/**
 * @name TextButton
 * @description A plain button with just text
 * @param {node} children The content inside the button. ie "View More"
 * @implements {ButtonBase}
 */
const TextButton = ({ children, ...props }) => {
  return (
    <StyledButton disableRipple disableTouchRipple {...props}>
      {children}
    </StyledButton>
  );
};

TextButton.propTypes = {
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node.isRequired,
  ...ButtonBase.propTypes
};

export default TextButton;
