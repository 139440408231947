import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@material-ui/core';
import Button from '../../common/components/button';
import CustomButton from '../../common/components/button';
import Alert from '../../routes/rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Text from '../../common/components/text';
import CustomLoading from '../../common/components/CustomLoading';
import * as yup from 'yup';
import { useFormik } from 'formik';
import BackButton from '../../common/components/button/BackButton';
import { Link } from 'react-router-dom';
import { adminService } from '../../services';
import { notiFailed, notiSuccess } from '../../utils/helpers';
import {useHistory, useParams} from 'react-router-dom';
import _ from 'lodash';
import {useProfileAdminDispatch, getProfile} from '../../contexts/profile';

const useStyles = makeStyles(() => ({
  container: {
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  tableHeader: {
    background: '#f4f5f7',
    fontWeight: '500',
    fontSize: '15px'
  },
  table: {
    borderTop: '1px solid #e4e4e4',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  noteTableHeader: {
    textAlign: 'center',
    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px'
  },
  noteTable: {
    borderTop: '1px solid #e4e4e4',
    marginTop: '10px',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  emptyNoteList: {
    '& td': {
      padding: '81px 15px'
    }
  },
  emailText: {
    margin: '8px 12px 8px 0'
  },
  multiSelect: {
    '& .select__control': {
      borderRadius: 0,
      padding: '4px'
    },
    '& .select__multi-value__remove:hover': {
      backgroundColor: '#00adef',
      color: '#fff'
    }
  },
  w9ImageInput: {
    display: 'none'
  },
  w9ImageBtn: {
    border: '1px solid gray',
    height: '56px',
    marginLeft: '10px'
  },
  textArea: {
    width: '100%',
    padding: '5px',
    fontSize: '14px',
    fontFamily: 'Actual'
  },
  textAreaError : {
    width: '100%',
    padding: '5px',
    borderColor: 'red'
  },
  newLetterText: {
    lineHeight: '40px',
    marginTop: 0,
    marginBottom: 0
  },
  newLetterRadio: {
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#019ee3'
    }
  },
  textEmail : {
    borderColor: 'red'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textConfirm : {
    fontSize: 36
  },
  textInput : {
    fontSize: 14
  },
  w9Download: {
    display: 'inline-block',
    position: 'relative',
    transition: 'color 0.4s',
    paddingLeft: '20px',
    // background: `url(${DownloadIcon}) left 1px no-repeat`,
    '&:after': {
      position: 'absolute',
      left: '19px',
      bottom: 0,
      width: 0,
      height: '1px',
      background: '#019ee3',
      content: '""',
      transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#019ee3'
      // background: `url(${DownloadIconOn}) left 1px no-repeat`,
    },
    '&:hover:after': {
      width: 'calc(100% - 20px)'
    }
  },
  helperText: {
    marginTop: '7px', 
    marginBottom: 0, 
    fontSize: '12px'
  }
}));



const validateSchemaFormEmail = yup.object({
  email : yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  firstName : yup.string().required('firstName is required'),
  lastName : yup.string().required('lastName is required'),
  password : yup.string().min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/^(?=.*([a-z]|[A-Z]))(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Password must be at least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm password is required'),
  textNumber : yup.number().required('Number is required')
})




function EditProfile() {

  const dispatch = useProfileAdminDispatch();

  const [initValue, setInitValue] = useState({
    role: 'Admin',
    email : '',
    firstName : '',
    lastName: '',
    mobileNumber : '',
    officeNumber : '',
    disabled : false,
    confirmPassword: '',
    textNumber : '',
    typeNumber : 1
  })

  const classes = useStyles();
  const history = useHistory();
  const {id} = useParams();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [infoUser, setInfoUser] = useState({});
  const [openFormUpdate, setOpenFormUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const userLoggedIn = JSON.parse(localStorage.getItem('qpartner_user'));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValue,
    validationSchema: validateSchemaFormEmail,
    onSubmit:  (values, {resetForm}) => {
      setOpenFormUpdate(true);
    }
  });




  useEffect(() => {
    getInfoDetailUser()
  }, [id])

  async function handleUpdateUser() {

    let makePassWord = {}

    if(formik.values.password !== "") {
      makePassWord = {
        password : formik.values.password
      }
    }

    setLoadingUpdate(true)
    const makeFormUser = {
      firstName : formik.values.firstName.trim(),
      lastName : formik.values.lastName.trim(),
      mobileNumber : formik.values.typeNumber === 1 ? "" : formik.values.mobileNumber,
      ...makePassWord,
      disabled : formik.values.disabled,
      role : formik.values.role,
      fullName : `${formik.values.firstName} ${formik.values.lastName}`.trim()
    }

    const makeFormBranch = {
      officeNumber : formik.values.typeNumber === 1 ? formik.values.officeNumber : ""
    }

    await adminService.updateUserAdmin(infoUser.id, makeFormUser, makeFormBranch).then(() => {
      notiSuccess('Update user success')
      getProfile(dispatch);
      history.push('/admincontrols/management')
    }).catch(() => {
      notiFailed('Update Failed')
    })

    setLoadingUpdate(false);
  }

  async function getInfoDetailUser() {
    const user = await adminService.getUserDetail(_.get(userLoggedIn, 'user.id', ''));
    setInfoUser(user.data);
    setInitValue({...user.data , textNumber : user.data.officeNumber  ? user.data.officeNumber : user.data.mobileNumber, typeNumber : user.data.officeNumber ? 1 : 2});
  }


  async function handleResetPassword() {
    await adminService.resetPassword(infoUser.id).then(() => {
      notiSuccess('Reset password success')
    }).catch(() => {
      notiFailed('Reset password Failed')
    })
  }

  async function handleDeleteUser() {
    setLoading(true)
    await adminService.deleteUser(infoUser.id).then(() => {
      notiSuccess('Deleted.');
      history.push('/admincontrols/management')
    }).catch(() => {
      notiFailed('Delete Failed');
    })

    setLoading(false);
  }



  return(
    <>
      <Grid container>
        <Grid
          style={{paddingRight: 10, marginTop: 20}}
          container
          direction="row"
          alignItems="center"
          xs={12} md={12} lg={12} sm={12}
        >
          <sm style={{color : 'red'}}>*</sm> Required
        </Grid>

      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <TableContainer style={{ marginTop: 20, marginBottom: 20 }} component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>ID(E-mail)<sm style={{color : 'red'}}>*</sm></TableCell>
              <TableCell align='left' style={{ minWidth: '300px' }}>
                {formik.values.email}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Password</TableCell>
              <TableCell align='left' style={{ minWidth: '300px' }}>
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    }
                  }}
                  name="password"
                  className={classes.textEmail}
                  value={formik.values.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  onChange={e => {
                    if (!formik.touched.password) {
                      formik.setFieldTouched('password');
                    }
                    formik.handleChange(e);
                  }}
                  style={{ minWidth: 300 }}
                  variant="outlined"
                  type="password"
                  size="small"
                />
                 {!formik.values.password ?
                    <Text size="small" className={classes.helperText}>
                      At least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)
                    </Text>
                    : 
                    formik.errors.password && 
                    <Text size="small" className={classes.helperText}>
                      Password must be at least 8 characters in combination of letters, numbers, and symbols (0-9, A-Z, at least one symbol)
                    </Text>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Password Confirm</TableCell>
              <TableCell align='left' style={{ minWidth: '300px' }}>
                <TextField
                  id="confirmPassword"
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    }
                  }}
                  name="confirmPassword"
                  type="password"
                  className={classes.textEmail}
                  value={formik.values.confirmPassword}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  onChange={e => {
                    if (!formik.touched.confirmPassword) {
                      formik.setFieldTouched('confirmPassword');
                    }
                    formik.handleChange(e);
                  }}
                  style={{ minWidth: 300 }}
                  variant="outlined"
                  size="small"
                />
                {formik.errors.confirmPassword && formik.touched.confirmPassword && 
                    <Text size="small" className={classes.helperText}>
                      Passwords do not match
                    </Text>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Name <sm style={{color : 'red'}}>*</sm></TableCell>
              <TableCell align='left' colSpan={3}>
                <TextField
                  id="firstName"
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    }
                  }}
                  name="firstName"
                  placeholder="First Name"
                  className={classes.textEmail}
                  value={formik.values.firstName}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  onChange={formik.handleChange}
                  style={{ minWidth: 300 }}
                  variant="outlined"
                  size="small"
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
                <TextField
                  id="lastName"
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    }
                  }}

                  name="lastName"
                  placeholder="Last Name"
                  className={classes.textEmail}
                  value={formik.values.lastName}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  onChange={formik.handleChange}
                  style={{ minWidth: 300, marginLeft: 20 }}
                  variant="outlined"
                  size="small"
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Main Contact Number<sm style={{color : 'red'}}>*</sm></TableCell>
              <TableCell align='left' colSpan={3}>
                <Grid container xs={12} spacing={2} className={classes.newLetterRadio}>
                  <Grid item>
                    <TextField
                      id="textNumber"
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        },

                      }}
                      name="textNumber"
                      type="number"
                      className={classes.textEmail}
                      value={formik.values.textNumber}
                      error={formik.touched.textNumber && Boolean(formik.errors.textNumber)}
                      onChange={formik.handleChange}
                      style={{ minWidth: 300 }}
                      variant="outlined"
                      placeholder="ex) 070-1234-1234"
                      size="small"
                      helperText={formik.touched.textNumber && formik.errors.textNumber}
                    />
                  </Grid>
                  <Grid item>
                    <RadioGroup id="typeNumber" value={parseInt(formik.values.typeNumber)} onChange={formik.handleChange} row aria-label="typeNumber" name="typeNumber" defaultValue={1}>
                      <FormControlLabel classes={{label : classes.textInput}} value={1} control={<Radio color="primary"/>} label="Office Number"/>
                      <FormControlLabel classes={{label : classes.textInput}} value={2} control={<Radio color="primary"/>} label="Mobile Number"/>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
        <Grid style={{marginBottom: 20}} container  alignItems={'center'} justify={'center'}>
          <Button type="submit" style={{marginRight: 5}}>Confirm</Button>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title-new"
          aria-describedby="transition-modal-description-new"
          className={classes.modal}
          open={openFormUpdate}
          onClose={() => setOpenFormUpdate(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={openFormUpdate}>
            <Alert>
              <Grid container justify="flex-end">
                <IconButton>
                  <CloseOutlinedIcon onClick={() => setOpenFormUpdate(false)} fontSize="large" style={{ color: '#8C8C8C' }} />
                </IconButton>
              </Grid>
              <Text className={classes.textConfirm}>
                Do you want to update ?
              </Text>
              <Grid container justify="center" spacing={2} style={{ marginBottom: '20px' }}>
                <Grid item>
                  <CustomButton onClick={() => setOpenFormUpdate(false)} color="secondary">
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid item>
                  <Button onClick={handleUpdateUser}  type="submit">{loadingUpdate ?  <CustomLoading size={18}/> : 'Confirm'}</Button>
                </Grid>
              </Grid>
            </Alert>
          </Fade>
        </Modal>


        <Modal
          aria-labelledby="transition-modal-title-new"
          aria-describedby="transition-modal-description-new"
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <Alert>
              <Grid container justify="flex-end">
                <IconButton>
                  <CloseOutlinedIcon onClick={() => setOpen(false)} fontSize="large" style={{ color: '#8C8C8C' }} />
                </IconButton>
              </Grid>
              <Text className={classes.textConfirm}>
                Do you want to delete ?
              </Text>
              <Grid container justify="center" spacing={2} style={{ marginBottom: '20px' }}>
                <Grid item>
                  <CustomButton onClick={()=> setOpen(false)} color="secondary">
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid item>
                  <Button onClick={handleDeleteUser}  type="submit">{loading ?  <CustomLoading size={18}/> : 'Confirm'}</Button>
                </Grid>
              </Grid>
            </Alert>
          </Fade>
        </Modal>

      </form>
    </>
  )
}

export default EditProfile;
