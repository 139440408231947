import React, { useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import Text from '../../../../../common/components/text';
import CustomButton from '../../../../../common/components/button';
import BackButton from '../../../../../common/components/button/BackButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { Grid, Paper, TextField, MenuItem, Button, Select, Backdrop, Fade, IconButton, Modal } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../../../../config';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { notiFailed, notiSuccess } from '../../../../../utils/helpers';
import { dataHubService } from '../../../../../services';
import Alert from '../../../../rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

const config = {
  readonly: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-start',
    '& > div': {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      '& > div': {
        padding: theme.spacing(1, 0)
      }
    },
    '& > div.stickyTopbar': {
      padding: '50px 57px 10px 87px'
    },
    '& > div.pageContent': {
      paddingLeft: '82px',
      paddingRight: '57px'
    }
  },
  container: {
    position: 'relative',
    width: '76px',
    height: '75px',
    '&:hover div[role=overlay]': {
      opacity: 1
    }
  },
  buttonItem: {
    height: 40,
    margin: 5
  },
  pageTitle: {
    lineHeight: 0
  },
  stickyTopbar: {
    position: 'sticky',
    top: '77px',
    zIndex: '999',
    marginBottom: '30px',
    backgroundColor: '#fff'
  },
  button: {
    height: 56,
    border: '1px solid gray',
    marginLeft: 10
  },
  input1: {
    display: 'none'
  },
  input2: {
    display: 'none'
  },
  errorMsg: {
    color: '#f44336',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em'
  },
  requred: {
    color: 'red'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textDel: {
    fontSize: 36
  },
  uploadedContentImageDownload: {
    marginTop: 0,
    marginBottom: 0,
    boxSizing: 'border-box',
    height: '26px',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: '1px solid'
    }
  },
  uploadedContentImage: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '10px'
  },
}));

const CustomCircularProgress = withStyles(theme => ({
  root: {
    '&.MuiCircularProgress-colorPrimary': {
      color: '#FFFFFF'
    }
  }
}))(CircularProgress);

const schema = yup.object().shape({
  type: yup.number().required('Category is required'),
  title: yup.string().required('Title is required'),
  content: yup.string().required('Content is required'),
  language: yup.string().required('Language is required')
});

export default function FormEditDataHub() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [infoDataHub, setInfoDataHub] = useState({});
  const [categories, setCategories] = useState([]);
  const [attachOne, setAttachOne] = useState('');
  const [nameThumb, setNameThumb] = useState('');
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, errors, control, setValue, getValues, clearErrors } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      type: 1,
      language : 'en'
    }
  });


  const handleDiscard = () => history.push('/admincontrols/data-hub');

  const handleSave = async () => {
    // const attachment = getValues('attachment');
    // if (attachment.length === 0) {
    //   return notiFailed('attachment file is required');
    // }

    setOpen(true);

  };

  async function updateInfoDataHub() {
    const thumbnail = getValues('thumbnail');
    const title = getValues('title');
    const content = getValues('content');
    const type = getValues('type');
    const language = getValues('language');
    const attachment = getValues('attachment');

    const formData = new FormData();

    formData.append('categoryId', type);
    formData.append('title', title);
    formData.append('content', content);
    formData.append('language', language);
    formData.append('id', id);
    thumbnail.length !== 0 && formData.append('image', thumbnail[0]);
    attachment.length !== 0 && formData.append('attachment', attachment[0]);

    setLoading(true);

    await dataHubService.updateDataHub(formData).then(() => {
      notiSuccess('Update successfully.');
      history.push('/admincontrols/data-hub');
    }).catch(() => {
      notiFailed('Update Data Hub Failed');
    });
    setLoading(false);
    setOpen(false);
  }

  const getCatergories = async () => {
    const { data } = await axios.get(`${baseUrl}/data-hub/categories`);
    setCategories(data);
  };

  function pickAttachOne(event) {
    const { files } = event.target;
    setAttachOne(files[0].name);
  }

  function handlePickThumbNail(event) {
    const { files } = event.target;
    setNameThumb(files[0].name);
  }

  async function handleDownloadUploadContentImage(linkImage) {
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common["Authorization"];
      const { data } = await instance.get(linkImage, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', linkImage.substr(linkImage.lastIndexOf('/') + 1));
      // document.body.appendChild(link);
      // document.body.removeChild(link);
      link.click();
    } catch (err) {
      notiFailed(`There was a problem download upload content image ${err.message}`);
    }
  }


  const onSubmitInvalid = () => {
    const title = getValues('title');
    const type = getValues('type');
    const content = getValues('content');
    const thumbnail = getValues('thumbnail');


    if (!type) {
      return notiFailed(`Category can't be blank`);
    }

    if (_.trim(title) === '') {
      return notiFailed(`Title can't be blank`);

    }

    if (_.trim(content) === '') {
      return notiFailed(`Content can't be blank`);
    }

    if (thumbnail.length === 0) {
      return notiFailed('Thumbnail is required');
    }
  };

  async function getInfoDetail() {
    const response = await dataHubService.getDetailDataHub(id);

    if(response && response.data) {
      setInfoDataHub(response.data);
      setValue('title', response.data?.title);
      setValue('content', response.data?.content);
      setValue('language', response.data?.language);
      setValue('type', response.data?.categoryId);
      setNameThumb(response.data?.image?.assetUrl)
      setAttachOne(response.data?.attachment?.assetUrl)
    }

  }

  useEffect(() => {
    getInfoDetail();
  },[id])

  useEffect(() => {
    getCatergories();
  }, []);



  return (
    <>
      <Grid container className={classes.root} style={{ marginBottom: '40px' }}>
        <Grid
          container
          item
          xs={12}
          className={`${classes.stickyTopbar} stickyTopbar`}
        >
          <BackButton component={Link} to="/admincontrols/data-hub">
            List
          </BackButton>
          <Grid container justify="space-between" alignItems="center">
            <Text size="h2" className={classes.pageTitle}>{id ? 'Edit Item' : 'Add Item'}</Text>

            <Grid item>
              <CustomButton
                minWidth="8rem"
                color="secondary"
                onClick={handleDiscard}
                className={classes.buttonItem}
              >
                Cancel
              </CustomButton>
              <CustomButton
                minWidth="8rem"
                onClick={handleSubmit(handleSave, onSubmitInvalid)}
                disabled={loading}
                className={classes.buttonItem}
              >
                Update
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={`${classes.root} pageContent`}>
        <Grid container item md={8} xs={12}>
          <Paper style={{ width: '100%' }}>
            <Grid
              container
              style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
            >

              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <Text
                  weight="medium"
                  style={{ color: '#8c8c8c', marginBottom: '1px' }}
                >
                  Title <em className={classes.requred}>*</em>
                </Text>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Title"
                  inputRef={register}
                  name="title"
                  error={errors.title}
                  helperText={errors.title ? 'Title can\'t be blank' : ''}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <Text
                  weight="medium"
                  style={{ color: '#8c8c8c', marginBottom: '1px' }}
                >
                  Content
                  <em className={classes.requred}>*</em>
                </Text>
                <Controller
                  name="content"
                  control={control}
                  render={(
                    { onChange, onBlur, value, name, ref },
                    { invalid, isTouched, isDirty }
                  ) => (
                    <JoditEditor
                      config={config}
                      tabIndex={1}
                      onChange={value => {
                        if (value !== '') {
                          clearErrors('content');
                        }
                        onChange(value);
                      }}
                      ref={register}
                      onBlur={onBlur}
                      value={value}
                      style={{ border: '1px solid red !important' }}
                    />
                  )
                  }
                />
                {errors.content && <p className={classes.errorMsg}>Content can't be blank</p>}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item md={4} xs={12} style={{ height: '360px' }}>
          <Grid container item xs={12} className={classes.paddingBottomTwoSpacing}>
            <Paper style={{ width: '100%' }}>
              <Grid container style={{ width: '90%', margin: 'auto' }}>
                <Text weight="medium">Category <em className={classes.requred}>*</em></Text>
                <Grid item xs={12}>
                  <Controller
                    name="type"
                    control={control}
                    as={
                      <Select
                        select
                        fullWidth
                        variant="outlined"
                        error={errors.type}
                        value={1}
                      >
                        {categories.map(ft => (
                          <MenuItem key={ft.id} value={ft.id}>{ft.name}</MenuItem>
                        ))}
                      </Select>
                    }
                  />
                  {errors.type && <p className={classes.errorMsg}>Category can't be blank</p>}
                </Grid>
                <Text weight="medium">Language <em className={classes.requred}>*</em></Text>
                <Grid item xs={12}>
                  <Controller
                    name="language"
                    control={control}
                    as={
                      <Select
                        select
                        fullWidth
                        variant="outlined"
                        error={errors.language}
                      >
                        <MenuItem value={'en'}>EN</MenuItem>
                      </Select>
                    }
                  />
                  {errors.language && <p className={classes.errorMsg}>Language can't be blank</p>}
                </Grid>

                <Grid item xs={12}>
                  <Text
                    weight="medium"
                    style={{ color: '#8c8c8c', marginBottom: '1px' }}
                  >
                    Thumbnail Image:
                    <em style={{ marginLeft: 5 }}><em style={{ color: '#ed7100' }}>285 x 280</em></em>
                  </Text>
                  <div>
                    <TextField
                      name={'thumbnailContent'}
                      disabled
                      inputRef={register}
                      value={nameThumb}
                      variant="outlined"
                      placeholder="Please choose file"
                    />

                    <input
                      ref={register}
                      name={'thumbnail'}
                      onChange={handlePickThumbNail}
                      accept="image/*"
                      className={classes.input1}
                      id="raised-button-file-thumbnail"
                      multiple
                      type="file"
                    />
                    <label htmlFor="raised-button-file-thumbnail">
                      <Button variant="raised" component="span" className={classes.button}>
                        Upload
                      </Button>
                    </label>


                  </div>
                  {!!infoDataHub?.image?.assetUrl  && (
                    <div className={classes.uploadedContentImage}>
                      <p
                        className={classes.uploadedContentImageDownload}
                        onClick={() => handleDownloadUploadContentImage(infoDataHub?.image?.assetUrl)}
                      >
                        {infoDataHub?.image?.assetUrl.substr(infoDataHub?.image?.assetUrl.length - 20)}
                      </p>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  <Text
                    weight="medium"
                    style={{ color: '#8c8c8c', marginBottom: '1px' }}
                  >
                    Attachment <em className={classes.requred}>*</em>
                  </Text>
                  <div>
                    <TextField
                      value={attachOne}
                      variant="outlined"
                      disabled
                      placeholder="Please choose file"
                    />
                    <input
                      ref={register}
                      name={'attachment'}
                      onChange={pickAttachOne}
                      className={classes.input2}
                      id="raised-button-file-2"
                      multiple
                      type="file"
                    />
                    <label htmlFor="raised-button-file-2">
                      <Button variant="raised" component="span" className={classes.button}>
                        Upload
                      </Button>
                    </label>

                  </div>
                  {!!infoDataHub?.attachment?.assetUrl  && (
                    <div className={classes.uploadedContentImage}>
                      <p
                        className={classes.uploadedContentImageDownload}
                        onClick={() => handleDownloadUploadContentImage(infoDataHub?.attachment?.assetUrl)}
                      >
                        {infoDataHub?.attachment?.assetUrl.substr(infoDataHub?.attachment?.assetUrl.length - 20)}
                      </p>
                    </div>
                  )}

                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title-new"
          aria-describedby="transition-modal-description-new"
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <Alert>
              <Grid container justify="flex-end">
                <IconButton>
                  <CloseOutlinedIcon onClick={() => setOpen(false)} fontSize="large" style={{ color: '#8C8C8C' }}/>
                </IconButton>
              </Grid>
              <Text className={classes.textDel}>
                Are you sure you want to update ?
              </Text>
              <Grid
                container
                justify="center"
                spacing={2}
                style={{ marginBottom: '20px' }}
              >
                <Grid item>
                  <CustomButton
                    color="secondary"
                    onClick={() => setOpen(true)}
                  >
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomButton
                    onClick={updateInfoDataHub}
                  >
                    {loading ? <CustomCircularProgress size={16}/> : 'Update'}
                  </CustomButton>
                </Grid>
              </Grid>
            </Alert>
          </Fade>
        </Modal>
      </Grid>
    </>
  );
}
