import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SupportCenterQcell from '../../pages/supportCenter/components/SupportCenterQcell';
import { ContactProvider } from '../../contexts/support';
import FormEditSupportCenter from '../../pages/supportCenter/components/FormEditSupportCenter';
import ClaimManagement from '../claimManagement/pages/ClaimManagement';

export default function SupportCenter() {
  return (
    <ContactProvider>
      <Switch>
        <Route
          exact
          path="/supportcenter/qcells"
          component={() => <SupportCenterQcell type={1} />}
        />
        <Route
          exact
          path="/supportcenter/partner"
          component={() => <SupportCenterQcell type={2} />}
        />
        <Route
          exact
          path="/supportcenter/claim-management"
          component={() => <ClaimManagement />}
        />
        <Route
          exact
          path="/supportcenter/:type/edit/:id"
          component={FormEditSupportCenter}
        />
        <Route
          exact
          path="/supportcenter/:type/edit/:id"
          component={FormEditSupportCenter}
        />
      </Switch>
    </ContactProvider>
  );
}
