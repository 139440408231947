import React, { useState, useRef } from 'react';
import Text from '../../common/components/text';
import Price from '../../common/components/price';
import Moment from 'react-moment';
import axios from '../../utils/axios';
import config from '../../config';
import Button from '../../common/components/button';
import defaultImage from '../../config/images/product_default_image.png';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  Link,
  Paper,
  TableContainer,
  Box
} from '@material-ui/core';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';

import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import classNames from 'classnames';
import Select from 'react-select';
import CustomLoading from './../../common/components/CustomLoading';
import { notiFailed, notiSuccess } from './../../utils/helpers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Alert from '../rewards/Alert';
import CustomButton from '../../common/components/button';

const { baseUrl } = config;

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textDel : {
    fontSize : 30,
    marginTop: 0,
    textAlign: 'center',
    marginLeft: '20px',
    marginRight: '20px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: '80%',
    width: '80rem',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    }
  },
  root: {
    height: '50vh',
    width: '60vw'
  },
  tableContainer: {
    boxShadow: 'none',
    borderRadius: 'unset',
    paddingBottom: 20
  },
  table: {
    minWidth: 650
  },
  mediumLabelColumnItem: {
    color: '#9ca4ab',
    fontWeight: '400',
    fontSize: '1.125rem'
  },
  normalLabelColumnItem: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400'
  },
  hideDivElUpXS: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  sizeVariantProduct: {
    color: '#979797'
  },
  tableCellPlaceholder: {
    width: '1vw'
  },
  shippingInfo: {
    marginBottom: '-15px'
  },
  addTrackingNumberBtn: {
    height: '40px'
  },
  consolidateLabel: {
    backgroundColor: '#b10035',
    width: '70px',
    color: '#ffffff',
    textAlign: 'center',
    borderRadius:'5px'
  },
  usedCoupon: {
    backgroundColor: '#002e54'
  },
  unusedCoupon: {
    backgroundColor: '#808080'
  },
  changeStatusCouponBtn: {
    color: '#fff',
    cursor: 'pointer',
    border: 'none',
    borderRadius: 5
  },
  couponCode: {
    color: '#979797',
    marginBottom: '8px'
  },
  modalUpdateCouponStatusAlert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

class OrderDetailsToPrint extends React.Component {
  render() {
    const {
      firstName,
      lastName,
      companyName,
      createdAt,
      total,
      orderNumber,
      shippingInformation
    } = this.props.orderDetail;
    const isAuroraOrder = !!_.get(this.props.orderDetail, 'products[0].product.custom');
    const products = this.props.products;

    let fName = firstName;
    let lName = lastName;

    if (!lastName) {
      if (firstName.indexOf(' ') >= 0) {
        fName = firstName.substr(0, firstName.indexOf(' '));
        lName = firstName.substr(firstName.indexOf(' ') + 1);
      }
    }

    return (
      <TableContainer
        component={Paper}
        style={{
          width: '95%',
          margin: '20px auto',
          borderRadius: '0',
          boxShadow: 'none',
          border: '1px solid'
        }}
      >
        <Table size="small" aria-label="spanning table">
          <TableRow>
            <TableCell
              align="left"
              colSpan={8}
              style={{
                backgroundColor: '#595959',
                color: '#fff',
                fontWeight: 'bold'
              }}
            >
              Order Information
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                borderRight: '1px solid',
                backgroundColor: '#F2F2F2'
              }}
              width="20%"
            >
              Order Number
            </TableCell>
            <TableCell
              align="center"
              style={{ borderRight: '1px solid' }}
              colSpan={3}
            >
              {orderNumber}
            </TableCell>
            <TableCell
              style={{ borderRight: '1px solid', backgroundColor: '#F2F2F2' }}
              width="20%"
            >
              Order Date
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <Moment format="MM/DD/YYYY">{createdAt}</Moment>
            </TableCell>
          </TableRow>
          {!isAuroraOrder && (
          <>
            <TableRow>
              <TableCell
                align="left"
                colSpan={8}
                style={{
                  backgroundColor: '#595959',
                  color: '#fff',
                  fontWeight: 'bold'
                }}
              >
                Shipping Information
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  backgroundColor: '#F2F2F2'
                }}
              >
                First Name
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                <span dangerouslySetInnerHTML={{ __html: fName }} />
              </TableCell>
              <TableCell
                style={{
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  backgroundColor: '#F2F2F2'
                }}
              >
                Last Name
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ borderBottom: '1px solid' }}
              >
                <span dangerouslySetInnerHTML={{ __html: lName }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  backgroundColor: '#F2F2F2'
                }}
              >
                Address 1
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                {shippingInformation.address}
              </TableCell>
              <TableCell
                style={{
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  backgroundColor: '#F2F2F2'
                }}
              >
                Address 2
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ borderBottom: '1px solid' }}
              >
                {shippingInformation.addressCont !== undefined
                  ? shippingInformation.addressCont
                  : shippingInformation['addressCont '] !== 'null'
                  ? shippingInformation['addressCont ']
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  backgroundColor: '#F2F2F2'
                }}
              >
                City
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                {shippingInformation.city}
              </TableCell>
              <TableCell
                style={{
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  backgroundColor: '#F2F2F2'
                }}
              >
                State
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ borderBottom: '1px solid' }}
              >
                {shippingInformation.state}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  backgroundColor: '#F2F2F2'
                }}
              >
                Zip Code
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                {shippingInformation.zipCode}
              </TableCell>
              <TableCell
                style={{
                  borderRight: '1px solid',
                  borderBottom: '1px solid',
                  backgroundColor: '#F2F2F2'
                }}
              >
                Mobile
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ borderBottom: '1px solid' }}
              >
                {shippingInformation.mobileNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ borderRight: '1px solid', backgroundColor: '#F2F2F2' }}
              >
                Company
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                style={{ borderRight: '1px solid' }}
              >
                <span dangerouslySetInnerHTML={{ __html: companyName }} />
              </TableCell>
              <TableCell
                style={{ borderRight: '1px solid', backgroundColor: '#F2F2F2' }}
              >
                Phone
              </TableCell>
              <TableCell align="center" colSpan={3}>
                {shippingInformation.officeNumber}
              </TableCell>
            </TableRow>
          </>)}
          <TableRow>
            <TableCell
              align="left"
              colSpan={8}
              style={{
                backgroundColor: '#595959',
                color: '#fff',
                fontWeight: 'bold'
              }}
            >
              Product Information
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                borderRight: '1px solid',
                borderBottom: '1px solid',
                backgroundColor: '#F2F2F2'
              }}
            >
              Items
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderRight: '1px solid',
                borderBottom: '1px solid',
                backgroundColor: '#F2F2F2'
              }}
            >
              { isAuroraOrder ? 'Custom' : 'Color' }
            </TableCell>
            <TableCell
              style={{
                borderRight: '1px solid',
                borderBottom: '1px solid',
                backgroundColor: '#F2F2F2'
              }}
            >
              { isAuroraOrder ? 'Coupon Code' : 'Size' }
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderRight: '1px solid',
                borderBottom: '1px solid',
                backgroundColor: '#F2F2F2'
              }}
            >
              Q'ty
            </TableCell>
            <TableCell
              style={{
                borderRight: '1px solid',
                borderBottom: '1px solid',
                backgroundColor: '#F2F2F2'
              }}
            >
              SKU
            </TableCell>
            <TableCell
              style={{
                borderRight: '1px solid',
                borderBottom: '1px solid',
                backgroundColor: '#F2F2F2'
              }}
            >
              Note
            </TableCell>
            <TableCell
              style={{ borderBottom: '1px solid', backgroundColor: '#F2F2F2' }}
            >
              Point
            </TableCell>
          </TableRow>
          {products.map(p => (
            <TableRow>
              <TableCell
                align="left"
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                {p.product?.product?.name}
              </TableCell>
              <TableCell
                align="center"
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                { isAuroraOrder ? _.get(p, 'product.custom') : p.product?.color }
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                { isAuroraOrder ? _.get(p, 'couponCode.code') : _.toUpper(p.product?.size)}
              </TableCell>
              <TableCell
                align="right"
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                {p.quantity}
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                {p.sku}
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: '1px solid', borderBottom: '1px solid' }}
              >
                {p.product.consolidate ? 'Consolidate' : ''}
              </TableCell>
              <TableCell align="right" style={{ borderBottom: '1px solid' }}>
                {p.total ? p.total.toLocaleString() : 0}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              align="right"
              style={{
                borderRight: '1px solid',
                fontWeight: 'bold'
              }}
              colSpan={6}
            >
              Total
            </TableCell>
            <TableCell
              align="right"
              style={{
                fontWeight: 'bold'
              }}
            >
              {total ? total.toLocaleString() : 0}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    );
  }
}

export default function OrderDetails({
  order,
  handleCheck,
  checked,
  orderStatuses,
  trackPackageSelected,
  addShippingInfo,
  getOrders,
  shippingList
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openFormUpdateCoupon, setOpenFormUpdateCoupon] = useState(false);
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState({});
  const [orderDetail, setOrderDetail] = useState(null);
  const [infoCouponUpdate, setInfoCouponUpdate] = useState({
    id : null,
    couponStatusId : null
  })

  const [trackingNumberButtonClicked, setAddTrackingNumberButtonClicked] = useState(
    false
  );
  const [timeline, setTimeline] = useState([]);
  const [single, setSingle] = useState(null);
  const [multiple, setMultiple] = useState(null);
  const [selected, setSelected] = useState([]);
  const [trackingNumberSelected, setTrackingNumberSelected] = useState(null);
  const [shippingCompanySelected, setShippingCompanySelected] = useState(null);
  const [addingTrackingNumber, setAddingTrackingNumber] = useState(null);
  const [expand, setExpand] = useState(null);
  const [loadingUpdateOrderStatus, setLoadingUpdateOrderStatus] = useState(false);
  const [loadingChangeCouponStatus, setLoadingChangeCouponStatus] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  if (order !== orderDetail) {
    setOrderDetail(order);
  }
  if (!orderDetail) {
    return null;
  }
  const {
    id,
    firstName,
    lastName,
    companyName,
    orderStatus,
    createdAt,
    total,
    orderNumber,
    trackingNumber,
    shippingCompany,
    shippingInformation,
    orderStatusId
  } = orderDetail;
  const isAuroraOrder = !!_.get(orderDetail, 'products[0].product.custom');

  const removeDuplicatesTrackingInfo = trackingNumberList => {
    let trackingNumberListWithoutDuplicate = [];

    trackingNumberList.forEach(o => {
      if (_.isEmpty(trackingNumberListWithoutDuplicate)) {
        trackingNumberListWithoutDuplicate.push(o);
      } else {
        let str = `${o.trackingNumber}-${o.shippingCompany}`;
        let listStr = trackingNumberListWithoutDuplicate.map(o1 => `${o1.trackingNumber}-${o1.shippingCompany}`);
        if (!listStr.includes(str)) {
          trackingNumberListWithoutDuplicate.push(o);
        }
      }
    });
    return trackingNumberListWithoutDuplicate;
  };

  const removeDuplicatesProductInfo = trackingNumberList => {
    let trackingNumberListWithoutDuplicate = [];
    trackingNumberList.forEach(o => {
      if (trackingNumberListWithoutDuplicate.length === 0) {
        trackingNumberListWithoutDuplicate.push(o);
      } else {
        let str = `${o.productId}-${o.productName}`;
        let listStr = trackingNumberListWithoutDuplicate.map(o1 => `${o1.productId}-${o1.productName}`);
        if (!listStr.includes(str)) {
          trackingNumberListWithoutDuplicate.push(o);
        } else {
          trackingNumberListWithoutDuplicate.forEach(product => {
            if (product.productName === o.productName) {
              product.id = `${product.id},${o.id}`;
            }
          });
        }
      }
    });
    return trackingNumberListWithoutDuplicate;
  };

  const trackingNumberListAll = orderDetail.products.map(o => {
    return {
      id: o.id,
      productId: o.product.product.id,
      productName: o.product.product.name,
      trackingNumber: o.trackingNumber,
      shippingCompany: o.shippingCompany
    };
  });

  const trackingNumberListAllNoDuplicate = removeDuplicatesProductInfo(
    trackingNumberListAll
  );
  const trackingNumberListAllNoDuplicateWithLink = processLinks(
    trackingNumberListAllNoDuplicate,
    shippingList
  );

  const trackingNumberListWithoutNull = [...trackingNumberListAll].filter(o => {
    return o.trackingNumber && o.shippingCompany;
  });

  const trackingNumberListNull = [...trackingNumberListAll].filter(o => {
    return !o.trackingNumber || !o.shippingCompany;
  });

  const trackingNumberListWithoutNullNoDuplicated = removeDuplicatesTrackingInfo([
    ...trackingNumberListWithoutNull
  ]);

  let noExistedTrackingNumber =
    trackingNumberListNull.length === trackingNumberListAll.length;

  let isSingleTracking = (trackingNumberListAll.length === 1) ||
    (trackingNumberListAll.length !== 1 &&
     trackingNumberListAll.length === trackingNumberListWithoutNull.length &&
     trackingNumberListWithoutNullNoDuplicated.length === 1) ||
    (trackingNumberListWithoutNull.length === 0 &&
     trackingNumberListWithoutNullNoDuplicated.length === 0 &&
     !!trackingNumber);

  let itemNotHaveTracking =
    trackingNumberListAll.length - trackingNumberListWithoutNull.length;

  let addMultipleAllTheSame =
    (trackingNumberListAll.length === 1 &&
      trackingNumberListWithoutNull.length === 1) ||
    (trackingNumberListWithoutNullNoDuplicated.length === 1 &&
      trackingNumberListWithoutNull.length !== 1 &&
      trackingNumberListWithoutNull.length === trackingNumberListAll.length);

  let productListGroupByProductId = removeDuplicatesProductInfo([...trackingNumberListNull]);

  const options = [];
  productListGroupByProductId.forEach(o => {
    options.push({ value: String(o.id), label: o.productName });
  });

  const getProduct = async () => {
    const { data } = await axios.get(`${baseUrl}/orders/${id}`);
    setProducts(data.products);
    setTimeline(data.timeline);
    setStatus({ orderStatusId: data.orderStatusId });
  };

  const handleOpen = () => {
    getProduct();
    setOpen(true);
  };

  const handleClose = () => {
    setTrackingNumberSelected(null);
    setShippingCompanySelected(null);
    setSingle(null);
    setMultiple(null);
    setAddTrackingNumberButtonClicked(false);
    setOpen(false);
  };

  const handleStatus = async order => {
    try {
      setLoadingUpdateOrderStatus(true);
      const { data } = await axios.patch(`${baseUrl}/orders/${order.id}`, {
        id: order.id,
        type: order.type || 'qpoints',
        orderStatusId: status
      });

      notiSuccess('Update Successfully!');
      setLoadingUpdateOrderStatus(false);
      setOrderDetail({ ...order, ...data });
      getOrders();
      getProduct();
    } catch (error) {
      notiFailed(`${error.data.message}`)
      setLoadingUpdateOrderStatus(false);
    }
  };

  const handleTracking = async order => {
    try {
      setAddingTrackingNumber(true);
      let params = {
        id: order.id,
        shippingCompany: shippingCompanySelected,
        trackingNumber: trackingNumberSelected,
        type: order.type,
        orderStatusId: 6
      };
      if (single) {
        let productIdList = order.products.map(o => o.id);
        params.products = productIdList;
      } else {
        let listProductIdSelected = [];
        selected.forEach(o => {
          listProductIdSelected.push.apply(
            listProductIdSelected,
            String(o.value).split(',')
          );
        });
        params.products = listProductIdSelected;
      }
      if (params.products.length === 0) {
        notiFailed('Please choose products!');
        return;
      } else if (!shippingCompanySelected) {
        notiFailed('Please choose shipping company!');
        return;
      } else if (!trackingNumberSelected) {
        notiFailed('Please type in tracking number!');
        return;
      }

      const { data } = await axios.patch(`${baseUrl}/orders/${order.id}`, params);
      setShippingCompanySelected(null);
      setTrackingNumberSelected(null);
      setAddTrackingNumberButtonClicked(false);
      getOrders();
      getProduct();
    } catch (err) {
      console.log(err);
    } finally {
      setAddingTrackingNumber(false);
    }
  };

  const handleStatusField = e => {
    setStatus(e.target.value);
  };

  const RenderOrderStatus = (orderStatus, shippingCompany) => {
    let link = null;

    if (shippingCompany && orderStatusId === 6) {
      shippingList.forEach(shipping => {
        if (shippingCompany === shipping.name) {


          const actions = {
            'usps' : `${config.urlTools}?number=${trackingNumber}`,
            'ups' : `${config.urlUps}&number=${trackingNumber}&requester=ST/`,
            'fedex' : `${config.urlFeDex}&number=${trackingNumber}`,
            'default' : ''
          }

          link = actions[shippingCompany] || actions['default'];
          return;
        }
      });
    }

    let renderOrderStatus = '';

    switch (orderStatusId) {
      case 1:
        renderOrderStatus = 'Order Received';
        break;
      case 2:
        renderOrderStatus = 'Processing';
        break;
      case 3:
        renderOrderStatus = 'Returned';
        break;
      case 4:
        renderOrderStatus = 'Delivered';
        break;
      case 5:
        renderOrderStatus = 'Canceled';
        break;
      case 6:
        renderOrderStatus = 'Track Package';
        break;
      case 7:
        renderOrderStatus = 'Custom Delivered';
        break;
      case 8:
        renderOrderStatus = 'Failed';
        break;
      default:
    }

    const getTrackingIndicator = () => {
      let existsTracking = {};
      let trackingIndicator = 0;
      for (let i = 0; i < trackingNumberListAllNoDuplicateWithLink.length; i++) {
        let item = trackingNumberListAllNoDuplicateWithLink[i];
        if (!item.link) {
          trackingIndicator += 1;
        } else {
          let tracking = `${item.shippingCompany}-${item.trackingNumber}`;
          if (!existsTracking[tracking]) {
            trackingIndicator += 1;
            existsTracking[tracking] = true;
          }
        }
      }
      return trackingIndicator;
    };

    return (
      <Text
        color={
          renderOrderStatus === 'Order Received'
            ? 'dark'
            : renderOrderStatus === 'Returned'
            ? 'dark'
            : renderOrderStatus === 'Processing'
            ? 'primaryLight'
            : renderOrderStatus === 'Track Package' || renderOrderStatus === 'Custom Delivered'
            ? 'primaryLight'
            : 'secondaryDark'
        }
      >
        {orderStatusId === 6 ? (
          <React.Fragment>
            {isSingleTracking ? (
              <Link href={link} target="_blank" style={{ color: '#00ADEF' }}>
                Track Package{' '}
                {!isSingleTracking ? (
                  <span
                    className={classNames('tracking_indicator', {
                      notall: itemNotHaveTracking >= 1
                    })}
                  >
                    {`(${trackingNumberListAll.length})`}
                  </span>
                ) : null}
              </Link>
            ) : (
              <p
                onClick={e => setExpand(!expand)}
                className="link_track"
                style={{ color: '#00ADEF' }}
              >
                Track Package{' '}
                {!isSingleTracking ? (
                  <span
                    className={classNames('wr_number_of_product', {
                      notall: itemNotHaveTracking >= 1
                    })}
                  >
                    {`${getTrackingIndicator()}`}
                  </span>
                ) : null}
              </p>
            )}
            {expand ? (
              <div className="wr_expand">
                {trackingNumberListAllNoDuplicateWithLink.map(item => {
                  if (item.link) {
                    return (
                      <a key={item.id} href={item.link} target="_blank">
                        <p>{RenderShippingOrderStatus(item)}</p>
                      </a>
                    );
                  } else {
                    return <p key={item.id}>{item.trackingStr}</p>;
                  }
                })}
              </div>
            ) : null}
          </React.Fragment>
        ) : (
          renderOrderStatus
        )}
      </Text>
    );
  };

  const RenderStatusProduct = p => {
    let productStatus = '';
    if (
      trackingNumberListAll.length >= 2 &&
      trackingNumberListNull.length >= 1 &&
      trackingNumberListWithoutNull.length >= 1
    ) {
      if (
        trackingNumberListNull
          .map(o => o.id)
          .join(',')
          .includes(String(p.id))
      ) {
        return (
          <Text size="h5" color="secondaryDark">
            { orderStatusId === 3 ? 'Returned' : 'Processing' }
          </Text>
        );
      }
    }
    switch (orderStatusId) {
      case 1:
        productStatus = 'Order Received';
        break;
      case 2:
        productStatus = 'Processing';
        break;
      case 3:
        productStatus = 'Returned';
        break;
      case 4:
        productStatus = 'Delivered';
        break;
      case 5:
        productStatus = 'Canceled';
        break;
      case 6:
        productStatus = 'Track Package';
        break;
      case 7:
        productStatus = 'Custom Delivered'
        break;
      case 8:
        productStatus = 'Failed';
        break;
      default:
    }

    return (
      <Text size="h5" color="primaryLight">
        {productStatus}
      </Text>
    );
  };

  const RenderShippingOrderStatus = p => {
    if (addMultipleAllTheSame) {
      return null;
    }

    let str = `${p.shippingCompany}-${p.trackingNumber}`;

    let isProductInTrackingList = trackingNumberListWithoutNull
      .map(o => o.id)
      .join(',')
      .includes(String(p.id));

    if (isProductInTrackingList) {
      for (let i = 0; i < _.size(trackingNumberListWithoutNullNoDuplicated); i++) {
        let item = trackingNumberListWithoutNullNoDuplicated[i];
        let str2 = `${item.shippingCompany}-${item.trackingNumber}`;
        if (str === str2) {
          return (
            <a className="shipping_link" href={item.link} target="_blank">
              <Text size="h5" color="primaryLight">
                Shipping {i + 1}
              </Text>
            </a>
          );
        }
      }

      return null;
    }
    return null;
  };

  const RenderShippingProduct = p => {
    if (isSingleTracking) {
      if (orderStatusId !== 6) {
        return <Text size="h5">-</Text>;
      }

      let link = null;
      if (p.shippingCompany) {
        shippingList.forEach(shipping => {
          if (p.shippingCompany === shipping.name) {
            link = shipping.link + '?number=' + p.trackingNumber;
            return;
          }
        });
      }

      return (
        <a className="shipping_link" href={link} target="_blank">
          <Text size="h5" color="primaryLight">
            {`${_.toUpper(p.shippingCompany)} ${p.trackingNumber}`}
          </Text>
        </a>
      );
    }

    if (addMultipleAllTheSame) {
      return null;
    }

    let str = `${p.shippingCompany}-${p.trackingNumber}`;

    let isProductInTrackingList = trackingNumberListWithoutNull
      .map(o => o.id)
      .join(',')
      .includes(String(p.id));

    if (isProductInTrackingList) {
      for (let i = 0; i < _.size(trackingNumberListWithoutNullNoDuplicated); i++) {
        let item = trackingNumberListWithoutNullNoDuplicated[i];
        let str2 = `${item.shippingCompany}-${item.trackingNumber}`;
        if (str === str2) {
          return (
            <a className="shipping_link" href={item.link} target="_blank">
              <Text size="h5" color="primaryLight">
                {`${_.toUpper(item.shippingCompany)} ${item.trackingNumber}`}
              </Text>
            </a>
          );
        }
      }

      return (
        <Text size="h5">-</Text>
      );
    }
    return <Text size="h5">-</Text>;
  };

  const RenderTrackingNumber = () => {
    if (trackingNumber && trackingNumberListWithoutNull.length === 0) {
      return (
        <React.Fragment>
          <Text size="h4" weight="medium" style={{ marginBottom: '-15px' }}>
            Tracking Number:
          </Text>
          <Text size="h4" color="secondaryDark" weight="light">
            {shippingCompany && _.toUpper(shippingCompany)} : {trackingNumber}
          </Text>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {trackingNumberListWithoutNullNoDuplicated.length === 0 ? null : (
          <div className="wr_aaa">
            <Text size="h4" weight="medium">
              Tracking Number:
            </Text>
            {addMultipleAllTheSame ? (
              <React.Fragment>
                <Text size="h4" weight="light" color="secondaryDark">
                  {' '}
                  {_.toUpper(trackingNumberListWithoutNullNoDuplicated[0].shippingCompany)}{' '}
                  : {trackingNumberListWithoutNullNoDuplicated[0].trackingNumber}{' '}
                </Text>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {trackingNumberListWithoutNullNoDuplicated.map((o, index) => (
                  <Text size="h4" weight="light" color="secondaryDark">
                    {' '}
                    Shipping {index + 1}: {_.toUpper(o.shippingCompany)} {' '}
                    {o.trackingNumber}
                  </Text>
                ))}
              </React.Fragment>
            )}
          </div>
        )}

        <React.Fragment>
          {trackingNumberButtonClicked ? (
            <Grid item xs={4}>
              {single || multiple ? null : (
                <div style={{ minWidth: '330px' }}>
                  <Button
                    className={classNames('', {
                      btn_disabled:
                        trackingNumberListAll.length >
                          trackingNumberListWithoutNull.length &&
                        trackingNumberListWithoutNull.length >= 1
                    })}
                    style={{ marginBottom: '10px', marginRight: '5px' }}
                    minWidth="7rem"
                    color="secondary"
                    onClick={() => {
                      setSingle(true);
                    }}
                  >
                    Single
                  </Button>
                  <Button
                    className={classNames('', {
                      btn_disabled: trackingNumberListAll.length === 1
                    })}
                    style={{ marginBottom: '10px' }}
                    minWidth="7rem"
                    color="secondary"
                    onClick={() => {
                      setMultiple(true);
                    }}
                  >
                    Multiple
                  </Button>
                  <CancelPresentationIcon
                    className="cancel_adding_tracking_number"
                    width="90px"
                    color="secondaryDark"
                    onClick={() => {
                      setAddTrackingNumberButtonClicked(false);
                      setSingle(null);
                      setMultiple(null);
                      setShippingCompanySelected(null);
                      setTrackingNumberSelected(null);
                    }}
                  ></CancelPresentationIcon>
                </div>
              )}
            </Grid>
          ) : (
            <React.Fragment>
              {!isHaveAllTrackingNumber && !isAuroraOrder && (
                <Grid item xs={5}>
                  <Button
                    minWidth="14rem"
                    color="secondary"
                    onClick={() => {
                      setAddTrackingNumberButtonClicked(true);
                    }}
                    disabled={checkDisabledAddTracking()}
                    style={{
                      backgroundColor: `${checkDisabledAddTracking() ? '#e6e6e6' : ''}`
                    }}
                    className={classes.addTrackingNumberBtn}
                  >
                    Add Tracking Number
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          )}
        </React.Fragment>

        {trackingNumberButtonClicked && (multiple || single) ? (
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              {multiple && (
                <Select
                  className="select1"
                  onChange={onChangeProduct}
                  options={options}
                  value={selected}
                  isMulti
                ></Select>
              )}

              <TextField
                className="select2"
                fullWidth
                select
                size="small"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                name="shippingCompany"
                value={shippingCompanySelected}
                onChange={e => {
                  setShippingCompanySelected(e.target.value);
                }}
              >
                {shippingList.map((shipping, index) => {
                  return (
                    <MenuItem key={index} value={shipping.name}>
                      {`${shipping.name}`.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                className="select3"
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Enter Tracking Number"
                value={trackingNumberSelected}
                onChange={e => {
                  setTrackingNumberSelected(e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <button
                className={classNames('btn_save_multiple', {
                  disabled_btn: addingTrackingNumber
                })}
                onClick={() => handleTracking(orderDetail)}
                style={{ marginRight: '5px' }}
              >
                {addingTrackingNumber ? <CustomLoading></CustomLoading> : 'Save'}
              </button>
              <button
                className={classNames('btn_cancel', {
                  disabled_btn: addingTrackingNumber
                })}
                onClick={() => {
                  setAddTrackingNumberButtonClicked(false);
                  setSingle(null);
                  setMultiple(null);
                  setShippingCompanySelected(null);
                  setTrackingNumberSelected(null);
                }}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        ) : (
          false
        )}
      </React.Fragment>
    );
  };

  const isHaveAllTrackingNumber =
    trackingNumberListAll.length === trackingNumberListWithoutNull.length;
  const onChangeProduct = selectedOptions => setSelected(selectedOptions);
  const getOrderStatuses = () => {
    let statuses = orderStatuses;

    if (![7, 8].includes(orderStatusId)) {
      statuses = statuses.filter(s => ![7, 8].includes(s.id));
    }

    switch(orderStatusId) {
      case 1:
        statuses = statuses.filter(s => s.id !== 3);
        break;
      case 2:
        statuses = statuses.filter(s => s.id !== 1 && s.id !== 5);
        break;
      case 3:
        statuses = statuses.filter(s => s.id === 3);
        break;
      case 4:
        statuses = statuses.filter(s => s.id === 4 || s.id === 3);
        break;
      case 5:
        statuses = statuses.filter(s => s.id === 5);
        break;
      case 6:
        statuses = statuses.filter(s => s.id === 6 || s.id === 3);
        break;
      case 7:
        statuses = statuses.filter(s => s.id === 5 || s.id === 7);
        break;
      case 8:
        statuses = statuses.filter(s => s.id === 8);
        break;
    }

    return statuses;
  }

  const checkDisabledAddTracking = () => {
    if (single || !isHaveAllTrackingNumber) {
      if (orderStatusId === 6 && !isHaveAllTrackingNumber) {
        return false;
      }

      return [3, 4, 5, 6].indexOf(orderStatusId) !== -1;
    }

    return false;
  }

  const checkDisabledUpdateStatus = () => {
    let statusId = status.orderStatusId || +status;
    return [3, 5].indexOf(orderStatusId) !== -1 || statusId === orderStatusId;
  }

  async function handleUpdateStatusCoupon() {

    const actions = {
      1 : 2,
      2 : 1,
      'default': 1
    }

    const couponStatusId = actions[infoCouponUpdate.couponStatusId] || actions['default'];
    const id = infoCouponUpdate.id;

    setLoadingChangeCouponStatus(true);
    await axios.patch(`${baseUrl}/orders/coupon/update`, {
      id,
      couponStatusId
    }).then(() => {
      setLoadingChangeCouponStatus(false);
      setOpenFormUpdateCoupon(false);
      notiSuccess('Update Success !');
    }).catch(error => {
      setLoadingChangeCouponStatus(false);
      setOpenFormUpdateCoupon(false);
      notiFailed(`${_.get(error, 'data.message', 'Update failed !')}`);
    });
    getProduct();

  }

  function handleShowModalUpdateCoupon(id, status) {

    setOpenFormUpdateCoupon(true);
    setInfoCouponUpdate({
      id,
      couponStatusId: status
    })
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <OrderDetailsToPrint
          ref={componentRef}
          orderDetail={orderDetail}
          products={products}
        />
      </div>
      <TableRow hover style={{ cursor: 'pointer' }}>
        {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>}
        <TableCell
          className={classNames('cell_order', { cell_expand: expand })}
          onClick={handleOpen}
          style={{ fontFamily: 'Actual' }}
        >
          <Moment format="L">{createdAt}</Moment>
        </TableCell>
        <TableCell
          className={classNames('cell_order', { cell_expand: expand })}
          style={{ fontFamily: 'Actual' }}
          onClick={handleOpen}
        >
          {_.toUpper(orderNumber)}
        </TableCell>
        <TableCell
          className={classNames('cell_order cell_company_name', {
            cell_expand: expand
          })}
          style={{ fontFamily: 'Actual' }}
          onClick={handleOpen}
        >
          <span dangerouslySetInnerHTML={{ __html: companyName }} />
          {expand && (
            <div className="wr_expand">
              {trackingNumberListAllNoDuplicate
                .map(o => o.productName)
                .map(name => (
                  <p key={name}>{name}</p>
                ))}
            </div>
          )}
        </TableCell>
        <TableCell
          className={classNames('cell_order', { cell_expand: expand })}
          style={{ fontFamily: 'Actual' }}
          onClick={handleOpen}
        >
          <span dangerouslySetInnerHTML={{ __html: firstName + ' ' + lastName }} />
        </TableCell>
        <TableCell
          className={classNames('cell_order', { cell_expand: expand })}
          onClick={handleOpen}
        >
          <Price className="cell_payment" color="dark">
            {total}
          </Price>
        </TableCell>
        {checked.some(c => c.id === order.id) && trackPackageSelected ? (
          <>
            <TableCell align="left">
              <TextField
                fullWidth
                select
                size="small"
                variant="outlined"
                name="shippingCompany"
                label="Carrier"
                value={order.shippingCompany || ''}
                onChange={e =>
                  addShippingInfo(id, { shippingCompany: e.target.value })
                }
              >
                {shippingList.map((shipping, index) => {
                  return (
                    <MenuItem key={index} value={shipping.name}>
                      {`${shipping.name}`.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Add Tracking"
                value={order.trackingNumber || ''}
                onChange={e =>
                  addShippingInfo(id, { trackingNumber: e.target.value })
                }
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              className={classNames('cell_order', { cell_expand: expand })}
              onClick={isSingleTracking || noExistedTrackingNumber ? handleOpen : null}
            >
              {RenderOrderStatus(orderStatus, shippingCompany)}
            </TableCell>
            <TableCell></TableCell>
          </>
        )}

        <TableCell align="right">
          <IconButton size="small" style={{ marginRight: '20px' }}>
            <Checkbox
              style={{
                color: `${
                  checked.some(c => c.id === order.id) ? '#00ADEF' : '#8C8C8C'
                }`
              }}
              color="primary"
              onChange={handleCheck}
              checked={checked.some(c => c.id === order.id)}
            />
          </IconButton>
        </TableCell>
      </TableRow>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div style={{ width: '95%', margin: 'auto' }}>
              <Grid container justify="flex-end">
                <IconButton onClick={handleClose}>
                  <CloseOutlinedIcon fontSize="large" />
                </IconButton>
              </Grid>
            </div>
            <Grid container>
              <div style={{ width: '90%', margin: 'auto' }}>
                <Grid container item justify="space-between" alignItems="center">
                  <Grid item md={8} xs={12}>
                    <Text size="h2" weight="medium">
                      Order Details
                      <IconButton onClick={handlePrint}>
                        <PrintIcon fontSize="large" />
                      </IconButton>
                    </Text>
                  </Grid>
                  <Grid
                    container
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    alignItems="center"
                    style={{
                      padding: '10px'
                    }}
                  >
                    <Grid item md={3} xs={6}>
                      <Text size="h3">Status:</Text>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <TextField
                        select
                        size="small"
                        variant="outlined"
                        fullWidth
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }
                        }}
                        defaultValue={
                          order.orderStatusId === 6 ? null : order.orderStatusId
                        }
                        onChange={handleStatusField}
                      >
                        {getOrderStatuses().map(status => {
                          return status.name !== 'Add Tracking' ? (
                            <MenuItem key={status.id} value={`${status.id}`}>
                              {status.name === 'Received'
                                ? 'Order Received'
                                : status.name === 'Canceled'
                                ? 'Cancel'
                                : status.name === 'Returned'
                                ? 'Return'
                                : status.name}
                            </MenuItem>
                          ) : null;
                        })}
                      </TextField>
                    </Grid>
                    <Grid
                      className={classes.hideDivElUpXS}
                      item
                      md={0}
                      xs={6}
                    ></Grid>
                    <Grid container justify="flex-end" item md={3} xs={6}>
                      <button
                        disabled={checkDisabledUpdateStatus() || loadingUpdateOrderStatus}
                        style={{marginLeft: '5px'}}
                        onClick={() => handleStatus(order)}
                        className={classNames('btn_update', {
                          disabled: checkDisabledUpdateStatus()
                        })}
                      >
                        { loadingUpdateOrderStatus ? <CustomLoading size={18} color="#fff" /> : 'Update' }
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <Grid
                container
                item
                spacing={2}
                style={{
                  width: '90%',
                  margin: 'auto',
                  marginTop: '20px',
                  marginBottom: '20px',
                  backgroundColor: '#f7f7f7'
                }}
              >
                <Grid
                  container
                  item
                  spacing={2}
                  style={{
                    width: '90%',
                    margin: 'auto',
                    marginTop: '20px',
                    marginBottom: '20px',
                    backgroundColor: '#f7f7f7'
                  }}
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item md={4} xs={12}>
                    <Text
                      size="h4"
                      weight="medium"
                      style={{ marginBottom: '-15px' }}
                    >
                      Order Date:
                    </Text>
                    <Text size="h4" color="secondaryDark" weight="light">
                      <Moment format="LL">{createdAt}</Moment>
                    </Text>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Text
                      size="h4"
                      weight="medium"
                      style={{ marginBottom: '-15px' }}
                    >
                      Order Number:
                    </Text>
                    <Text size="h4" color="secondaryDark" weight="light">
                      {_.toUpper(orderNumber)}
                    </Text>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    {RenderTrackingNumber()}
                  </Grid>
                </Grid>
              </Grid>

              <>
                {!isAuroraOrder && (
                <>
                  <div style={{ width: '90%', margin: 'auto', marginBottom: '-20px' }}>
                    <Grid container justify="space-between">
                      <Text size="h3" weight="medium">Shipping Address</Text>
                    </Grid>
                  </div>
                  <Grid
                    container
                    item
                    spacing={2}
                    style={{
                      width: '90%',
                      margin: 'auto',
                      marginTop: '20px',
                      marginBottom: '20px',
                      backgroundColor: '#f7f7f7'
                    }}
                  >
                    <Grid
                      container
                      item
                      spacing={2}
                      style={{
                        width: '90%',
                        margin: 'auto',
                        marginTop: '20px',
                        marginBottom: '20px',
                        backgroundColor: '#f7f7f7'
                      }}
                    >
                      <Grid item md={4} xs={6}>
                        <Text
                          size="h4"
                          weight="medium"
                          className={classes.shippingInfo}
                        >
                          Shipping Information:
                        </Text>
                        <Text
                          size="h4"
                          color="secondaryDark"
                          weight="light"
                          className={classes.shippingInfo}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: firstName + ' ' + lastName
                            }}
                          />
                        </Text>
                        <Text
                          size="h4"
                          color="secondaryDark"
                          weight="light"
                          className={classes.shippingInfo}
                        >
                          {shippingInformation.address +
                            ' ' +
                            `${
                              shippingInformation.addressCont !== undefined &&
                              shippingInformation.addressCont !== 'null'
                                ? shippingInformation.addressCont
                                : shippingInformation['addressCont '] !== undefined &&
                                  shippingInformation['addressCont '] !== 'null'
                                ? shippingInformation['addressCont ']
                                : ''
                            }`}
                        </Text>
                        <Text
                          size="h4"
                          color="secondaryDark"
                          weight="light"
                          className={classes.shippingInfo}
                        >
                          {shippingInformation.city +
                            ', ' +
                            shippingInformation.state +
                            ' ' +
                            shippingInformation.zipCode}
                        </Text>
                        <Text size="h4" color="secondaryDark" weight="light">
                          {shippingInformation.officeNumber}
                        </Text>
                      </Grid>
                      <Grid item md={4} xs={6}>
                        <Text
                          size="h4"
                          weight="medium"
                          className={classes.shippingInfo}
                        >
                          Company:
                        </Text>
                        <Text
                          size="h4"
                          color="secondaryDark"
                          weight="light"
                          className={classes.shippingInfo}
                        >
                          <span dangerouslySetInnerHTML={{ __html: companyName }} />
                        </Text>
                        <Text size="h4" color="secondaryDark" weight="light">
                          {shippingInformation.officeNumber}
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                </>)}
                <div style={{ width: '90%', margin: 'auto', marginBottom: '-25px' }}>
                  <Grid container justify="space-between">
                    <Text size="h3" weight="medium">Products</Text>
                  </Grid>
                </div>
                <Grid
                  container
                  item
                  style={{
                    width: '90%',
                    margin: 'auto',
                    color: '#8C8C8C',
                    marginBottom: '20px'
                  }}
                >
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table size="small" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.mediumLabelColumnItem}>
                            Items
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell className={classes.mediumLabelColumnItem}>
                            Q'ty
                          </TableCell>
                          <TableCell className={classes.mediumLabelColumnItem}>
                            SKU
                          </TableCell>
                          <TableCell className={classes.mediumLabelColumnItem}>
                            Points
                          </TableCell>
                          <TableCell className={classes.mediumLabelColumnItem}>
                            Status
                          </TableCell>
                          {!isAuroraOrder && (
                            <TableCell className={classes.mediumLabelColumnItem}>
                              Shipping #
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products.map(product => (
                          <TableRow key={product.id}>
                            <TableCell>
                              <img
                                alt="placeholder"
                                src={
                                  product.product.variantImages && product.product.variantImages[0]
                                    ? product.product.variantImages[0].imageUrl.indexOf(
                                        'http'
                                      ) === 0
                                      ? product.product.variantImages[0].imageUrl
                                      : `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${product.product.variantImages[0].imageUrl}`
                                    : defaultImage
                                }
                                style={{ height: '50px', width: 'auto' }}
                              />
                            </TableCell>
                            <TableCell>
                              <Text color="dark" weight="medium">
                                {product.product?.product?.name}
                              </Text>
                              {((product.product &&
                                product.product.variant &&
                                product.product.variant.size) ||
                                (product.product &&
                                  product.product.variant &&
                                  product.product.variant.color)) && (
                                <Text
                                  size="h6"
                                  className={classes.sizeVariantProduct}
                                  color="secondaryDark"
                                >
                                  {product.product.variant.color
                                    ? `Color: ${product.product.variant.color} `
                                    : ''}
                                  {product.product.variant.size
                                    ? `Size: ${_.toUpper(product.product.variant.size)}`
                                    : ''}
                                </Text>
                              )}
                              {_.get(product, 'product.custom') &&
                                <>
                                  <Text
                                    size="h6"
                                    className={classes.sizeVariantProduct}
                                    color="secondaryDark"
                                  >
                                    Option: {_.get(product, 'product.custom')}
                                  </Text>
                                  {(_.get(product, 'couponCode') || _.get(product, 'product.custom')) && (
                                    <>
                                      <Text
                                        size="h6"
                                        className={classes.couponCode}
                                        color="secondaryDark"
                                      >
                                        <Box display="flex">
                                          <Box pr={1}>Code:</Box>
                                          <Box>
                                              <div>
                                                {_.get(product, 'couponCode.code', 'N/A')}
                                              </div>
                                          </Box>
                                        </Box>
                                      </Text>
                                      {_.get(product, 'couponCode') && <button
                                        className={classNames([classes.changeStatusCouponBtn], {
                                          [classes.usedCoupon]: _.get(product, 'couponCode.couponStatus') === 'Used',
                                          [classes.unusedCoupon]: _.get(product, 'couponCode.couponStatus') === 'Unused'
                                        })}
                                        onClick={() => handleShowModalUpdateCoupon(_.get(product, 'couponCode.id'), _.get(product, 'couponCode.couponStatusId'))}
                                      >
                                        {_.get(product, 'couponCode.couponStatus')}
                                      </button>}
                                    </>
                                  )}
                                </>
                              }
                              {_.get(product, 'product.consolidate') &&
                                <Text
                                  size="h6"
                                  weight="regular"
                                  className={classes.consolidateLabel}
                                >
                                  Consolidate
                                </Text>}
                            </TableCell>
                            <TableCell>{product.quantity}</TableCell>
                            <TableCell>{product.product.sku}</TableCell>
                            <TableCell>
                              <Price color="dark">{product.total}</Price>
                            </TableCell>
                            <TableCell>{RenderStatusProduct(product)}</TableCell>
                            {!isAuroraOrder && <TableCell>{RenderShippingProduct(product)}</TableCell>}
                          </TableRow>
                        ))}

                        {!isAuroraOrder ? (
                          <>
                           <TableRow>
                             <TableCell rowSpan={3} colSpan={5}></TableCell>
                             <TableCell
                               className={classes.normalLabelColumnItem}
                               style={{
                                 border: 'none'
                               }}
                             >
                               Order Total
                             </TableCell>
                             <TableCell
                               align="right"
                               style={{
                                 border: 'none'
                               }}
                               className={classes.normalLabelColumnItem}
                             >
                               <Price color="dark">{total}</Price>
                             </TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell className={classes.normalLabelColumnItem}>
                               Shipping
                             </TableCell>
                             <TableCell
                               align="right"
                               className={classes.normalLabelColumnItem}
                             >
                               FREE
                             </TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>
                               <Text size="h5" weight="medium">
                                 Checkout Total
                               </Text>
                             </TableCell>
                             <TableCell align="right">
                               <Text size="h5" color="primaryLight">
                                 <Price
                                   color="dark"
                                   style={{
                                     color: '#00ADEF',
                                     fontWeight: 500
                                   }}
                                 >
                                   {total}
                                 </Price>
                               </Text>
                             </TableCell>
                           </TableRow>
                         </>
                        ) : (
                          <TableRow>
                             <TableCell rowSpan={3} colSpan={4}></TableCell>
                             <TableCell>
                               <Text size="h5" weight="medium">
                                 Checkout Total
                               </Text>
                             </TableCell>
                             <TableCell align="right">
                               <Text size="h5" color="primaryLight">
                                 <Price
                                   color="dark"
                                   style={{
                                     color: '#00ADEF',
                                     fontWeight: 500
                                   }}
                                 >
                                   {total}
                                 </Price>
                               </Text>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Grid item xs={12}>
                    <Text
                      size="h4"
                      style={{
                        textAlign: 'left',
                        backgroundColor: '#E6E6E6',
                        color: '#000000',
                        paddingLeft: '16px',
                        marginBottom: '-2px'
                      }}
                    >
                      Status History
                    </Text>
                  </Grid>

                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table size="small" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.normalLabelColumnItem}>
                            Date
                          </TableCell>
                          <TableCell className={classes.normalLabelColumnItem}>
                            Time
                          </TableCell>
                          <TableCell className={classes.normalLabelColumnItem}>
                            Update
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {timeline.map(t => (
                          <TableRow key={t.id}>
                            <TableCell style={{ fontFamily: 'Actual' }}>
                              <Moment format="MM/DD/YYYY">{t.createdAt}</Moment>
                            </TableCell>
                            <TableCell style={{ fontFamily: 'Actual' }}>
                              <Moment format="HH:mm">{t.createdAt}</Moment>
                            </TableCell>
                            <TableCell style={{ fontFamily: 'Actual' }}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `Changed to ${t.name} by the ${t.firstName} ${t.lastName}`
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            </Grid>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title-new"
        aria-describedby="transition-modal-description-new"
        className={classes.modal}
        open={openFormUpdateCoupon}
        onClose={() => setOpenFormUpdateCoupon(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openFormUpdateCoupon}>
          <Alert
            style={{
              width: '40rem',
              padding: '0px 0px 32px 0px',
              overflowX: 'hidden'
            }}
          >
            <Grid container justify="flex-end">
              <IconButton>
                <CloseOutlinedIcon onClick={() => setOpenFormUpdateCoupon(false)} fontSize="default" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.textDel}>
              Are you sure you want to update the coupon status?
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={() => setOpenFormUpdateCoupon(false)}
                  minWidth="158px"
                  style={{
                    padding: '1rem 2rem'
                  }}
                >
                  No
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  color="primary"
                  onClick={() => handleUpdateStatusCoupon()}
                  minWidth="158px"
                  style={{
                    padding: '1rem 2rem'
                  }}
                >
                  { loadingChangeCouponStatus ? <CustomLoading size={16} color="#fff" /> : 'Yes' }
                </CustomButton>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
    </>
  );
}

function processLinks(arr, shippingList) {
  let result = [...arr];
  result.forEach(o => {
    let trackingStr = 'Processing';
    let link = null;
    if (o.shippingCompany && o.trackingNumber) {
      trackingStr = `${_.toUpper(o.shippingCompany)} - ${o.trackingNumber}`;
      shippingList.forEach(shipping => {
        if (o.shippingCompany === shipping.name) {
          switch (o.shippingCompany) {
            case 'usps':
              link =
                config.urlTools +
                '?number=' +
                o.trackingNumber;
              break;
            case 'ups':
              link =
                `${config.urlUps}&number=${o.trackingNumber}&requester=ST/`
              break;
            case 'fedex':
              link =
                 config.urlFeDex +
                '&number=' +
                o.trackingNumber;
              break;
            default:
          }
        }
      });
    }
    o.trackingStr = trackingStr;
    o.link = link;
  });
  return result;
}
