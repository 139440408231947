import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    margin: 0,
    padding: "32px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const CustomDialog = withStyles(theme => ({
  root: {
    "& .MuiPaper-rounded": {
      borderRadius: 0
    },
    "& .MuiDialog-paperWidthSm": {
      width: "709px",
      minWidth: "709px",
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset'
      }
    }
  }
}))(Dialog);

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderTop: 0,
    borderBottom: 0
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    marginBottom: "80px"
  }
}))(MuiDialogActions);

const DeleteButton = withStyles(theme => ({
  root: {
    borderRadius: "20px",
    width: "200px",
    backgroundColor: "#DF3719",
    textTransform: 'none',
    "&.MuiButton-textPrimary": {
      color: "#FFFFFF"
    },
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#DF3719"
    }
  }
}))(Button);

const CancelButton = withStyles(theme => ({
  root: {
    border: "1px solid #9F9F9F",
    borderRadius: "20px",
    width: "200px",
    fontWeight: "bold",
    textTransform: 'none',
    "&.MuiButton-textPrimary": {
      color: "#9F9F9F"
    },
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  }
}))(Button);

const CustomCircularProgress = withStyles(theme => ({
  root: {
    "&.MuiCircularProgress-colorPrimary": {
      color: '#FFFFFF'
    }
  }
}))(CircularProgress);

export default function DeleteVariantModal({ open, handleClose, handleDelete }) {
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    try {
      setLoading(true);
      await handleDelete();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }

  return (
    <div>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" gutterBottom>
                Delete this variant?
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Once you do this, it can't be undone
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <CancelButton autoFocus onClick={handleClose} color="primary">
              Cancel
            </CancelButton>
            <DeleteButton
              disableRipple
              autoFocus
              onClick={onDelete}
              color="primary"
              disabled={loading}
            >
              { !loading && "Delete" }
              { loading && <CustomCircularProgress size={18} /> }
            </DeleteButton>
          </Box>
        </DialogActions>
      </CustomDialog>
    </div>
  );
}
