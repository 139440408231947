import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import ConfigurationsModules from './configurations.modules';
import ConfigurationsForecasts from './configurations.forecasts';
import ApprovalsModules from './approvals.modules';
import ApprovalsForecasts from './approvals.forecasts';
import ArticleCodeUpload from './upload.articleCode';
import SerialNumberUpload from './upload.serialNumber';
import NotFound from './../../common/notfound'
import UpdateModules from './approvals.updateModules';
import QPointManagement from './QpointManagement';
import { RoleContext } from './../../contexts/roles';

export default function Rewards() {
  const { rewardRoutes } = useContext(RoleContext);

  return (
    <Switch>
      {rewardRoutes.map(route => (
        <Route exact key={route.path} path={route.path} component={route.component} />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
}
