import React, { useEffect, useState } from 'react';
import Button from '../../../common/components/button';
import CustomButton from '../../../common/components/button';
import Text from '../../../common/components/text';
import {
  Backdrop,
  Checkbox, Fade,
  Grid, IconButton,
  MenuItem, Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { academyService, dataHubService } from '../../../services';
import Pagination from '@material-ui/lab/Pagination';
import CustomLoading from '../../../common/components/CustomLoading';
import _ from 'lodash';
import { notiFailed, notiSuccess } from '../../../utils/helpers';
import Alert from '../../rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {useHistory} from 'react-router-dom';
import config from '../../../config/index';

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    marginTop: '20px',
    borderRadius: 0,
    '& td': {
      fontFamily: 'Actual'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    },
  },
  tableCellPlaceholder: {
    width: '1vw'
  },
  tableHeaderProductImage: {
    width: '72.64px'
  },
  tableProductColumn: {
    maxWidth: '200px',
    width: '25%'
  },
  tableHeaderInventory: {
    width: '15%'
  },
  tableHeaderPrice: {
    width: '15%'
  },
  tableHeaderStatus: {
    width: '13%'
  },
  paginationContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    '& .Mui-selected': {
      backgroundColor: '#00ADEF',
      color: '#fff'
    }
  },
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '40px'
  },
  tableHeaderCell: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1rem'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  updatePriorityModalTitle: {
    textAlign: 'center',
    marginTop: '15px',
    fontSize: '36px',
    lineHeight: '45px'
  },
  updatePriorityModalSubtitle: {
    marginTop: '-32px',
    marginBottom: '20px',
    fontSize: '20px !important'
  },
  tableHeaderRow : {
    height : 60
  }
}));

export default function MainDataHub() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [dataHubData, setDateHubData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [checked, setChecked] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [record, setTotalRecord] = useState(1);
  const [openFormDelete, setOpenFormDelete] = useState(false);
  const [type, setType] = useState('all');
  const total = Math.ceil(record / 10);

  const getDataHubData = async (condition) => {
    setLoading(true)
    const info = await dataHubService.getListDataHub(condition)
    setDateHubData(info?.data?.data);
    setTotalRecord(info?.data?.total);
    setLoading(false)
  };

  async function getListCategories() {
    const info = await dataHubService.getListCategoryDataHub();
    setListCategory(info.data);
  }

  function handleChangePage(event, page) {
    setCurrentPage(page)
  }

  useEffect(() => {
    getDataHubData({page : currentPage});
    getListCategories();
  }, []);

  useEffect(() => {
    const makePrams = {
      page : currentPage,
      ... type === 'all' ? {} : {categoryId : type}
    }

    getDataHubData(makePrams);
  }, [currentPage, type]);

  function handleSelectType(event) {
    setType(event.target.value);
    setCurrentPage(1);
  }

  function handleCheck(value) {
    const doesExist = checked.some(c => c.id === value.id);
    let newCheck = [...checked];
    if (doesExist) {
      newCheck = checked.filter(c => c.id !== value.id);
    } else {
      newCheck.push(value);
    }

    setChecked(newCheck);

  }
  async function handleShowModalDelete() {
    const ids = _.map(checked, 'id');
    if(ids.length === 0) {
      return notiFailed('Please select item')
    }else {

    }
    setOpenFormDelete(true);

  }

  async function handleDeleteDataHub() {
    const ids = _.map(checked, 'id');

      dataHubService.deleteDataHub(ids).then(() => {
      notiSuccess('Deleted successfully');
      const makePrams = {
        page : 1,
        ... type === 'all' ? {} : {categoryId : type}
      }
      getDataHubData(makePrams);
      setOpenFormDelete(false);

      setCurrentPage(1);
    }).catch(() => {
      notiFailed(`Deleted Failed`);
      setOpenFormDelete(false);
    })
  }

  return (
    <>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{
            marginBottom: 10
          }}
        >
          <Text size="h2" style={{ lineHeight: '0px' }}>
            Data Hub
          </Text>
        </Grid>
      <Paper className={classes.container}>
        <Grid style={{ padding: '40px 30px 15px' }} container spacing={1} justify="space-between">
          <Grid item xs={12} md={2} lg={2} sm={2}>
            <TextField
              fullWidth
              select
              size="small"
              variant="outlined"
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }
              }}
              label="Type"
              value={type}
              onChange={handleSelectType}
            >
              <MenuItem key={'all'} value={'all'}>All</MenuItem>
              {listCategory?.map(ft => (
                <MenuItem key={ft.id} value={ft.id}>{ft.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  style={{ height: '40px' }}
                  component={Link}
                  to="/admincontrols/data-hub/portal"
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


      <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            size="small"
            style={{
              borderBottom: 'solid',
              borderWidth: '1px',
              borderColor: '#8C8C8C',
              margin: 'auto'
            }}
          >
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                {/* {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>} */}
                <TableCell className={classes.tableHeaderProductImage}></TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableProductColumn}`}>
                  Information
                </TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderInventory}`}>
                  Products
                </TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderPrice}`}>
                  Language
                </TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderStatus}`}>
                  File format
                </TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderStatus}`}>
                  File size
                </TableCell>
                <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderStatus}`}>
                  Select
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {loading ? (
                  <TableRow>
                    <TableCell colSpan="12">
                      <div className={classes.loadingIndicator}>
                        <CustomLoading size={40} color="#00adef"/>
                      </div>
                    </TableCell>
                  </TableRow>
                ):
                (
                  <>
                    {dataHubData?.map(data => {
                      return (
                        <TableRow hover onClick={() => history.push(`/admincontrols/data-hub/portal/${data.id}`)} style={{cursor: 'pointer'}}>
                          <TableCell>
                            <img
                              src={data?.image?.assetUrl || `${config.linkDefault}`}
                              style={{
                                width: '72.64px',
                                height: '72.64px',
                                margin: 'auto'
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Text
                              weight="medium"
                            >
                              {data.title}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              weight="medium"
                            >

                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              weight="medium"
                            >
                              {data.language}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              weight="medium"
                            >
                              {_.get(data, 'attachment.assetKey')?.split('.')?.pop().toUpperCase()}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              weight="medium"
                            >
                              {Math.round(data?.fileSize / 1024)} KB
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              color="#000000"
                              onChange={() => handleCheck(data)}
                              // checked={checked.some(
                              //   c => c.companyId === m.companyId
                              // )}
                              style={{ color: '#9ca4ab' }}
                              onClick={e => e.stopPropagation()}
                            />
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                )}


            </TableBody>
          </Table>
        </TableContainer>
        <Grid style={{ padding: '0px 30px 15px' }} container>
          <Grid
            style={{paddingRight: 10, marginTop: 35, marginBottom: 30}}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            xs={12} md={12} lg={12} sm={12}
          >
            <em>* Delete selected items</em>
            <CustomButton onClick={handleShowModalDelete} style={{marginLeft: 8}} color="secondary">
              DELETE
            </CustomButton>
          </Grid>

        </Grid>
      </Paper>
      { total !== 0 && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.paginationContainer}
        >
          <Pagination count={total} page={currentPage} onChange={handleChangePage}/>
        </Grid>
      ) }

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openFormDelete}
        onClose={() => setOpenFormDelete(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openFormDelete}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={() => setOpenFormDelete(false)}
                // className={classes.updatePriorityCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.updatePriorityModalTitle}>
              Are you sure you want to delete ?
            </Text>
            <Text
              color="secondaryDark"
              className={classes.updatePriorityModalSubtitle}
            >
              Once you do this, it can't be undone.
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={() => setOpenFormDelete(false)}
                  className={classes.updatePriorityModalCancelBtn}
                >
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  color="alert"
                  onClick={handleDeleteDataHub}
                  className={classes.updatePriorityModalDeleteBtn}
                >
                  Delete
                </CustomButton>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
    </>
  )
};
