import * as news from './news';
import * as contact from './contact';
import * as admin from './admin';

import * as profile from './profile';
import * as academy from './academy';
import * as dataHub from './data-hub';

export const newsService = news;
export const contactService = contact;
export const adminService = admin;
export const academyService = academy;
export const dataHubService = dataHub;
export const profileService = profile;
