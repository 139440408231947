import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    TableContainer,
    Grid,
    Paper,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Modal,
    Backdrop,
    Fade,
    makeStyles,
    withStyles,
    CircularProgress,
    MenuItem
} from '@material-ui/core';
import Text from '../../common/components/text';
import Button from '../../common/components/button';
import Pagination from '@material-ui/lab/Pagination';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import { notiFailed } from './../../utils/helpers';
import CustomLoading from './../../common/components/CustomLoading';
import NumberFormat from 'react-number-format';
import Alert from './../rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CustomButton from '../../common/components/button';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    tableHeaderCell: {
        color: '#9ca4ab',
        fontFamily: 'Actual',
        fontWeight: '400',
        fontSize: '1rem'
    },
    tableContainer: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        marginTop: '20px',
        borderRadius: 0,
        '& td': {
            fontFamily: 'Actual'
        },
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        }
    },
    container: {
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: 0,
        borderRadius: 0,
        boxShadow: 'none',
        marginBottom: '30px',
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    paginationContainer: {
        marginTop: '20px',
        marginBottom: '20px',
        '& .Mui-selected': {
            backgroundColor: '#00ADEF',
            color: '#fff'
        }
    },
    loadingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    noFaqContainer: {
        width: '95%',
        margin: 'auto',
        textAlign: 'center',
        backgroundColor: '#e5f3fb',
        paddingTop: '50px',
        paddingBottom: '50px',
        marginTop: '50px',
        marginBottom: '50px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    updatePriorityModalTitle: {
        textAlign: 'center', 
        marginTop: '15px',
        fontSize: '36px',
        lineHeight: '45px'
    },
    updatePriorityModalSubtitle: {
        marginTop: '-32px', 
        marginBottom: '20px',
        fontSize: '20px !important'
    },
    updatePriorityModalCancelBtn: {
        padding: '.75rem 2rem !important'
    },
    updatePriorityModalDeleteBtn: {
        padding: '.75rem 2rem !important'
    },
    updatePriorityModuleModalCloseBtn: {
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '-20px'
    },
    tableRow: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            cursor: 'pointer'
        }
    },
    priorityTextField: {
        '& input': {
            backgroundColor: '#fff'
        }
    }
}));

function PriorityNumberFormat(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator
        isNumericString
        allowNegative={false}
      />
    );
}

export default function Faq() {
    const history = useHistory();
    const classes = useStyles();
    const [faqs, setFaqs] = useState([]);
    const [countFaq, setCountFaq] = useState(0);
    const [faqTypes, setFaqTypes] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState({ limit, offset });
    const [selectedFaqType, setSelectedFaqType] = useState('all');
    const [loadingFaq, setLoadingFaq] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPage, setCountPage] = useState(1);
    const [openUpdatePriorityModal, setOpenUpdatePriorityModal] = useState(false);

    const getFaqs = async (filters = {}) => {
        try {
            setLoadingFaq(true);
            const { data } = await axios.get(`${baseUrl}/faq/search`, { params: filters });
            setFaqs(data.data);
            setCountFaq(data.total);
            const numberPage = Math.ceil(data.total / limit);
            setCountPage(numberPage);
        } catch (err) {
            notiFailed(`There was a problem getting faq ${err.message}`);
        } finally {
            setLoadingFaq(false);
        }
    };

    const getFaqTypes = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/faq/types`);
            setFaqTypes(data);
        } catch (err) {
            notiFailed(`There was a problem getting faq ${err.message}`);
        }
    };

    const handleSelectFaqType = e => {
        const newFaqType = e.target.value;
        const newFilters = {
            ...filters,
            offset: 0
        };
        if (newFaqType === 'all') {
            delete newFilters.faqTypeId;
        } else {
            newFilters.faqTypeId = newFaqType;
        }
        setSelectedFaqType(newFaqType);
        setCurrentPage(1);
        setOffset(0);
        setFilters(newFilters);
        getFaqs(newFilters);
    };

    const handleChangePage = (event, currentPage) => {
        const newOffset = limit * currentPage - limit;
        const newFilters = {
            ...filters,
            offset: newOffset
        };
        setCurrentPage(currentPage);
        setOffset(newOffset);
        setFilters(newFilters);
        getFaqs(newFilters);
    };

    const handleChangePriority = (id, value) => {
        setFaqs(faqs.map(f => {
            if (f.id === id) {
                return {
                    ...f,
                    priority: Number(value)
                }
            }

            return f;
        }))
    };

    const handleCloseUpdatePriorityModal = () => {
        setOpenUpdatePriorityModal(false);
    };

    const handleUpdatePriority = async () => {
        try {
            const priorityData = faqs.map(f => ({ id: f.id, priority: f.priority }));
            await axios.post(`${baseUrl}/faq/priority`, priorityData);
            handleCloseUpdatePriorityModal();
            getFaqs(filters);
        } catch (err) {
            notiFailed(`There was a problem update faq priority ${err.message}`);
        }
    };

    useEffect(() => {
        getFaqs(filters);
        getFaqTypes();
    }, []);

    return (
        <>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{
                    marginBottom: 10
                }}
            >
                <Text size="h2" style={{ lineHeight: '0px' }}>
                    FAQ
                </Text>
            </Grid>
            <Paper className={classes.container}>
                <Grid style={{ padding: '40px 30px 15px' }} container spacing={1} justify="space-between">
                    <Grid item xs={12} md={2} lg={2} sm={2}>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            variant="outlined"
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                }
                            }}
                            label="Type"
                            value={selectedFaqType}
                            onChange={handleSelectFaqType}
                        >
                            <MenuItem key={'all'} value={'all'}>All</MenuItem>
                            {faqTypes.map(ft => (
                                <MenuItem key={ft.id} value={ft.id}>{ft.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    style={{ height: '40px' }}
                                    color="secondary"
                                    onClick={() => {
                                        setOpenUpdatePriorityModal(true);
                                    }}
                                >
                                    Priority
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    style={{ height: '40px' }}
                                    component={Link}
                                    to="/admincontrols/faq/add"
                                >
                                    Register
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeaderCell} align='center'>No.</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>Type</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>Title</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>Priority</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loadingFaq ? (
                                <TableRow>
                                    <TableCell colSpan="6">
                                        <div className={classes.loadingIndicator}>
                                            <CustomLoading size={40} color="#00adef"></CustomLoading>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>  
                                    {
                                        countFaq === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan="6">
                                                    <div className={classes.noFaqContainer}>
                                                        <Text color="primaryLight" weight="bold">
                                                            No faq.
                                                        </Text>
                                                    </div>
                                                </TableCell>
                                            </TableRow> 
                                        ) : faqs.map((f, idx) => (
                                            <TableRow 
                                                key={f.id} 
                                                className={classes.tableRow}
                                                onClick={() => history.push(`/admincontrols/faq/${f.id}`)}
                                            >
                                                <TableCell align='center'>{countFaq - (currentPage - 1) * limit - idx}</TableCell>
                                                <TableCell align='center'>{f.typeName}</TableCell>
                                                <TableCell>{f.title}</TableCell>
                                                <TableCell align='center'>
                                                    <TextField
                                                        value={f.priority}
                                                        placeholder="User Priority"
                                                        variant="outlined"
                                                        onChange={e => handleChangePriority(f.id, e.target.value)}
                                                        InputProps={{
                                                            inputComponent: PriorityNumberFormat
                                                        }}
                                                        onClick={e => e.stopPropagation()}
                                                        className={classes.priorityTextField}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            { !loadingFaq && countPage > 1 && (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.paginationContainer}
                >
                    <Pagination count={countPage} page={currentPage} onChange={handleChangePage}/>
                </Grid>
            ) }

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openUpdatePriorityModal}
                onClose={handleCloseUpdatePriorityModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openUpdatePriorityModal}>
                    <Alert>
                        <Grid container justify="flex-end">
                        <IconButton
                            onClick={handleCloseUpdatePriorityModal}
                            className={classes.updatePriorityCloseBtn}
                        >
                            <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
                        </IconButton>
                        </Grid>
                        <Text className={classes.updatePriorityModalTitle}>
                            Are you sure you want to update priority ?
                        </Text>
                        <Text
                            color="secondaryDark"
                            className={classes.updatePriorityModalSubtitle}
                        >
                            Once you do this, it can't be undone.
                        </Text>
                        <Grid
                            container
                            justify="center"
                            spacing={2}
                            style={{ marginBottom: '20px' }}
                        >
                        <Grid item>
                            <CustomButton
                                color="secondary"
                                onClick={handleCloseUpdatePriorityModal}
                                className={classes.updatePriorityModalCancelBtn}
                            >
                                Cancel
                            </CustomButton>
                        </Grid>
                        <Grid item>
                            <CustomButton
                                color="alert"
                                onClick={handleUpdatePriority}
                                className={classes.updatePriorityModalDeleteBtn}
                            >
                                Update Priority
                            </CustomButton>
                        </Grid>
                        </Grid>
                    </Alert>
                </Fade>
            </Modal>
        </>
    )
}