import React from 'react';
import SquareButton from '../../common/components/button/SquareButton';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import Text from '../../common/components/text';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Pagination = ({
  postsPerPage,
  totalPosts,
  paginate,
  pagenumber,
  orderstatus
}) => {
  let pageNumbers = [];
  let pageGroups = {};
  let currentPageGroup = [];
  let pageGroupCount = 0;

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    if (i === Math.ceil(totalPosts / postsPerPage)) {
      pageNumbers.push(i);
      pageGroupCount += 1;
      pageGroups[pageGroupCount] = pageNumbers;
      pageNumbers = [];
    } else if (pageNumbers.length === 10) {
      pageGroupCount += 1;
      pageGroups[pageGroupCount] = pageNumbers;
      pageNumbers = [];
    }
    pageNumbers.push(i);
    currentPageGroup = pageGroups[1];
  }

  const keys = Object.keys(pageGroups);
  for (const key of keys) {
    if (pageGroups[key].indexOf(+pagenumber) > -1) {
      currentPageGroup = pageGroups[key];
    }
  }

  return (
    <Grid
      container
      justify="center"
      item
      xs={12}
      style={{ margin: '0px' }}
      wrap="nowrap"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        {+pagenumber === 1 ? (
          <SquareButton
            disabled={+pagenumber === 1}
            style={{
              width: '30px',
              height: '30px',
              visibility: 'hidden',
              borderColor: '#cccccc'
            }}
          >
            <Link
              onClick={() => paginate(+pagenumber - 1)}
              to={`/marketing-shop/orders/${orderstatus}/${+pagenumber - 1}`}
              style={{ textDecoration: 'none', color: '#8c8c8c' }}
            >
              <LeftArrowIcon />
            </Link>
          </SquareButton>
        ) : (
          <SquareButton
            disabled={+pagenumber === 1}
            style={{ width: '30px', height: '30px', borderColor: '#cccccc' }}
          >
            <Link
              onClick={() => paginate(+pagenumber - 1)}
              to={`/marketing-shop/orders/${orderstatus}/${+pagenumber - 1}`}
              style={{ textDecoration: 'none', color: '#8c8c8c' }}
            >
              <LeftArrowIcon />
            </Link>
          </SquareButton>
        )}
      </Grid>

      {pageGroups[1]
        ? pageGroups[1].length > 1
          ? currentPageGroup.map(number => (
              <Grid item>
                <li key={number} style={{ listStyle: 'none' }}>
                  <Link
                    onClick={() => paginate(number)}
                    to={`/marketing-shop/orders/${orderstatus}/${number}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      size="h4"
                      color={pagenumber != number ? 'secondaryDark' : 'primaryLight'}
                    >
                      {number}
                    </Text>
                  </Link>
                </li>
              </Grid>
            ))
          : false
        : false}

      <Grid item>
        {!pageNumbers.length || pageNumbers[pageNumbers.length - 1] === +pagenumber ? (
          <SquareButton
            disabled={pageNumbers[pageNumbers.length - 1] === +pagenumber}
            style={{
              width: '30px',
              height: '30px',
              visibility: 'hidden',
              borderColor: '#cccccc'
            }}
          >
            <Link
              onClick={() => paginate(+pagenumber + 1)}
              to={`/marketing-shop/orders/${orderstatus}/${+pagenumber + 1}`}
              style={{ textDecoration: 'none', color: '#8c8c8c' }}
            >
              <RightArrowIcon />
            </Link>
          </SquareButton>
        ) : (
          <SquareButton
            disabled={pageNumbers[pageNumbers.length - 1] === +pagenumber}
            style={{ width: '30px', height: '30px', borderColor: '#cccccc' }}
          >
            <Link
              onClick={() => paginate(+pagenumber + 1)}
              to={`/marketing-shop/orders/${orderstatus}/${+pagenumber + 1}`}
              style={{ textDecoration: 'none', color: '#8c8c8c' }}
            >
              <RightArrowIcon />
            </Link>
          </SquareButton>
        )}
      </Grid>
    </Grid>
  );
};

export default Pagination;
