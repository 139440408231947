import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

const datePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#00ADEF'
      }
    },
    MuiPickersDay: {
      root: {
        '&:focus': {
          color: '#00ADEF'
        }
      },
      daySelected: {
        backgroundColor: '#00ADEF'
      }
    },
    MuiPickersMonth: {
      root: {
        '&:focus': {
          color: '#00ADEF'
        }
      },
      monthSelected: {
        color: '#00ADEF'
      }
    },
    MuiPickersYear: {
      root: {
        '&:focus': {
          color: '#00ADEF'
        }
      },
      yearSelected: {
        color: '#00ADEF'
      }
    }
  }
});

const DatePicketCustom = props => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={datePickerTheme}>
        <DatePicker
          disablePast
          fullWidth
          size="small"
          variant="inline"
          inputVariant="outlined"
          autoOk={true}
          {...props}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default DatePicketCustom;
