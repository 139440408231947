import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Fade, Grid, IconButton, Modal } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Text from '../../common/components/text';
import Button from '../../common/components/button';
import CustomLoading from './CustomLoading';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '35rem',
    padding: 15,
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  }
}));

export default function AlertComponent(props) {
  const classes = useStyles();
  const { open, handleClose, handleDelete, content, title, loading } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Grid container justify="flex-end">
            <IconButton onClick={handleClose} style={{ padding: 0 }}>
              <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
            </IconButton>
          </Grid>
          <Text
            size="h3"
            style={{
              marginTop: '0px',
              marginBottom: '0px'
            }}
          >
            {title}
          </Text>
          <Text size="subtitle" color="secondaryDark">
            {content}
          </Text>
          <Grid container justify="center" spacing={1}>
            <Grid item>
              <Button color="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button color="alert" onClick={handleDelete}>

                {loading ? <CustomLoading size={18}/> : 'Delete'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
