import React from 'react';
import './notfound.css';

const NotFound = props => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2>Oops! This Page Could Not Be Found</h2>
        <p>
          SORRY BUT THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST, HAS BEEN REMOVED OR IS TEMPORARILY UNAVAILABLE.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
