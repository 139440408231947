import axios from './../utils/axios';
import config  from "../config";

function getListTypeOnline(condition) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/academy/types`,
    params : condition
  }

  return axios(request);
}


function addAcademy(formData) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/academy`,
    data : formData
  }

  return axios(request);
}

function getListProduct(condition) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/academy/search`,
    params : condition
  }

  return axios(request);
}

function updateProduct(formData) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/academy`,
    data : formData
  }

  return axios(request);
}

function deleteProduct(ids) {
  const request = {
    method : 'DELETE',
    url : `${config.baseUrl}/academy/delete`,
    data : ids
  }

  return axios(request);
}

function updatePriority(ids) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/academy/priority`,
    data : ids
  }

  return axios(request);
}


function getDetailProduct(id) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/academy/detail/${id}`
  }

  return axios(request);
}

export {getListTypeOnline, addAcademy, getListProduct, deleteProduct, updatePriority, getDetailProduct, updateProduct}
