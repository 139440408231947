import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';
import LeftChevron from '@material-ui/icons/ChevronLeft';

const StyledButton = styled(ButtonBase)(
  ({ theme: { colors } }) => `
  color: ${colors.secondaryDark};
  font-size: 1rem;
  line-height: 1rem;

  &:hover {
    color: ${colors.primaryLight};
  }
`
);

const Icon = styled(LeftChevron)`
  font-size: 1rem;
  margin-right: 1rem;
`;

/**
 * @name BackButton
 * @description A Button with a back arrow
 * @param {node} children The content inside the button. ie "Back"
 * @implements {ButtonBase}
 */
const BackButton = ({ children, ...props }) => {
  return (
    <StyledButton disableRipple disableTouchRipple {...props}>
      <Icon />
      {children}
    </StyledButton>
  );
};

BackButton.propTypes = {
  children: PropTypes.node.isRequired,
  ...ButtonBase.propTypes
};

export default BackButton;
