import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import ToastImg from './../config/images/toast-icon.png';
import ToastImgFailed from './../config/images/toast-icon-failed.png';
import React from 'react';
import _ from 'lodash'

export const notiSuccess = content => {
  toast(
    () => (
      <div className="noti_body">
        <div className="noti_img">
          {' '}
          <img src={ToastImg} alt="" />{' '}
        </div>
        <div className="noti_content">{content}</div>
      </div>
    ),
    {
      className: 'toast-body',
      progressClassName: 'toast-progress-bar'
    }
  );
};

export const notiFailed = content => {
  toast(
    () => (
      <div className="noti_body">
        <div className="noti_img">
          {' '}
          <img src={ToastImgFailed} alt="" />{' '}
        </div>
        <div className="noti_content">{content}</div>
      </div>
    ),
    {
      className: 'toast-error-body',
      progressClassName: 'toast-error-progress-bar'
    }
  );
};

export const decodeHtml = html => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const warningError = (err, defaultMsg) => {
  let e1 = _.get(err, 'message');
  let e2 = _.get(err, 'data.message');
  let e3 = _.get(err, 'request.responseText');
  if (!!e1) {
    notiFailed(e1);
  } else if (!!e2) {
    notiFailed(e2);
  } else if (!!e3) {
    let errMsg = e3
      .match(/<pre>.{1,1000}?<br>/i)[0]
      .replace('<pre>', '')
      .replace('<br>', '');
    notiFailed(errMsg);
  } else {
    notiFailed(!!defaultMsg ? defaultMsg : 'Error!');
  }
};

export const downloadReport = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
};

export const convertHTMLEntity = str => {
  return String(str)
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"');
};


export const decodeStringHtml = (string) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(`<!doctype html><body>${string}`, 'text/html').body.textContent;

  return decodedString || '';
}
