import React, { useState, useEffect, useRef } from 'react';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import Text from '../../common/components/text';
import Button from '../../common/components/button';
import BackButton from '../../common/components/button/BackButton';
import { Link, useParams } from 'react-router-dom';
import AlertDeleteComponent from './../../common/components/AlertDeleteComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import 'react-dropzone-uploader/dist/styles.css';

import {
  TextField,
  Grid,
  Paper,
  MenuItem,
  IconButton,
  InputLabel
} from '@material-ui/core';

import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import ProductOption from './ProductOption';
import NumberFormat from 'react-number-format';
import ProductUploader from './ProductUploader';
import ProductUploaderEdit from './ProductUploaderEdit';
import LeavePageModal from './LeavePageModal';
import DeleteVariantModal from './DeleteVariantModal';
import VariantImageSelect from './VariantImageSelect';
import ProductOptionEdit from './ProductOptionEdit';
import { notiSuccess, notiFailed } from './../../utils/helpers';
import _ from 'lodash';
import JoditEditor from 'jodit-react';

function PriceNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      suffix=" Q"
      allowNegative={false}
    />
  );
}

function QuantityNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
}

const CustomCircularProgress = withStyles(theme => ({
  root: {
    '&.MuiCircularProgress-colorPrimary': {
      color: '#FFFFFF'
    }
  }
}))(CircularProgress);

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'flex-start',
    '& > div': {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      '& > div': {
        padding: theme.spacing(1, 0)
      }
    },
    '& > div.stickyTopbar': {
      padding: '50px 57px 10px 87px'
    },
    '& > div.pageContent': {
      paddingLeft: '82px',
      paddingRight: '57px'
    }
  },
  paddingBottomTwoSpacing: {
    paddingBottom: theme.spacing(2)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    position: 'relative',
    width: '76px',
    height: '75px',
    '&:hover div[role=overlay]': {
      opacity: 1
    }
  },
  overlay: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: 'rgba(0, 0, 0, 0.1)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: 0,
    transition: 'opacity 0.5s ease'
  },
  delete: {
    backgroundColor: '#FFFFFF',
    color: '#A9A9A9',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '5px 8px',
    cursor: 'pointer'
  },
  buttonItem: {
    height: 40,
    margin: 5
  },
  pageTitle: {
    lineHeight: 0
  },
  subtitle: {
    color: '#8c8c8c'
  },
  stickyTopbar: {
    position: 'sticky',
    top: '77px',
    zIndex: '999',
    marginBottom: '30px',
    backgroundColor: '#FAFAFA'
  },
  variantImage: {
    width: '76px',
    height: '75px',
    overflow: 'hidden',
    padding: 0,
    minHeight: '75px',
    maxHeight: '75px',
    border: '2px dashed #ACACAC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#2484FF',
    cursor: 'pointer'
  }
}));

export default function AddProducts(props) {
  const editor = useRef(null);

  const config = {
    readonly: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false
  };

  const allOptions = [
    {
      id: 1,
      option: 'Size',
      value: 'Size'
    },
    {
      id: 2,
      option: 'Color',
      value: 'Color'
    },
    {
      id: 3,
      option: 'Custom',
      value: 'Custom'
    }
  ];
  const classes = useStyles();
  const history = useHistory();
  const [variantsSelected, setVariantsSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [options, setOptions] = useState([
    { id: 1, option: 'Size', value: 'Size', options: allOptions }
  ]);
  const [shippingSelection, setShippingSelection] = useState('free');
  const [inventorySelection, setInventorySelection] = useState('unlimited');
  const [product, setProduct] = useState({});
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [variants, setVariants] = useState([]);
  const [commonPrice, setCommonPrice] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [productImages, setProductImages] = useState([]);
  const [inventoryQuantity, setInventoryQuantity] = useState(0);
  const [inventorySkuNumber, setInventorySkuNumber] = useState('');
  const [toggleErrorTitle, setToggleErrorTitle] = useState(false);
  const [toggleErrorCategory, setToggleErrorCategory] = useState(false);
  const [visability, setVisability] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [openVariantImageSelectModal, setOpenVariantImageSelectModal] = useState(
    false
  );
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [openDeleteVariantModal, setOpenDeleteVariantModal] = useState(false);
  const [sizeSelected, setSizeSelected] = useState(false);
  const [colorSelected, setColorSelected] = useState(false);
  const [editOptions, setEditOptions] = useState([]);
  const [isHtml, setIsHtml] = useState(true);

  const [descHtml, setDescHtml] = useState(_.get(product, 'description', ''));
  const [descText, setDescText] = useState(_.get(product, 'description', ''));

  const [isContact, setIsContact] = useState(false);
  const [contactPrices, setContactPrices] = useState('');
  const [customs, setCustoms] = useState([]);

  const { id } = useParams();

  const isDirty = () => {
    return (
      variantsSelected ||
      selectedCategory !== '' ||
      visability ||
      shippingSelection !== 'free' ||
      inventorySelection !== 'unlimited' ||
      (product.name && product.name !== '') ||
      (product.description && product.description !== '') ||
      commonPrice !== 0 ||
      productImages.length > 0 ||
      inventorySkuNumber !== ''
    );
  };

  const makeid = length => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseDeletePopup = () => {
    setOpen(false);
  };

  const handleOpenLeaveModal = () => {
    setOpenLeaveModal(true);
  };

  const handleCloseLeaveModal = () => {
    setOpenLeaveModal(false);
  };

  const getCatergories = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/categories`);
      setCategories(data);
    } catch (err) {
      notiFailed(`There was a problem getting the categories ${err.message}`);
    }
  };

  const getProduct = async id => {
    try {
      const { data } = await axios.get(`${baseUrl}/products/${id}`);
      if (_.get(data, 'isHtml') === true) {
        setDescHtml(_.get(data, 'description'));
        setDescText('');
      } else {
        setDescText(_.get(data, 'description'));
        setDescHtml('');
      }

      if (_.get(data, 'isContact') === true) {
        setIsContact(true);
        setContactPrices(_.get(data, 'contactPrices', ''));
      }
      setIsHtml(_.get(data, 'isHtml', true));

      setProduct(data);
      if (data.categories.length) {
        setSelectedCategory(data.categories[0].id);
      }
      setCommonPrice(data.prices);
      setVisability(data.active);
      if (data.shipping === 'free') {
        setShippingSelection(data.shipping);
      } else {
        setShippingSelection('charge');
        setShippingPrice(Number(data.shipping));
      }

      const editOptions = [];
      let remainOptions = [...allOptions];
      if (data.sizes && data.sizes.length > 0) {
        setSizes(data.sizes);
        setSizeSelected(data.sizes.length > 0);
        editOptions.push({
          id: 1,
          option: 'Size',
          value: 'Size',
          options: remainOptions.filter(o => o.value !== 'Custom')
        });
        remainOptions = remainOptions.filter(o => o.value !== 'Size' && o.value !== 'Custom');
      } else {
        setSizes([]);
        setSizeSelected(false);
      }

      if (data.colors && data.colors.length > 0) {
        setColors(data.colors);
        setColorSelected(data.colors.length > 0);
        editOptions.push({
          id: 2,
          option: 'Color',
          value: 'Color',
          options: remainOptions.filter(o => o.value !== 'Custom')
        });
      } else {
        setColors([]);
        setColorSelected(false);
      }

      if (data.custom && data.custom.length > 0) {
        setCustoms(data.custom);
        editOptions.push({
          id: 3,
          option: 'Custom',
          value: 'Custom',
          options: remainOptions.filter(o => o.value === 'Custom')
        });
      } else {
        setCustoms([]);
      }

      if (editOptions.length === 0) {
        editOptions.push({
          id: 1,
          option: 'Size',
          value: 'Size',
          options: remainOptions
        });
      }

      setEditOptions(editOptions);
      if (
        (data.colors === null && data.sizes === null && data.custom === null) ||
        (data.colors.length === 0 && data.sizes.length === 0 && (!data.custom || data.custom.length === 0))
      ) {
        setVariants([]);
        setVariantsSelected(false);
        const variantDefault = data.variants[0] || {};
        setInventorySkuNumber(variantDefault.sku);
        setInventorySelection(variantDefault.unlimited ? 'unlimited' : 'limited');
        setInventoryQuantity(variantDefault.stock);
      } else {
        setVariants(data.variants);
        if (data.variants && data.variants.length > 0 && data.variants[0]) {
          setVariantsSelected(true);
        }
      }
    } catch (err) {
      notiFailed(`There was a problem getting the product.`);
    }
  };

  const addOptions = () => {
    const isChooseCustom = !!options.find(o => o.option === 'Custom');
    if (isChooseCustom) {
      return notiFailed(`You can't add other option with custom`);
    }
    let id = makeid(20);
    let remainOptions = [...allOptions];
    for (let option of options) {
      remainOptions = remainOptions.filter(o => o.option !== option.option);
    }
    if (!isChooseCustom) {
      remainOptions = remainOptions.filter(o => o.option !== 'Custom');
    }
    if (!remainOptions.length) return;
    setOptions([
      ...options,
      {
        id: id,
        option: remainOptions[0].option,
        value: remainOptions[0].value,
        options: remainOptions
      }
    ]);
  };

  const handleShippingSelection = selection => setShippingSelection(selection);
  const handleInventorySelection = selection => setInventorySelection(selection);
  const handleVariantsSelected = () => setVariantsSelected(!variantsSelected);
  const switchCustomOrder = () => setIsContact(!isContact);
  const handleProduct = (key, value) => {
    const updateProduct = { ...product, [key]: value };
    setProduct(updateProduct);
  };

  const handleDeleteProduct = async () => {
    try {
      await axios.delete(`${baseUrl}/products/${id}`);
      setOpen(false);
      history.push('/marketing-shop/products');
      notiSuccess('Delete product successfully');
    } catch (err) {
      notiFailed(`There was a deleting the selected product ${err.message}`);
    }
  };

  const handleRemoveOption = (id, type) => {
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    setOptions(options.filter(o => o.id !== id));
    if (type === 'Size') {
      let newVariants = [];
      for (let i = 0; i < colors.length; i++) {
        newVariants.push({
          color: colors[i].name,
          qpoints: commonPrice || 0,
          stock: 0,
          sku: '',
          lastUpdatedBy: user.id
        });
      }
      setVariants(newVariants);
      setSizes([]);
    } else if (type === 'Color') {
      let newVariants = [];
      for (let i = 0; i < sizes.length; i++) {
        newVariants.push({
          size: sizes[i],
          qpoints: commonPrice || 0,
          stock: 0,
          sku: '',
          lastUpdatedBy: user.id
        });
      }
      setVariants(newVariants);
      setColors([]);
    }
  };

  const handleChangeOption = (index, value) => {
    const newOptions = [...options];
    newOptions[index].option = value;
    newOptions[index].value = value;
    setOptions(newOptions);
  };

  const handleAddValue = (type, v) => {
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    if (type === 'Size') {
      let newVariants = [...variants];
      if (colors.length > 0) {
        if (sizes.length > 0) {
          for (let i = 0; i < colors.length; i++) {
            newVariants.push({
              size: v,
              color: colors[i].name,
              qpoints: commonPrice || 0,
              stock: 0,
              sku: '',
              lastUpdatedBy: user.id
            });
          }
        } else {
          for (let i = 0; i < newVariants.length; i++) {
            newVariants[i].size = v;
          }
        }
      } else {
        newVariants.push({
          size: v,
          qpoints: commonPrice || 0,
          stock: 0,
          sku: '',
          lastUpdatedBy: user.id
        });
      }

      setVariants(newVariants);

      setSizes([...sizes, v]);
    } else if (type === 'Color') {
      let newVariants = [...variants];
      if (sizes.length > 0) {
        if (colors.length > 0) {
          for (let i = 0; i < sizes.length; i++) {
            newVariants.push({
              size: sizes[i],
              color: v,
              qpoints: commonPrice || 0,
              stock: 0,
              sku: '',
              lastUpdatedBy: user.id
            });
          }
        } else {
          for (let i = 0; i < newVariants.length; i++) {
            newVariants[i].color = v;
          }
        }
      } else {
        newVariants.push({
          color: v,
          qpoints: commonPrice || 0,
          stock: 0,
          sku: '',
          lastUpdatedBy: user.id
        });
      }

      setVariants(newVariants);
      setColors([...colors, { name: v, hex: '' }]);
    } else if (type === 'Custom') {
      let newVariants = [...variants];
      newVariants.push({
        custom: v,
        qpoints: commonPrice || 0,
        stock: 0,
        sku: '',
        lastUpdatedBy: user.id
      });
      setVariants(newVariants);
      setCustoms([...customs, v]);
    }
  };

  const handleDeleteValue = (type, val) => {
    if (type === 'Size') {
      setVariants(variants.filter(v => v.size !== val));
      setSizes(sizes.filter(s => s !== val));
    } else if (type === 'Color') {
      setVariants(variants.filter(v => v.color !== val));
      setColors(colors.filter(c => c.name !== val));
    } else if (type === 'Custom') {
      setVariants(variants.filter(v => v.custom !== val));
      setCustoms(customs.filter(c => c !== val));
    }
  };

  const handleChangeCommonPrice = e => {
    setCommonPrice(Number(e.target.value));
  };

  const handleChangeCustomOrderText = e => {
    setContactPrices(e.target.value);
  };

  const handleChangeHex = (idx, val) => {
    const newColors = [...colors];
    newColors[idx].hex = val;
    setColors(newColors);

    const newVariants = [...variants];
    for (let i = 0; i < newVariants.length; i++) {
      if (newVariants[i].color === newColors[idx].name) {
        newVariants[i].hexCode = val;
      }
    }
    setVariants(newVariants);
  };

  const handleRemoveVariant = idx => {
    setVariants(variants.filter((v, i) => i !== idx));
  };

  const handleChangeVariant = (index, key, value) => {
    const newVariants = [...variants];
    newVariants[index][key] = value;
    setVariants(newVariants);
  };

  const handleChangeShippingPrice = price => {
    setShippingPrice(price);
  };

  const handleSave = async () => {
    const name = product.name;
    if (!name) {
      setToggleErrorTitle(true);

      return notiFailed(`Title can't be blank`);
    }

    if (!selectedCategory) {
      setToggleErrorCategory(true);
      return notiFailed(`Category can't be blank`);
    }

    if (!name || !selectedCategory) {
      return;
    }

    const categories = product.category ? [product.category] : [];
    const active = visability;
    const recommended = false;
    const shipping = shippingSelection;
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    const lastUpdateBy = user.id;
    const prices = Number(commonPrice);
    const unlimited = inventorySelection === 'unlimited';
    const files = productImages.map(p => p.file);
    const invSku = inventorySkuNumber;
    const invQuantity = inventoryQuantity;

    if (!id) {
      let formData = new FormData();
      formData.append('name', name);
      formData.append('categories', JSON.stringify(categories));
      formData.append('active', active);
      if (isHtml === true) {
        if (descHtml == `<p><br></p>` || descHtml == '') {
          formData.append('description', 'new product');
        } else {
          formData.append('description', descHtml);
        }
      } else {
        if (descText == '') {
          formData.append('description', 'new product');
        } else {
          formData.append('description', descText);
        }
      }

      formData.append('recommended', recommended);
      formData.append('isHtml', isHtml);
      if (shipping === 'free') {
        formData.append('shipping', shipping);
      } else {
        formData.append('shipping', shippingPrice);
      }

      if (variantsSelected) {
        formData.append('sizes', JSON.stringify(sizes));
        formData.append('colors', JSON.stringify(colors));
        formData.append('custom', JSON.stringify(customs));
        formData.append('variants', JSON.stringify(variants));
      } else {
        formData.append('sizes', JSON.stringify([]));
        formData.append('colors', JSON.stringify([]));
        formData.append('custom', JSON.stringify([]));
        formData.append('variants', JSON.stringify([]));
        formData.append('unlimited', unlimited);
        formData.append('sku', invSku);
        formData.append('quantity', invQuantity);
      }

      formData.append('isContact', isContact);
      if (isContact === true) {
        formData.append('contactPrices', contactPrices);
      } else {
        formData.append('prices', prices);
      }

      formData.append('lastUpdateBy', lastUpdateBy);
      formData.append('priority', 1);
      for (let i = 0; i < files.length; i++) {
        formData.append('media', files[i]);
      }
      const mediaOrders = [];
      for (let i = 0; i < files.length; i++) {
        mediaOrders.push({ id: i + 1, name: files[i].name });
      }
      formData.append('mediaOrders', JSON.stringify(mediaOrders));

      try {
        setLoading(true);
        const { data } = await axios.post(`${baseUrl}/products`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        setLoading(false);
        notiSuccess('Add product successfully');
        history.push(`/marketing-shop/add-products/${data.id}`);
      } catch (err) {
        setLoading(false);
        if (err.data && err.data.statusCode === '23505') {
          notiFailed(`SKU already exists`);
        } else if (err.data && err.data.statusCode === 500) {
          notiFailed(err.data.message);
        } else {
          notiFailed(`There was a problem when add new product`);
        }
      }
    } else {
      const editProductData = {
        name,
        categories: [{ id: selectedCategory }],
        active,
        recommended,
        shipping: shipping === 'free' ? shipping : shippingPrice,
        variants,
        lastUpdateBy,
        id,
        isContact,
        isHtml
      };

      if (isHtml === true) {
        if (descHtml == `<p><br></p>` || descHtml == '') {
          editProductData.description = 'new product';
        } else {
          editProductData.description = descHtml;
        }
      } else {
        if (descText === '') {
          editProductData.description = 'new product';
        } else {
          editProductData.description = descText;
        }
      }

      if (isContact === true) {
        editProductData.contactPrices = contactPrices;
      } else {
        editProductData.prices = prices;
      }

      if (variants && variants.length && variants[0] === null) {
        editProductData.variants = [];
      }

      if (!variantsSelected) {
        editProductData.unlimited = unlimited;
        editProductData.sku = invSku;
        editProductData.quantity = invQuantity;
      }

      if (!variantsSelected && (sizes.length > 0 || colors.length > 0 || (customs && customs.length > 0))) {
        editProductData.variants = [];
        editProductData.sizes = JSON.stringify([]);
        editProductData.colors = JSON.stringify([]);
        editProductData.custom = JSON.stringify([]);
        editProductData.deleteVariant = JSON.stringify(variants);
      }

      try {
        setLoading(true);
        const { data } = await axios.patch(
          `${baseUrl}/products/${id}`,
          editProductData
        );

        if (
          (data.colors === null && data.sizes === null && data.custom === null) ||
          (data.colors.length === 0 && data.sizes.length === 0 && data.custom && data.custom.length === 0)
        ) {
          setVariants([]);
          setColors([]);
          setSizes([]);
          setVariantsSelected(false);
          setEditOptions([
            { id: 1, option: 'Size', value: 'Size', options: allOptions }
          ]);
        }
        setLoading(false);
        notiSuccess('Edit product successfully');
      } catch (err) {
        setLoading(false);
        if (err.data && err.data.statusCode === '23505') {
          notiFailed(`SKU already exists`);
        } else if (err.data && err.data.statusCode === 500) {
          notiFailed(err.data.message);
        } else {
          notiFailed(`There was a problem when edit product`);
        }
      }
    }
  };

  const handleDiscard = () => {
    if (isDirty()) {
      return handleOpenLeaveModal();
    }

    history.push('/marketing-shop/products');
  };

  const handleLeave = () => {
    history.push('/marketing-shop/products');
  };

  const handleChooseVariantImage = async image => {
    if (selectedVariant !== null) {
      if (image !== null) {
        const variantId = variants[selectedVariant].id;
        try {
          const { data } = await axios.post(
            `${baseUrl}/productVariants/${variantId}/images`,
            {
              imageUrl: image.assetUrl
            }
          );
          const newVariants = [...variants];
          newVariants[selectedVariant].variantImages = [data];
          setVariants(newVariants);
        } catch (err) {
          notiFailed(`There was a problem updating variant image`);
        }
      } else {
      }
      setSelectedVariant(null);
      setOpenVariantImageSelectModal(false);
    }
  };

  const handleDeleteVariant = async () => {
    if (selectedVariant !== null) {
      const variant = variants[selectedVariant];
      let params = {};
      if (variant.variantImages && variant.variantImages[0]) {
        params = { variantImageId: variant.variantImages[0].id };
      }

      try {
        await axios.delete(`${baseUrl}/productVariants/${variant.id}`, { params });
        setVariants(variants.filter((v, i) => i !== selectedVariant));
      } catch (err) {
        notiFailed(`There was a problem deleting variant`);
      }
    }
    setSelectedVariant(null);
    setOpenDeleteVariantModal(false);
  };

  const addNewColorVariants = async v => {
    const newColors = [...colors, { name: v, hex: '' }];
    const name = product.name;
    const description = product.description ? product.description : 'new product';
    const active = visability;
    const recommended = false;
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    const lastUpdateBy = user.id;
    const prices = Number(commonPrice);
    const insertVariant = [];

    if (newColors.length > 1) {
      if (sizes && sizes.length > 0) {
        for (let i = 0; i < sizes.length; i++) {
          insertVariant.push({
            size: sizes[i],
            color: v,
            qpoints: commonPrice || 0,
            stock: 0,
            hexCode: '',
            sku: makeid(10),
            lastUpdatedBy: user.id
          });
        }
      } else {
        insertVariant.push({
          color: v,
          qpoints: commonPrice || 0,
          stock: 0,
          hexCode: '',
          sku: makeid(10),
          lastUpdatedBy: user.id
        });
      }

      try {
        const { data } = await axios.patch(`${baseUrl}/products/${id}`, {
          name,
          description,
          active,
          recommended,
          lastUpdateBy,
          prices,
          sizes: JSON.stringify(sizes),
          colors: JSON.stringify(newColors),
          insertVariant: JSON.stringify(insertVariant),
          id
        });
        setColors(newColors);
        setVariants([...variants, ...data.newVt]);
      } catch (err) {
        notiFailed(`There was a problem adding new variant`);
      }
    } else {
      if (sizes && sizes.length > 0 && variants.length > 0) {
        try {
          const { data } = await axios.patch(`${baseUrl}/products/${id}`, {
            name,
            description,
            active,
            recommended,
            lastUpdateBy,
            prices,
            sizes: JSON.stringify(sizes),
            colors: JSON.stringify(newColors),
            updateVariant: JSON.stringify(variants),
            status: true,
            id
          });
          setColors(newColors);
          setVariants(data.variants);
        } catch (err) {
          notiFailed(`There was a problem adding new variant`);
        }
      } else {
        insertVariant.push({
          color: v,
          qpoints: commonPrice || 0,
          stock: 0,
          hexCode: '',
          sku: makeid(10),
          lastUpdatedBy: user.id
        });

        try {
          const { data } = await axios.patch(`${baseUrl}/products/${id}`, {
            name,
            description,
            active,
            recommended,
            lastUpdateBy,
            prices,
            sizes: JSON.stringify(sizes),
            colors: JSON.stringify(newColors),
            insertVariant: JSON.stringify(insertVariant),
            id
          });
          setColors(newColors);
          setVariants([...variants, ...data.newVt]);
        } catch (err) {
          notiFailed(`There was a problem adding new variant`);
        }
      }
    }
  };

  const addNewSizeVariants = async v => {
    const newSizes = [...sizes, v];
    const name = product.name;
    const description = product.description ? product.description : 'new product';
    const active = visability;
    const recommended = false;
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    const lastUpdateBy = user.id;
    const prices = Number(commonPrice);
    const insertVariant = [];

    if (newSizes.length > 1) {
      if (colors && colors.length > 0) {
        for (let i = 0; i < colors.length; i++) {
          insertVariant.push({
            size: v,
            color: colors[i].name,
            qpoints: commonPrice || 0,
            stock: 0,
            hexCode: '',
            sku: makeid(10),
            lastUpdatedBy: user.id
          });
        }
      } else {
        insertVariant.push({
          size: v,
          qpoints: commonPrice || 0,
          stock: 0,
          sku: makeid(10),
          lastUpdatedBy: user.id
        });
      }

      try {
        const { data } = await axios.patch(`${baseUrl}/products/${id}`, {
          name,
          description,
          active,
          recommended,
          lastUpdateBy,
          prices,
          sizes: JSON.stringify(newSizes),
          colors: JSON.stringify(colors),
          insertVariant: JSON.stringify(insertVariant),
          id
        });
        setSizes(newSizes);
        setVariants([...variants, ...data.newVt]);
      } catch (err) {
        notiFailed(`There was a problem adding new variant`);
      }
    } else {
      if (colors && colors.length > 0 && variants.length > 0) {
        try {
          const { data } = await axios.patch(`${baseUrl}/products/${id}`, {
            name,
            description,
            active,
            recommended,
            lastUpdateBy,
            prices,
            sizes: JSON.stringify(newSizes),
            colors: JSON.stringify(colors),
            updateVariant: JSON.stringify(variants),
            status: false,
            id
          });
          setSizes(newSizes);
          setVariants(data.variants);
        } catch (err) {
          notiFailed(`There was a problem adding new variant`);
        }
      } else {
        insertVariant.push({
          size: v,
          qpoints: commonPrice || 0,
          stock: 0,
          sku: makeid(10),
          lastUpdatedBy: user.id
        });

        try {
          const { data } = await axios.patch(`${baseUrl}/products/${id}`, {
            name,
            description,
            active,
            recommended,
            lastUpdateBy,
            prices,
            sizes: JSON.stringify(newSizes),
            colors: JSON.stringify(colors),
            insertVariant: JSON.stringify(insertVariant),
            id
          });
          setSizes(newSizes);
          setVariants([...variants, ...data.newVt]);
        } catch (err) {
          notiFailed(`There was a problem adding new variant`);
        }
      }
    }
  };

  const addNewCustomVariants = async v => {
    const newCustoms = [...customs, v];
    const name = product.name;
    const description = product.description ? product.description : 'new product';
    const active = visability;
    const recommended = false;
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    const lastUpdateBy = user.id;
    const prices = Number(commonPrice);
    const insertVariant = [{
      custom: v,
      qpoints: 0,
      stock: 0,
      sku: makeid(10),
      lastUpdatedBy: user.id
    }];

    try {
      const { data } = await axios.patch(`${baseUrl}/products/${id}`, {
        name,
        description,
        active,
        recommended,
        lastUpdateBy,
        prices,
        sizes: JSON.stringify([]),
        colors: JSON.stringify([]),
        custom: JSON.stringify(newCustoms),
        insertVariant: JSON.stringify(insertVariant),
        id
      });
      setCustoms(newCustoms);
      setVariants([...variants, ...data.newVt]);
    } catch (err) {
      notiFailed(`There was a problem adding new variant`);
    }
  };

  const removeSizeVariant = async v => {
    const deleteVariant = variants.filter(variant => variant.size === v);
    const name = product.name;
    const description = product.description ? product.description : 'new product';
    const active = visability;
    const recommended = false;
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    const lastUpdateBy = user.id;
    const prices = Number(commonPrice);
    const newSizes = sizes.filter(s => s !== v);

    try {
      await axios.patch(`${baseUrl}/products/${id}`, {
        name,
        description,
        active,
        recommended,
        lastUpdateBy,
        prices,
        sizes: JSON.stringify(newSizes),
        colors: JSON.stringify(colors),
        deleteVariant: JSON.stringify(deleteVariant),
        id
      });
      setSizes(newSizes);
      setVariants(variants.filter(variant => variant.size !== v));
    } catch (err) {
      notiFailed(`There was a problem delete variant`);
    }
  };

  const removeColorVariant = async v => {
    const deleteVariant = variants.filter(variant => variant.color === v);
    const name = product.name;
    const description = product.description ? product.description : 'new product';
    const active = visability;
    const recommended = false;
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    const lastUpdateBy = user.id;
    const prices = Number(commonPrice);
    const newColors = colors.filter(color => color.name !== v);

    try {
      await axios.patch(`${baseUrl}/products/${id}`, {
        name,
        description,
        active,
        recommended,
        lastUpdateBy,
        prices,
        sizes: JSON.stringify(sizes),
        colors: JSON.stringify(newColors),
        deleteVariant: JSON.stringify(deleteVariant),
        id
      });
      setColors(newColors);
      setVariants(variants.filter(variant => variant.color !== v));
    } catch (err) {
      notiFailed(`There was a problem delete variant`);
    }
  };

  const removeCustomVariant = async v => {
    const deleteVariant = variants.filter(variant => variant.custom === v);
    const name = product.name;
    const description = product.description ? product.description : 'new product';
    const active = visability;
    const recommended = false;
    const { user } = JSON.parse(localStorage.getItem('qpartner_user'));
    const lastUpdateBy = user.id;
    const prices = Number(commonPrice);
    const newCustoms = customs.filter(c => c !== v);

    try {
      await axios.patch(`${baseUrl}/products/${id}`, {
        name,
        description,
        active,
        recommended,
        lastUpdateBy,
        prices,
        sizes: JSON.stringify([]),
        colors: JSON.stringify([]),
        custom: JSON.stringify(newCustoms),
        deleteVariant: JSON.stringify(deleteVariant),
        id
      });
      setCustoms(newCustoms);
      setVariants(variants.filter(variant => variant.custom !== v));
    } catch (err) {
      notiFailed(`There was a problem delete variant`);
    }
  };

  useEffect(() => {
    getCatergories();
    if (id) {
      getProduct(id);
    }
  }, [id]);

  return (
    <>
      <Grid container className={classes.root} style={{ marginLeft: '10px', marginBottom: '40px' }}>
        <Grid
          container
          item
          xs={12}
          className={`${classes.stickyTopbar} stickyTopbar`}
        >
          <BackButton component={Link} to="/marketing-shop/products">
            Products
          </BackButton>
          <Grid container justify="space-between" alignItems="center">
            <Text size="h2" className={classes.pageTitle}>{id ? 'Edit Product' : 'Add Product'}</Text>

            <Grid item>
              {id ? (
                <Button
                  minWidth="8rem"
                  color="secondary"
                  onClick={handleOpen}
                  className={classes.buttonItem}
                >
                  Delete
                </Button>
              ) : (
                <Button
                  minWidth="8rem"
                  color="secondary"
                  onClick={handleDiscard}
                  className={classes.buttonItem}
                >
                  Discard
                </Button>
              )}
              <Button
                minWidth="8rem"
                onClick={handleSave}
                disabled={loading}
                className={classes.buttonItem}
              >
                {loading && <CustomCircularProgress size={16} />}
                {!loading && 'Save'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={`${classes.root} pageContent`}>
          <Grid container item md={8} xs={12}>
            <Paper style={{ width: '100%' }}>
              <Grid
                container
                style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
              >
                <Grid item xs={12}>
                  <Text size="h3" weight="medium" style={{ marginBottom: '-10px' }}>Product Info</Text>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  <Text
                    weight="medium"
                    style={{ color: '#8c8c8c', marginBottom: '1px' }}
                  >
                    Title
                  </Text>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Title"
                    value={product ? product.name : ''}
                    onChange={e => {
                      setToggleErrorTitle(!e.target.value);
                      handleProduct('name', e.target.value);
                    }}
                    error={toggleErrorTitle}
                    helperText={toggleErrorTitle ? "Title can't be blank" : ''}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Text
                    weight="medium"
                    style={{ color: '#8c8c8c', marginBottom: '-20px' }}
                  >
                    Description
                  </Text>
                  <div className="wr_btn-text_html">
                    <span
                      className={`${!isHtml ? 'active10' : ''}`}
                      onClick={e => setIsHtml(false)}
                    >
                      Text
                    </span>{' '}
                    <span
                      className={`${isHtml ? 'active10' : ''}`}
                      onClick={e => setIsHtml(true)}
                    >
                      HTML
                    </span>
                  </div>

                  <div className="wr_desc">
                    {isHtml ? (
                      <div className="foo99">
                        <JoditEditor
                          ref={editor}
                          value={descHtml}
                          config={config}
                          tabIndex={1}
                          onBlur={newContent => {
                            setDescHtml(newContent);
                          }}
                          onChange={newContent => {}}
                        />
                      </div>
                    ) : (
                      <TextField
                        className="desc_product"
                        fullWidth
                        id="outlined-multiline-static"
                        multiline
                        rows="8"
                        variant="outlined"
                        value={descText}
                        onChange={e => setDescText(e.target.value)}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container item md={4} xs={12}>
            <Grid container item xs={12} className={classes.paddingBottomTwoSpacing}>
              <Paper style={{ width: '100%' }}>
                <Grid
                  container
                  style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
                >
                  <Text size="h3" weight="medium">Visibility</Text>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    value={visability}
                    onChange={e => setVisability(e.target.value)}
                    style={{ paddingBottom: '20px' }}
                  >
                    <MenuItem value={true}>Visible</MenuItem>
                    <MenuItem value={false}>Hidden</MenuItem>
                  </TextField>
                </Grid>
              </Paper>
            </Grid>
            <Grid container item xs={12}>
              <Paper style={{ width: '100%' }}>
                <Grid
                  container
                  style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
                >
                  <Grid item>
                    <Text size="h3" weight="medium">Category</Text>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      style={{ paddingBottom: '20px' }}
                      pb={2}
                      value={selectedCategory}
                      onChange={e => {
                        if (e.target.value) {
                          setToggleErrorCategory(false);
                        }
                        setSelectedCategory(e.target.value);
                        handleProduct('category', { id: e.target.value });
                      }}
                      error={toggleErrorCategory}
                      helperText={toggleErrorCategory ? "Category can't be blank" : ''}
                    >
                      {categories.map(c => (
                        <MenuItem
                          key={c.id}
                          value={c.id}
                          style={{ textTransform: 'uppercase' }}
                        >
                          {c.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item md={8} xs={12}>
            <Paper style={{ width: '100%' }}>
              {id ? (
                <ProductUploaderEdit id={id} />
              ) : (
                <ProductUploader handleChange={images => setProductImages(images)} />
              )}
            </Paper>
          </Grid>
          <Grid container item md={8} xs={12}>
            <Paper style={{ width: '100%' }}>
              <Grid
                container
                style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
              >
                <Grid item xs={12}>
                  <Text size="h3" weight="medium">Pricing</Text>
                </Grid>
                <Grid container alignItems="center">
                  <Text weight="medium" className={classes.subtitle} style={{ marginRight: '30px' }}>Price</Text>
                  {isContact ? (
                    <CheckBoxOutlinedIcon
                      onClick={switchCustomOrder}
                      fontSize="large"
                      style={{ color: '#00ADEF' }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon
                      onClick={switchCustomOrder}
                      fontSize="large"
                      style={{ color: '#E6E6E6' }}
                    />
                  )}
                  <Text weight="medium" className={classes.subtitle}>Custom Order?</Text>
                </Grid>

                {!isContact ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Price"
                    name="commonPrice"
                    value={commonPrice}
                    onChange={handleChangeCommonPrice}
                    InputProps={{
                      inputComponent: PriceNumberFormat
                    }}
                  ></TextField>
                ) : (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={contactPrices}
                    onChange={handleChangeCustomOrderText}
                    placeholder="Contact Us"
                    name="commonPrice"
                  ></TextField>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid container item md={8} xs={12}>
            <Paper style={{ width: '100%', paddingBottom: '20px' }}>
              <Grid
                container
                style={{
                  width: '90%',
                  margin: 'auto',
                  paddingBottom: '20px'
                }}
              >
                <Text size="h3" weight="medium">Variants</Text>
                <Grid container wrap="nowrap" item alignItems="center" xs={12}>
                  {variantsSelected ? (
                    <CheckBoxOutlinedIcon
                      onClick={handleVariantsSelected}
                      fontSize="large"
                      style={{ color: '#00ADEF' }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon
                      onClick={handleVariantsSelected}
                      fontSize="large"
                      style={{ color: '#E6E6E6' }}
                    />
                  )}

                  <Text style={{ color: '#8c8c8c' }}>
                    This product has multiple options, like different sizes or colors
                  </Text>
                </Grid>
                {variantsSelected && id && (
                  <ProductOptionEdit
                    editOptions={editOptions}
                    allOptions={allOptions}
                    onChangeEditOptions={options => setEditOptions(options)}
                    onRemoveSizeVariant={removeSizeVariant}
                    onRemoveColorVariant={removeColorVariant}
                    onRemoveCustomVariant={removeCustomVariant}
                    onAddNewColorVariants={addNewColorVariants}
                    onAddNewSizeVariants={addNewSizeVariants}
                    onAddNewCustomVariants={addNewCustomVariants}
                    onChangeColorSelected={value => setColorSelected(value)}
                    onChangeSizeSelected={value => setSizeSelected(value)}
                    sizeSelected={sizeSelected}
                    colorSelected={colorSelected}
                    sizes={sizes}
                    colors={colors}
                    customs={customs}
                  />
                )}
                {variantsSelected && !id
                  ? options.map((o, index) => (
                      <ProductOption
                        key={o.id}
                        id={o.id}
                        index={index}
                        value={o.value}
                        options={o.options}
                        sizes={sizes}
                        colors={colors}
                        customs={customs}
                        isShowRemove={options.length > 1}
                        handleRemoveOption={handleRemoveOption}
                        handleChangeOption={handleChangeOption}
                        handleAddValue={handleAddValue}
                        handleDeleteValue={handleDeleteValue}
                        handleChangeHex={handleChangeHex}
                      />
                    ))
                  : false}
                {variantsSelected && options.length < 2 && !id ? (
                  <Button
                    color="secondary"
                    onClick={addOptions}
                    style={{ marginTop: '20px' }}
                  >
                    Add another option
                  </Button>
                ) : (
                  false
                )}
              </Grid>
              {variantsSelected ? (
                <>
                  {variants &&
                    variants.length > 0 &&
                    variants[0] &&
                    variants.map((v, idx) => (
                      <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        style={{
                          textAlign: 'center',
                          marginTop: '20px',
                          alignItems: 'flex-end'
                        }}
                        key={v.id}
                      >
                        {id ? (
                          <Grid
                            item
                            xs
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <div
                              className={classes.variantImage}
                              onClick={() => {
                                setSelectedVariant(idx);
                                setOpenVariantImageSelectModal(true);
                              }}
                            >
                              {v.variantImages &&
                              v.variantImages.length > 0 &&
                              v.variantImages[0] ? (
                                <img
                                  src={
                                    v.variantImages[0].imageUrl.indexOf('http') === 0
                                      ? v.variantImages[0].imageUrl
                                      : `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${v.variantImages[0].imageUrl}`
                                  }
                                  alt={'name'}
                                  style={{
                                    width: '76px',
                                    height: '75px',
                                    border: '1px solid #EDEDED'
                                  }}
                                />
                              ) : (
                                <FilterNoneIcon
                                  style={{ color: '#D0D0D0', width: '40px' }}
                                />
                              )}
                            </div>
                          </Grid>
                        ) : (
                          <Grid item xs>
                            <div style={{ width: '66px', height: '65px' }}></div>
                          </Grid>
                        )}
                        {v.size !== undefined && v.size !== null && (
                          <Grid item xs>
                            <InputLabel
                              style={{ textAlign: 'left', marginBottom: '5px' }}
                            >
                              Size
                            </InputLabel>
                            <TextField
                              fullWidth
                              disabled={!id}
                              name="size"
                              value={v.size}
                              variant="outlined"
                              onChange={e =>
                                handleChangeVariant(idx, 'size', e.target.value)
                              }
                              placeholder="Size"
                            ></TextField>
                          </Grid>
                        )}
                        {v.color !== undefined && v.color !== null && (
                          <Grid item xs>
                            <InputLabel
                              style={{ textAlign: 'left', marginBottom: '5px' }}
                            >
                              Color
                            </InputLabel>
                            <TextField
                              fullWidth
                              disabled={!id}
                              name="color"
                              value={v.color}
                              variant="outlined"
                              onChange={e =>
                                handleChangeVariant(idx, 'color', e.target.value)
                              }
                              placeholder="Color"
                            ></TextField>
                          </Grid>
                        )}
                        {v.custom !== undefined && v.custom !== null && (
                          <Grid item xs>
                            <InputLabel
                              style={{ textAlign: 'left', marginBottom: '5px' }}
                            >
                              Custom
                            </InputLabel>
                            <TextField
                              fullWidth
                              disabled={!id}
                              name="custom"
                              value={v.custom}
                              variant="outlined"
                              onChange={e =>
                                handleChangeVariant(idx, 'custom', e.target.value)
                              }
                              placeholder="Custom"
                            ></TextField>
                          </Grid>
                        )}
                        {id && v.hexCode !== undefined && v.hexCode !== null && (
                          <Grid item xs>
                            <InputLabel
                              style={{ textAlign: 'left', marginBottom: '5px' }}
                            >
                              Hex Code
                            </InputLabel>
                            <TextField
                              fullWidth
                              name="hexCode"
                              value={v.hexCode}
                              variant="outlined"
                              onChange={e =>
                                handleChangeVariant(idx, 'hexCode', e.target.value)
                              }
                              placeholder="Hex Code"
                            ></TextField>
                          </Grid>
                        )}
                        <Grid item xs>
                          <InputLabel
                            style={{ textAlign: 'left', marginBottom: '5px' }}
                          >
                            Price
                          </InputLabel>
                          <TextField
                            fullWidth
                            name="qpoints"
                            variant="outlined"
                            placeholder="Price"
                            value={v.qpoints}
                            onChange={e =>
                              handleChangeVariant(
                                idx,
                                'qpoints',
                                Number(e.target.value)
                              )
                            }
                            InputProps={{
                              inputComponent: PriceNumberFormat
                            }}
                          ></TextField>
                        </Grid>
                        <Grid item xs>
                          <InputLabel
                            style={{ textAlign: 'left', marginBottom: '5px' }}
                          >
                            Quantity
                          </InputLabel>
                          <TextField
                            fullWidth
                            name="stock"
                            variant="outlined"
                            placeholder="Quantity"
                            value={v.stock}
                            onChange={e =>
                              handleChangeVariant(idx, 'stock', Number(e.target.value))
                            }
                            InputProps={{
                              inputComponent: QuantityNumberFormat
                            }}
                          ></TextField>
                        </Grid>
                        <Grid item xs>
                          <InputLabel
                            style={{ textAlign: 'left', marginBottom: '5px' }}
                          >
                            SKU
                          </InputLabel>
                          <TextField
                            fullWidth
                            name="sku"
                            variant="outlined"
                            value={v.sku}
                            onChange={e =>
                              handleChangeVariant(idx, 'sku', e.target.value)
                            }
                            placeholder="SKU"
                          ></TextField>
                        </Grid>
                        <Grid item xs>
                          {id ? (
                            <>
                              {variants.length > 1 && (
                                <IconButton
                                  onClick={() => {
                                    setSelectedVariant(idx);
                                    setOpenDeleteVariantModal(true);
                                  }}
                                >
                                  <CloseOutlinedIcon
                                    fontSize="large"
                                    style={{ color: '#8C8C8C' }}
                                  />
                                </IconButton>
                              )}
                            </>
                          ) : (
                            <IconButton onClick={() => handleRemoveVariant(idx)}>
                              <CloseOutlinedIcon
                                fontSize="large"
                                style={{ color: '#8C8C8C' }}
                              />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                </>
              ) : (
                false
              )}
            </Paper>
          </Grid>
          {!variantsSelected ? (
            <Grid container item md={8} xs={12}>
              <Paper style={{ width: '100%' }}>
                <Grid
                  container
                  style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
                >
                  <Text size="h3" weight="medium">Inventory</Text>
                  <Grid item xs={12}>
                    <Text weight="medium" className={classes.subtitle}>SKU</Text>
                  </Grid>

                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="SKU Number"
                    value={inventorySkuNumber}
                    onChange={e => setInventorySkuNumber(e.target.value)}
                  ></TextField>
                  <Grid container wrap="nowrap" item alignItems="center" md={2} xs={6}>
                    {inventorySelection === 'unlimited' ? (
                      <RadioButtonCheckedOutlinedIcon
                        onClick={() => handleInventorySelection('')}
                        fontSize="large"
                        style={{ color: '#00ADEF' }}
                      />
                    ) : (
                      <RadioButtonUncheckedOutlinedIcon
                        onClick={() => handleInventorySelection('unlimited')}
                        fontSize="large"
                        style={{ color: '#E6E6E6' }}
                      />
                    )}

                    <Text color="secondaryDark">Unlimited</Text>
                  </Grid>
                  <Grid container wrap="nowrap" item alignItems="center" md={3} xs={6}>
                    {inventorySelection === 'limited' ? (
                      <RadioButtonCheckedOutlinedIcon
                        onClick={() => handleInventorySelection('')}
                        fontSize="large"
                        style={{ color: '#00ADEF' }}
                      />
                    ) : (
                      <RadioButtonUncheckedOutlinedIcon
                        onClick={() => handleInventorySelection('limited')}
                        fontSize="large"
                        style={{ color: '#E6E6E6' }}
                      />
                    )}
                    <Text color="secondaryDark">Set a Quantity</Text>
                  </Grid>
                  {inventorySelection === 'limited' ? (
                    <>
                      <Grid item xs={12}>
                        <Text weight="medium" className={classes.subtitle}>Quantity</Text>
                      </Grid>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Quantity"
                        value={inventoryQuantity}
                        onChange={e => setInventoryQuantity(Number(e.target.value))}
                        InputProps={{
                          inputComponent: QuantityNumberFormat
                        }}
                      ></TextField>
                    </>
                  ) : (
                    false
                  )}
                </Grid>
              </Paper>
            </Grid>
          ) : (
            false
          )}
          <Grid container item md={8} xs={12}>
            <Paper style={{ width: '100%' }}>
              <Grid
                container
                style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
              >
                <Grid item xs={12}>
                  <Text size="h3" weight="medium">Shipping</Text>
                </Grid>
                <Grid container wrap="nowrap" item alignItems="center" md={2} xs={6}>
                  {shippingSelection === 'free' ? (
                    <CheckBoxOutlinedIcon
                      onClick={() => handleShippingSelection('')}
                      fontSize="large"
                      style={{ color: '#00ADEF' }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon
                      onClick={() => handleShippingSelection('free')}
                      fontSize="large"
                      style={{ color: '#E6E6E6' }}
                    />
                  )}

                  <Text color="secondaryDark">Free</Text>
                </Grid>
                <Grid container wrap="nowrap" item alignItems="center" md={3} xs={6}>
                  {shippingSelection === 'charge' ? (
                    <CheckBoxOutlinedIcon
                      onClick={() => handleShippingSelection('')}
                      fontSize="large"
                      style={{ color: '#00ADEF' }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon
                      onClick={() => handleShippingSelection('charge')}
                      fontSize="large"
                      style={{ color: '#E6E6E6' }}
                    />
                  )}
                  <Text color="secondaryDark">Charge for Shipping</Text>
                </Grid>
                {shippingSelection === 'charge' ? (
                  <>
                    <Grid item xs={12}>
                      <Text weight="medium" className={classes.subtitle}>Price</Text>
                    </Grid>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={shippingPrice}
                      InputProps={{
                        inputComponent: PriceNumberFormat
                      }}
                      onChange={e => handleChangeShippingPrice(Number(e.target.value))}
                      placeholder="Enter a Price"
                    ></TextField>
                  </>
                ) : (
                  false
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <AlertDeleteComponent
        open={open}
        handleClose={() => {
          handleCloseDeletePopup();
        }}
        handleDelete={() => {
          handleDeleteProduct();
        }}
        title="Delete this product?"
        content="Once you do this, it can't be undone."
      />

      <DeleteVariantModal
        open={openDeleteVariantModal}
        handleClose={() => {
          setSelectedVariant(null);
          setOpenDeleteVariantModal(false);
        }}
        handleDelete={handleDeleteVariant}
      />
      <LeavePageModal
        open={openLeaveModal}
        handleClose={handleCloseLeaveModal}
        handleLeave={handleLeave}
      />
      <VariantImageSelect
        open={openVariantImageSelectModal}
        id={id}
        handleClose={() => {
          setSelectedVariant(null);
          setOpenVariantImageSelectModal(false);
        }}
        handleDone={handleChooseVariantImage}
      />
    </>
  );
}
