import React from 'react';
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import moment from 'moment';
import {decodeStringHtml} from '../../../../utils/helpers';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  tableHeader: {
    background: '#f4f5f7',
    fontWeight: '500',
    fontSize: '15px'
  },
  table: {
    borderTop: '1px solid #e4e4e4',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  noteTableHeader: {
    textAlign: 'center',
    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px'
  },
  textArea: {
    width: '100%',
    padding: '5px'
  },
}));

function InformationContact({info, type}) {


  const classes = useStyles();

  return(
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell align='center' className={classes.tableHeader}>Category</TableCell>
            <TableCell align='left' style={{ minWidth: '300px' }}>
              {info?.contactCategoryName}
            </TableCell>
            <TableCell align='center' className={classes.tableHeader}>Date</TableCell>
            <TableCell align='left'>
              {moment(info?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='center' className={classes.tableHeader}>Name</TableCell>
            <TableCell align='left'>
              {info?.name}
            </TableCell>
            <TableCell align='center' className={classes.tableHeader}>Customer Group</TableCell>
            <TableCell align='left'>-</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='center' className={classes.tableHeader}>Company / Organization</TableCell>
            <TableCell align='left'>
              {info?.companyName}
            </TableCell>
            <TableCell align='center' className={classes.tableHeader}>Address</TableCell>
            <TableCell align='left'>
              {info?.address}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='center' className={classes.tableHeader}>Contact Number</TableCell>
            <TableCell align='left'>
              {info?.mobileNumber}
            </TableCell>
            <TableCell align='center' className={classes.tableHeader}>E-mail</TableCell>
            <TableCell align='left'>
              {info?.email}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='center' className={classes.tableHeader}>Message</TableCell>
            <TableCell align='left'>
              <span dangerouslySetInnerHTML={{ __html: `${decodeStringHtml(info?.message).replace(/(?:\r\n|\r|\n)/g, '<br />')}` }} />
            </TableCell>

            {type === '2' ? <>
              <TableCell align='center' className={classes.tableHeader}>Style # </TableCell>
              <TableCell align='left'>-</TableCell>
            </> : <>
              <TableCell align='center'/>
              <TableCell align='left'/>
            </>}
          </TableRow>
          {type === '2' && <TableRow>
            <TableCell align='center' className={classes.tableHeader}>Company Logo</TableCell>
            <TableCell align='left'>

              <a href={_.get(info, 'image.assetUrl')}>{_.get(info, 'image.assetKey', '-')}</a>
            </TableCell>
            <TableCell align='center'/>
            <TableCell align='left'/>

          </TableRow>}

        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(InformationContact);

