import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import ToastImg from './../../config/images/toast-icon.png';
import ToastImgFailed from './../../config/images/toast-icon-failed.png';

const useUpdatedToast = () => {
  const toastId = useRef(null);

  const notifyInit = content => {
    if(!toast.isActive(toastId.current)) {  
        toastId.current = toast(
            <div className="noti_body">
              <div className="noti_img">
                {' '}
                <img src={ToastImg} alt="" />{' '}
              </div>
              <div className="noti_content">{content}</div>
            </div>
        , { autoClose: false, className: 'toast-body', progressClassName: 'toast-progress-bar' });
    }
  };

  const notifySuccess = content => {
    toast.update(toastId.current, {
        render: () => (
            <div className="noti_body">
              <div className="noti_img">
                {' '}
                <img src={ToastImg} alt="" />{' '}
              </div>
              <div className="noti_content">{content}</div>
            </div>
        ),
        autoClose: 5000,
        bodyClassName: 'toast-body',
        progressClassName: 'toast-progress-bar',
        progress: null
    });
  };

  const notifyFailed = content => {
    toast.update(toastId.current, {
        render: () => (
            <div className="noti_body">
              <div className="noti_img">
                {' '}
                <img src={ToastImgFailed} alt="" />{' '}
              </div>
              <div className="noti_content">{content}</div>
            </div>
        ),
        autoClose: 5000,
        bodyClassName: 'toast-error-body',
        progressClassName: 'toast-error-progress-bar',
        progress: null
    });
  };

  const notifyUpdateProgress = progress => {
    toast.update(toastId.current, {
      progress
    });
  };

  const notifyDismiss = () => toast.dismiss(toastId.current);

  return [
    notifyInit,
    notifySuccess,
    notifyFailed,
    notifyUpdateProgress,
    notifyDismiss
  ];
};

export default useUpdatedToast;
