import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { HIDE, NavbarStore } from '../../contexts/navbar';
import { RoleContext } from '../../contexts/roles';
import { ACCESS_CONTROL_LIST_TYPES, PTI_ADMIN_URL } from './../../utils/constants';

const useStyles = makeStyles({
  list: {
    width: 250
  },
  mainMenu: {
    borderBottom: '1px solid rgb(230, 230, 230)'
  },
  mainMenuActive: {
    borderBottom: '2px rgb(0, 173, 239) solid'
  },
  labelMainMenu: {
    fontWeight: 'bold'
  },
  labelMainMenuActive: {
    color: 'rgb(0, 173, 239)'
  },
  subMenuItem: {
    borderBottom: '1px solid rgb(230, 230, 230)',
    textTransform: 'uppercase',
    '& span': {
      fontSize: '0.9rem'
    }
  },
  menuIconContainer: {
    height: 77,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: '1px solid rgb(230, 230, 230)',
    '& button': {
      marginRight: 5
    }
  }
});

const ANCHOR = 'left';
const MAIN_MENU = {
  PARTNERS: {
    code: 'partners',
    label: 'Q.PARTNERS',
    sub: [
      {
        code: 'registration',
        label: 'Registration',
        isOutSide: false,
        url: '/qpartners/registration/list/0'
      }
    ]
  },
  REWARDS: {
    code: 'rewards',
    label: 'REWARDS',
    sub: [
      {
        code: 'approvals',
        label: 'Approvals',
        isOutSide: false,
        url: '/rewards/module-approvals',
        accessType: ACCESS_CONTROL_LIST_TYPES.APPROVAL_MANAGEMENT
      },
      {
        code: 'uploads',
        label: 'Uploads',
        isOutSide: false,
        url: '/rewards/articlecode-upload',
        accessType: ACCESS_CONTROL_LIST_TYPES.UPLOAD_MANAGEMENT
      },
      {
        code: 'configurations',
        label: 'Configurations',
        isOutSide: false,
        url: '/rewards/configurations/modules',
        accessType: ACCESS_CONTROL_LIST_TYPES.CONFIGURATIONS_MANAGEMENT
      },
      {
        code: 'qpointManagement',
        label: 'Q.Point Management',
        isOutSide: false,
        url: '/rewards/qpoint-management',
        accessType: ACCESS_CONTROL_LIST_TYPES.QPOINT_MANAGEMENT
      }
    ]
  },
  MARKETING_SHOP: {
    code: 'marketing_shop',
    label: 'SHOP',
    sub: [
      {
        code: 'products',
        label: 'Products',
        isOutSide: false,
        url: '/marketing-shop/products/1',
        accessType: ACCESS_CONTROL_LIST_TYPES.CONFIGURATIONS_MANAGEMENT
      },
      {
        code: 'orders',
        label: 'Orders',
        isOutSide: false,
        url: '/marketing-shop/orders/all/1',
        accessType: ACCESS_CONTROL_LIST_TYPES.ORDERS_MANAGEMENT
      }
    ]
  },
  SUPPORT_CENTER: {
    code: 'support_center',
    label: 'SUPPORT CENTER',
    sub: [
      {
        code: 'q_cells_website',
        label: 'Q.cells website',
        isOutSide: false,
        url: '/supportcenter/qcells'
      },
      {
        code: 'q_partner_website',
        label: 'Q.partner website',
        isOutSide: false,
        url: '/supportcenter/partner'
      },
      {
        code: 'claim_management',
        label: 'Claim Management',
        isOutSide: true,
        url: PTI_ADMIN_URL
      }
    ]
  },
  ADMIN_CONTROLS: {
    code: 'admin_controls',
    label: 'ADMIN CONTROLS',
    sub: [
      {
        code: 'admin_management',
        label: 'Admin management',
        isOutSide: false,
        url: '/admincontrols/management'
      },
      {
        code: 'news',
        label: 'News',
        isOutSide: false,
        url: '/admincontrols/news'
      },
      {
        code: 'data_hub',
        label: 'Data hub',
        isActive: true,
        isOutSide: false,
        url: '/admincontrols/faq'
      },
      {
        code: 'offline_training_schedule',
        label: 'Offline training schedule',
        isActive: true,
        isOutSide: false,
        url: `/admincontrols/configurations/list`
      }
    ]
  },
  PROJECT_MANAGEMENT: {
    code: 'project_management',
    label: 'PROJECT STATUS',
    url: '/projects/projects-management'
  }
};

export default function TemporaryDrawer(props) {
  const { checkShowLegacyMenuItem, checkShowMenuItem } = useContext(RoleContext);
  const classes = useStyles();
  const [collapseSelected, setCollapseSelected] = useState('');
  const [subMenuSelected, setSubMenuSelected] = useState('');
  const { state, dispatch } = React.useContext(NavbarStore);

  const LabelMainMenu = label => {
    return <Typography className={`navbar_item ${classes.labelMainMenu}`}> {label} </Typography>;
  };

  const selectMainMenu = code => {
    setCollapseSelected(collapseSelected === code ? '' : code);
  };

  const hideNavbar = code => {
    setSubMenuSelected(code);
    dispatch({ type: HIDE });
  };

  const showExpand = isShow => {
    return isShow ? <ExpandLess /> : <ExpandMore />;
  };

  const SubMenu = (isOutSide, url, isActive, label, code) => {
    if (isOutSide) {
      return (
        <ListItem
          button
          key={code}
          className={classes.subMenuItem}
          onClick={() => window.open(url, '_self')}
        >
          <ListItemText
            className={isActive ? classes.labelMainMenuActive : ''}
            primary={<span className="navbar_item">{label}</span>}
          />
        </ListItem>
      );
    }
    return (
      <ListItem
        button
        key={code}
        className={classes.subMenuItem}
        component={Link}
        onClick={() => hideNavbar(code)}
        to={url}
      >
        <ListItemText
          primary={<span className="navbar_item">{label}</span>}
          className={subMenuSelected === code ? classes.labelMainMenuActive : ''}
        />
      </ListItem>
    );
  };

  const list = () => (
    <div className={classes.list} role="presentation">
      <div className={classes.menuIconContainer}>
        <IconButton onClick={hideNavbar} color="inherit">
          <MenuIcon></MenuIcon>
        </IconButton>
      </div>

      <List>
        {/* Q.PARTNERS start */}
        {checkShowLegacyMenuItem() &&
        <>
          <ListItem
            className={collapseSelected === MAIN_MENU.PARTNERS.code ? classes.mainMenuActive: classes.mainMenu}
            button
            onClick={() => selectMainMenu(MAIN_MENU.PARTNERS.code)}
          >
            <ListItemText primary={LabelMainMenu(MAIN_MENU.PARTNERS.label)} />
            {showExpand(collapseSelected === MAIN_MENU.PARTNERS.code)}
          </ListItem>
          <Collapse
            in={collapseSelected === MAIN_MENU.PARTNERS.code}
            timeout="auto"
            unmountOnExit
          >
            {MAIN_MENU.PARTNERS.sub.map(info => SubMenu(info.isOutSide, info.url, info.isActive, info.label, info.code))}
          </Collapse>
        </>}
        {/* Q.PARTNERS end */}

        {/* Rewards start */}
        {checkShowMenuItem([
          ACCESS_CONTROL_LIST_TYPES.APPROVAL_MANAGEMENT,
          ACCESS_CONTROL_LIST_TYPES.UPLOAD_MANAGEMENT,
          ACCESS_CONTROL_LIST_TYPES.CONFIGURATIONS_MANAGEMENT,
          ACCESS_CONTROL_LIST_TYPES.QPOINT_MANAGEMENT
        ]) && (
        <>
          <ListItem
            className={collapseSelected === MAIN_MENU.REWARDS.code ? classes.mainMenuActive: classes.mainMenu}
            button
            onClick={() => selectMainMenu(MAIN_MENU.REWARDS.code)}
          >
            <ListItemText primary={LabelMainMenu(MAIN_MENU.REWARDS.label)} />
            {showExpand(collapseSelected === MAIN_MENU.REWARDS.code)}
          </ListItem>
          <Collapse
            in={collapseSelected === MAIN_MENU.REWARDS.code}
            timeout="auto"
            unmountOnExit
          >
            {MAIN_MENU.REWARDS.sub.map(info => {
              if (checkShowMenuItem([info.accessType])) {
                return SubMenu(info.isOutSide, info.url, info.isActive, info.label, info.code)
              }

              return null;
            })}
          </Collapse>
        </>)}
        {/* Rewards end */}

        {/* Marketing shop start */}
        {checkShowMenuItem([
          ACCESS_CONTROL_LIST_TYPES.PRODUCTS_MANAGEMENT,
          ACCESS_CONTROL_LIST_TYPES.ORDERS_MANAGEMENT
        ]) && (
        <>
          <ListItem
            className={collapseSelected === MAIN_MENU.MARKETING_SHOP.code ? classes.mainMenuActive: classes.mainMenu}
            button
            onClick={() => selectMainMenu(MAIN_MENU.MARKETING_SHOP.code)}
          >
            <ListItemText primary={LabelMainMenu(MAIN_MENU.MARKETING_SHOP.label)} />
            {showExpand(collapseSelected === MAIN_MENU.MARKETING_SHOP.code)}
          </ListItem>
          <Collapse
            in={collapseSelected === MAIN_MENU.MARKETING_SHOP.code}
            timeout="auto"
            unmountOnExit
          >
            {MAIN_MENU.MARKETING_SHOP.sub.map(info => {
              if (checkShowMenuItem([info.accessType])) {
                return SubMenu(info.isOutSide, info.url, info.isActive, info.label, info.code)
              }
              return null;
            })}
          </Collapse>
        </>)}
        {/* Marketing shop end */}

        {/* Support center start */}
        {checkShowLegacyMenuItem() &&
        <>
          <ListItem
            className={collapseSelected === MAIN_MENU.SUPPORT_CENTER.code ? classes.mainMenuActive: classes.mainMenu}
            button
            onClick={() => selectMainMenu(MAIN_MENU.SUPPORT_CENTER.code)}
          >
            <ListItemText primary={LabelMainMenu(MAIN_MENU.SUPPORT_CENTER.label)} />
            {showExpand(collapseSelected === MAIN_MENU.SUPPORT_CENTER.code)}
          </ListItem>
          <Collapse
            in={collapseSelected === MAIN_MENU.SUPPORT_CENTER.code}
            timeout="auto"
            unmountOnExit
          >
            {MAIN_MENU.SUPPORT_CENTER.sub.map(info => SubMenu(info.isOutSide, info.url, info.isActive, info.label, info.code))}
          </Collapse>
        </>}
        {/* Support center end */}

        {/* Admin controls start */}
        {checkShowLegacyMenuItem() &&
        <>
          <ListItem
            className={collapseSelected === MAIN_MENU.ADMIN_CONTROLS.code ? classes.mainMenuActive: classes.mainMenu}
            button
            onClick={() => selectMainMenu(MAIN_MENU.ADMIN_CONTROLS.code)}
          >
            <ListItemText primary={LabelMainMenu(MAIN_MENU.ADMIN_CONTROLS.label)} />
            {showExpand(collapseSelected === MAIN_MENU.ADMIN_CONTROLS.code)}
          </ListItem>
          <Collapse
            in={collapseSelected === MAIN_MENU.ADMIN_CONTROLS.code}
            timeout="auto"
            unmountOnExit
          >
            {MAIN_MENU.ADMIN_CONTROLS.sub.map(info => SubMenu(info.isOutSide, info.url, info.isActive, info.label, info.code))}
            {/* Admin controls end */}
          </Collapse>
        </>}

        <ListItem
          button
          key={MAIN_MENU.PROJECT_MANAGEMENT.code}
          className={classes.subMenuItem}
          component={Link}
          onClick={() => hideNavbar(MAIN_MENU.PROJECT_MANAGEMENT.code)}
          to={MAIN_MENU.PROJECT_MANAGEMENT.url}
        >
          <ListItemText
            primary={LabelMainMenu(MAIN_MENU.PROJECT_MANAGEMENT.label)}
            className={subMenuSelected === MAIN_MENU.PROJECT_MANAGEMENT.code ? classes.labelMainMenuActive : ''}
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <Drawer
        anchor={ANCHOR}
        open={state.isShow}
        onClose={() => dispatch({ type: HIDE })}
      >
        {list()}
      </Drawer>
    </div>
  );
}
