import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Grid, Paper, makeStyles, TextField, InputAdornment, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Text from '../../common/components/text';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import DatePicketCustom from '../../utils/components/datePicket';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../common/components/button';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import { notiFailed, convertHTMLEntity } from './../../utils/helpers';
import Moment from 'react-moment';
import CustomLoading from './../../common/components/CustomLoading';
import moment from 'moment';
import _ from 'lodash';
import { ExcelCustomComponent } from '../../utils/components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: 0,
        borderRadius: 0,
        boxShadow: 'none',
        marginBottom: '30px',
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    searchContainer: {
        marginTop: '30px',
        marginBottom: '20px',
        paddingLeft: '30px',
        paddingRight: '30px'
    },
    inputField: {
        '& .MuiOutlinedInput-notchedOutline': {
          transition: 'background 0.3s, border 0.3s',
          borderColor: '#C4C4C4'
        },
        '& .MuiInputBase-input': {
          color: '#333333',
        },
        '& .MuiInputBase-input::placeholder': {
          color: '#BDBDBD',
          opacity: 1
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '3px',
          fontSize: '14px',
          color: '#333333',
          fontFamily: 'Actual',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '17.5px'
        },
        '& .MuiOutlinedInput-input': {
          padding: '14px 15px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#C4C4C4 !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000 !important',
          borderWidth: '1px'
        }
    },
    datePicker: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '3px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: '#C4C4C4 !important'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000 !important',
            borderWidth: '1px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px 15px',
            fontSize: '14px'
        }
    },
    iconButton: {
        padding: 0,
        position: 'absolute',
        top: '22px',
        right: '22px'
    },
    downloadButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    downloadButton: {
        border: '1px solid #8C8C8C',
        boxSizing: 'border-box',
        borderRadius: '100px',
        height: '100%',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '19px',
        textAlign: 'center',
        color: '#8C8C8C',
        textTransform: 'capitalize',
        width: '100%',
        maxWidth: '150px',
        padding: '0 !important',
        [theme.breakpoints.down(500)]: {
            padding: '10px !important',
        }
    },
    projectsTable: {
        '& .MuiTableRow-head': {
            borderTop: '1px solid rgba(224, 224, 224, 1)',
        },
        '& .MuiTableCell-head': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            lineHeight: '28px',
            color: '#9ca4ab',
            fontWeight: '400',
            fontSize: '1.0625rem'
        },
        '& .MuiTableCell-body': {
            fontFamily: 'Actual',
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '15px',
            lineHeight: '28px',
            color: '#000000'
        },
        '& .MuiTableFooter-root': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            borderRight: '1px solid rgba(224, 224, 224, 1)'
        },
        '& .MuiTableCell-root': {
            borderBottomColor: 'rgba(224, 224, 224, 1)'
        },
        '& .MuiTableRow-hover': {
            cursor: 'pointer'
        },
        '& .MuiTableRow-root .MuiTableCell-root:first-child': {
            paddingLeft: '30px'
        }
    },
    viewMoreButton: {
        border: '1px solid #8C8C8C',
        boxSizing: 'border-box',
        borderRadius: '100px',
        fontFamily: 'Actual',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '19px',
        textAlign: 'center',
        color: '#8C8C8C',
        textTransform: 'capitalize',
        width: '100%',
        maxWidth: '185px',
        height: '45px',
        padding: '0 !important'
   },
   loadingIndicator: {
        height: '350px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '40px',
        paddingBottom: '40px'
   },
   emptyProject: {
        height: '350px',
        margin: '20px',
        backgroundColor: 'rgb(247, 247, 247)',
        textAlign: 'center',
        lineHeight: '350px'
   },
   viewMoreContainer: {
       marginBottom: '50px'
   },
   pageTitle: {
    [theme.breakpoints.down(500)]: {
        fontSize: '24px !important'
    },
    [theme.breakpoints.down(450)]: {
        fontSize: '20px !important'
    }
   }
}));

const PAGE_SIZE = 25;

export default function Projects() {
    const classes = useStyles();
    const history = useHistory();
    const [projects, setProjects] = useState([]);
    const [size, setSize] = useState(PAGE_SIZE);
    const [filters, setFilters] = useState({ page: 1, size, isDraft: false });
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [installationDate, setInstallationDate] = useState(null);
    const [operateDate, setOperateDate] = useState(null);
    const typingTimeoutRef = useRef(null);

    const getProjects = async (filters = {}) => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${baseUrl}/projects/search`, { params: filters });
            setProjects(data.data);
            setTotal(data.total);
            return Promise.resolve();
        } catch (err) {
            notiFailed(`Something happened getting projects`);
            return Promise.reject();
        } finally {
            setLoading(false);
        }
    };

    const renderProjectName = p => {
        if (p.projectName) {
            return p.projectName;
        }

        if (p.firstName && p.lastName) {
            return `${p.firstName} ${p.lastName}`;
        } else if (p.firstName) {
            return p.firstName;
        } else if (p.lastName) {
            return p.lastName;
        }

        if (p.projectCity) {
            return p.projectCity;
        }

        return null;
    };

    const viewMore = async () => {
        const newSize = size + PAGE_SIZE;
        const filter = {
            ...filters,
            size: newSize
        }

        try {
            setLoadingMore(true);
            await getProjects(filter);
            setSize(newSize);
            setFilters(filter);
        } catch (err) {}
        finally {
            setLoadingMore(false);
        }
    };

    const handleSelectInstallationDate = async date => {
        setInstallationDate(date);

        let filter = { ...filters, size: PAGE_SIZE};

        if (date) {
            filter.startInstallationDate = moment(date).format('YYYY-MM-DD');
            filter.endInstallationDate = moment(date).format('YYYY-MM-DD');
        }
         else {
            delete filter.startInstallationDate;
            delete filter.endInstallationDate;
        }

        try {
            await getProjects(filter);
            setSize(PAGE_SIZE);
            setFilters(filter);
        } catch (err) {}
    };

    const handleSelectOperateDate = async date => {
        setOperateDate(date);

        const filter = { ...filters, size: PAGE_SIZE };

        if (date) {
            filter.operateDate = moment(date).format('YYYY-MM-DD');
        } else {
            delete filter.operateDate;
        }

        try {
            await getProjects(filter);
            setSize(PAGE_SIZE);
            setFilters(filter);
        } catch (err) {}
    };

    const handleSearchText = async text => {
        clearTimeout(typingTimeoutRef.current);
        setSearchText(text);
        const filter = {
            ...filters,
            size: PAGE_SIZE,
            keyword: text
        };

        typingTimeoutRef.current = setTimeout(async () => {
            try {
                await getProjects(filter);
                setSize(PAGE_SIZE);
                setFilters(filter);
            } catch (err) {}
        }, 300);
    };

    const exportProjects = async () => {
        document.getElementById('projects-data-sheet').click();
    };


    function renderNumberOfModule(p) {
        if(p.serialNumbers !== null) {
            const uniqModuleSerialNumber = _.remove(
              _.uniqBy(p.serialNumbers, 'productName'),
              p => p.productName
            );

            return uniqModuleSerialNumber.length || 0

        }

        return p.numberOfModules
    }

    const getProjectsDataSheet = projects => {
        if (!projects.length) {
            return [{
                'Draft date': '',
                'Registered date': '',
                'Update date': '',
                'Company id': '',
                'Company name': '',
                'Project name': '',
                'Project address1': '',
                'Project address2': '',
                City: '',
                State: '',
                'Zip code': '',
                'System size': '',
                'Module brand': '',
                'Module type': '',
                'Module power class': '',
                'Module q\'ty': '',
                Battery: '',
                'System type': '',
                'Battery brand': '',
                'Battery type': '',
                'Installation date': '',
                'Name of purchasing channel': '',
                'Registered by': '',
                'Labor warranty': '',
                Registered: '',
                'First saved source': '',
                'First registered source': '',
                'Last updated source': '',
                'Module upload source': '',
                'Inverter upload source': ''
            }];
        }

        return projects.map(p => ({
            'Draft date': p.draftedAt ? moment(p.draftedAt).format('MM/DD/YYYY') : '',
            'Registered date': p.createdAt ? moment(p.createdAt).format('MM/DD/YYYY') : '',
            'Update date': p.updatedAt ? moment(p.updatedAt).format('MM/DD/YYYY') : '',
            'Company id': _.get(p, 'user.companyId'),
            'Company name': convertHTMLEntity(_.get(p, 'user.companyName')),
            'Project name': renderProjectName(p),
            'Project address1': p.projectAddress,
            'Project address2': p.projectAddress2,
            City: p.projectCity,
            State: p.projectState,
            'Zip code': p.projectZipCode,
            'System size': p.systemSize,
            'Module brand': p.moduleBrand,
            'Module Model': p.moduleModel,
            'Module power class': p.modulePowerClass,
            'Module q\'ty': renderNumberOfModule(p),
            Battery: p.battery ? 'Y' : 'N',
            'System type': p.systemType,
            'Battery brand': p.batteryBrand,
            'Battery Model': p.batteryModel,
            'Installation date': p.installationDate ? moment(p.installationDate).format('MM/DD/YYYY') : '',
            'Name of purchasing channel': p.nameOfPurchasingChannel,
            'Registered by': _.trim(`${_.get(p, 'user.firstName') || ''} ${_.get(p, 'user.lastName') || ''}`),
            'Labor warranty': p.qualifyLaborWarranty ? 'Yes' : 'No',
            Registered: '',
            'First saved source': p.firstSavedSource,
            'First registered source': p.firstRegisteredSource,
            'Last updated source': p.source,
            'Module upload source': p.moduleUploadSource,
            'Inverter upload source': p.inverterUploadSource
        }));
    };

    const projectsDataSheet = useMemo(() => getProjectsDataSheet(projects), [projects]);

    useEffect(() => {
        getProjects(filters);
    }, []);

    return (
        <>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{
                    marginBottom: 10
                }}
            >
                <Text size="h2" className={classes.pageTitle} style={{ lineHeight: '0px' }}>
                    PROJECT MANAGEMENT
                </Text>
            </Grid>

            <Paper className={classes.container}>
                <Grid className={classes.searchContainer} container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search"
                            className={classes.inputField}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <SearchOutlinedIcon style={{ color: '#BDBDBD' }} />
                                    </InputAdornment>
                            }}
                            value={searchText}
                            onChange={e => handleSearchText(e.target.value)}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} style={{ position: 'relative' }}>
                        <DatePicketCustom
                            fullWidth
                            size="small"
                            variant="inline"
                            format="MM/dd/yyyy"
                            placeholder="Installation Date"
                            inputVariant="outlined"
                            value={installationDate}
                            onChange={handleSelectInstallationDate}
                            views={['year', 'month', 'date']}
                            className={classes.datePicker}
                            disablePast={false}
                        />
                        <IconButton
                            className={classes.iconButton}
                            edge="end"
                            size="small"
                            disabled={!installationDate}
                            onClick={() => handleSelectInstallationDate(null)}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid item md={3} xs={12} style={{ position: 'relative' }}>
                        <DatePicketCustom
                            fullWidth
                            size="small"
                            variant="inline"
                            format="MM/dd/yyyy"
                            placeholder="Operate Date"
                            inputVariant="outlined"
                            value={operateDate}
                            onChange={handleSelectOperateDate}
                            views={['year', 'month', 'date']}
                            className={classes.datePicker}
                            disablePast={false}
                        />
                        <IconButton
                            className={classes.iconButton}
                            edge="end"
                            size="small"
                            disabled={!operateDate}
                            onClick={() => handleSelectOperateDate(null)}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid item md={3} xs={12} className={classes.downloadButtonContainer}>
                        <Button
                            color="primary"
                            className={classes.downloadButton}
                            onClick={exportProjects}
                        >
                            Download
                        </Button>
                        <ExcelCustomComponent
                            filename={`projects${new Date()}`}
                            id="projects-data-sheet"
                            data={projectsDataSheet}
                        />
                    </Grid>
                </Grid>

                <div
                    style={{
                        borderTop: 'solid',
                        borderWidth: '1px',
                        borderColor: 'rgba(224, 224, 224, 1)'
                    }}
                >
                    <Grid
                        container
                        justify="space-between"
                        style={{
                            marginLeft: '30px'
                        }}
                    >
                        <Grid item md={4} xs={12}>
                            <Text color="dark" size="h4" weight="medium">
                                {total} Projects Total
                            </Text>
                        </Grid>
                    </Grid>
                </div>
                <TableContainer>
                    <Table className={classes.projectsTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Project Name</TableCell>
                                <TableCell>Project State</TableCell>
                                <TableCell>System Type</TableCell>
                                <TableCell>System Size (kW)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                projects.length > 0 ? (
                                    <>
                                        {
                                            projects.map((p, idx) => (
                                                <TableRow
                                                    key={p.id}
                                                    hover
                                                    onClick={() => {
                                                        history.push(`/projects/${p.id}`)
                                                    }}
                                                >
                                                    <TableCell>{total - idx}</TableCell>
                                                    <TableCell>{convertHTMLEntity(_.get(p, 'user.companyName'))}</TableCell>
                                                    <TableCell>{renderProjectName(p)}</TableCell>
                                                    <TableCell>{p.projectState}</TableCell>
                                                    <TableCell>{p.systemType}</TableCell>
                                                    <TableCell>{p.systemSize}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            {loading ? (
                                                <div className={classes.loadingIndicator}>
                                                    <CustomLoading size={40} color="#00adef"></CustomLoading>
                                                </div>
                                            ) : (
                                                <div className={classes.emptyProject}>There is no projects</div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                className={classes.viewMoreContainer}
            >
                {projects.length < total && (
                    <Button
                        color="secondary"
                        className={classes.viewMoreButton}
                        onClick={viewMore}
                        disabled={loadingMore}
                    >
                        { loadingMore ? <CustomLoading size={16} color="#009EE3" ></CustomLoading> : 'View More' }
                    </Button>
                )}
            </Grid>

        </>
    )
}
