import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from './../../common/notfound';
import Faq from './Faq';
import AddFaq from './AddFaq';
import EditFaq from './EditFaq';

export default function Faqs() {
    return (
      <Switch>
        <Route exact path="/admincontrols/faq" component={Faq} />
        <Route exact path="/admincontrols/faq/add" component={AddFaq} />
        <Route exact path="/admincontrols/faq/:id" component={EditFaq} />
        <Route component={NotFound} />
      </Switch>
    );
}