import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from '../../utils/axios';
import { baseUrl } from '../../config';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import {notiSuccess, notiFailed} from './../../utils/helpers'

const styles = theme => ({
  root: {
    margin: 0,
    padding: "24px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const CustomDialog = withStyles(theme => ({
  root: {
    "& .MuiPaper-rounded": {
      borderRadius: 0
    },
    "& .MuiDialog-paperWidthSm": {
      width: "850px",
      minWidth: "850px",
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset'
      }
    }
  }
}))(Dialog);

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderTop: 0,
    borderBottom: 0
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    marginBottom: "36px"
  }
}))(MuiDialogActions);

const DoneButton = withStyles(theme => ({
  root: {
    borderRadius: "20px",
    width: "200px",
    height: "40px",
    backgroundColor: "#319EE3",
    "&.MuiButton-textPrimary": {
      color: "#FFFFFF"
    },
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#319EE3"
    }
  }
}))(Button);


const CustomCircularProgress = withStyles(theme => ({
  root: {
    "&.MuiCircularProgress-colorPrimary": {
      color: '#319EE3'
    }
  }
}))(CircularProgress);

export default function VariantImageSelect({ id, open, handleClose, handleDone }) {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDone = () => {
    handleDone(selectedImage);
    setSelectedImage(null);
  }

  const onClose = () => {
    setSelectedImage(null);
    handleClose();
  }

  const getMedia = async () => {
    try {
        setLoading(true);
        const { data } = await axios.get(`${baseUrl}/products/${id}/media`);
        setImages(data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        notiFailed(`There was a problem getting media`);
    }
  }

  useEffect(() => {
    if (id) {
      getMedia();
    }
  }, []);

  const onEnter = () => {
    if (id) {
      getMedia();
    }
  };

  return (
    <div>
      <CustomDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        onEnter={onEnter}
        disableBackdropClick
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose} />
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" gutterBottom>
              Select variant image
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              You can only choose images as variant media
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ marginTop: "30px" }}
          >
            {loading ? 
              <CustomCircularProgress />
            :
            <Grid
              container
              spacing={3}
              style={{
                width: "95%",
                background: "#F6F6F6",
                padding: "10px",
                display: "flex"
              }}
            >
              {images &&
                images.map(image => {
                  if (selectedImage && image.id === selectedImage.id) {
                    return (
                      <Grid container justify="center" item md={3} xs={12} key={image.id}>
                        <img
                          style={{
                            width: "167px",
                            height: "167px",
                            boxSizing: "border-box",
                            border: "4px solid #319EE3",
                            transform: "scale(0.9)"
                          }}
                          src={image.assetUrl.indexOf('http') === 0 ? image.assetUrl : `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${image.assetUrl}`}
                          alt={image.assetKey}
                          onClick={() => setSelectedImage(null)}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid container justify="center" item md={3} xs={12} key={image.id}>
                        <img
                          style={{
                            width: "167px",
                            height: "167px",
                            cursor: "pointer"
                          }}
                          src={image.assetUrl.indexOf('http') === 0 ? image.assetUrl : `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${image.assetUrl}`}
                          alt={image.assetKey}
                          onClick={() => setSelectedImage(image)}
                        />
                      </Grid>
                    );
                  }
                })}
            </Grid>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <DoneButton
              disableRipple
              autoFocus
              onClick={onDone}
              color="primary"
            >
              Done
            </DoneButton>
          </Box>
        </DialogActions>
      </CustomDialog>
    </div>
  );
}
