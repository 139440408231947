import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import Button from '../button';
import { contactService } from '../../../services';
import { useContactDispatch, getListContact } from '../../../contexts/support';


function SearchSupportCenter({type}) {

  const dispatch = useContactDispatch();

  const [listCategories, setListCategories] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [typeContact, setTypeContact] = useState('all');
  const [category, setCategory] = useState('category');
  const [status, setStatus] = useState('status');
  const [account, setAccount] = useState('1');
  const [keyword, setKeyWord] = useState('');

  useEffect(() => {
    getListStatus();
    getListCategories();
  }, []);

  async function getListCategories() {
    const categories = await contactService.getListCategories(type);
    setListCategories(categories.data);
  }

  async function getListStatus() {
    const listStatus = await contactService.getListStatus(type);
    setListStatus(listStatus.data);
  }

  async function handleSubmitValue() {
    let payload = {};
    if(typeContact === 'name') {
      payload = {
        name : keyword
      }
    }else if(typeContact === 'assign') {
      payload = {
        assign : keyword
      }
    }
    let payloadCate = {};

    if(category !== 'category') {
      payloadCate = {
        categoryId : category
      }
    }

    let payloadStatus = {};

    if(status !== 'status') {
      payloadStatus = {
        statusId : status,
      }
    }
    getListContact(dispatch, {
      ...payloadCate,
      ...payloadStatus,
      size: 10,
      page : 1,
      contactTypeId : type,
      ...payload
    })
  }

  function handleChangeCategory(event) {
    const {value} = event.target;
    setCategory(value);
  }

  function handleChangeStatus(event) {
    const {value}= event.target;
    setStatus(value);
  }

  function handleChangeTypeContact(event) {
    const {value} = event.target;
    setTypeContact(value);
  }

  function handleChangeKeyWord(event) {
    const {value} = event.target;
    setKeyWord(value);
  }


  return (
    <Grid style={{ padding: '40px 30px 15px' }} container spacing={1} justify="flex-end">

      <Grid item sm={2} xs={12}>
        <TextField
          fullWidth
          select
          size="small"
          variant="outlined"
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}
          value={category}
          onChange={handleChangeCategory}
        >
          <MenuItem key={'category'} value={'category'}>Category</MenuItem>
          {listCategories?.map((item, index) => {
            return(
              <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid item sm={2} xs={12}>
        <TextField
          fullWidth
          select
          size="small"
          variant="outlined"
          placeholder={'Status'}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}

          value={status}
          onChange={handleChangeStatus}
        >
          <MenuItem value={'status'}>Status</MenuItem>
          {listStatus?.map((item, index) => {
            return(
              <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid item sm={2} xs={12}>
        <TextField
          fullWidth
          select
          size="small"
          variant="outlined"
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}
          onChange={handleChangeTypeContact}
          value={typeContact}
        >
          <MenuItem key={'all'} value={'all'}>All</MenuItem>
          <MenuItem key={'name'} value={'name'}>Name</MenuItem>
          <MenuItem key={'account'} value={'assign'}>Account Manager</MenuItem>
        </TextField>
      </Grid>
      <Grid item sm={2} xs={12}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}
          value={keyword}
          onChange={handleChangeKeyWord}
        />
      </Grid>
      <Grid item sm={1}>
        <Button
          onClick={handleSubmitValue}
          style={{
            height: '40px',
            minWidth: '100%',
            borderRadius: '5px',
            marginRight: '10px',
            padding: '.5rem 1rem'
          }}
        >
          Search
        </Button>
      </Grid>

    </Grid>
  );
}

export default React.memo(SearchSupportCenter);
