import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Checkbox, Fade,
  Grid, IconButton, makeStyles,
  MenuItem, Modal,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import Button from '../../../../common/components/button';
import { Link } from 'react-router-dom';
import CustomLoading from '../../../../common/components/CustomLoading';
import Text from '../../../../common/components/text';
import Pagination from '@material-ui/lab/Pagination';
import { academyService } from '../../../../services';
import Price from '../../../../common/components/price';
import moment from 'moment';
import NumberFormat from "react-number-format";
import Alert from '../../../../routes/rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CustomButton from '../../../../common/components/button';
import { notiFailed, notiSuccess } from '../../../../utils/helpers';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  tableHeaderCell: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1rem'
  },
  tableContainer: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    marginTop: '20px',
    borderRadius: 0,
    '& td': {
      fontFamily: 'Actual'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    }
  },
  container: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  paginationContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    '& .Mui-selected': {
      backgroundColor: '#00ADEF',
      color: '#fff'
    }
  },
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '40px'
  },
  noFaqContainer: {
    width: '95%',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: '#e5f3fb',
    paddingTop: '50px',
    paddingBottom: '50px',
    marginTop: '50px',
    marginBottom: '50px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  updatePriorityModalTitle: {
    textAlign: 'center',
    marginTop: '15px',
    fontSize: '36px',
    lineHeight: '45px'
  },
  updatePriorityModalSubtitle: {
    marginTop: '-32px',
    marginBottom: '20px',
    fontSize: '20px !important'
  },
  updatePriorityModalCancelBtn: {
    padding: '.75rem 2rem !important'
  },
  updatePriorityModalDeleteBtn: {
    padding: '.75rem 2rem !important'
  },
  updatePriorityModuleModalCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer'
    }
  },
  priorityTextField: {
    '& input': {
      backgroundColor: '#fff'
    }
  }
}));

function PriorityNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
}


function ListAcademy() {
  const classes = useStyles();
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [record, setTotalRecord] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectType] = useState('all');
  const [checked, setChecked] = useState([]);
  const [typeAcademy, setAcademyType] = useState([]);
  const [products, setProducts] = useState([]);
  const [openUpdatePriorityModal, setOpenUpdatePriorityModal] = useState(false);
  const [openFormDelete, setOpenFormDelete] = useState(false);
  const total = Math.ceil(record / 10);


  useEffect(() => {
    getListTypeOnline();
    getListProduct({page : currentPage});
  }, [])

  async function getListTypeOnline() {
    const types = await academyService.getListTypeOnline({categoryId : 1});
    setAcademyType(types.data);
  }

  async function getListProduct(condition) {
    setLoading(true);
    const response = await academyService.getListProduct(condition);
    setProducts(response.data.data);
    setTotalRecord(response.data.total);
    setLoading(false);
  }

  useEffect(() => {
    const makePrams = {
      page : currentPage,
      ... selectedType === 'all' ? {} : {academyTypeId : selectedType}
    }

    getListProduct(makePrams);
  }, [currentPage, selectedType]);


  function handleSelectFaqType(event) {
    setSelectType(event.target.value);
    setCurrentPage(1);
  }

  function handleChangePage(event, page) {
    setCurrentPage(page)
  }

  function handleChangePriority(id, value) {
    setProducts(products.map(item => {
      if (item.id === id) {
        return {
          ...item,
          priority: Number(value)
        }
      }

      return item;
    }))
  }

  function handleCloseUpdatePriorityModal() {
    setOpenUpdatePriorityModal(false);
  }

  function handleCloseFormDeleteModal() {
    setOpenFormDelete(false);
  }

  async function handleUpdatePriority() {
    const makePayload = products.map((item) => {
      return {
        id : item.id,
        priority : item.priority
      }
    })
    await academyService.updatePriority(makePayload).then(() => {
      notiSuccess('Update successfully');
      const makePrams = {
        page : currentPage,
        ... selectedType === 'all' ? {} : {academyTypeId : selectedType}
      }
      getListProduct(makePrams);
      setOpenUpdatePriorityModal(false)
    }).catch(() => {
      notiFailed('Update failed');
      setOpenUpdatePriorityModal(false)
    })
  }

  function handleCheck(value) {
    const doesExist = checked.some(c => c.id === value.id);
    let newCheck = [...checked];
    if (doesExist) {
      newCheck = checked.filter(c => c.id !== value.id);
    } else {
      newCheck.push(value);
    }

    setChecked(newCheck);

  }

  function handleShowModalDelete() {
    setOpenFormDelete(true);
  }

  async function handleDeleteProduct() {
    const ids = _.map(checked, 'id')
    await academyService.deleteProduct(ids).then(() => {
      notiSuccess('Deleted successfully');
      const makePrams = {
        page : 1,
        ... selectedType === 'all' ? {} : {academyTypeId : selectedType}
      }
      getListProduct(makePrams);
      setOpenFormDelete(false);

      setCurrentPage(1);
    }).catch(() => {
      notiFailed(`Deleted Failed`);
      setOpenFormDelete(false);
    })

  }


  return(
    <>
    <Paper className={classes.container}>
      <Grid style={{ padding: '40px 30px 15px' }} container spacing={1} justify="space-between">
        <Grid item xs={12} md={2} lg={2} sm={2}>
          <TextField
            fullWidth
            select
            size="small"
            variant="outlined"
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }
            }}
            label="Type"
            value={selectedType}
            onChange={handleSelectFaqType}
          >
            <MenuItem key={'all'} value={'all'}>All</MenuItem>
            {typeAcademy.map(ft => (
              <MenuItem key={ft.id} value={ft.id}>{ft.name}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                style={{ height: '40px' }}
                color="secondary"
                onClick={() => {
                  setOpenUpdatePriorityModal(true);
                }}
              >
                Priority Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ height: '40px' }}
                component={Link}
                to="/admincontrols/configurations/add"
              >
                Register
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: '50%'}} className={classes.tableHeaderCell} align='center'>Information</TableCell>
              <TableCell className={classes.tableHeaderCell} align='center'>Date</TableCell>
              <TableCell className={classes.tableHeaderCell} align='center'>Select</TableCell>
              <TableCell className={classes.tableHeaderCell} align='center'>Priority</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan="6">
                  <div className={classes.loadingIndicator}>
                    <CustomLoading size={40} color="#00adef"/>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>

                {
                  products.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan="6">
                        <div className={classes.noFaqContainer}>
                          <Text color="primaryLight" weight="bold">
                            No product.
                          </Text>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : products.map((item, index) => {
                      return(
                        <TableRow
                          hover
                          key={item.id}
                          className={classes.tableRow}
                          onClick={() => history.push(`/admincontrols/configurations/edit/${item.id}`)}
                        >
                          <TableCell style={{display: 'flex', flexDirection: 'row', alignContent : 'center', alignItems: 'center'}}>
                            <div style={{width : 160, border : '1px solid #80808038' ,height : 100, backgroundSize : 'cover' ,backgroundImage: "url(" + `${item?.thumbnail?.assetUrl}` + ")"}}>

                            </div>
                            <b style={{marginLeft : 20}}>{item.title}</b>
                          </TableCell>
                          <TableCell align="center">
                            {moment(item.createdAt).format('YYYY-MM-DD')}
                          </TableCell>


                          <TableCell align="center">
                            <Checkbox
                              color="#000000"
                              onChange={() => handleCheck(item)}
                              // checked={checked.some(
                              //   c => c.companyId === m.companyId
                              // )}
                              style={{ color: '#9ca4ab' }}
                              onClick={e => e.stopPropagation()}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              value={item.priority}
                              placeholder="Priority"
                              variant="outlined"
                              onChange={e => handleChangePriority(item.id, e.target.value)}
                              InputProps={{
                                inputComponent: PriorityNumberFormat
                              }}
                              onClick={e => e.stopPropagation()}
                              className={classes.priorityTextField}
                            />
                          </TableCell>
                        </TableRow>
                      )
                  })
                }
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid style={{ padding: '0px 30px 15px' }} container>
        <Grid
          style={{paddingRight: 10, marginTop: 35, marginBottom: 30}}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          xs={12} md={12} lg={12} sm={12}
        >
          <em>* Delete selected items</em>
          <CustomButton onClick={handleShowModalDelete} style={{marginLeft: 8}} color="secondary">
            DELETE
          </CustomButton>
        </Grid>

      </Grid>
    </Paper>
      { total !== 0 && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.paginationContainer}
        >
          <Pagination count={total} page={currentPage} onChange={handleChangePage}/>
        </Grid>
      ) }

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openFormDelete}
        onClose={handleCloseFormDeleteModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openFormDelete}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={handleCloseFormDeleteModal}
                // className={classes.updatePriorityCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.updatePriorityModalTitle}>
              Are you sure you want to delete ?
            </Text>
            <Text
              color="secondaryDark"
              className={classes.updatePriorityModalSubtitle}
            >
              Once you do this, it can't be undone.
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={handleCloseFormDeleteModal}
                  className={classes.updatePriorityModalCancelBtn}
                >
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  color="alert"
                  onClick={handleDeleteProduct}
                  className={classes.updatePriorityModalDeleteBtn}
                >
                  Delete
                </CustomButton>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openUpdatePriorityModal}
        onClose={handleCloseUpdatePriorityModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openUpdatePriorityModal}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={handleCloseUpdatePriorityModal}
                // className={classes.updatePriorityCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.updatePriorityModalTitle}>
              Are you sure you want to update priority ?
            </Text>
            <Text
              color="secondaryDark"
              className={classes.updatePriorityModalSubtitle}
            >
              Once you do this, it can't be undone.
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={handleCloseUpdatePriorityModal}
                  className={classes.updatePriorityModalCancelBtn}
                >
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  color="alert"
                  onClick={handleUpdatePriority}
                  className={classes.updatePriorityModalDeleteBtn}
                >
                  Update Priority
                </CustomButton>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
      </>

  )
}

export default ListAcademy;
