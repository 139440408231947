import React, { useCallback } from 'react';
import { adminService } from '../../services';



export async function getListAdmin(dispatch, condition) {
  try {

    dispatch({
      type : 'GET_LIST_ADMIN_PROGRESS',
      payload : condition
    })

    await adminService.getListUserAdmin({...condition}).then((res) => {
      dispatch({
        type : 'GET_LIST_ADMIN_SUCCESS',
        payload : {
          users : res.data.users,
          total_record: res.data.total
        }
      })
    });


  }catch (e) {

  }
}




