import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import Layout from './common/layout';
import Login from './routes/login';
import Rewards from './routes/rewards';
import Dashboard from './routes/dashboard';
import MarketingShop from './routes/marketingShop';
import Faq from './routes/faq';
import NotFound from './common/notfound';
import { NavbarProvider } from './contexts/navbar';
import NewRouter from './routes/news';
import { RoleProvider } from './contexts/roles';
import Registration from './routes/registration';
import DataHub from './routes/dataHub';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Offline from './routes/offline';
import SupportCenter from './routes/supportCenter';
import AdminManagement from './routes/admin';
import ClaimManagement from './routes/claimManagement';
import EditProfile from './pages/profile';
import Projects from './routes/projects';
import { ProfileAdminProvider } from './contexts/profile';

export const theme = {
  colors: {
    dark: '#000000',
    light: '#FFFFFF',
    primaryDark: '#002D56',
    primaryLight: '#00ADEF',
    secondaryDark: '#8C8C8C',
    secondaryLight: '#E6E6E6'
  },
  fontSizes: {
    h1: 'var(--font-size-1)',
    h2: 'var(--font-size-2)',
    h3: 'var(--font-size-3)',
    h4: 'var(--font-size-4)',
    h5: 'var(--font-size-5)',
    h6: 'var(--font-size-6)'
  }
};

function App() {
  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <NavbarProvider>
          <Router>
            <ToastContainer />
            <Switch>
              <Route exact path="/" component={Login}></Route>
              <RoleProvider>
                <ProfileAdminProvider>
                  <Layout>
                    <Switch>
                      <Route exact path="/dashboard" component={Dashboard}/>
                      <Route path="/rewards" component={Rewards}/>
                      <Route path="/marketing-shop" component={MarketingShop}/>
                      <Route path="/admincontrols/news" component={NewRouter}/>
                      <Route path="/supportcenter" component={SupportCenter}/>
                      <Route path="/admincontrols/management" component={AdminManagement}/>
                      <Route path="/supportcenter/claim-management" component={ClaimManagement}/>
                      <Route path="/admincontrols/configurations" component={Offline}/>
                      <Route path="/edit-profile" component={EditProfile}/>
                      <Route path="/admincontrols/data-hub" component={DataHub}></Route>
                      <Route path="/admincontrols/faq" component={Faq}></Route>
                      <Route path="/qpartners/registration/" component={Registration}></Route>
                      <Route path="/projects" component={Projects}></Route>
                      <Route component={NotFound} />
                    </Switch>
                  </Layout>
                </ProfileAdminProvider>
              </RoleProvider>
            </Switch>
          </Router>
        </NavbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
