import React from 'react';

const MoveIcon = ({ color, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
    <image
      id="move_1_copy_13"
      data-name="move (1) copy 13"
      class="cls-1"
      width="23"
      height="23"
      href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAXCAYAAAAP6L+eAAABkElEQVQ4jb3UzYuPURQH8M/oNyNvWYgaDSsLEll4mUwpmYW1sqOU7Pwpvz9BYTVbS5psaAoZbISyGbGwEDN5yYh0dZ46PV2Pp/lNvnV77jn33O9z7v2ee8aGw6Ge2BhjpU/whp6kJe4iLgX5uhGfxXaM49x6Ee/HgWTvxvFRiUuGsxX/SWwdhfgH5rGUfO9wB19HIS54hQ/J/oQX+DUq8ZrQJp7EiQrRtzT/Xlk/hX3ZMUjzIsg0PuNZ8q/iQYy8b3OyS9Ucw/PQ5OcglD8f2RZsw9W06T4etTI8iplK5gexF3OF+DK2VIJqp2rQpU1J7EIJuImPHYE19Vc74ks5XhuEMDdwGkfijudS4BeMYSLsIt7jGA2uRKYLeJiPWbK6i9fYU+lgZ+KnQsSF1voTvMX7xtG+v6XWK2swnuYTlfXFtuO/PZDa+mHsSL5dOBT3/lfUSiljU4ia46awEy+jSa0p41IR9yr++S7SPsQFT/Em2aWzlY7Xib7i3Yr6XcbtPhv+dccNyrGvx7ezD/8BfgMscUfsOlwg+AAAAABJRU5ErkJggg=="
    />
  </svg>
);

export default MoveIcon;