import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainDataHub from './pages/mainDataHub';
import DataHubPortal from './pages/dataHubPortal';
import FormEditDataHub from './pages/components/FormEditDataHub';

export default function DataHub() {
  return(
    <Switch>
      <Route
        exact
        path="/admincontrols/data-hub"
        component={MainDataHub}
      />
      <Route
        exact
        path="/admincontrols/data-hub/portal/:id"
        component={FormEditDataHub}
      />
      <Route
        exact
        path="/admincontrols/data-hub/portal"
        component={DataHubPortal}
      />
    </Switch>
  )
}
