import axios from './../utils/axios';
import config  from "../config";


function getListCategoryDataHub() {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/data-hub/categories`,
  }

  return axios(request);
}

function getListDataHub(condition) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/data-hub/search`,
    params : condition
  }

  return axios(request);
}

function deleteDataHub(ids) {
  const request = {
    method : 'DELETE',
    url : `${config.baseUrl}/data-hub/delete`,
    data : ids
  }

  return axios(request);

}

function registerDataHub(formData) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/data-hub`,
    header: {'content-type': 'multipart/form-data'},
    data : formData
  }

  return axios(request)
}

function updateDataHub(formData) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/data-hub`,
    header: {'content-type': 'multipart/form-data'},
    data : formData
  }

  return axios(request)
}

function getDetailDataHub(id) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/data-hub/detail/${id}`,
  }

  return axios(request)
}



export {getListCategoryDataHub, getListDataHub, deleteDataHub, registerDataHub, getDetailDataHub, updateDataHub};


