import React, { useEffect, useState } from 'react';
import JoditEditor from 'jodit-react';
import Text from '../../../../common/components/text';
import { Backdrop, Button, Fade, Grid, IconButton, makeStyles, Modal, Paper, TextField } from '@material-ui/core';
import BackButton from '../../../../common/components/button/BackButton';
import { Link, useHistory, useParams } from 'react-router-dom';
import CustomButton from '../../../../common/components/button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { newsService } from '../../../../services';
import { notiFailed, notiSuccess } from '../../../../utils/helpers';
import CustomLoading from '../../../../common/components/CustomLoading';
import Alert from '../../../../routes/rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../../../../utils/axios';

const config = {
  readonly: false,
  uploader: {
    insertImageAsBase64URI: true
  },
  // askBeforePasteHTML: false,
  // askBeforePasteFromWord: false
};

const useStyle = makeStyles(them => ({
  input: {
    display: 'none'
  },
  input1: {
    display: 'none'
  },
  input2: {
    display: 'none'
  },
  button: {
    height: 56,
    border: '1px solid gray',
    marginLeft: 10
  },
  buttonSubmit: {
    height: 32,
    width: 182,
    backgroundColor: '#00ADEF',
    border: 'none',
    color: 'white',
    borderRadius: 34
  },
  txtPoint : {
    color : 'red'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textDel : {
    fontSize: 36
  },
  uploadedContentImage: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '10px'
  },
  uploadedContentImageDownload: {
    marginTop: 0,
    marginBottom: 0,
    boxSizing: 'border-box',
    height: '26px',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: '1px solid'
    }
  },
  uploadedContentImageIcon: {
    fontSize: '16px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));


const schema = yup.object().shape({
  title: yup.string().required(),
  content : yup.string().required()
});

function FormEditNew() {

  const history = useHistory();
  const { id } = useParams();
  const [linkImage, setLinkImage] = useState('');
  const [attackOne, setAttackOne] = useState('');
  const [open, setOpen] = useState(false);
  const [attackTow, setAttackTow] = useState('');
  const [loading, setLoading] = useState(false);
  const [infoNew, setInfoNew] = useState({});
  const [error, setError] = useState(false);
  const { register, handleSubmit, watch, errors, setValue } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false
  });

  useEffect(() => {
    getInfoNew();
  }, [id]);

  async function getInfoNew() {
    const response = await newsService.getNewById(id);
    if(response && response.data) {
      setInfoNew(response.data);
      setValue('title', response.data?.title);
      setValue('video', response.data?.video);
      setLinkImage(response.data?.image?.assetUrl);
      setAttackOne(response.data?.attachment1?.assetUrl);
      setAttackTow(response.data?.attachment2?.assetUrl);
    }
  }

  useEffect(() => {
    if(errors?.title?.message && errors?.content?.message) {
      setError(true);
      notiFailed(`Title can't blank`)
    }else if(errors?.title?.message) {
      setError(true);
      notiFailed(`Title can't blank`)
    }else if(errors?.content?.message) {
      notiFailed(`Content can't blank`)
    }
  }, [errors]);



  const onSubmit = async data => {

    const formData = new FormData();
    formData.append('id', infoNew.id);
    formData.append('title', data.title);
    formData.append('content', data.content);
    formData.append('video', data.video);
    // if(!/(ftp:\/\/|www\.|https?:\/\/){1}[a-zA-Z0-9u00a1-\uffff0-]{2,}\.[a-zA-Z0-9u00a1-\uffff0-]{2,}(\S*)/.test(data.video)) {
    //   notiFailed('Not in video URL format.');
    //   return;
    // }

    if(data.image.length !== 0) {
      formData.append('image', data.image[0])
    }else if (!linkImage){
      formData.append('image', 'delete')
    }

    if(data.attachment1.length !== 0) {
      formData.append('attachment1', data.attachment1[0])
    }else if (!attackOne){
      formData.append('attachment1', 'delete')
    }

    if(data.attachment2.length !== 0) {
      formData.append('attachment2', data.attachment2[0])
    }else if (!attackTow){
      formData.append('attachment2', 'delete')
    }

    setLoading(true);
    await newsService.updateNew(formData).then(() => {
      notiSuccess('Updated successfully!');
      history.push('/admincontrols/news');
    }).catch(() => {
      notiFailed('Updated failed!');
    });
    setLoading(false);

  };

  const classes = useStyle();

  function pickImageContent(event) {
    setLinkImage(event.target.value);
  }

  function pickImageAttackTow(event) {
    setAttackTow(event.target.value);
  }

  function pickImageAttackOne(event) {
    setAttackOne(event.target.value);
  }

  async function handleDeleteNew() {
    await newsService.deleteNew(id).then(() => {
      notiSuccess('Delete successfully!');
      history.push('/admincontrols/news');
    }).catch(() => {
      notiFailed('Delete failed !');
    });
  }

  function handleCloseModal() {
    setOpen(false);
  }

  function handleClose() {
    setOpen(false);
  }

  async function handleDownloadUploadContentImage(linkImage) {
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common["Authorization"];
      const { data } = await instance.get(linkImage, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', linkImage.substr(linkImage.lastIndexOf('/') + 1));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      notiFailed(`There was a problem download upload content image ${err.message}`);
    }
  }

  function handleDeleteImage() {
    setInfoNew(prevState => ({
      ...prevState,
      image : {
        assetUrl : ''
      }
    }))
    setLinkImage('');
    setValue('image', null);
    notiSuccess('File delete.');
  }

  function handleDeleteImageAttackOne() {
    setInfoNew(prevState => ({
      ...prevState,
      attachment1 : {
        assetUrl: ''
      }
    }))
    setAttackOne('');
    setValue('attachment1', null);
    notiSuccess('File delete.');
  }

  function handleDeleteImageAttackTow() {
    setInfoNew(prevState => ({
      ...prevState,
      attachment2 : {
        assetUrl: ''
      }
    }))
    setAttackTow('');
    setValue('attachment2', null);
    notiSuccess('File delete.');
  }

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          marginBottom: 10
        }}
      >
        <div>
          <BackButton component={Link} to="/admincontrols/news">
            List news
          </BackButton>
          <Text size="h2" style={{ lineHeight: '0px' }}>
            Notice
          </Text>
        </div>
      </Grid>
      <Paper>

        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Title
              <em className={classes.txtPoint}>*</em>
            </Text>
            <TextField
              inputRef={register}
              name="title"
              fullWidth
              variant="outlined"
              placeholder="Title"
              error={error}
              onChange={(event) => {
                const { value } = event.target;
                if (value !== '') {
                  setError(false);
                }
              }}
              helperText={errors.title?.message && <p style={{ color: 'red' }}>{`Title can't blank`}</p>}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Content Image
            </Text>
            <div>
              <TextField
                name={'content_image'}
                disabled
                value={linkImage}
                variant="outlined"
                placeholder="Please choose file"
                onChange={e => {

                }}
              />
              <input
                onChange={pickImageContent}
                ref={register}
                name={'image'}
                accept="image/*"
                className={classes.input}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>

            {!!infoNew?.image?.assetUrl && (
              <div className={classes.uploadedContentImage}>
                <p
                  className={classes.uploadedContentImageDownload}
                  onClick={() => handleDownloadUploadContentImage(infoNew.image?.assetUrl)}
                >
                  {infoNew?.image?.assetUrl.substr(infoNew?.image?.assetUrl.lastIndexOf('/') + 1)}
                </p>
                <CloseIcon className={classes.uploadedContentImageIcon} onClick={handleDeleteImage}/>
              </div>
            )}

          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Video
            </Text>
            <TextField
              fullWidth
              inputRef={register}
              name="video"
              variant="outlined"
              placeholder="Video"
              onChange={e => {

              }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Content
              <em className={classes.txtPoint}>*</em>
              {' '}<span style={{color: '#000000', fontSize: '10px'}}>(When uploading images make sure image size is not greater than 3mb)</span>
            </Text>
            <JoditEditor
              ref={register}
              name={'content'}
              value={infoNew.content}
              config={config}
              tabIndex={1}
              helperText="test"
              onChange={newContent => {
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Attachment1
            </Text>
            <div>
              <TextField
                value={attackOne}
                variant="outlined"
                disabled
                placeholder="Please chose file"
              />
              <input
                ref={register}
                name={'attachment1'}
                onChange={pickImageAttackOne}
                className={classes.input2}
                id="raised-button-file-2"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file-2">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>
            {!!infoNew?.attachment1?.assetUrl  && (
              <div className={classes.uploadedContentImage}>
                <p
                  className={classes.uploadedContentImageDownload}
                  onClick={() => handleDownloadUploadContentImage(infoNew?.attachment1?.assetUrl)}
                >
                  {infoNew?.attachment1?.assetUrl.substr(infoNew?.attachment1?.assetUrl.lastIndexOf('/') + 1)}
                </p>
                <CloseIcon className={classes.uploadedContentImageIcon} onClick={handleDeleteImageAttackOne}/>
              </div>
            )}

          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text weight="medium" style={{ color: '#8c8c8c', marginBottom: '1px' }}>
              Attachment2
            </Text>
            <div>
              <TextField value={attackTow} disabled variant="outlined" placeholder="Please chose file" onChange={e => {
              }}/>
              <input
                name={'attachment2'}
                ref={register}
                onChange={pickImageAttackTow}
                className={classes.input2}
                id="raised-button-file-3"
                type="file"
              />
              <label htmlFor="raised-button-file-3">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>

            {!!infoNew?.attachment2?.assetUrl && (
              <div className={classes.uploadedContentImage}>
                <p
                  className={classes.uploadedContentImageDownload}
                  onClick={() => handleDownloadUploadContentImage(infoNew?.attachment2?.assetUrl)}
                >
                  {infoNew?.attachment2?.assetUrl.substr(infoNew?.attachment2?.assetUrl.lastIndexOf('/') + 1)}
                </p>
                <CloseIcon className={classes.uploadedContentImageIcon} onClick={handleDeleteImageAttackTow}/>
              </div>
            )}


          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              marginBottom: 10
            }}
          >
            {loading ?
              <CustomButton color="secondary">
                <CustomLoading color="#00adef"/>
              </CustomButton> :
              <input style={{cursor: 'pointer'}} className={classes.buttonSubmit} value="Confirm" type="submit"/>}
            <CustomButton onClick={() => history.push('/admincontrols/news')}
                          style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, marginTop: 5 }} color="gray">
              Cancel
            </CustomButton>

            <CustomButton onClick={() => setOpen(true)}
                          style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, marginTop: 5 }} color="gray">
              Delete
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>
    </form>
      <Modal
        aria-labelledby="transition-modal-title-new"
        aria-describedby="transition-modal-description-new"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton>
                <CloseOutlinedIcon onClick={() => setOpen(false)} fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.textDel}>
              Are you sure you want to delete ?
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  color="alert"
                  onClick={handleDeleteNew}
                >
                  Delete
                </CustomButton>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
      </>
  );
}

export default FormEditNew;
