import React from 'react';
import Text from '../text';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({

  companyInfoContainerSummary: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    backgroundColor: 'rgb(247, 247, 247)',
    padding: '30px 50px',
    marginTop: '23px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(100%, 1fr))',
      padding: '10px 10px',
      marginTop: '15px'
    }
  },
}));

function PointSummary({totalQpoints , consumed, availableQpoints}) {

  const classes = useStyles();

  return(
    <>
      <Text style={{marginTop: 15}} size="h4" weight="medium">Point Summary</Text>
      <Grid
        container
        item
        justify="space-around"
        alignItems="flex-start"
        className={classes.companyInfoContainerSummary}
      >

        <div>
          <Text
            size="h4"
            weight="medium"
          >
            Total Accrued
          </Text>
          <Text
            size="h4"
            color="secondaryDark"
            weight="light"
          >
            {Number(totalQpoints).toLocaleString()} Q
          </Text>

        </div>

        <div>
          <Text
            size="h4"
            weight="medium"
          >
            Total Consumed
          </Text>
          <Text
            size="h4"
            color="secondaryDark"
            weight="light"
          >
            {Number(consumed).toLocaleString()} Q
          </Text>

        </div>

        <div>
          <Text
            size="h4"
            weight="medium"
          >
            Total Available
          </Text>
          <Text
            size="h4"
            color="secondaryDark"
            weight="light"
          >
            {Number(availableQpoints).toLocaleString()} Q
          </Text>

        </div>


      </Grid>
    </>
  )
}

export default PointSummary;
