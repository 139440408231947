import React, { useState, useEffect } from 'react';
import Text from '../../common/components/text';
import { makeStyles } from '@material-ui/core/styles';
import TextButton from '../../common/components/button/TextButton';
import {
    Grid,
    Paper,
    TextField,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Checkbox,
    InputAdornment,
    TableContainer
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SquareButton from '../../common/components/button/SquareButton';
import styled from "styled-components";
import QpointMagementDetails from './QpointManagementDetails';
import classNames from 'classnames';
import Pagination from '@material-ui/lab/Pagination';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import { notiSuccess, notiFailed } from './../../utils/helpers';
import CustomLoading from './../../common/components/CustomLoading';
import { ExcelCustomComponent } from '../../utils/components';
import { convertHTMLEntity } from './../../utils/helpers';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
    pageTitle: {
        lineHeight: '0px',
        "& span": {
            textTransform: 'uppercase'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '28px !important'
        }
    },
    qpointManagementContainer: {
        backgroundColor: '#ffffff',
        border: 'solid #E6E6E6 1px',
        borderBottom: 0,
        marginBottom: '20px'
    },
    updateBtn: {
        height: '40px',
        width: '90px',
        display: 'inline-block',
        borderRadius: '6px',
        fontFamily: 'Actual',
        backgroundColor: '#00adef',
        border: '1px solid rgba(0, 0, 0, 0)',
        color: 'white',
        padding: '.65rem',
        marginRight: '5px',
        marginLeft: '5px',
        "&:hover": {
            backgroundColor: '#002d56',
            cursor: 'pointer'
        }
    },
    tableContainer: {
        boxShadow: 'none'
    },
    table: {
        minWidth: 650
    },
    tableHeaderCell: {
        paddingLeft: 0,
        color: '#9ca4ab',
        fontFamily: 'Actual',
        fontWeight: '400',
        fontSize: '1.0625rem',
        whiteSpace: 'nowrap'
    },
    tableCellPlaceholder: {
        width: '2.5vw'
    },
    checkbox: {
        color: '#8C8C8C'
    },
    checkboxChecked: {
        '&.Mui-checked': {
            color: '#00ADEF'
        }
    },
    paginationContainer: {
        marginTop: '20px',
        marginBottom: '20px',
        "& .Mui-selected": {
            backgroundColor: '#00ADEF',
            color: '#fff'
        }
    },
    loadingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    noCompanyContainer: {
        width: '95%',
        margin: 'auto',
        textAlign: 'center',
        backgroundColor: '#e5f3fb',
        paddingTop: '50px',
        paddingBottom: '50px',
        marginTop: '50px',
        marginBottom: '50px'
    },
    searchField: {
        [theme.breakpoints.down('md')]: {
            marginBottom: '10px'
        }
    }
}));

export default function QpointManagement() {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [page, setPage] = useState(1);
    const [companies, setCompanies] = useState([]);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState({
        limit,
        offset
    });
    const [loadingCompanies, setLoadingCompanies] = useState([]);
    const [searchField, setSearchField] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [countPage, setCountPage] = useState(1);
    const [pointRequestStatuses, setPointRequestStatuses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [qpointManagementReportDataSheet, setQpointManagementReportDataSheet] = useState([]);

    const getCompanies = async (filters = {}) => {
        setLoadingCompanies(true);
        try {
            const { data } = await axios.get(`${baseUrl}/users/qpoints/search`, { params: filters });
            setCompanies(data.data);
            setTotalCompanies(data.total);
            const numberPage = Math.ceil(data.total / limit);
            setCountPage(numberPage);
        } catch (err) {
            notiFailed(
                `There was a problem getting companies ${err.message}`
            );
        } finally {
            setLoadingCompanies(false);
        }
    };

    const handleSearch = async () => {
        const filter = {
            ...filters,
            offset: 0,
            keyword: searchField
        };
        setOffset(0);
        setCurrentPage(1);
        setFilters(filter);
        getCompanies(filter);
    };

    const handleChange = (event, value) => {
        setCurrentPage(value);
        const newOffset = limit * value - limit;
        setOffset(newOffset);
        const filter = {
            ...filters,
            offset: newOffset
        };
        setFilters(filter);
        getCompanies(filter);
    };

    const getPointRequestStatuses = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/point-request/statuses`);
            setPointRequestStatuses(data);
        } catch (err) {
            notiFailed(
                `There was a problem getting point request statuses ${err.message}`
            );
        }
    };

    const handleFilterPointUpdateStatus = e => {
        const status = e.target.value;
        const filter = {
            ...filters,
            offset: 0
        };
        if (status === 'all') {
            delete filter.status;
        } else {
            filter.status = status;
        }
        setOffset(0);
        setCurrentPage(1);
        setSelectedStatus(status);
        setFilters(filter);
        getCompanies(filter);
    };

    const handleReloadCompanies = () => {
        getCompanies(filters);
    };

    const downloadQpointManagementReport = async () => {
        try {
            const { limit, offset, ...restFilters } = filters;
            const { data } = await axios.get(`${baseUrl}/users/qpoints/search`, { params: restFilters });
            let createReport = [{
                company: '',
                state: '',
                step: '',
                totalAccured: '',
                totalConsumed: '',
                totalAvailable: '',
                latestPointUpdate: ''
            }];
            if (data.total > 0) {
                createReport = await data.data.map(c => {
                    return {
                        company: convertHTMLEntity(c.name),
                        state: c.state,
                        step: c.approvalStep,
                        totalAccured: `${Number(c.totalQpoints).toLocaleString()} Q`,
                        totalConsumed: `${Number(c.consumed).toLocaleString()} Q`,
                        totalAvailable: `${Number(c.availableQpoints).toLocaleString()} Q`,
                        latestPointUpdate: _.get(c, 'pointRequest.requestStatus', 'N/A')
                    }
                });
            }

            setQpointManagementReportDataSheet(createReport);
            document.getElementById('qpoint-management-report-data-sheet').click();
        } catch (err) {
            notiFailed(
                `There was a problem getting companies ${err.message}`
            );
        }
    };

    useEffect(() => {
        getPointRequestStatuses();
        getCompanies(filters);
    }, []);

    return (
        <>
            <Text size="h2" className={classes.pageTitle}>
                <span>Q.Point</span> Management
            </Text>
            <Grid container spacing={1} style={{ marginBottom: '16px' }}>
                <Grid item>
                    <TextButton
                        onClick={downloadQpointManagementReport}
                    >
                        Download Report
                    </TextButton>
                    <ExcelCustomComponent
                        filename={`qpoint-management-report-${new Date()}.csv`}
                        id="qpoint-management-report-data-sheet"
                        data={qpointManagementReportDataSheet}
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.qpointManagementContainer}>
                <div
                    style={{
                        borderBottom: 'solid',
                        borderWidth: '1px',
                        borderColor: '#E6E6E6',
                        width: '100%'
                    }}
                >
                    <Grid
                        container
                        spacing={1}
                        style={{
                            maxWidth: '75vw',
                            margin: 'auto',
                            paddingTop: '20px',
                            paddingBottom: '20px'
                        }}
                    >
                        <Grid item md={10} xs={12} className={classes.searchField}>
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                placeholder="Search"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon />
                                      </InputAdornment>
                                    ),
                                }}
                                value={searchField}
                                onChange={e => setSearchField(e.target.value)}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                variant="outlined"
                                SelectProps={{
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        },
                                        getContentAnchorEl: null
                                    }
                                }}
                                label="Point Update Status"
                                onChange={handleFilterPointUpdateStatus}
                                defaultValue={'all'}
                                value={selectedStatus}
                            >
                                <MenuItem key={'all'} value={'all'}>All</MenuItem>
                                {pointRequestStatuses.map(s => (
                                    <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </div>
                <div
                    style={{
                        borderBottom: 'solid',
                        borderWidth: '1px',
                        borderColor: '#E6E6E6',
                        width: '100%'
                    }}
                >
                    <Grid
                        container
                        justify="space-between"
                        style={{
                            maxWidth: '75vw',
                            margin: 'auto'
                        }}
                    >
                        <Grid item md={4} xs={12}>
                            <Text color="dark" size="h4" weight="medium">
                                {totalCompanies} Companies Total
                            </Text>
                        </Grid>
                    </Grid>
                </div>
                <Grid
                    container
                    justify="flex-start"
                    style={{
                        maxWidth: '80vw',
                        margin: 'auto'
                    }}
                >
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>}
                                    <TableCell className={classes.tableHeaderCell}>
                                        Company
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell}>
                                        State
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="center">
                                        Step
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="right">
                                        Total Accrued
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="right">
                                        Total Consumed
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="right">
                                        Total Available
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell} align="center">
                                        Latest Point Update​
                                    </TableCell>
                                    {/*<TableCell className={classes.tableHeaderCell} align="right">*/}
                                    {/*    <IconButton size="small" disabled>*/}
                                    {/*        <Checkbox*/}
                                    {/*            color="primary"*/}
                                    {/*            checked={false}*/}
                                    {/*        />*/}
                                    {/*    </IconButton>*/}
                                    {/*</TableCell>*/}
                                    {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { loadingCompanies ? (
                                     <TableRow>
                                        <TableCell colSpan="10">
                                            <div className={classes.loadingIndicator}>
                                                <CustomLoading size={40} color="#00adef"></CustomLoading>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {companies.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan="10">
                                                    <div className={classes.noCompanyContainer}>
                                                        <Text color="primaryLight" weight="bold">
                                                            No company.
                                                        </Text>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : companies.map(company => (
                                            <QpointMagementDetails
                                                key={company.id}
                                                company={company}
                                                handleReloadCompanies={handleReloadCompanies}
                                                isSmallScreen={isSmallScreen}
                                            />
                                        ))}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {!loadingCompanies && countPage > 1 && (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.paginationContainer}
                >
                    <Pagination count={countPage} page={currentPage} onChange={handleChange} />
                </Grid>
            )}
        </>
    )
}
