import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import Products from './Products';
import Orders from './Orders';
import Categories from './Categories';
import AddProducts from './AddProducts';
import NotFound from './../../common/notfound'
import { RoleContext } from './../../contexts/roles';

export default function MarketingShop() {
  const { marketingShopRoutes } = useContext(RoleContext);

  return (
    <Switch>
      {marketingShopRoutes.map(route => (
        <Route exact key={route.path} path={route.path} component={route.component} />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
}
