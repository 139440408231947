
import React, { useState, useReducer } from 'react';

export const initialState = {
  loading: true,
  profile : {}
};

export const profileReducer = (initialState, action) => {
  switch (action.type) {
    case 'GET_PROFILE_PROGRESS':
      return {
        ...initialState
      }
    case 'GET_PROFILE_SUCCESS':
      return {
        ...initialState,
        profile : action.payload
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
