import ReactExport from 'react-data-export';
import React from 'react';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const ExcelCustomComponent = props => {
  const { filename, data, id } = props;

  const formatDataTemplate = () => {
    if (!data || !data.length) return [];
    return [
      {
        columns: Object.keys(data[0]).map(key => ({ title: key })),
        data: data.map(item => {
          return Object.keys(item).map(key => ({
            value: item[key] || item[key] === 0 ? item[key] + '' : ''
          }));
        })
      }
    ];
  };

  return (
    <ExcelFile
      filename={filename}
      element={<div id={id} style={{ display: 'none' }} />}
    >
      <ExcelSheet name={filename} dataSet={formatDataTemplate()} />
    </ExcelFile>
  );
};
