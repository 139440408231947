import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import ShirtImg from '../../config/images/shirt.png';
import { Grid, Box } from '@material-ui/core';
import Text from '../../common/components/text';
import { makeStyles } from '@material-ui/core/styles';
import 'react-dropzone-uploader/dist/styles.css';
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import { toast } from 'react-toastify';
import DeleteImageModal from './DeleteImageModal';

import {notiSuccess, notiFailed} from './../../utils/helpers'

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        width: '169px',
        height: '169px',
        '&:hover div[role=overlay]': {
            opacity: 1
        }
    },
    overlay: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.1)',
        width: '90%',
        height: '90%',
        position: 'absolute',
        top: '9px',
        bottom: 0,
        opacity: 0,
        transition: 'opacity 0.5s ease'
    },
    delete: {
        backgroundColor: '#FFFFFF',
        color: '#A9A9A9',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '5px 15px',
        cursor: 'pointer',
        border: 0
    }
}));

const ProductUploaderInput = ({
    className,
    labelClassName,
    labelWithFilesClassName,
    style,
    labelStyle,
    labelWithFilesStyle,
    getFilesFromEvent,
    accept,
    multiple,
    disabled,
    content,
    withFilesContent,
    onFiles,
    files
}) => {
    return (
        <label
            className={labelClassName}
            style={labelStyle}
        >
            <p>
                <span
                    style={{
                        color: "#52B7E8",
                        textDecoration: "underline",
                        fontSize: "16px",
                        fontWeight: "normal"
                    }}
                >
                    Add Images
                </span>{" "}
                <br />
                <span style={{ fontSize: "12px", color: "#000", fontWeight: 300 }}>
                    or drop files to upload
                </span>
            </p>
            <input
                className={className}
                style={style}
                type="file"
                accept={accept}
                multiple={multiple}
                disabled={disabled}
                onChange={async e => {
                    const target = e.target;
                    const chosenFiles = await getFilesFromEvent(e);
                    onFiles(chosenFiles);
                    //@ts-ignore
                    target.value = null;
                }}
            />
        </label>
    );
};
  

const ProductUploaderLayout = ({
    input,
    previews,
    dropzoneProps,
    files,
    extra: { maxFiles }
}) => {
    return (
        <>
            {files.length < maxFiles && <Grid item md={3} xs={12} {...dropzoneProps}>
                { input }
            </Grid>}
        </>    
    )
}

const ProductUploaderPreview = SortableElement(({ name, url, remove }) => {
    const classes = useStyles();

    return (
        <Grid item md={3} xs={12} className={classes.container}>
            <img src={url} alt={name} style={{ width: '100%', height: '100%', border: '1px solid #EDEDED' }}/>
            <div className={classes.overlay} role="overlay">
                <button onClick={remove} className={classes.delete}>Delete</button>
            </div>
        </Grid>
    )
});

const Container = SortableContainer(props => {
    const { images, handleRemove, handleChangeStatus, ...restProps } = props;

    return (
        <Grid
            container
            item
            xs={12}
            style={{
                backgroundColor: '#F6F6F6',
                textAlign: 'center',
                padding: '20px',
            }}
            spacing={2}
        >
            {images && images.map((image, index) => (
                <ProductUploaderPreview
                    key={image.id}
                    index={index}
                    name={image.assetKey}
                    url={image.assetUrl.indexOf('http') === 0 ? image.assetUrl : `${process.env.REACT_APP_QPARTNER_LEGACY_IMAGE_BASE_URL}${image.assetUrl}`}
                    remove={() => handleRemove(image.id)}
                    {...restProps}
                />
            ))}
            <Dropzone
                onChangeStatus={handleChangeStatus}
                LayoutComponent={ProductUploaderLayout}
                PreviewComponent={null}
                InputComponent={ProductUploaderInput}
                SubmitButtonComponent={null}
                accept="image/*"
                maxFiles={10}
                multiple={true}
                styles={{
                    dropzone: {
                        width: '169px',
                        height: '169px',
                        overflow: 'hidden',
                        borderRadius: 0,
                        border: "2px dashed #ACACAC",
                        padding: 0
                    },
                    preview: {
                        margin: '10px',
                    }
                }}
            />
      </Grid>
    );
});

export default function ProductUploaderEdit({ handleChange, id }) {
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const getMedia = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/products/${id}/media`);
            setImages(data);
        } catch (err) {
            notiFailed(`There was a problem getting media`);
        }
    }

    useEffect(() => {
        getMedia();
    }, []);

    const handleChangeStatus = async (fileWithMeta, status, filesWithMeta) => {
        if (status === 'done') {
            let formData = new FormData();
            formData.append('productImages', fileWithMeta.file);

            try {
                const { data } = await axios.post(`${baseUrl}/products/${id}/media`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                
                getMedia();
            } catch (err) {
                notiFailed(`There was a problem upload media`);
            }
        }   
    };

    const arrayMoveMutate = (array, from, to) => {
        const startIndex = to < 0 ? array.length + to : to;
        const item = array.splice(from, 1)[0];
        array.splice(startIndex, 0, item);
    };
    
    const arrayMove = (array, from, to) => {
        array = array.slice();
        arrayMoveMutate(array, from, to);
        return array;
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setImages(arrayMove(images, oldIndex, newIndex));
    };

    const handleCloseDeleteImageModal = () => {
        setOpenDeleteModal(false);
        setSelectedImage(null);
    };

    const handleOpenDeleteImageModal = (id) => {
        setOpenDeleteModal(true);
        setSelectedImage(id);
    };

    const handleDeleteImage = async () => {
        if (selectedImage){
            try {
                const { data } = await axios.delete(`${baseUrl}/mediaLibraries/${selectedImage}`);
                handleCloseDeleteImageModal();
                getMedia();
            } catch (err) {
                handleCloseDeleteImageModal();
                notiFailed(`There was a problem delete media`);
            }
        }
    }

    return (
        <Grid
            container
            style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
        >
            <Grid item xs={12}>
                <Box style={{ display: "flex", flexDirection: "row", marginBottom: '10px' }}>
                    <Text size="h3" weight="medium" style={{ flexGrow: 1 }}>Images</Text>
                    <Text size="subtitle" style={{ lineHeight: '58px', color: '#666666' }}>Drag Images to Rearrange Edit</Text>
                </Box>
            </Grid>
            
            <Container 
                images={images}
                axis="xy"
                onSortEnd={onSortEnd}
                handleChangeStatus={handleChangeStatus}
                handleRemove={handleOpenDeleteImageModal}
            />

            <DeleteImageModal 
                open={openDeleteModal}
                handleClose={handleCloseDeleteImageModal}
                handleDelete={handleDeleteImage}
            />
        </Grid>
    )
}