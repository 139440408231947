import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from './../../common/notfound';
import RegistrationList from './Registration';
import RegistrationDetail from './RegistrationDetail';
import Survey from './Survey';

export default function Registration() {
    return (
      <Switch>
        <Route exact path="/qpartners/registration/list/:tab" component={RegistrationList} />
        <Route exact path="/qpartners/registration/:id" component={RegistrationDetail} />
        <Route exact path="/qpartners/registration/survey/admin" component={Survey}/>
        <Route component={NotFound} />
      </Switch>
    );
}
