const REQUEST_TYPE_ID = {
    Add: 1,
    Hold: 2,
    Deduct: 3,
    Approve: 4,
    Cancel: 5
};

const REQUEST_STATUS_ID = {
    PendingAddApproval: 1,
    AddCanceled: 2,
    AddCompleted: 3,
    PendingHoldApproval: 4,
    HoldCanceled: 5,
    HoldCompleted: 6,
    PendingDeductApproval: 7,
    DeductCanceled: 8,
    DeductCompleted: 9,
    NA: 10
};

const ADMIN_ROLE_ID = {
    ADMIN: 2,
    REWARD_MANAGEMENT: 8
};

const ALL_ADMIN_ROLE_IDS = [
    ADMIN_ROLE_ID.ADMIN,
    ADMIN_ROLE_ID.REWARD_MANAGEMENT
];

const ACCESS_CONTROL_LIST_TYPES = {
    APPROVAL_MANAGEMENT: 'ApprovalsManagement',
    UPLOAD_MANAGEMENT: 'UploadsManagement',
    CONFIGURATIONS_MANAGEMENT: 'ConfigurationsManagement',
    QPOINT_MANAGEMENT: 'QpointManagement',
    PRODUCTS_MANAGEMENT: 'ProductsManagement',
    ORDERS_MANAGEMENT: 'OrdersManagement',
    CATEGORIES_MANAGEMENT: 'CategoriesManagement'
};

const APPROVAL_STEP = {
    PENDING: [0, 1],
    APPROVE: [2]
};

const PTI_ADMIN_URL = 'http://qcells-claims.us-west-1.elasticbeanstalk.com/helpdesk/dashboard/';
export {
    REQUEST_TYPE_ID,
    REQUEST_STATUS_ID,
    ADMIN_ROLE_ID,
    ALL_ADMIN_ROLE_IDS,
    ACCESS_CONTROL_LIST_TYPES,
    APPROVAL_STEP,
    PTI_ADMIN_URL
};