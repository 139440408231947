import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from '../../utils/axios';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useSSOLoginForm from './hooks/useSSOLoginForm';
import { baseUrl } from '../../config';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ForgotForm from './forms/ForgotForm';
import QcellsLogo from '../../icons/login1.png';
import QcellsIcon from '../../icons/login2.png';
import { notiSuccess, notiFailed } from './../../utils/helpers.js';
import './Login.css';
import CustomLoading from './../../common/components/CustomLoading';
import { ALL_ADMIN_ROLE_IDS, ADMIN_ROLE_ID } from './../../utils/constants';
import { useHistory } from "react-router-dom";

function Copyright() {
  const classes = useStyles();

  return (
    <Typography variant="body2" align="center" class={classes.copyright}>
      <Link color="inherit" href="#">
        <img src={QcellsIcon} alt="" className="Login_Icon" />
      </Link>
    </Typography>
  );
}

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#36ADEF'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#36ADEF'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#002d56'
      }
    }
  }
})(TextField);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '150px 0px'
  },
  image: {
    width: '550px',
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    width: '500px',
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    // margin: '28px',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingRight: '15px',
    paddingLeft: '15px'
  },
  submit: {
    backgroundColor: '#002d56',
    color: '#FFF',
    margin: theme.spacing(3, 0, 2),
    width: '200px',
    height: '47px',
    borderRadius: '30px'
  },
  logo: {
    color: '#009ce2',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  copyright: {
    height: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    backgroundColor: '#002d56',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0px'
  },
  headline: {
    fontSize: '28px',
    fontWeight: 500
  }
}));

export default function SSOLogin() {
  const history = useHistory();
  const classes = useStyles();
  const [openForgotDialog, setOpenForgotDialog] = React.useState(false);
  const [forgotType, setForgotType] = React.useState(0);
  const [toggleTextFieldError, setToggleTextFieldError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputsForgotPassword, setInputsForgotPassword] = useState({
    forgotPasswordEmail: '',
    forgotPasswordName: ''
  });
  const [
    toggleTextFieldForgotPasswordError,
    setToggleTextFieldForgotPasswordError
  ] = useState(false);
  const { forgotPasswordEmail, forgotPasswordName } = inputsForgotPassword;
  const [inputsForgotID, setInputsForgotID] = useState({
    forgotIDName: '',
    forgotIDMobile: ''
  });
  const [toggleTextFieldForgotIDError, setToggleTextFieldForgotIDError] = useState(
    false
  );
  const { forgotIDName, forgotIDMobile } = inputsForgotID;
  const [userID, setUserID] = useState(null);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleForgotPasswordInputChange = event => {
    event.persist();
    setInputsForgotPassword(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const handleForgotIDInputChange = event => {
    event.persist();
    setInputsForgotID(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const login = async () => {

    try {
      setLoading(true);
      const { data } = await axios.post(`${baseUrl}/login`, inputs);

      if(data.status === 'error' && data.statusCode === 401) {
        setToggleTextFieldError(true);
        return notiFailed(data.message);
      }

      if (data.user && !ALL_ADMIN_ROLE_IDS.includes(data.user.roleId)) {
        handleLogout();
        setLoading(false);
        return;
      }

      localStorage.setItem('qpartner_user', JSON.stringify(data));
      setIsAuthenticated(true);
      if (data.user.roleId === ADMIN_ROLE_ID.ADMIN) {
        history.push('/qpartners/registration/list/0');
      } else {
        history.push('/rewards/qpoint-management');
      }
    } catch (error) {
      setToggleTextFieldError(true);
      notiFailed(`Your account doesn't exist. Please contact q.partner@qcells.com for any questions`);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      notiFailed(`Invalid email address or password`);
      await axios.post(`${baseUrl}/logout`);
    } finally {
      localStorage.clear();
    }
  };

  const handleOpenForgotDialog = type => () => {
    setForgotType(type);
    setOpenForgotDialog(true);
  };

  const handleCloseForgotDialog = () => {
    setInputsForgotPassword({ forgotPasswordEmail: '', forgotPasswordName: '' });
    setInputsForgotID({ forgotIDName: '', forgotIDMobile: '' });
    setToggleTextFieldForgotPasswordError(false);
    setToggleTextFieldForgotIDError(false);
    setUserID(null);
    setOpenForgotDialog(false);
  };

  const handleChangeType = (event, type) => {
    setForgotType(type);
  };

  const handleConfirmDialog = async () => {
    if (forgotType === 1 && userID) {
      return handleCloseForgotDialog();
    }

    setLoadingForgot(true);
    if (forgotType === 0) {
      try {
        await axios.post(`${baseUrl}/forgotPassWord`, {
          email: forgotPasswordEmail,
          name: forgotPasswordName
        });
        setLoadingForgot(false);
        handleCloseForgotDialog();
        notiSuccess(
          'A temporary password has been sent to your registered email address. After logging in, please update your password.'
        );
      } catch (error) {
        setToggleTextFieldForgotPasswordError(true);
        setLoadingForgot(false);
        notiFailed(`Invalid email address or name`);
      }
    } else {
      try {
        const { data } = await axios.post(`${baseUrl}/forgotId`, {
          mobileNumber: forgotIDMobile,
          name: forgotIDName
        });
        setUserID(data.email);
        setLoadingForgot(false);
        setToggleTextFieldForgotIDError(false);
      } catch (error) {
        setToggleTextFieldForgotIDError(true);
        setLoadingForgot(false);
        notiFailed(`Invalid name or mobile number`);
      }
    }
  };

  const {
    inputs,
    handleLoginInputChange,
    handleSubmit,
    isValidLoginFormInputs
  } = useSSOLoginForm(login);

  if (isAuthenticated) {
    return <Redirect to="/dashboard"></Redirect>;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item component={Paper} elevation={6} square className="Login_Wrapper">
        <div className="Login">
          <div style={{ marginBottom: 15 }}>
            <img src={QcellsLogo} alt="QCELLS Logo" className="Login_Logo" />
          </div>
          <Typography component="h1" variant="h5" className="Login_Headline">
            Welcome to your{' '}
            <span style={{ color: '#002d56' }} className={classes.logo}>
              Q.Partner
            </span>{' '}
            Admin Portal
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <CustomTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                )
              }}
              placeholder="E-mail address"
              error={toggleTextFieldError}
              onChange={handleLoginInputChange}
              // helperText={toggleTextFieldError ? 'Email is incorrect' : ''}
            />
            <CustomTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                )
              }}
              placeholder="Password"
              error={toggleTextFieldError}
              onChange={handleLoginInputChange}
              // helperText={toggleTextFieldError ? 'Password is incorrect' : ''}
            />
            <Box display="flex" justifyContent="center">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={`${classes.submit} login_btn_1 Login_Button`}
                disabled={!isValidLoginFormInputs() || loading}
              >
                {loading ? (
                  <CustomLoading color="#00adef" size={16}></CustomLoading>
                ) : (
                  'Login'
                )}
              </Button>
            </Box>
          </form>
        </div>
        <Box>
          {/* <Copyright /> */}
        </Box>
      </Grid>
      <ForgotForm
        userID={userID}
        loading={loadingForgot}
        open={openForgotDialog}
        handleClose={handleCloseForgotDialog}
        handleConfirm={handleConfirmDialog}
        handleChange={handleChangeType}
        type={forgotType}
        forgotPasswordEmail={forgotPasswordEmail}
        forgotPasswordName={forgotPasswordName}
        toggleTextFieldForgotPasswordError={toggleTextFieldForgotPasswordError}
        handleForgotPasswordInputChange={handleForgotPasswordInputChange}
        toggleTextFieldForgotIDError={toggleTextFieldForgotIDError}
        forgotIDName={forgotIDName}
        forgotIDMobile={forgotIDMobile}
        handleForgotIDInputChange={handleForgotIDInputChange}
      />
    </Grid>
  );
}
