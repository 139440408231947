import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import config from '../../config';
import axios from '../../utils/axios';
import { toast } from 'react-toastify';
import AlertDeleteComponent from './../../common/components/AlertDeleteComponent';
import moment from 'moment';
import {
  TableContainer,
  Grid,
  Paper,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CheckIcon from '@material-ui/icons/Check';

import { notiSuccess, notiFailed } from '../../utils/helpers';
import Text from '../../common/components/text';
import TextButton from '../../common/components/button/TextButton';
import CustomButton from '../../common/components/button';
import CustomLoading from '../../common/components/CustomLoading';
import DatePicketCustom from '../../utils/components/datePicket';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionsDefaultContainer: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 15,
      '& button': {
        marginBottom: 10
      }
    }
  },
  actionItemContainer: {
    minWidth: 100
  },
  titleEndPromotionDialog: {
    fontSize: '3.375rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem'
    }
  },
  descriptionEndPromotionDialog: {
    marginTop: -50,
    fontSize: '1.5rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      marginTop: -30
    }
  },
  navTab: {
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    marginTop: '-5px',
    paddingRight: '7px',
    fontWeight: '100'
  },
  navTabForecast: {
    borderBottom: 'solid',
    borderColor: '#00ADEF',
    paddingBottom: '10px'
  },
  configTable: {
    border: 'solid #E6E6E6 1px'
  }
}));

const { baseUrl } = config;

const CustomCircularProgress = withStyles(theme => ({
  root: {
    '&.MuiCircularProgress-colorPrimary': {
      color: '#FFFFFF'
    }
  }
}))(CircularProgress);

const PROMOTION_STATUS = {
  active: {
    code: 'active',
    label: 'Active'
  },
  notStarted: {
    code: 'notStarted',
    label: 'Not Started'
  },
  expired: {
    code: 'expired',
    label: 'Expired'
  }
};

const NumberFieldCustom = props => {
  return (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      onKeyPress={e => {
        if (isNaN(e.key)) {
          e.preventDefault();
        }
      }}
      type="number"
      InputProps={{
        inputProps: {
          min: 0
        }
      }}
      {...props}
    />
  );
};

const initSelectedPromotionConfig = {
  id: null,
  rate: 0,
  startDate: '',
  endDate: '',
  status: '',
  achievedVolume: 0,
  submissionRate: 0,
  isEdit: false
};

const initNewPromotion = {
  configurationId: null,
  configurationType: 'forecasts',
  rate: 0,
  achievedVolume: 0,
  submissionRate: 0,
  startDate: moment()
    .startOf('day')
    .format(),
  endDate: moment()
    .endOf('day')
    .format()
};

export default function ConfigurationsForecasts() {
  const classes = useStyles();
  const [editDefault, setEditDefault] = useState(false);
  const [idOfForecastConfiguration, setIdOfForecastConfiguration] = useState(null);
  const [defaultForecastConfig, setDefaultForecastConfig] = useState({});
  const [
    defaultForecastConfigFromServer,
    setDefaultForecastConfigFromServer
  ] = useState({});
  const [promotions, setPromotions] = useState([]);
  const [newPromotion, setNewPromotion] = useState(initNewPromotion);
  const [selectedPromotionConfig, setSelectedPromotionConfig] = useState(
    initSelectedPromotionConfig
  );
  const [open, setOpen] = useState(false);
  const [loadingPromotions, setLoadingPromotions] = useState(false);
  const [isLoadingDeletePromotion, setIsLoadingDeletePromotion] = useState(false);
  const [isLoadingDefaultRate, setIsLoadingDefaultRate] = useState(false);
  const [isLoadingAddButton, setIsLoadingAddButton] = useState(false);

  const handleOpen = promotionConfig => {
    setSelectedPromotionConfig({ ...promotionConfig });
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPromotionConfig(initSelectedPromotionConfig);
    setOpen(false);
  };

  const handleUpdate = async () => {
    const hardCode = 2; // :((( Required param
    try {
      setIsLoadingDeletePromotion(true);
      const { data } = await axios.patch(
        `${baseUrl}/promotions/${hardCode}`,
        {
          id: selectedPromotionConfig.id,
          endDate: moment().endOf('day'),
          configurationId: idOfForecastConfiguration,
          configurationType: 'forecasts'
        },
        {
          params: {
            httpReq: 'delete'
          }
        }
      );
      getPromotionList();
      setSelectedPromotionConfig(initSelectedPromotionConfig);
      notiSuccess(`You have successfully cancelled the promotion for ${data.id}`);
    } catch ({ data }) {
      notiFailed(data && data.message);
    } finally {
      setOpen(false);
      setIsLoadingDeletePromotion(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoadingDeletePromotion(true);
      const { data } = await axios.delete(
        `${baseUrl}/promotions/${selectedPromotionConfig.id}`
      );
      notiSuccess(`You have successfully removed the promotion ${data.id}`);
      setSelectedPromotionConfig(initSelectedPromotionConfig);
      getPromotionList();
    } catch ({ data }) {
      notiFailed(data && data.message);
    } finally {
      setOpen(false);
      setIsLoadingDeletePromotion(false);
    }
  };

  const getForecastConfiguration = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/configurations/forecasts`);
      setIdOfForecastConfiguration(data.id);
    } catch (err) {
      notiFailed(
        `There was an error getting the configuration types ${err.message}`
      );
    }
  };

  const getDefaultForecast = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/configurationTypes/all/configurations`
      );
      const configForecasts = data.filter(m => m.configurationType === 'forecasts');
      setDefaultForecastConfig(configForecasts[0]);
      setDefaultForecastConfigFromServer(configForecasts[0]);
    } catch (err) {
      notiFailed(
        `There was an error getting the configuration types ${err.message}`
      );
    }
  };

  const getPromotionList = async () => {
    try {
      setLoadingPromotions(true);
      const { data } = await axios.get(
        `${baseUrl}/configurationTypes/all/promotions`
      );
      const forecastsPromotion = data.filter(
        m => m.configurationType === 'forecasts'
      );
      filterPromotionStatus(forecastsPromotion);
    } catch (err) {
      notiFailed(
        `There was an error getting forecast promotions list ${err.message}`
      );
    } finally {
      setLoadingPromotions(false);
    }
  };

  const handleColorPromotionStatus = promotionStatus => {
    switch (promotionStatus) {
      case PROMOTION_STATUS.active.code:
        return 'primaryLight';
      case PROMOTION_STATUS.notStarted.code:
        return 'dark';
      case PROMOTION_STATUS.expired.code:
        return 'secondaryDark';
      default:
        break;
    }
  };

  const filterPromotionStatus = data => {
    let today = new Date();

    const filterOutPastPromo = data.map(promo => {
      if (moment(promo.startDate).isAfter(today)) {
        return {
          ...promo,
          status: PROMOTION_STATUS.notStarted.code
        };
      } else if (moment(promo.endDate).isBefore(today)) {
        return {
          ...promo,
          status: PROMOTION_STATUS.expired.code
        };
      } else {
        return { ...promo, status: PROMOTION_STATUS.active.code };
      }
    });

    setPromotions(filterOutPastPromo);
  };

  const saveDefaultConfigChanges = async () => {
    try {
      setIsLoadingDefaultRate(true);
      await axios.patch(`${baseUrl}/configurations/${defaultForecastConfig.id}`, {
        configurationType: 'forecasts',
        id: defaultForecastConfig.id,
        rate: defaultForecastConfig.rate,
        percentage: defaultForecastConfig.percentage,
        achievedVolume: defaultForecastConfig.achievedVolume,
        submissionRate: defaultForecastConfig.submissionRate
      });
      getDefaultForecast();
      notiSuccess(`Successfully edited default forecast configuration`);
    } catch (err) {
      if (err && err.data && err.data.errors[0]) {
        notiFailed(err.data.errors[0].msg);
      }
    } finally {
      setEditDefault(false);
      setIsLoadingDefaultRate(false);
    }
  };

  const handleStartDateChange = date => {
    const startOfDay = moment(date)
      .startOf('day')
      .format();
    setNewPromotion({
      ...newPromotion,
      startDate: startOfDay
    });
  };

  const handleEndDateChange = date => {
    const endOfDay = moment(date)
      .endOf('day')
      .format();
    setNewPromotion({
      ...newPromotion,
      endDate: endOfDay
    });
  };

  const createNewPromotionConfig = async () => {
    try {
      setIsLoadingAddButton(true);
      await axios.post(`${baseUrl}/promotions`, {
        configurationId: idOfForecastConfiguration,
        configurationType: newPromotion.configurationType,
        rate: +newPromotion.rate,
        startDate: newPromotion.startDate,
        endDate: newPromotion.endDate,
        achievedVolume: +newPromotion.achievedVolume,
        submissionRate: +newPromotion.submissionRate
      });
      getPromotionList();
      setNewPromotion(initNewPromotion);
      notiSuccess(`Successfully created new forecast promotion`);
    } catch ({ data }) {
      notiFailed(data && data.message);
    } finally {
      setIsLoadingAddButton(false);
    }
  };

  const editPromotionStartDate = date => {
    setSelectedPromotionConfig({
      ...selectedPromotionConfig,
      startDate: moment(date)
        .startOf('day')
        .format()
    });
  };

  const editPromotionEndDate = date => {
    setSelectedPromotionConfig({
      ...selectedPromotionConfig,
      endDate: moment(date)
        .endOf('day')
        .format()
    });
  };

  const savePromotionConfigChanges = async () => {
    const hardCode = 2; // :((( Required param
    try {
      await axios.patch(`${baseUrl}/promotions/${hardCode}`, {
        id: selectedPromotionConfig.id,
        rate: +selectedPromotionConfig.rate,
        startDate: selectedPromotionConfig.startDate,
        endDate: selectedPromotionConfig.endDate,
        configurationId: idOfForecastConfiguration,
        configurationType: 'forecasts',
        achievedVolume: +selectedPromotionConfig.achievedVolume,
        submissionRate: +selectedPromotionConfig.submissionRate
      });
      getPromotionList();
      setSelectedPromotionConfig(initSelectedPromotionConfig);
      notiSuccess(`Successfully edited forecast promotion configuration.`);
    } catch ({ data }) {
      notiFailed(data && data.message);
    }
  };

  useEffect(() => {
    getForecastConfiguration();
    getDefaultForecast();
    getPromotionList();
  }, []);

  return (
    <>
      <Text size="h2" style={{ lineHeight: '0px' }}>
        Configurations
      </Text>

      <Paper elevation={0} style={{ marginBottom: '20px' }} className={classes.configTable}>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              height: '55px'
            }}
          >
            <Grid
              item
              component={Link}
              to="/rewards/configurations/modules"
              className={classes.navTab}
            >
              <Text 
                size="h4" 
                color="dark"
                weight="regular"
              >
                Modules
              </Text>
            </Grid>
            <Grid
              item
              className={classes.navTab}
              style={{
                marginLeft: '25px'
              }}
            >
              <Text 
                color="primaryLight" 
                size="h4"
                weight="regular"
                className={classes.navTabForecast}
              >
                Forecasts
              </Text>
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          justify="flex-start"
          style={{
            maxWidth: '75vw',
            margin: 'auto'
          }}
          spacing={2}
          className="page_title_wrapper"
        >
          <React.Fragment>
            <Grid
              container
              justify="space-between"
              item
              xs={12}
              style={{ paddingBottom: '10px' }}
            >
              <Grid item>
                <Text size="h3" style={{ margin: 0 }}>
                  Default Rate
                </Text>
              </Grid>
              {editDefault && (
                <Grid item className={classes.actionsDefaultContainer}>
                  <CustomButton
                    style={{ height: '40px' }}
                    color="secondary"
                    onClick={() => {
                      setDefaultForecastConfig(defaultForecastConfigFromServer);
                      setEditDefault(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    style={{ height: '40px' }}
                    color="primary"
                    onClick={saveDefaultConfigChanges}
                    disabled={isLoadingDefaultRate}
                  >
                    {isLoadingDefaultRate ? (
                      <CustomCircularProgress size={24} />
                    ) : (
                      'Save'
                    )}
                  </CustomButton>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              justify="space-between"
              xs={12}
              alignItems="center"
              spacing={2}
            >
              {editDefault ? (
                <React.Fragment>
                  <Grid item md={3} xs={12}>
                    <NumberFieldCustom
                      label="Submission Rate (Q)"
                      value={defaultForecastConfig.submissionRate}
                      onChange={e =>
                        setDefaultForecastConfig({
                          ...defaultForecastConfig,
                          submissionRate: e.target.value || 0
                        })
                      }
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <NumberFieldCustom
                      label="Achievement Rate (Q)"
                      value={defaultForecastConfig.rate}
                      onChange={e =>
                        setDefaultForecastConfig({
                          ...defaultForecastConfig,
                          rate: e.target.value || 0
                        })
                      }
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <NumberFieldCustom
                      label="Percent Rate"
                      value={defaultForecastConfig.percentage}
                      onChange={e =>
                        setDefaultForecastConfig({
                          ...defaultForecastConfig,
                          percentage: e.target.value || 0
                        })
                      }
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <NumberFieldCustom
                      label="Min. Forecast Volume (W)"
                      value={defaultForecastConfig.achievedVolume}
                      onChange={e =>
                        setDefaultForecastConfig({
                          ...defaultForecastConfig,
                          achievedVolume: e.target.value || 0
                        })
                      }
                    />
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid container item md={11} xs={12}>
                    <Grid item md={3} xs={12}>
                      <Text size="h4" style={{ margin: 0 }} className="page_title3">
                        Submission Rate (Q):{' '}
                        <span style={{ color: '#00ADEF' }}>
                          {defaultForecastConfig &&
                          defaultForecastConfig.submissionRate
                            ? defaultForecastConfig.submissionRate.toLocaleString()
                            : 0}
                        </span>
                      </Text>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Text size="h4" style={{ margin: 0 }} className="page_title3">
                        Achievement Rate (Q):{' '}
                        <span style={{ color: '#00ADEF' }}>
                          {defaultForecastConfig && defaultForecastConfig.rate
                            ? defaultForecastConfig.rate.toLocaleString()
                            : 0}
                        </span>
                      </Text>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Text size="h4" style={{ margin: 0 }} className="page_title3">
                        Percent Rate:{' '}
                        <span style={{ color: '#00ADEF' }}>
                          {defaultForecastConfig
                            ? defaultForecastConfig.percentage
                            : 0}
                          {'%'}
                        </span>
                      </Text>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Text size="h4" style={{ margin: 0 }} className="page_title3">
                        Min. Forecast Volume (W):{' '}
                        <span style={{ color: '#00ADEF' }}>
                          {defaultForecastConfig &&
                          defaultForecastConfig.achievedVolume
                            ? defaultForecastConfig.achievedVolume.toLocaleString()
                            : 0}
                        </span>
                      </Text>
                    </Grid>
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <TextButton onClick={() => setEditDefault(true)}>
                      Edit
                    </TextButton>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </React.Fragment>
        </Grid>
      </Paper>

      {/* Add Promotion*/}
      <Paper elevation={0} style={{ marginBottom: '20px' }} className={classes.configTable}>
        <Grid
          container
          justify="flex-start"
          style={{
            maxWidth: '75vw',
            margin: 'auto',
            paddingTop: '15px',
            paddingBottom: '20px'
          }}
          spacing={1}
        >
          <Text
            size="h3"
            style={{
              width: '100%',
              marginBottom: '2px',
              fontFamily: 'Actual'
            }}
          >
            Add a Promotion
          </Text>
          <Grid item md={2} xs={12}>
            <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
              Submission Rate (Q)
            </Text>
            <NumberFieldCustom
              value={newPromotion.submissionRate}
              onChange={e =>
                setNewPromotion({ ...newPromotion, submissionRate: e.target.value })
              }
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
              Achievement Rate (Q)
            </Text>
            <NumberFieldCustom
              value={newPromotion.rate}
              onChange={e =>
                setNewPromotion({ ...newPromotion, rate: e.target.value })
              }
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
              Min. Forecast Volume (W)
            </Text>
            <NumberFieldCustom
              value={newPromotion.achievedVolume}
              onChange={e =>
                setNewPromotion({ ...newPromotion, achievedVolume: e.target.value })
              }
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
              Start Date
            </Text>
            <DatePicketCustom
              format="MM/yyyy"
              value={newPromotion.startDate}
              views={['year', 'month']}
              onChange={handleStartDateChange}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
              End Date
            </Text>
            <DatePicketCustom
              format="MM/yyyy"
              minDate={newPromotion.startDate}
              views={['year', 'month']}
              value={newPromotion.endDate}
              onChange={handleEndDateChange}
            />
          </Grid>
          <Grid container item justify="center" md={1} xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoadingAddButton}
              style={{
                background: '#002D56',
                color: '#ffffff',
                marginTop: '40px'
              }}
              onClick={createNewPromotionConfig}
            >
              {isLoadingAddButton ? <CustomCircularProgress size={24} /> : '+ Add'}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={0} style={{ marginBottom: '20px' }} className={classes.configTable}>
        <Grid
          container
          justify="flex-start"
          style={{
            maxWidth: '75vw',
            margin: 'auto'
          }}
          spacing={2}
        >
          <Grid container justify="space-between" alignItems="center">
            <Text size="h3">Promotions</Text>
          </Grid>
          <TableContainer
            component={Paper}
            style={{
              marginBottom: '30px',
              boxShadow: 'none',
              border: 'solid rgb(231, 231, 231) 1px',
              borderTop: 'none'
            }}
          >
            <Table
              style={{
                borderTop: 'solid 1px #e7e7e7'
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: '#9ca4ab',
                      fontFamily: 'Actual',
                      fontWeight: '400'
                    }}
                  >
                    Submission Rate (Q)
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#9ca4ab',
                      fontFamily: 'Actual',
                      fontWeight: '400'
                    }}
                  >
                    Achievement Rate (Q)
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#9ca4ab',
                      fontFamily: 'Actual',
                      fontWeight: '400'
                    }}
                  >
                    Min. Forecast Volume (W)
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#9ca4ab',
                      fontFamily: 'Actual',
                      fontWeight: '400'
                    }}
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#9ca4ab',
                      fontFamily: 'Actual',
                      fontWeight: '400'
                    }}
                  >
                    End Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#9ca4ab',
                      fontFamily: 'Actual',
                      fontWeight: '400'
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loadingPromotions &&
                  promotions.map(p => (
                    <TableRow key={p.id}>
                      {selectedPromotionConfig.id === p.id &&
                      selectedPromotionConfig.isEdit ? (
                        <>
                          <TableCell>
                            <NumberFieldCustom
                              disabled={p.status === PROMOTION_STATUS.active.code}
                              value={selectedPromotionConfig.submissionRate}
                              onChange={e =>
                                setSelectedPromotionConfig({
                                  ...selectedPromotionConfig,
                                  submissionRate: e.target.value
                                })
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFieldCustom
                              disabled={p.status === PROMOTION_STATUS.active.code}
                              value={selectedPromotionConfig.rate}
                              onChange={e =>
                                setSelectedPromotionConfig({
                                  ...selectedPromotionConfig,
                                  rate: e.target.value
                                })
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFieldCustom
                              disabled={p.status === PROMOTION_STATUS.active.code}
                              value={selectedPromotionConfig.achievedVolume}
                              onChange={e =>
                                setSelectedPromotionConfig({
                                  ...selectedPromotionConfig,
                                  achievedVolume: e.target.value
                                })
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <DatePicketCustom
                              format="MM/yyyy"
                              disabled={p.status === PROMOTION_STATUS.active.code}
                              views={['year', 'month']}
                              value={selectedPromotionConfig.startDate}
                              onChange={editPromotionStartDate}
                            />
                          </TableCell>
                          <TableCell>
                            <DatePicketCustom
                              format="MM/yyyy"
                              disabled={p.status === PROMOTION_STATUS.active.code}
                              views={['year', 'month']}
                              value={selectedPromotionConfig.endDate}
                              onChange={editPromotionEndDate}
                            />
                          </TableCell>
                          <TableCell>
                            <Text color={handleColorPromotionStatus(p.status)}>
                              {PROMOTION_STATUS[p.status].label}
                            </Text>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="small"
                              style={{ marginRight: '20px' }}
                              onClick={savePromotionConfigChanges}
                            >
                              <CheckIcon />
                            </IconButton>

                            <Button
                              onClick={() =>
                                setSelectedPromotionConfig(
                                  initSelectedPromotionConfig
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {p.submissionRate && p.submissionRate.toLocaleString()}
                          </TableCell>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {p.rate && p.rate.toLocaleString()}
                          </TableCell>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {p.achievedVolume && p.achievedVolume.toLocaleString()}
                          </TableCell>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            <Moment format="L">{p.startDate}</Moment>
                          </TableCell>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            <Moment format="L">{p.endDate}</Moment>
                          </TableCell>
                          <TableCell
                            style={{
                              fontFamily: 'Actual'
                            }}
                          >
                            <Text color={handleColorPromotionStatus(p.status)}>
                              {PROMOTION_STATUS[p.status].label}
                            </Text>
                          </TableCell>
                          <TableCell align="right">
                            {p.status !== PROMOTION_STATUS.expired.code && (
                              <>
                                {' '}
                                <IconButton
                                  size="small"
                                  style={{ marginRight: '20px' }}
                                  onClick={() =>
                                    setSelectedPromotionConfig({
                                      id: p.id,
                                      rate: p.rate,
                                      startDate: p.startDate,
                                      endDate: p.endDate,
                                      achievedVolume: p.achievedVolume,
                                      submissionRate: p.submissionRate,
                                      status: p.status,
                                      isEdit: true
                                    })
                                  }
                                >
                                  <CreateOutlinedIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handleOpen(p)}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {!loadingPromotions && (!promotions || !promotions.length) && (
              <div className="wr_loading" style={{ marginTop: '30px' }}>
                List empty
              </div>
            )}

            {loadingPromotions && (
              <div className="wr_loading">
                <CustomLoading size={40} color="#00adef"></CustomLoading>
              </div>
            )}
          </TableContainer>
        </Grid>
      </Paper>

      <AlertDeleteComponent
        open={open}
        handleClose={() => handleClose()}
        handleDelete={() => handleDelete()}
        title="Are you sure you want to delete this promotion?"
        content="This will delete the promotion immediately and delete it from the list."
      />
    </>
  );
}
