import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    TextField,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Checkbox,
    TableContainer,
    Modal,
    Backdrop,
    Fade,
    ListItemText,
    ListItem,
    Menu
} from '@material-ui/core';
import classNames from 'classnames';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Text from '../../common/components/text';
import { convertHTMLEntity } from './../../utils/helpers';
import _ from 'lodash';
import Moment from 'react-moment';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import { notiSuccess, notiFailed } from './../../utils/helpers';
import PriceNumberFormat from './../../utils/components/priceNumberFormat';
import { REQUEST_TYPE_ID, REQUEST_STATUS_ID } from './../../utils/constants';
import CustomLoading from './../../common/components/CustomLoading';
import { ADMIN_ROLE_ID } from './../../utils/constants';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Alert from './Alert';
import CustomButton from '../../common/components/button';
import PointSummary from '../../common/components/PointSummary';
import CompanyInformation from '../../common/components/CompanyInformation';
import UserInformation from '../../common/components/UserInformation';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        maxHeight: '80%',
        width: '80rem',
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
          width: '80%'
        }
    },
    tableRow: {
        cursor: 'pointer'
    },
    tableCell: {
        fontFamily: 'Actual',
        paddingLeft: 0,
        height: 62
    },
    tableCellPlaceholder: {
        width: '2.5vw'
    },
    checkbox: {
        color: '#8C8C8C'
    },
    checkboxChecked: {
        '&.Mui-checked': {
            color: '#00ADEF'
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    companyInfo: {
        width: '90%',
        margin: 'auto',
        marginBottom: '-20px',
        "& p": {
            marginTop: '3px',
            marginBottom: '3px'
        }
    },
    pointUpdateHistory: {
        width: '90%',
        margin: 'auto',
        marginTop: '20px'
    },
    pointUpdateHistoryTitle: {
        marginBottom: '5px'
    },
    tableContainer: {
        boxShadow: 'none',
        borderRadius: 'unset',
        paddingBottom: 20
    },
    normalLabelColumnItem: {
        paddingLeft: 0,
        fontFamily: 'Actual',
        fontWeight: '400',
        whiteSpace: 'nowrap'
    },
    normalLabelHeaderColumnItem: {
        paddingLeft: 0,
        fontFamily: 'Actual',
        fontWeight: '400',
        color: '#9ca4ab',
        whiteSpace: 'nowrap'
    },
    pointUpdateStatus: {
        width: '120px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    wrapper: {
        // display: 'grid',
        // gridTemplateColumns: '1fr 2fr',
        // gap: '0px 10px',
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%',
        //     '& div': {
        //         gridColumn: 'span 2',
        //         textAlign: 'left'
        //     }
        // }
    },
    pointUpdateAmount: {
        width: '125px',
        marginLeft: '5px',
        borderRadius: '4px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: 0
        }
    },
    pointUpdatePurpose: {
        width: '200px',
        marginLeft: '5px',
        borderRadius: '4px',
        "& .MuiListItemText-root": {
            marginTop: 0,
            marginBottom: 0
        },
        "& .purposeAction": {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: 0
        }
    },
    pointUpdateFieldContainer: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '0px'
        }
    },
    pointUpdateFieldHelperText: {
        position: 'absolute',
        top: '-30px',
        left: '8px',
        fontSize: '12px',
        fontFamily: 'Actual_Light',
        color: '#C8C8C8'
    },
    updateStatusTitle: {
        textAlign: 'right'
    },
    pointUpdatePurposeRequest: {
        width: '200px',
        backgroundColor: '#C8C8C8',
        marginLeft: '5px',
        borderRadius: '4px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: 0
        }
    },
    companyInfoContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        backgroundColor: 'rgb(247, 247, 247)',
        padding: '30px 50px',
        marginTop: '25px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(100%, 1fr))',
            padding: '10px 20px',
            marginTop: '15px'
        }
    },

    companyInfoContainerSummary: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 2fr',
        backgroundColor: 'rgb(247, 247, 247)',
        padding: '30px 50px',
        marginTop: '25px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(100%, 1fr))',
            padding: '10px 20px',
            marginTop: '15px'
        }
    },
    containerSearcher : {
        backgroundColor: 'rgb(247, 247, 247)',
        padding: '30px 50px',
    },
    companyNameColumn: {
        gridRowStart: 1,
        gridRowEnd: 4
    },
    disabledPointAmount: {
        backgroundColor: '#C8C8C8'
    },
    disabledPurpose: {
        backgroundColor: '#C8C8C8'
    },
    btnUpdate: {
        height: '40px',
        width: '90px',
        display: 'inline-block',
        borderRadius: '6px',
        fontFamily: 'Actual',
        marginLeft: '5px',
        backgroundColor: '#00adef',
        border: '1px solid rgba(0, 0, 0, 0)',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#002d56',
            border: '1px solid rgba(0, 0, 0, 0)',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20,
            marginTop: '0px'
        }
    },
    btnUpdateDisabled: {
        backgroundColor: '#e6e6e6',
        border: '1px solid #8C8C8C',
        color: '#8C8C8C',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#e6e6e6',
            border: '1px solid #8C8C8C',
            color: '#8C8C8C',
            cursor: 'default'
        }
    },
    wrapperBeforeSelected: {
        gridTemplateColumns: '1fr 1fr',
    },
    pointUpdateStatusBeforeSelected: {
        width: '200px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    addNewPurpose: {
        "& .MuiInputBase-root": {
            fontSize: '13px'
        },
        "& .MuiInput-underline:after": {
            borderBottom: 0
        },
        "& .MuiInput-underline:before": {
            borderBottom: 0
        },
        "& .MuiInput-underline:hover:before": {
            borderBottom: 0
        },
        padding: '8px 12px'
    },
    purposeListItem: {
        paddingRight: '6px',
        "& .MuiListItemText-root": {
            marginTop: 0,
            marginBottom: 0
        },
        "&:hover": {
            backgroundColor: '#c9e7f7',
            cursor: 'pointer'
        },
        "&:hover .MuiListItemText-root": {
            color: '#00adef'
        },
        "&:hover.Mui-selected": {
            backgroundColor: '#c9e7f7'
        }
    },
    updateStatusContainer: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
        }
    },
    header: {
        fontSize: '36px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px'
        }
    },
    deletePurposeModalTitle: {
        textAlign: 'center',
        marginTop: '15px',
        fontSize: '40px',
        lineHeight: '45px'
    },
    deletePurposeModalSubtitle: {
        marginTop: '-32px',
        marginBottom: '20px',
        fontSize: '20px !important'
    },
    deletePurposeModalCancelBtn: {
        padding: '.75rem 2rem !important'
    },
    deletePurposeModalDeleteBtn: {
        padding: '.75rem 2rem !important'
    },
    deletePurposeModuleModalCloseBtn: {
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '-20px'
    },
    loadingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    noHistoryContainer: {
        width: '95%',
        margin: 'auto',
        textAlign: 'center'
    },
    wrapItem : {
        display : 'flex',
        flexDirection : 'row'
    },
    wrapText : {
        display: 'flex',
        flexDirection : 'row'
    }
}));

function PurposeListItem({
    purpose,
    handleClickPurposeActionIcon,
    handleRenamePurposeType,
    ...rest
}) {
    const classes = useStyles();

    return purpose.isEdit ? (
        <div className={classes.addNewPurpose}>
            <TextField
                autoFocus={true}
                onClick={e => e.stopPropagation()}
                fullWidth
                type="text"
                defaultValue={purpose.name}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && e.target.value !== '') {
                        handleRenamePurposeType(purpose.id, e.target.value);
                    }
                }}
            />
        </div>
    ) : (
        <ListItem
            className={classes.purposeListItem}
            {...rest}
        >
            <ListItemText primary={purpose.name}></ListItemText>
            <MoreHorizIcon className="purposeAction" onClick={handleClickPurposeActionIcon} value={purpose.id} />
        </ListItem>
    )
}

export default function QpointMagementDetails({ company, handleReloadCompanies, isSmallScreen }) {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('qpartner_user'));
    const [requestTypes, setRequestTypes] = useState([]);
    const [selectedRequestType, setSelectedRequestType] = useState(null);
    const [filterRequestTypes, setFilterRequestTypes] = useState([]);
    const [pointAmount, setPointAmount] = useState(0);
    const [open, setOpen] = useState(false);
    const [loadingPointRequest, setLoadingPointRequest] = useState(false);
    const [isQcellAdmin] = useState(user.user.roleId === ADMIN_ROLE_ID.ADMIN);
    const [selectedPurposeType, setSelectedPurposeType] = useState(_.get(company, 'pointRequest.purposeTypeId', 1));
    const [purposeTypes, setPurposeTypes] = useState([]);
    const pendingApprovalGroup = [
        REQUEST_STATUS_ID.PendingAddApproval,
        REQUEST_STATUS_ID.PendingDeductApproval,
        REQUEST_STATUS_ID.PendingHoldApproval
    ];
    const inProgressGroup = [REQUEST_STATUS_ID.HoldCompleted];
    const actionCompletedGroup = [
        REQUEST_STATUS_ID.HoldCanceled,
        REQUEST_STATUS_ID.DeductCanceled,
        REQUEST_STATUS_ID.DeductCompleted,
        REQUEST_STATUS_ID.AddCanceled,
        REQUEST_STATUS_ID.AddCompleted
    ];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPurposeActionMenu = Boolean(anchorEl);
    const [newPurpose, setNewPurpose] = useState('');
    const isOpenPurposeSelect = useRef(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [pointHistoryPage, setPointHistoryPage] = useState(1);
    const [pointHistory, setPointHistory] = useState([]);
    const [pointHistoryTotal, setPointHistoryTotal] = useState(0);
    const [filter, setFilter] = useState({ companyId: company.id, size: 5, page: pointHistoryPage });
    const [loadingPointHistory, setLoadingPointHistory] = useState(false);
    const [loadingViewMorePointHistory, setLoadingViewMorePointHistory] = useState(false);
    const [subUsers, setSubUsers] = useState([]);

    const getPurposeType = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/purpose-type/search`);
            setPurposeTypes(data.map(pt => ({ ...pt, isEdit: false })));
        } catch (err) {
            notiFailed(
                `There was a problem getting purpose types ${err.message}`
            );
        }
    };

    const getPointRequestTypes = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/point-request/types`);
            setRequestTypes(data);
            const filterRequestTypes = getFilterRequestTypes(data);
            setFilterRequestTypes(filterRequestTypes);
        } catch (err) {
            notiFailed(
                `There was a problem getting point request types ${err.message}`
            );
        }
    };

    const getFilterRequestTypes = types => {
        const { pointRequest } = company;
        let filterTypeIds = [];

        if (pointRequest === null) {
            filterTypeIds = [REQUEST_TYPE_ID.Add, REQUEST_TYPE_ID.Deduct, REQUEST_TYPE_ID.Hold];
        } else if (pendingApprovalGroup.includes(pointRequest.requestStatusId)) {
            filterTypeIds = [REQUEST_TYPE_ID.Cancel];
            if (isQcellAdmin) {
                filterTypeIds.push(REQUEST_TYPE_ID.Approve);
            }
        } else if (inProgressGroup.includes(pointRequest.requestStatusId)) {
            filterTypeIds = [REQUEST_TYPE_ID.Deduct, REQUEST_TYPE_ID.Cancel];
        } else if (actionCompletedGroup.includes(pointRequest.requestStatusId)) {
            filterTypeIds = [REQUEST_TYPE_ID.Add, REQUEST_TYPE_ID.Deduct, REQUEST_TYPE_ID.Hold];
        }

        const filterRequestTypes = types.filter(t => filterTypeIds.includes(t.id));

        return filterRequestTypes;
    };

    const isDisablePointAmount = () => {
        const requestStatusId = _.get(company, 'pointRequest.requestStatusId', null);
        return selectedRequestType == REQUEST_TYPE_ID.Cancel ||
        selectedRequestType === REQUEST_TYPE_ID.Approve && isQcellAdmin ||
        (selectedRequestType == REQUEST_TYPE_ID.Deduct && company.pointRequest !== null &&
         !actionCompletedGroup.includes(requestStatusId));
    };

    const handleOpen = () => {
        getPointRequestHistories(filter);
        getPointRequestTypes();
        getPurposeType();
        // getSubUsers();
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedRequestType(null);
        setPointAmount(0);
        setPointHistoryPage(1);
        setPointHistoryTotal(0);
        setPointHistory([]);
        setFilter({ ...filter, page: 1 });
        setOpen(false);
    };

    const handleSelectRequestType = e => {
        const newRequestType = e.target.value;
        setSelectedRequestType(newRequestType);
    };

    const handleRequest = async () => {
        setLoadingPointRequest(true);

        const body = {
            companyId: company.id,
            qpoints: pointAmount,
            requestTypeId: selectedRequestType,
            purposeTypeId: selectedPurposeType
        };

        const pointRequestId = _.get(company, 'pointRequest.id');
        if (pointRequestId) {
            body.id = pointRequestId;
        }

        if (!isQcellAdmin) {
            body.purposeTypeId = 1;
        }

        try {
            const { data } = await axios.post(`${baseUrl}/point-request/batch`, [body]);
            if (data.pointRequestError.length > 0) {
                notiFailed(data.pointRequestError[0].message);
            } else {
                handleReloadCompanies();
                handleClose();
            }
        } catch (err) {
            notiFailed(
                `There was a problem create point request ${err.message}`
            );
        } finally {
            setLoadingPointRequest(false);
        }
    };

    const isDisableRequestButton = () => {
        return selectedRequestType === null;
    }

    const renderUpdateStatusButton = () => {
        return  (
            <button
                disabled={loadingPointRequest || isDisableRequestButton()}
                onClick={handleRequest}
                className={classNames([classes.btnUpdate], {
                    [classes.btnUpdateDisabled]: loadingPointRequest || isDisableRequestButton()
                })}
            >
                { loadingPointRequest ? <CustomLoading size={18} color="#fff" /> :
                  isQcellAdmin || selectedRequestType === REQUEST_TYPE_ID.Cancel ? 'Update' : 'Request' }
            </button>
        )
    };

    const renderQpointUpdatePurpose = () => {
        return (
            <>
                <div className={classes.pointUpdateFieldContainer}>
                    <Text className={classes.pointUpdateFieldHelperText}>Point Amount</Text>
                    <TextField
                        size="small"
                        variant="outlined"
                        placeholder="Enter Point Amount"
                        value={pointAmount}
                        onChange={e => setPointAmount(Number(e.target.value))}
                        InputProps={{
                            inputComponent: PriceNumberFormat
                        }}
                        disabled={isDisablePointAmount()}
                        className={classNames([classes.pointUpdateAmount], { [classes.disabledPointAmount]: isDisablePointAmount() })}
                    />
                </div>
                {isQcellAdmin ?
                    <div className={classes.pointUpdateFieldContainer}>
                        <Text className={classes.pointUpdateFieldHelperText}>Purpose</Text>
                        <TextField
                            select
                            className={classNames([classes.pointUpdatePurpose], {
                                [classes.disabledPurpose]: isDisablePointAmount()
                            })}
                            size="small"
                            variant="outlined"
                            placeholder="Select or Create New"
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                },
                                onOpen: () => {
                                    handleResetPurposeTypes();
                                    isOpenPurposeSelect.current = true;
                                },
                                onClose: () => isOpenPurposeSelect.current = false
                            }}
                            defaultValue={selectedPurposeType}
                            onChange={e => setSelectedPurposeType(e.target.value)}
                            disabled={isDisablePointAmount()}
                        >
                            <div className={classes.addNewPurpose}>
                                <TextField
                                    autoFocus
                                    onClick={e => e.stopPropagation()}
                                    fullWidth
                                    type="text"
                                    placeholder="Search or Create New"
                                    value={newPurpose}
                                    onChange={e => setNewPurpose(e.target.value)}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            handleCreatePurposeType();
                                        }
                                    }}
                                    onBlur={e => {
                                        e.stopPropagation();

                                        if (!purposeTypes.  d(pt => pt.isEdit) && e.target && isOpenPurposeSelect.current) {
                                            e.target.focus();
                                        }
                                    }}
                                />
                            </div>
                            {getFilterPurposeTypes().map(pt => (
                                <PurposeListItem
                                    key={pt.id}
                                    purpose={pt}
                                    value={pt.id}
                                    handleClickPurposeActionIcon={handleClickPurposeActionIcon}
                                    handleRenamePurposeType={handleRenamePurposeType}
                                >
                                    {pt.name}
                                </PurposeListItem>
                            ))}
                            <Menu
                                id="purposeActionMenu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={openPurposeActionMenu}
                                onClose={handleClosePurposeActionMenu}
                            >
                                <MenuItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenDeleteModal(true);
                                    }}
                                >
                                    Delete
                                </MenuItem>
                                <MenuItem onClick={handleChangeToRename}>Rename</MenuItem>
                            </Menu>
                        </TextField>
                    </div>
                : (
                    <div className={classes.pointUpdateFieldContainer}>
                        <Text className={classes.pointUpdateFieldHelperText}>Purpose</Text>
                        <TextField
                            disabled
                            className={classes.pointUpdatePurposeRequest}
                            size="small"
                            variant="outlined"
                            placeholder="Enter Point Amount"
                            value="Aurora Subscription​"
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                }
                            }}
                        />
                    </div>
                )}
            </>
        )
    };

    const handleClickPurposeActionIcon = e => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClosePurposeActionMenu = e => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleChangeToRename = e => {
        const id = anchorEl.getAttribute('value');
        const newPurposeTypes = purposeTypes.map(pt => {
            if (pt.id === +id) {
                return {
                    ...pt,
                    isEdit: true
                }
            }

            return {
                ...pt,
                isEdit: false
            }
        });
        setPurposeTypes(newPurposeTypes);
        handleClosePurposeActionMenu(e);
    };

    const handleResetPurposeTypes = () => {
        const newPurposeTypes = purposeTypes.map(pt => ({ ...pt, isEdit: false }));
        setPurposeTypes(newPurposeTypes);
        setNewPurpose('');
    };

    const handleRenamePurposeType = async (id, name) => {
        try {
            const { data } = await axios.patch(`${baseUrl}/purpose-type`, {
                id,
                name
            });
            setPurposeTypes(purposeTypes.map(pt => {
                if (pt.id === id) {
                    return {
                        ...pt,
                        name,
                        isEdit: false
                    }
                }

                return pt;
            }));
        } catch (err) {
            notiFailed(
                `There was a problem update name purpose types ${err.message}`
            );
        }
    };

    const getFilterPurposeTypes = () => {
        if (newPurpose === '') {
            return purposeTypes;
        }

        return purposeTypes.filter(pt => _.toLower(pt.name).includes(_.toLower(newPurpose)));
    };

    const handleCreatePurposeType = async () => {
        if (newPurpose === '') return;

        try {
            const { data } = await axios.post(`${baseUrl}/purpose-type`, {
                name: newPurpose
            });
            setPurposeTypes([
                ...data,
                ...purposeTypes
            ])
            setNewPurpose('');
        } catch (err) {
            notiFailed(
                `There was a problem getting create purpose types ${err.message}`
            );
        }
    };

    const handleDeletePurposeType = async () => {
        const id = anchorEl.getAttribute('value');
        setAnchorEl(null);

        try {
            const { data } = await axios.delete(`${baseUrl}/purpose-type`, {
                params: { id }
            });
            setPurposeTypes(purposeTypes.filter(pt => pt.id !== +id))
        } catch (err) {
            notiFailed(
                `There was a problem delete purpose types ${err.message}`
            );
        } finally {
            setOpenDeleteModal(false);
        }
    };

    const handleCloseDeleteModal = () => {
        setAnchorEl(null);
        setOpenDeleteModal(false);
    };

    const getPointRequestHistories = async (filter = {}, isViewMore = false) => {
        try {
            if (isViewMore) {
                setLoadingViewMorePointHistory(true);
            } else {
                setLoadingPointHistory(true);
            }
            const { data } = await axios.get(`${baseUrl}/point-request/histories`, {
                params: filter
            });
            setPointHistory([...pointHistory, ...data.data]);
            setPointHistoryTotal(data.total);
        } catch (err) {
            notiFailed(
                `There was a problem get point request histories ${err.message}`
            );
        } finally {
            if (isViewMore) {
                setLoadingViewMorePointHistory(false);
            } else {
                setLoadingPointHistory(false);
            }
        }
    };

    const viewMorePointHistory = () => {
        const currentPage = pointHistoryPage + 1;
        setPointHistoryPage(currentPage);
        const filters = {
            ...filter,
            page: currentPage
        };
        setFilter(filters);
        getPointRequestHistories(filters, true);
    };

    const getSubUsers = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/users/sub-user/by-company`, {
                params: {
                    companyId: company.id,
                    page: 1
                }
            });
            setSubUsers(data.data);
        } catch (err) {
            notiFailed(
                `There was a problem get sub users ${err.message}`
            );
        }
    };

    useEffect(() => {
        if (isDisablePointAmount()) {
            const qpoints = _.get(company, 'pointRequest.qpoints', 0);
            setPointAmount(qpoints);
        }
    }, [selectedRequestType]);

    return (
        <>
            <TableRow hover className={classes.tableRow} onClick={handleOpen}>
                {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>}
                <TableCell className={classes.tableCell}>
                    {convertHTMLEntity(company.name)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {company.state}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                    {company.approvalStep}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                    {Number(company.totalQpoints).toLocaleString()} Q
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                    {Number(company.consumed).toLocaleString()} Q
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                    {Number(company.availableQpoints).toLocaleString()} Q
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                    {_.get(company, 'pointRequest.requestStatus', 'N/A')}​
                </TableCell>
                {/*<TableCell className={classes.tableCell} align="right">*/}
                {/*    <IconButton size="small" disabled onClick={e => e.stopPropagation()}>*/}
                {/*        <Checkbox*/}
                {/*            color="primary"*/}
                {/*            checked={false}*/}
                {/*        />*/}
                {/*    </IconButton>*/}
                {/*</TableCell>*/}
                {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>}
            </TableRow>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div style={{ width: '95%', margin: 'auto' }}>
                            <Grid container justify="flex-end">
                                <IconButton onClick={handleClose}>
                                    <CloseOutlinedIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                        </div>
                        <Grid container>
                            <div style={{ width: '90%', margin: 'auto' }}>
                                <Grid container justify={'space-between'} alignItems="center">
                                    <div className={classes.updateStatusTitle}>
                                        <Text size="h3" weight="medium">Point Update</Text>
                                    </div>
                                    <div className={classes.wrapText}>
                                        <Text style={{marginRight: 5}} size="h4"> Latest Point Update Status: </Text>
                                        <Text
                                          size="h4"
                                          color="secondaryDark"
                                        >{_.get(company, 'pointRequest.requestStatus', 'N/A')}</Text>
                                    </div>
                                </Grid>

                                <Grid container item alignItems="center" className={classes.containerSearcher}>

                                            <div className={classes.wrapItem}>
                                                <TextField
                                                  select
                                                  className={classNames([classes.pointUpdateStatus], {
                                                      [classes.pointUpdateStatusBeforeSelected]: selectedRequestType == null
                                                  })}
                                                  size="small"
                                                  variant="outlined"
                                                  SelectProps={{
                                                      MenuProps: {
                                                          anchorOrigin: {
                                                              vertical: 'bottom',
                                                              horizontal: 'left'
                                                          },
                                                          getContentAnchorEl: null
                                                      }
                                                  }}
                                                  value={selectedRequestType}
                                                  onChange={handleSelectRequestType}
                                                >
                                                    {filterRequestTypes.map(rt => (
                                                      <MenuItem key={rt.id} value={rt.id}>{rt.name}</MenuItem>
                                                    ))}
                                                </TextField>
                                                {selectedRequestType !== null && renderQpointUpdatePurpose()}
                                            </div>
                                            {renderUpdateStatusButton()}
                                    {/*</Grid>*/}
                                </Grid>
                            </div>
                            <div className={classes.companyInfo}>
                                <Text style={{marginTop : 50}} size="h3" weight="medium">Company Details</Text>
                                <PointSummary totalQpoints={company?.totalQpoints} consumed={company?.consumed} availableQpoints={company?.availableQpoints}/>
                                <CompanyInformation name={company?.name} address={company?.address} mobileNumber={company?.mobileNumber} officeNumber={company?.officeNumber} website={company?.website}/>
                                <UserInformation email={company?.user.email} firstName={company?.user.firstName} lastName={company?.user.lastName} companyId={company?.id}/>
                            </div>
                            <div className={classes.pointUpdateHistory}>
                                <Text size="h3" weight="medium" className={classes.pointUpdateHistoryTitle}>Point Update History</Text>

                                <TableContainer
                                    component={Paper}
                                    className={classes.tableContainer}
                                >
                                    <Table size="small" className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.normalLabelHeaderColumnItem}>
                                                    Date
                                                </TableCell>
                                                <TableCell className={classes.normalLabelHeaderColumnItem}>
                                                    Time
                                                </TableCell>
                                                <TableCell className={classes.normalLabelHeaderColumnItem}>
                                                    Update
                                                </TableCell>
                                                <TableCell className={classes.normalLabelHeaderColumnItem}>
                                                    Qpoints
                                                </TableCell>
                                                <TableCell className={classes.normalLabelHeaderColumnItem}>
                                                    Update By
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loadingPointHistory ? (
                                                <TableRow>
                                                    <TableCell colSpan="5" className={classes.normalLabelColumnItem}>
                                                        <div className={classes.loadingIndicator}>
                                                            <CustomLoading size={30} color="#00adef"></CustomLoading>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                <>
                                                    {
                                                        pointHistory.length === 0 ? (
                                                            <TableRow>
                                                                <TableCell colSpan="5" className={classes.normalLabelColumnItem}>
                                                                    <div className={classes.noHistoryContainer}>
                                                                        <Text>No point update history.</Text>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : pointHistory.map(rh => (
                                                            <TableRow>
                                                                <TableCell className={classes.normalLabelColumnItem}>
                                                                    <Moment format="MM/DD/YYYY">{rh.createdAt}</Moment>
                                                                </TableCell>
                                                                <TableCell className={classes.normalLabelColumnItem}>
                                                                    <Moment format="HH:mm">{rh.createdAt}</Moment>
                                                                </TableCell>
                                                                <TableCell className={classes.normalLabelColumnItem}>
                                                                    Changed to {rh.requestStatus}
                                                                </TableCell>
                                                                <TableCell className={classes.normalLabelColumnItem}>
                                                                    {Number(rh.qpoints).toLocaleString()} Q
                                                                </TableCell>
                                                                <TableCell className={classes.normalLabelColumnItem}>
                                                                    {_.trim(`${rh.firstName} ${rh.lastName}`)}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                {pointHistory.length < pointHistoryTotal && (
                                    <Grid
                                        container
                                        justify="center"
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <Grid item>
                                            <CustomButton
                                                disabled={loadingViewMorePointHistory}
                                                color="secondary"
                                                onClick={viewMorePointHistory}
                                                className="RewardHistory_ViewMoreButton"
                                            >
                                                {loadingViewMorePointHistory ? <CustomLoading size={18} color="#00adef"></CustomLoading> : 'View More'}
                                            </CustomButton>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openDeleteModal}>
                <Alert>
                    <Grid container justify="flex-end">
                    <IconButton
                        onClick={handleCloseDeleteModal}
                        className={classes.deletePurposeModuleModalCloseBtn}
                    >
                        <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
                    </IconButton>
                    </Grid>
                    <Text className={classes.deletePurposeModalTitle}>
                        Are you sure you want to delete ?
                    </Text>
                    <Text
                        color="secondaryDark"
                        className={classes.deletePurposeModalSubtitle}
                    >
                        Once you do this, it can't be undone.
                    </Text>
                    <Grid
                        container
                        justify="center"
                        spacing={2}
                        style={{ marginBottom: '20px' }}
                    >
                    <Grid item>
                        <CustomButton
                            color="secondary"
                            onClick={handleCloseDeleteModal}
                            className={classes.deletePurposeModalCancelBtn}
                        >
                            Cancel
                        </CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomButton
                            color="alert"
                            onClick={handleDeletePurposeType}
                            className={classes.deletePurposeModalDeleteBtn}
                        >
                            Delete
                        </CustomButton>
                    </Grid>
                    </Grid>
                </Alert>
                </Fade>
            </Modal>
        </>
    )
}
