
import axios from './../utils/axios';
import config  from "../config";

function getListCategories(type) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/contact/categories`,
    params : {
      contactTypeId : type
    }
  }

  return axios(request);
}

function getListStatus(type) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/contact/statuses`,
    params : {
      contactTypeId : type
    }
  }

  return axios(request);
}

function getListUserAdmin(type) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/users/admin/list`,
    params : {
      contactTypeId : type
    }
  }

  return axios(request);

}

function getDetailContactSupportCenter(id) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/contact/detail/${id}`
  }

  return axios(request);
}

function getListContact(condition) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/contact/search`,
    params: condition
  }

  return axios(request);
}

function assignUser(ids, idUser) {
  const request = {
    method : 'PATCH',
    url : `${config.baseUrl}/contact/assign`,
    data : {
      ids : ids,
      assigned : idUser
    }
  }

  return axios(request);
}

function sendContact(formContact) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/contact/action`,
    data : formContact
  }


  return axios(request);

}

export {getListCategories, getListStatus, getListUserAdmin, getListContact, assignUser, getDetailContactSupportCenter, sendContact}
