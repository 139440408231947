import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	bar1: {
		color: props => props.color
	}
});

const CustomLoading = ({ size = 16, color = '#ffffff' }) => {
	const classes = useStyles({ color });
	return <CircularProgress size={size} className={classes.bar1} />;
};

export default CustomLoading;
