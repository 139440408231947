import React, { useState, useEffect, useRef } from 'react';
import Text from '../../common/components/text';
import SquareButton from '../../common/components/button/SquareButton';
import OrderDetails from './OrderDetails';
import axios from '../../utils/axios';
import config from '../../config';
import moment from 'moment';
import TextButton from '../../common/components/button/TextButton';
import Pagination from '../../common/components/Pagination';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  Grid,
  Paper,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Checkbox,
  InputAdornment,
  TableContainer
} from '@material-ui/core';

import { notiSuccess, notiFailed, decodeHtml } from './../../utils/helpers';
import _ from 'lodash';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { ExcelCustomComponent } from '../../utils/components';
import DatePicketCustom from '../../utils/components/datePicket';
import CustomLoading from './../../common/components/CustomLoading';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';

const StyledCircularProgress = withStyles(theme => ({
  root: {
    '&.MuiCircularProgress-colorPrimary': {
      color: '#00ADEF'
    }
  }
}))(CircularProgress);

const FilterTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px 0px 0px 4px'
    }
  }
})(TextField);

const SearchTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px 4px 4px 0px'
    }
  }
})(TextField);

const useStyles = makeStyles(theme => ({
  tableContainer: {
    boxShadow: 'none',
    "& thead, tbody": {
      borderRight: 'solid #E6E6E6 1px'
    }
  },
  table: {
    minWidth: 650
  },
  orderStatusContainer: {
    justifyContent: 'flex-start',
    margin: 0,
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  orderStatusItem: {
    cursor: 'pointer',
    fontWeight: 400,
    fontFamily: 'Actual',
    fontSize: '1.125rem',
    margin: 5,
    paddingBottom: '0 !important',
    marginBottom: '0 !important',
    [theme.breakpoints.down('xs')]: {
      width: '45%'
    }
  },
  orderStatusAllItem: {
    '& span': {
      paddingRight: '0 !important'
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  orderStatusItemLink: {
    width: 'fit-content',
    color: '#000000',
    textDecoration: 'none',
    display: 'inline-block',
    height: '43px',
    paddingLeft: '4px',
    paddingRight: '4px'
  },
  orderStatusItemActive: {
    color: '#00adef',
    borderBottom: 'solid 3px #00adef'
  },
  orderStatusItemNumber: {
    paddingRight: 4,
    color: '#00adef'
  },
  iconButton: {
    padding: 0,
    position: 'absolute',
    top: '13px',
    right: '15px'
  },
  tableHeaderCell: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1.0625rem'
  },
  tableCellPlaceholder: {
    width: '1vw'
  },
  tableHeaderRow: {
    '& span.MuiCheckbox-root': {
      paddingTop: '1px',
      paddingBottom: '1px'
    }
  },
  orderContainer: {
    marginBottom: '20px', 
    marginTop: '20px',
    border: 'solid #E6E6E6 1px',
    borderBottom: 0
  },
  tableHeaderDate: {
    width: '12%'
  },
  tableHeaderOrderNumber: {
    width: '15%'
  },
  tableHeaderCompanyName: {
    width: '20%'
  },
  tableHeaderName: {
    width: '12%'
  },
  tableHeaderPayment: {
    width: '12%'
  },
  tableHeaderStatus: {
    width: '13%'
  },
  tableHeaderAddTracking: {
    width: '18%'
  },
  orderStatusFailedItem: {
    [theme.breakpoints.down('xs')]: {
      width: '92.5%'
    }
  }
}));

const { baseUrl } = config;

export default function Orders() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  let history = useHistory();
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [orders_backup, set_orders_backup] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [orderStatusesAll, setOrderStatusesAll] = useState([]);
  const [checked, setChecked] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [filterBy, setFilterBy] = useState('');
  const [betweenDates, setBetweenDates] = useState({
    startDate: null,
    endDate: null
  });
  const [searchField, setSearchField] = useState('');
  const [status, setStatus] = useState('');
  const [ordersAndCount, setOrdersAndCount] = useState([
    { id: 'all', name: 'All', count: 0, active: false },
    { id: 1, name: 'Order Received', count: 0, active: false },
    { id: 2, name: 'Processing', count: 0, active: false },
    { id: 6, name: 'Shipped', count: 0, active: false },
    { id: 4, name: 'Delivered', count: 0, active: false },
    { id: 3, name: 'Returned', count: 0, active: false },
    { id: 5, name: 'Canceled', count: 0, active: false },
    { id: 8, name: 'Failed', count: 0, active: false }
  ]);
  const [postsPerPage] = useState(25);
  const { pagenumber, orderstatus } = useParams();
  const [currentPage, setCurrentPage] = useState(pagenumber);
  const [currentOrderStatus, setCurrentOrderStatus] = useState(orderstatus);
  const [currentOrdersTotal, setCurrentOrdersTotal] = useState(0);
  const [orderReportDataSheet, setOrderReportDataSheet] = useState([]);
  const [shippingList, setShippingList] = useState([]);
  const [loadingUpdateOrderStatus, setLoadingUpdateOrderStatus] = useState(false);
  const isMounted = useRef(false);
  let isUpdateAddTrackingStatus = status === '6' && checked.length > 0;

  const getOrders = async filters => {
    setLoadingOrders(true);
    const { data } = await axios.get(`${baseUrl}/orders`, {
      params: filters
    });
    setOrders(
      data.orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );

    let indexOfLastOrder = currentPage * postsPerPage;
    let indexOfFirstOrder = indexOfLastOrder - postsPerPage;

    setCurrentOrders(
      orderstatus === 'all'
        ? data.orders
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(indexOfFirstOrder, indexOfLastOrder)
        : data.orders
            .filter(o => {
              if (+currentOrderStatus === 4) {
                return [4, 7].includes(o.orderStatusId);
              }

              return o.orderStatusId === +currentOrderStatus;
            })
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(indexOfFirstOrder, indexOfLastOrder)
    );

    setCurrentOrdersTotal(
      orderstatus === 'all'
        ? data.orders
        : data.orders.filter(o => {
          if (+orderstatus === 4) {
            return [4, 7].includes(o.orderStatusId);
          }

          return o.orderStatusId === +orderstatus;
        })
    );

    setLoadingOrders(false);

    set_orders_backup(
      data.orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );

    return data.orders;
  };

  const paginate = async pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getOrderStatuses = async () => {
    const { data } = await axios.get(`${baseUrl}/orders/statuses`);
    setOrderStatuses(data.filter(status => status.name !== 'Delivered'));
    setOrderStatusesAll(data);
  };

  useEffect(() => {
    if (isMounted.current) {
      handleSearch();
    }
  }, [betweenDates.startDate, betweenDates.endDate])

  useEffect(() => {
    let a = Object.assign([], ordersAndCount);
    let b = Object.assign([], orders_backup);
    let c = Object.assign([], orderStatusesAll);
    if (c.length === 0) {
    } else {
      // compute active status
      for (let i = 0; i < a.length; i++) {
        if (a[i].id == orderstatus) {
          a[i].active = true;
        } else {
          a[i].active = false;
        }
      }

      // comtupe count values
      if (b.length === 0) {
        a[0].count = 0;
        a[1].count = 0;
        a[2].count = 0;
        a[3].count = 0;
        a[4].count = 0;
        a[5].count = 0;
        a[6].count = 0;
        a[7].count = 0;
      } else {
        let countId1 = 0;
        let countId2 = 0;
        let countId3 = 0;
        let countId4 = 0;
        let countId5 = 0;
        let countId6 = 0;
        let countId8 = 0;

        for (let i = 0; i < b.length; i++) {
          let order = b[i];
          switch (order.orderStatusId) {
            case 1:
              countId1++;
              break;
            case 2:
              countId2++;
              break;
            case 3:
              countId3++;
              break;
            case 4:
            case 7:
              countId4++;
              break;
            case 5:
              countId5++;
              break;
            case 6:
              countId6++;
              break;
            case 8:
              countId8++;
              break;
            default:
              break;
          }
        }
        a[0].count = orders_backup.length;
        a[1].count = countId1;
        a[2].count = countId2;
        a[3].count = countId6;
        a[4].count = countId4;
        a[5].count = countId3;
        a[6].count = countId5;
        a[7].count = countId8;
      }
      setOrdersAndCount(a);
    }
  }, [orders_backup, orderStatusesAll]);

  const getShippingList = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/shipping`);
      setShippingList(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getFilters = () => {
    const filters = {};
    if (filterBy && searchField) {
      filters.searchBy = filterBy;
      filters.searchFor = searchField;
    }

    if (!filterBy && searchField) {
      filters.searchFor = searchField;
    }

    if (betweenDates.startDate || betweenDates.endDate) {
      filters.dateField = 'orders.createdAt';
    }

    if (betweenDates.startDate && betweenDates.endDate) {
      filters.startDate = betweenDates.startDate;
      filters.endDate = betweenDates.endDate;
    } else if (betweenDates.startDate) {
      filters.startDate = betweenDates.startDate;
    } else if (betweenDates.endDate) {
      filters.endDate = betweenDates.endDate;
    }

    return filters;
  }

  useEffect(() => {
    getOrders();
    getOrderStatuses();
    getShippingList();
    isMounted.current = true;
  }, []);

  const selectAll = () => {
    let currentChecked = [];
    if (!isSelectAllChecked) {
      currentChecked = [...orders];
    }
    setChecked(currentChecked);
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  const handleCheck = value => () => {
    const doesExist = checked.some(c => c.id === value.id);
    let newCheck = [...checked];
    if (doesExist) {
      newCheck = checked.filter(c => c.id !== value.id);
    } else {
      newCheck.push(value);
    }
    setChecked(newCheck);
    setIsSelectAllChecked(newCheck.length === orders.length);
  };

  const handleFilterBy = e => setFilterBy(e.target.value);

  const handleStartDateChange = date => {
    const startOfDay = moment(date)
      .startOf('day')
      .format();
    setBetweenDates({ ...betweenDates, startDate: startOfDay });
  };

  const handleEndDateChange = date => {
    const endOfDay = moment(date)
      .endOf('day')
      .format();
    setBetweenDates({ ...betweenDates, endDate: endOfDay });
  };

  const handleSearch = async () => {
    let temp = ordersAndCount.filter(o => {
      return o.active === true;
    });

    let orderStatusIdFilter = _.get(temp, '[0].id');

    const filters = getFilters();

    setLoadingOrders(true);

    // can make a front end search (filter) here, no need backend search
    const { data } = await axios.get(`${baseUrl}/orders`, {
      params: filters
    });
    let dataFilterByStatusId = data.orders
      .filter(o => {
        if (orderStatusIdFilter && orderStatusIdFilter !== 'all') {
          return o.orderStatusId === orderStatusIdFilter;
        } else {
          return true;
        }
      })
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setOrders(dataFilterByStatusId);
    set_orders_backup(
      data.orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );
    let currentPage2 = 1;
    if (!_.isEmpty(filters)) {
      currentPage2 = 1;
      history.push(`/marketing-shop/orders/${orderstatus}/1`);
    } else {
      currentPage2 = currentPage;
    }

    let indexOfLastOrder = currentPage2 * postsPerPage;
    let indexOfFirstOrder = indexOfLastOrder - postsPerPage;
    setCurrentOrders(
      dataFilterByStatusId.slice(indexOfFirstOrder, indexOfLastOrder)
    );
    setCurrentOrdersTotal(dataFilterByStatusId);
    setLoadingOrders(false);
  };

  const handleStatusField = e => {
    setStatus(e.target.value);
  };

  const handleStatus = async () => {
    if (status === '') {
      return notiFailed("Status can't be blank");
    }
    const isAllNormalOrder = checked.every(c => !_.get(c, 'products[0].product.custom'));

    const updatedOrders = checked.map(async order => {
      return await axios
        .patch(`${baseUrl}/orders/${order.id}`, {
          id: order.id,
          type: order.type,
          orderStatusId: status,
          shippingCompany: order.shippingCompany,
          trackingNumber: order.trackingNumber
        })
        .catch(() => {
          return {
            id: order.id,
            notUpdated: true,
            orderNumber: order.orderNumber
          };
        });
    });
    setLoadingUpdateOrderStatus(true);
    await Promise.all(updatedOrders)
      .then(data => {
        setChecked([]);
        setOrders([]);
        set_orders_backup([]);
        setStatus('');
        setLoadingUpdateOrderStatus(false);
        return data;
      })
      .then(data => {
        let allData = data.filter(item => !!item);
        let fail = allData.filter(item => item.notUpdated);
        if (fail.length === 0) {
          notiSuccess(`Successfully updated ${allData.length} order statuses`);
        } else {
          if (isAllNormalOrder) {
            notiFailed(
              <>
                <p>
                  You have successfully updated {allData.length - fail.length} orders.
                </p>
                <p>Please re-check the failed orders</p>
                <ul>
                  {fail.map((item, index) => (
                    <li key={index}>{item.orderNumber}</li>
                  ))}
                </ul>
              </>
            );
          } else {
            notiFailed('Unsuccessful order status update: You cannot cancel the order for the used coupons.')
          }
        }
        const filters = getFilters();
        return getOrders(filters);
      })
      .then(orders => {
        handleFilterByOrderStatus(orderstatus, orders);
      })
      .catch(() => {
        setLoadingUpdateOrderStatus(false);
      });
  };

  const updateShipTracking = (ordersArr, orderId, shippingInfo) => {
    return ordersArr.map(c => {
      if (c.id === orderId) {
        return { ...c, ...shippingInfo };
      }
      return c;
    });
  };

  const handleShippingTracking = (orderId, shippingInfo) => {
    setCurrentOrders(updateShipTracking(currentOrders, orderId, shippingInfo));
    setChecked(updateShipTracking(checked, orderId, shippingInfo));
    setOrders(updateShipTracking(orders, orderId, shippingInfo));
    set_orders_backup(updateShipTracking(orders, orderId, shippingInfo));
  };

  const downloadOrderReport = async () => {
    try {
      const filters = getFilters();
      const { data } = await axios.get(`${baseUrl}/orders`, {
        params: filters
      });
      const createReport = await data.orders.map(o => {
        let newProducts = [];
        o.products.forEach((p, idx) => {
          const custom = _.get(p, 'product.custom');
          const category = _.get(p, 'categoryName');
          const couponCode = _.get(p, 'couponCode.code');
          const couponStatus = _.get(p, 'couponCode.couponStatus');
          
          newProducts.push({
            date: `${moment(o.createdAt).format('MM[/]DD[/]YYYY')}`,
            orderNumber: o.orderNumber,
            product: p.product?.product?.name || p.product.name || '',
            'product category': category || '',
            sku: p.sku,
            quantity: p.quantity,
            size: p.size || p.product.size,
            color: p.color || p.product.color,
            custom: custom || '',
            company: decodeHtml(o.companyName),
            name: decodeHtml(`${o.firstName} ${o.lastName}`),
            email: o.email,
            paymentAmount: (+o.total).toLocaleString(),
            orderStatus: p.status || o.orderStatus,
            carrier: p.shippingCompany ? p.shippingCompany : '',
            trackingNumber: p.trackingNumber,
            'coupon code': couponCode || '',
            'coupon status': couponStatus || ''
          });
        });
        return newProducts;
      });

      setOrderReportDataSheet(
        createReport.flat().sort((a, b) => new Date(b.date) - new Date(a.date))
      );
      document.getElementById('order-report-data-sheet').click();
    } catch (error) {
      notiFailed(`There was a problem getting orders.`);
    }
  };

  const handleFilterByOrderStatus = (orderStatusId, orders) => {
    let ordersBackup = [...orders_backup];
    if (_.isArray(orders) && !_.isEmpty(orders)) {
      ordersBackup = [...orders];
    } 
    if (_.isEmpty(ordersBackup)) {
      return;
    }
    let ordersAndCountClone = Object.assign([], ordersAndCount);

    ordersAndCountClone = ordersAndCountClone.map(item => {
      item.active = false;
      return item;
    });

    if (orderStatusId === 'all') {
      ordersAndCountClone[0].active = true;
      let indexOfLastOrder = 1 * postsPerPage;
      let indexOfFirstOrder = indexOfLastOrder - postsPerPage;

      setCurrentOrders(
        ordersBackup
          .filter(o => {
            let search_source = '';
            switch (filterBy) {
              case '':
                let skuStr = '';
                let productNameStr = '';
                let couponCodeStr = '';
                o.products.forEach(p => {
                  skuStr += p.sku;
                  productNameStr += _.get(p, 'product.product.name', '');
                  couponCodeStr += _.get(p, 'couponCode.code', '');
                });

                search_source +=
                  o.firstName +
                  o.lastName +
                  o.orderNumber +
                  o.companyName +
                  skuStr +
                  skuStr +
                  productNameStr +
                  couponCodeStr;
                break;
              case 'orderNumber':
                search_source += String(o.orderNumber);
                break;
              case 'companies.name':
                search_source += o.companyName;
                break;
              case 'op.sku':
                search_source += String(o.products[0].sku);
                break;
              default:
                break;
            }
            search_source = search_source.replace(/ /g, '').toLowerCase();
            if (filterBy === '') {
              return search_source.includes(
                searchField.replace(/ /g, '').toLowerCase()
              );
            }
            return search_source.includes(searchField);
          })
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(indexOfFirstOrder, indexOfLastOrder)
      );

      setCurrentOrdersTotal(ordersBackup);
    } else {
      switch (+orderStatusId) {
        case 1:
          ordersAndCountClone[1].active = true;
          break;
        case 2:
          ordersAndCountClone[2].active = true;
          break;
        case 3:
          ordersAndCountClone[5].active = true;
          break;
        case 4:
          ordersAndCountClone[4].active = true;
          break;
        case 5:
          ordersAndCountClone[6].active = true;
          break;
        case 6:
          ordersAndCountClone[3].active = true;
          break;
        case 8:
          ordersAndCountClone[7].active = true;
          break;
        default:
          break;
      }

      let target = ordersBackup.filter(o => {
        let condition1 = o.orderStatusId === +orderstatus;

        if (+orderstatus === 4) {
          condition1 = [4, 7].includes(o.orderStatusId);
        }

        let search_source = '';
        switch (filterBy) {
          case '':
            let skuStr = '';
            let productNameStr = '';
            let couponCodeStr = '';
            o.products.forEach(p => {
              skuStr += p.sku;
              productNameStr += _.get(p, 'product.product.name', '');
              couponCodeStr += _.get(p, 'couponCode.code', '');
            });
            search_source +=
              o.firstName + o.lastName + o.orderNumber + o.companyName + skuStr + productNameStr + couponCodeStr;
            break;
          case 'orderNumber':
            search_source += String(o.orderNumber);
            break;
          case 'companies.name':
            search_source += o.companyName;
            break;
          case 'op.sku':
            search_source += String(o.products[0].sku);
            break;
          default:
            break;
        }
        search_source = search_source.replace(/ /g, '').toLowerCase();

        let condition2;
        if (filterBy === '') {
          condition2 = search_source.includes(
            searchField.replace(/ /g, '').toLowerCase()
          );
        } else {
          condition2 = search_source.includes(searchField);
        }
        return condition1 && condition2;
      });

      if (target.length < postsPerPage) {
        setCurrentOrders(
          target.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      } else {
        let indexOfLastOrder = 1 * postsPerPage;
        let indexOfFirstOrder = indexOfLastOrder - postsPerPage;

        setCurrentOrders(
          target
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(indexOfFirstOrder, indexOfLastOrder)
        );
      }
      setCurrentOrdersTotal(target);
      setOrders(target);
    }
    setOrdersAndCount(ordersAndCountClone);
  };

  const renderUpdateOrderStatus = () => {
    const isAllNormalOrder = checked.every(c => !_.get(c, 'products[0].product.custom'));
    
    if (isAllNormalOrder) {
      return (
        <TextField
          fullWidth
          select
          size="small"
          variant="outlined"
          placeholder="Select an Action"
          value={status}
          onChange={handleStatusField}
        >
          <MenuItem value="2">Processing</MenuItem>
          <MenuItem value="1">Order Received</MenuItem>
          <MenuItem value="3">Return</MenuItem>
          <MenuItem value="6">Add Tracking</MenuItem>
          <MenuItem value="5">Cancel</MenuItem>
        </TextField>
      )
    }

    return (
      <TextField
        fullWidth
        select
        size="small"
        variant="outlined"
        placeholder="Select an Action"
        value={status}
        onChange={handleStatusField}
      >
        <MenuItem value="5">Cancel</MenuItem>
      </TextField>
    )
  };
 
  useEffect(() => {
    handleFilterByOrderStatus(orderstatus, currentPage);
  }, [orderstatus, orders_backup]);

  useEffect(() => {
    if (_.isEmpty(orders_backup)) {
      return;
    }

    let indexOfLastOrder = currentPage * postsPerPage;
    let indexOfFirstOrder = indexOfLastOrder - postsPerPage;
    let target = [];

    if (orderstatus === 'all') {
      target = orders_backup.slice(indexOfFirstOrder, indexOfLastOrder);
    } else {
      target = orders_backup
        .filter(ob => {
          if (+orderstatus === 4) {
            return [4, 7].includes(ob.orderStatusId);
          }
          
          return ob.orderStatusId === +orderstatus
        })
        .slice(indexOfFirstOrder, indexOfLastOrder);
    }
    setCurrentOrders(target);
  }, [currentPage, orders_backup]);

  return (
    <>
      <Text size="h2" style={{ lineHeight: '0px' }}>
        Orders
      </Text>

      <>
        <TextButton color="secondaryDark" onClick={downloadOrderReport}>
          Download Report
        </TextButton>
        <ExcelCustomComponent
          filename={`order-report-${new Date()}.csv`}
          id="order-report-data-sheet"
          data={orderReportDataSheet}
        />
      </>

      <Paper elevation={0} className={classes.orderContainer}>
        <Grid container className={classes.orderStatusContainer} spacing={3}>
          {ordersAndCount &&
            ordersAndCount.map(status => (
              <Grid
                item
                className={`${classes.orderStatusItem} ${
                  status.name === 'All' 
                    ? classes.orderStatusAllItem  
                    : status.name === 'Failed' 
                    ? classes.orderStatusFailedItem
                    : ''
                }`}
                key={status.id}
              >
                <Link
                  className={`${classes.orderStatusItemLink} ${
                    status.active ? classes.orderStatusItemActive : ''
                  }`}
                  to={{
                    pathname: `/marketing-shop/orders/${status.id}/1`
                  }}
                >
                  <span className={classes.orderStatusItemNumber}>
                    {status.name === 'All' ? '' : status.count}
                  </span>
                  {status.name}
                </Link>
              </Grid>
            ))}
          {ordersAndCount.length % 2 === 1 && (
            <Grid
              item
              className={classes.orderStatusItem}
              style={{ visibility: 'hidden' }}
            ></Grid>
          )}
        </Grid>

        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6',
            borderTop: 'solid #E6E6E6 1px'
          }}
        >
          <Grid
            container
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              paddingTop: '20px',
              paddingBottom: '20px'
            }}
            spacing={1}
          >
            <Grid item md={1} xs={4} style={{ paddingRight: 0 }}>
              <FilterTextField
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                fullWidth
                select
                size="small"
                variant="outlined"
                label="Filter"
                value={filterBy}
                onChange={handleFilterBy}
              >
                <MenuItem value="orderNumber">Order Number</MenuItem>
                <MenuItem value="companies.name">Company Name</MenuItem>
                <MenuItem value="op.sku">SKU Number</MenuItem>
              </FilterTextField>
            </Grid>
            <Grid item md={7} xs={8} style={{ paddingLeft: 0 }}>
              <SearchTextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Search"
                value={searchField}
                onChange={e => setSearchField(e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon style={{ color: '#BDBDBD' }} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item md={2} xs={6} style={{ position: 'relative' }}>
              <DatePicketCustom
                fullWidth
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="Start Date"
                inputVariant="outlined"
                value={betweenDates.startDate}
                onChange={handleStartDateChange}
                maxDate={betweenDates.endDate || new Date()}
                views={['year', 'month', 'date']}
                disablePast={false}
              />
              <IconButton
                className={classes.iconButton}
                edge="end"
                size="small"
                disabled={!betweenDates.startDate}
                onClick={() => setBetweenDates({ ...betweenDates, startDate: null })}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            <Grid item md={2} xs={6} style={{ position: 'relative' }}>
              <DatePicketCustom
                fullWidth
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="End Date"
                inputVariant="outlined"
                value={betweenDates.endDate}
                onChange={handleEndDateChange}
                minDate={betweenDates.startDate}
                maxDate={new Date()}
                views={['year', 'month', 'date']}
                disablePast={false}
              />
              <IconButton
                className={classes.iconButton}
                edge="end"
                size="small"
                disabled={!betweenDates.endDate}
                onClick={() => setBetweenDates({ ...betweenDates, endDate: null })}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="space-between"
            style={{
              maxWidth: '75vw',
              margin: 'auto'
            }}
          >
            <Grid item md={4} xs={12}>
              <Text color="dark" size="h4" weight="medium">
                {orders_backup.length} Orders Total
              </Text>
            </Grid>
            {checked.length > 0 ? (
              <Grid
                container
                item
                md={8}
                xs={12}
                justify="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid container item md={2} xs={4}>
                  <SquareButton
                    selected
                    color="primary"
                    style={{
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                      width: 'inherit',
                      padding: '.65rem',
                      marginLeft: 0
                    }}
                  >
                    {checked.length} selected
                  </SquareButton>
                </Grid>
                <Grid item md={4} xs={5}>
                  {renderUpdateOrderStatus()}
                </Grid>
                <Grid item md={2} xs={3} style={{ width: 'inherit' }}>
                  <button
                    className="btn_update"
                    onClick={handleStatus}
                    style={{ padding: '.65rem' }}
                    disabled={loadingUpdateOrderStatus}
                  >
                    {loadingUpdateOrderStatus ? (
                      <CustomLoading size={18} color="#fff" />
                    ) : (
                      'Update'
                    )}
                  </button>
                </Grid>
              </Grid>
            ) : (
              false
            )}
          </Grid>
        </div>

        <Grid
          container
          justify="flex-start"
          style={{
            maxWidth: '80vw',
            margin: 'auto'
          }}
          spacing={2}
        >
          {loadingOrders ? (
            <div className="wr_loading_order">
              <StyledCircularProgress size={36} />
            </div>
          ) : (
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHeaderRow}>
                    {!isSmallScreen && <TableCell className={classes.tableCellPlaceholder}></TableCell>}
                    <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderDate}`}>
                      Date
                    </TableCell>
                    <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderOrderNumber}`}>
                      Order Number
                    </TableCell>
                    <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderCompanyName}`}>
                      Company Name
                    </TableCell>
                    <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderName}`}>
                      Name
                    </TableCell>
                    <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderPayment}`}>
                      Payment
                    </TableCell>
                    <TableCell className={`${classes.tableHeaderCell} ${classes.tableHeaderStatus}`}>
                      Status
                    </TableCell>
                    <TableCell className={classNames('', { [classes.tableHeaderAddTracking]: isUpdateAddTrackingStatus })}></TableCell>
                    <TableCell align="right">
                      <IconButton size="small" style={{ marginRight: '20px' }}>
                        <Checkbox
                          style={{
                            color: `${isSelectAllChecked ? '#00ADEF' : '#8C8C8C'}`
                          }}
                          color="primary"
                          onChange={selectAll}
                          checked={isSelectAllChecked}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentOrders.map(order => {
                    return (
                      <OrderDetails
                        key={order.id}
                        order={order}
                        handleCheck={handleCheck(order)}
                        checked={checked}
                        orderStatuses={orderStatuses}
                        getOrders={getOrders}
                        trackPackageSelected={status === '6' ? true : false}
                        addShippingInfo={handleShippingTracking}
                        shippingList={shippingList}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Paper>
      {!loadingOrders && (
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={currentOrdersTotal.length}
          paginate={paginate}
          pagenumber={pagenumber}
          orderstatus={orderstatus}
        />
      )}
    </>
  );
}
