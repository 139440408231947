import React, { useReducer, useEffect, useMemo } from 'react';
import { initialState, profileReducer } from './reducer';
import { getProfile } from './actions';


const Context = React.createContext();

export default Context;


const ProfileStateContext = React.createContext();
const ProfileDispatchContext = React.createContext();

export function useProfileAdminState() {
  const context = React.useContext(ProfileStateContext);
  if (context === undefined) {
    throw new Error('useContactState must be used within a UserProvider');
  }

  return context;
}

export function useProfileAdminDispatch() {
  const context = React.useContext(ProfileDispatchContext);
  if (context === undefined) {
    throw new Error('useContactDispatch must be used within a UserProvider');
  }

  return context;
}

export const ProfileAdminProvider = ({ children }) => {
  const [profile, dispatch] = useReducer(profileReducer, initialState);

  useEffect(() => {
    getProfile(dispatch)
  }, [])

  return (
    <ProfileStateContext.Provider value={profile}>
      <ProfileDispatchContext.Provider value={dispatch}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  );
};

