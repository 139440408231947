import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Fade,
  FormControlLabel,
  Grid,
  IconButton, InputAdornment,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Checkbox,
  TextField
} from '@material-ui/core';
import Text from '../../common/components/text';
import Button from '../../common/components/button';
import CustomButton from '../../common/components/button';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import ReactSelect from 'react-select';
import axios from '../../utils/axios';
import config from '../../config';
import { notiFailed, notiSuccess } from './../../utils/helpers';
import DownloadIcon from './../../config/images/download-icon.png';
import DownloadIconOn from './../../config/images/download-icon-on.png';
import BackButton from '../../common/components/button/BackButton';
import { Link, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import CustomLoading from './../../common/components/CustomLoading';
import Moment from 'react-moment';
import AlertDeleteComponent from './../../common/components/AlertDeleteComponent';
import DoneIcon from '@material-ui/icons/Done';
import EditNoteModal from './EditNoteModal';
import { useField, useFormik } from 'formik';
import moment from 'moment';
import Alert from '../rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import * as yup from 'yup';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  textChangeMasterId: {
    fontSize: 30
  },
  tableHeader: {
    background: '#f4f5f7',
    fontWeight: '500',
    fontSize: '15px'
  },
  table: {
    borderTop: '1px solid #e4e4e4',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  noteTableHeader: {
    textAlign: 'left',
    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px'
  },
  noteTable: {
    borderTop: '1px solid #e4e4e4',
    marginTop: '10px',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  emptyNoteList: {
    '& td': {
      padding: '81px 15px'
    }
  },
  emailText: {
    margin: '8px 12px 8px 0',
    fontSize : '14px'
  },
  multiSelect: {
    '& .select__control': {
      borderRadius: 0,
      padding: '1px'
    },
    '& .select__multi-value__remove:hover': {
      backgroundColor: '#00adef',
      color: '#fff'
    }
  },
  w9ImageInput: {
    display: 'none'
  },
  w9ImageBtn: {
    border: '1px solid gray',
    height: '56px',
    marginLeft: '10px'
  },
  textArea: {
    width: '100%',
    padding: '5px',
    fontSize: '14px'
  },
  newLetterText: {
    lineHeight: '40px',
    fontSize : 14,
    marginTop: 0,
    marginBottom: 0
  },
  newLetterRadio: {
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#019ee3'
    }
  },
  selectState : {
    width : '100%',
    height : '100%',
    border : '1px solid #8080809e',
    borderRadius : 4
  },
  textInput: {
    fontSize: 14
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapModule : {
    display: 'flex',
    alignItems: 'center'
  },
  wrapProduct : {
    display : 'flex',
    marginTop: 10
  },
  required : {
    color : 'red'
  },
  w9Download: {
    display: 'inline-block',
    position: 'relative',
    transition: 'color 0.4s',
    paddingLeft: '20px',
    background: `url(${DownloadIcon}) left 1px no-repeat`,
    '&:after': {
      position: 'absolute',
      left: '19px',
      bottom: 0,
      width: 0,
      height: '1px',
      background: '#019ee3',
      content: '""',
      transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#019ee3',
      background: `url(${DownloadIconOn}) left 1px no-repeat`
    },
    '&:hover:after': {
      width: 'calc(100% - 20px)'
    }
  }
}));

const { baseUrl } = config;

const RegistrationSchema = yup.object({
  partnerType : yup.string().required('partnerType is required')
})

export default function RegistrationDetail() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [isApprovedUser, setApprovedUser] = useState(false);
  const [saleRegions, setSaleRegions] = useState([]);
  const [mainPurchasingChannel, setMainPurchasingChannel] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [notes, setNotes] = useState([]);
  const [newNoteText, setNewNoteText] = useState('');
  const [loadingAddNote, setLoadingAddNote] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedEditNote, setSelectedEditNote] = useState(null);
  const [changeNote, setChangeNote] = useState('');
  const [originalNote, setOriginalNote] = useState('');
  const [openEditNoteModal, setOpenEditNoteModal] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [isOpenChangeMaster, setIsOpenChangeMaster] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [loadingUpdateInfo, setLoadingUpdateInfo] = useState(false);
  const [isOpenFormUpdate, setIsOpenFormUpdate] = useState(false);
  const [mobileType, setMobileType] = useState('1');
  const [listState, setListState] = useState([]);
  const [isDistributor, setIsDistributor] = useState(false);
  const [listModule, setListModule] = useState([]);
  const [listEss, setListEss] = useState([]);
  const [module, setModule] = useState([]);
  const [listTitle, setListTitle] = useState([]);
  const [ess, setEss] = useState([]);
  const [dataModule, setDataModule] = useState([]);
  const [dataEss, setDataEss] = useState([]);
  const [listBrandModule, setListBrandModule] = useState([]);
  const [listBrandEss, setListBrandEss] = useState([]);
  const [isTitle, setIsTitle] = useState(false);
  const [isModule, setIsModule] = useState(false);
  const [isEss, setIsEss] = useState(false);
  const [isCompanySize , setIsCompanySize] = useState(false);


  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: RegistrationSchema,
    initialValues: {
      masterId: -1,
      size : _.get(userDetail, 'size', ''),
      textInstallationVolumeDataModule : 0,
      textInstallationVolumeDataEss : 0,
      website : _.get(userDetail, 'website', ''),
      survey : _.get(userDetail, 'survey'),
      distributor : '',
      surveyText : _.get(userDetail, 'surveyText',''),
      jobTitle : _.get(userDetail, 'jobTitle', ''),
      description: _.get(userDetail, 'description', ''),
      disabled: _.get(userDetail, 'disabled', true) ? 'true' : 'false',
      segment: _.get(userDetail, 'segment', ''),
      firstName: _.get(userDetail, 'firstName', ''),
      lastName: _.get(userDetail, 'lastName', ''),
      receptionYn: _.get(userDetail, 'receptionYn', 'Y'),
      masterID: '',
      partnerType: _.get(userDetail, 'partnerType', '') === null ? '' : _.get(userDetail, 'partnerType', ''),
      companyName: _.get(userDetail, 'companyName', ''),
      mobileNumber: _.get(userDetail, 'mobileNumber', ''),
      officeNumber : _.get(userDetail, 'officeNumber', ''),
      type: -1,
      purchaseChannel: [],
      purchasingChannelValue: '',
      salesArea : _.get(userDetail, 'salesArea', ''),
      listSalesArea: (!!_.get(userDetail, 'salesArea', []) && _.get(userDetail, 'salesArea', []).length > 0) ? _.get(userDetail, 'salesArea', []).split('|') : [],
      address: _.get(userDetail, 'address', ''),
      addressCont: _.get(userDetail, 'addressCont', ''),
      city: _.get(userDetail, 'city', ''),
      state: _.get(userDetail, 'state', ''),
      zipCode: _.get(userDetail, 'zipCode', ''),
      shippingAddress: _.get(userDetail, 'shippingAddress', '') === null ? _.get(userDetail, 'address', '') : _.get(userDetail, 'shippingAddress', '') ,
      shippingAddress2: _.get(userDetail, 'shippingAddress2', '') === null ? _.get(userDetail, 'addressCont', '') : _.get(userDetail, 'shippingAddress2', '') ,
      shippingCity: _.get(userDetail, 'shippingCity', '') === null ? _.get(userDetail, 'city', '') : _.get(userDetail, 'shippingCity', ''),
      shippingState: _.get(userDetail, 'shippingState', '') === null ? _.get(userDetail, 'state', '') : _.get(userDetail, 'shippingState', ''),
      shippingZipCode: _.get(userDetail, 'shippingZipCode', '') === null ? _.get(userDetail, 'zipCode', '') : _.get(userDetail, 'shippingZipCode', ''),
      taxId: _.get(userDetail, 'taxId'),
      installationVolumeModule : '',
      installationVolumeEss : '',
      titleImage: _.get(userDetail, 'w9.assetUrl', ''),
      installationQuantity : '',
      installationVolumeData : _.get(userDetail, 'installationVolumeData', ''),
      textInstallationQuantity : '',
      modules: [],
      primaryProductsEss  : [],
      textPrimaryProductsEss : "",
      primaryProductsModule : [],
      textPrimaryProductsModule : "",
      textTitle : "",
      isNoneModule : false,
      w9 : _.get(userDetail, 'w9.assetUrl', ''),
      isNoneEss : false,
      ess : [],
      isNotWebSite : !_.get(userDetail, 'website', ''),
      mobileType: !!_.get(userDetail, 'officeNumber', '') ? '2' : '1',
      textNumber : !!_.get(userDetail, 'officeNumber', '') ? _.get(userDetail, 'officeNumber', '') : _.get(userDetail, 'mobileNumber', '')
    }
  });


  function addZeroes(num) {
    const dec = num.split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }

  useEffect(() => {
    const isOtherTitle = listTitle.filter(item => {
      return item.name === _.get(userDetail, 'jobTitle', '')
    })

    if(!!_.get(userDetail, 'officeNumber')) {
      formik.setFieldValue('mobileType', '2')
    }else {
      formik.setFieldValue('mobileType', '1')
    }


    if(isOtherTitle.length === 0) {
      formik.setFieldValue('jobTitle', -1);
      formik.setFieldValue('textTitle',_.get(userDetail, 'jobTitle', '') )
    }else {
      formik.setFieldValue('jobTitle', _.get(userDetail, 'jobTitle', ''))
    }
  }, [_.get(userDetail, 'jobTitle', ''), listTitle.length])


  useEffect(() => {
    if(formik.values.partnerType === 2) {
      formik.setFieldValue('size', '');
    }
  }, [formik.values.partnerType])

  useEffect(() => {

    if(formik.values.textInstallationVolumeDataEss !== null) {
      if(formik.values.installationVolumeEss === 11 && (formik.values.textInstallationVolumeDataEss >= 10 || formik.values.textInstallationVolumeDataEss < 0)) {
        setIsEss(true);
      }else if(formik.values.installationVolumeEss === 18 && formik.values.textInstallationVolumeDataEss <= 100) {
        setIsEss(true);
      }else {
        setIsEss(false);
      }
    }

  }, [formik.values.installationVolumeEss])

  useEffect(() => {
    if(formik.values.textInstallationVolumeDataModule !== null) {
      if(formik.values.installationVolumeModule === 1 && (formik.values.textInstallationVolumeDataModule >= 250 || formik.values.textInstallationVolumeDataModule < 0)) {
        setIsModule(true)
      }else if(formik.values.installationVolumeModule === 10 && formik.values.textInstallationVolumeDataModule <= 20) {
        setIsModule(true)
      }else {
        setIsModule(false)
      }
    }
  }, [formik.values.installationVolumeModule])



  useEffect(() => {
    const isOther  = mainPurchasingChannel.filter(item => {
      return item.name === _.get(userDetail, 'purchaseChannel')
    })



    if(isOther.length === 0) {
      formik.setFieldValue('purchaseChannel', [{name : 'Other', value: 'Other'}]);
      formik.setFieldValue('distributor', _.get(userDetail, 'purchaseChannel'))
    }else {
      formik.setFieldValue('purchaseChannel', isOther);
    }

  }, [_.get(userDetail, 'purchaseChannel'), mainPurchasingChannel.length])


  useEffect(() => {
    if(_.get(userDetail, 'modules', []).length > 0) {
      formik.setFieldValue('installationVolumeModule', _.get(userDetail, 'modules[0].installationVolumeId', ''))
      formik.setFieldValue('textInstallationVolumeDataModule', _.get(userDetail, 'modules[0].installationVolumeData', ''))
      formik.setFieldValue('isNoneModule', _.get(userDetail, 'modules[0].productBrand', 'None') === 'None')
      const makeDataModule = _.get(userDetail, 'modules[0].productBrand', "")?.split('|').map(item => {
        return {
          name : item,
          value : item
        }
      })



      formik.setFieldValue('primaryProductsModule', makeDataModule)
      setModule(_.get(userDetail, 'modules', []));

    }
  }, [_.get(userDetail, 'modules')])

  useEffect(() => {
    if(_.get(userDetail, 'ess', []).length > 0) {
      formik.setFieldValue('installationVolumeEss', _.get(userDetail, 'ess[0].installationVolumeId', ''))
      formik.setFieldValue('textInstallationVolumeDataEss', _.get(userDetail, 'ess[0].installationVolumeData', ''))
      formik.setFieldValue('isNoneEss', _.get(userDetail, 'ess[0].productBrand', "None") === 'None')
      const makeDataEss = _.get(userDetail, 'ess[0].productBrand', "").split('|').map(item => {
        return {
          name : item,
          value : item
        }
      })

      formik.setFieldValue('primaryProductsEss', makeDataEss)

      setEss(_.get(userDetail, 'ess', []));

    }
  }, [_.get(userDetail, 'modules')])

  useEffect(() => {
    if(formik.values.isNoneEss) {
      formik.setFieldValue('primaryProductsEss', [])
    }
  },[formik.values.isNoneEss])

  useEffect(() => {
    if(formik.values.isNoneModule) {
        formik.setFieldValue('primaryProductsModule', [])
    }
  },[formik.values.isNoneModule])

  useEffect(() => {
    if(formik.values.isNotWebSite) {
      formik.setFieldValue('website', '');
    }
  },[formik.values.isNotWebSite])



  function handleChangeTypePhone(event) {
    setMobileType(event.target.value);
  }


  const getMainPurchasingChannel = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/distributors`);

      setMainPurchasingChannel([...data, {name : 'Other', value : 'Other'}]);
    } catch (err) {
      notiFailed(`Something happened getting main purchasing channel`);
    }
  };

  async function handleUpdateInfo(type) {
    type === 1 && setLoadingUpdateInfo(true);
    const mobileNumber = formik.values.mobileType === '1' ? formik.values.textNumber : _.get(userDetail, 'mobileNumber', '');
    const officeNumber = formik.values.mobileType === '2' ? formik.values.textNumber : _.get(userDetail, 'officeNumber', '')
    let formData = new FormData();

    let primaryProducts = [];

    const makeDataEss = {
      productType: 'ess',
      productBrand: formik.values.isNoneEss ? 'None' : _.join(_.map(formik.values.primaryProductsEss, 'name'), '|'),
      installationVolumeId : formik.values.installationVolumeEss
    }

    if (formik.values.installationVolumeEss === 11 || formik.values.installationVolumeEss === 18) {
      makeDataEss.installationVolumeData = formik.values.textInstallationVolumeDataEss;
    }

    const makeDataModule = {
      productType: 'module',
      productBrand: formik.values.isNoneModule ? 'None' : _.join(_.map(formik.values.primaryProductsModule, 'name'), '|'),
      installationVolumeId : formik.values.installationVolumeModule
    }

    if (formik.values.installationVolumeModule === 1 || formik.values.installationVolumeModule === 10) {
      makeDataModule.installationVolumeData = formik.values.textInstallationVolumeDataModule;
    }

    primaryProducts.push(makeDataEss);
    primaryProducts.push(makeDataModule);

    let makePayload = {...formik.values};


    Object.keys(makePayload).map((key, index) => {
      if(makePayload[key] === null) {
        delete makePayload[key]
      }

    })


    Object.entries(makePayload).map(([key, value]) => {
      formData.append(key, value);
    });

    formData.set('mobileNumber', mobileNumber === null ? '' : mobileNumber);
    formData.set('officeNumber', officeNumber === null ? '' : officeNumber);


    formData.append('primaryProducts', JSON.stringify(primaryProducts));
    formData.append('userId', id);

    if(formik.values.jobTitle === -1) {
      formData.set('jobTitle', formik.values.textTitle);
    }

    if(formik.values.purchaseChannel[0].name === 'Other') {
      formData.set('purchaseChannel' , formik.values.distributor)
    }else {
      formData.set('purchaseChannel' , formik.values.purchaseChannel[0].name)
    }

    if (formik.values.partnerType !== 1) {
      formData.set('size', '');
    }



    axios.patch(`${baseUrl}/users/registration/updateUserInfo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(() => {
      type === 1 && history.push('/qpartners/registration/list/1');
    }).catch(() => {
      notiFailed('Update failed !');
    });

    setIsOpenFormUpdate(false);

    setLoadingUpdateInfo(false);


  }

  async function changeMasterId() {
    setLoadingAssign(true);
    await axios.patch(`${baseUrl}/users/registration/changeMasterId`, {
      userId: id,
      subUserId: formik.values.masterId
    }).then(() => {
      history.push('/qpartners/registration/list/1');
    }).catch(() => {
      notiFailed('Failed assign MasterID');
    });

    setLoadingAssign(false);
  }

  function handleShowModalChangeMasterId() {
    if (formik.values.masterId === -1) {
      notiFailed('Please select a Master ID.');
      return;
    }

    setIsOpenChangeMaster(true);
  }

  const getSaleRegions = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/companies/salesregions/search`);
      setSaleRegions(data);
    } catch (err) {
      notiFailed(`Something happened getting sale regions`);
    }
  };

  const getListTitle = async () => {
    try {
      const {data} = await axios.get(`${baseUrl}/companies/register/job-titles`);
      setListTitle(data)
    }catch (e) {
      notiFailed('Something happened getting title')
    }
  }

  async function getListInstallationModule() {
    try {
      const { data } = await axios.get(`${baseUrl}/companies/register/installation-volumes`, {
        params : {
          productType : 1
        }
      });
      setListModule(data);
    } catch (err) {
      notiFailed(`Something happened getting sale regions`);
    }
  }

  async function getListInstallationEss() {
    try {
      const { data } = await axios.get(`${baseUrl}/companies/register/installation-volumes`, {
        params : {
          productType : 2
        }
      });
      setListEss(data);
    } catch (err) {
      notiFailed(`Something happened getting sale regions`);
    }
  }

  function handleValidate(type) {

    if((formik.values.distributor === '' || formik.values.distributor === null) && formik.values.purchaseChannel === -1) {
      setIsDistributor(true);
      return notiFailed('Distributor is required !')
    }else if(formik.values.companyName === '' || formik.values.companyName === null){
      return notiFailed('Company Name is required')
    }else if(formik.values.textNumber === '' || formik.values.textNumber === null){
      return notiFailed('Main Contact Number is required')
    }else if((formik.values.website === '' || formik.values.website === null) && !formik.values.isNotWebSite) {
      return notiFailed('URL is required')
    }else if((formik.values.website !== '' && !/((\w+:\/\/\S+)|(\w+[\.:]\w+\S+))[^\s,\.]/ig.test(formik.values.website)) && !formik.values.isNotWebSite) {
      return notiFailed('Please enter the url except for http://. ex) www.qpartner.com')
    }else if(!formik.values.isNoneEss && _.join(_.map(formik.values.primaryProductsEss, 'name'), '|').length === 0) {
      return notiFailed('Primary Products Ess is required')
    }else if(!formik.values.isNoneModule && _.join(_.map(formik.values.primaryProductsModule, 'name'), '|').length === 0) {
      return notiFailed('Primary Products Module is required')
    }else if(formik.values.partnerType === "") {
      return notiFailed('Main Business Type is required')
    }else if(formik.values.jobTitle === -1 && (formik.values.textTitle === "" || formik.values.textTitle === null)) {
      setIsTitle(true);
      return notiFailed('Title is required')
    }else if(formik.values.taxId === "" || formik.values.taxId === null){
      return notiFailed('TaxId is required')
    }else if(!/^[0-9.-]+$/.test(formik.values.taxId)) {
      return notiFailed('TaxId wrong is format')
    }else if(formik.values.address === '' || formik.values.address === null){
      return notiFailed('Address is required')
    }else if(formik.values.city === '' || formik.values.city === null) {
      return notiFailed('City is required')
    }else if(formik.values.state === '' || formik.values.state === null){
      return notiFailed('State is required')
    }else if(formik.values.zipCode === '' || formik.values.zipCode === null) {
      return notiFailed('ZipCode is required')
    }else if(isEss) {
      return notiFailed('Invalid installation volume value');
    }else if(isModule) {
      return notiFailed('Invalid installation volume value');
    }else if(formik.values.listSalesArea.length === 0) {
      return notiFailed('Sales region is required')
    }else if(formik.values.installationVolumeModule === '' || formik.values.installationVolumeModule === null){
      return notiFailed('Installation volume Module is required')
    }else if(formik.values.installationVolumeModule === 1 && (formik.values.textInstallationVolumeDataModule >= 250 || formik.values.textInstallationVolumeDataModule === null || formik.values.textInstallationVolumeDataModule === '' || formik.values.textInstallationVolumeDataModule < 0)) {
      setIsModule(true)
      return notiFailed('Invalid installation volume value module');
    }else if(formik.values.installationVolumeModule === 10 && (formik.values.textInstallationVolumeDataModule <= 20 || formik.values.textInstallationVolumeDataModule === null || formik.values.textInstallationVolumeDataModule === '')) {
      setIsModule(true)
      return notiFailed('Invalid installation volume value module');
    }else if(formik.values.installationVolumeEss === ''|| formik.values.installationVolumeEss === null){
      return notiFailed('Installation volume Ess is required')
    }else if(formik.values.installationVolumeEss === 11 && (formik.values.textInstallationVolumeDataEss >= 10 || formik.values.textInstallationVolumeDataEss === null|| formik.values.textInstallationVolumeDataEss === '' || formik.values.textInstallationVolumeDataEss < 0 )) {
      setIsEss(true)
      return notiFailed('Invalid installation volume value ess');
    }else if(formik.values.installationVolumeEss === 18 && (formik.values.textInstallationVolumeDataEss <= 100 || formik.values.textInstallationVolumeDataEss === null || formik.values.textInstallationVolumeDataEss === '')) {
      setIsEss(true);
      return notiFailed('Invalid installation volume value ess');
    }else if(formik.values.partnerType === 1 && (formik.values.size === '' || formik.values.size === null)) {
      return notiFailed('Company Size is required')
    }else if(formik.values.firstName === '' || formik.values.firstName === null) {
      return notiFailed('First Name is required')
    }else if(formik.values.lastName === '' || formik.values.lastName === null) {
      return notiFailed('Last Name is required')
    }else if(formik.values.w9 === '' || formik.values.w9 === null) {
      return notiFailed('W9 is required')
    }
    type === 2 && setIsOpenFormUpdate(true);

    return 1
  }

  function handleShowModalUpdateInfo() {


    return handleValidate(2);


  }

  const getUserDetail = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/users/registration/getUserDetail`, {
        params: {
          userId: id
        }
      });
      setUserDetail(data);
      setApprovedUser(data.approvalStep === 2);
      setNotes(data.notes);
    } catch (err) {
      notiFailed(`Something happened getting user detail`);
    }
  };

  const getSurvey = () => {
    let survey = '';
    switch(_.get(userDetail, 'survey')) {
      case 7:
        survey = 'Through event';
        break;
      case 2:
        survey = 'Through web ads';
        break;
      case 4:
        survey = 'Through other installers';
        break;
      case 6:
        survey = 'Through Q CELLS';
        break;
      case 1:
        survey = 'Through distribution';
        break;
      case 5:
        survey = 'Other';
        break;
      case 3:
        survey = 'Through social media'
        break;
      default:
        survey = 'Through event';
        break;
    }

    return `${survey}: ${_.get(userDetail, 'surveyText', '-')}`;
  };

  const handleApprove = async () => {

    if(handleValidate(1) !== 1) {
      return
    }

    try {
      setLoadingApprove(true);
      await handleUpdateInfo(2);
      await axios.patch(`${baseUrl}/users/registration/approve`, {
        userIds: [id],
        step: 2
      });
      history.push('/qpartners/registration/list/1');
    } catch (err) {
      notiFailed(
        `There was a problem approve user ${err.message}`
      );
    } finally {
      setLoadingApprove(false);
    }
  };

  const handleAddNewNote = async () => {
    if (_.trim(newNoteText) === '') {
      return notiFailed('Please enter note content.');
    }

    try {
      setLoadingAddNote(true);
      const { data } = await axios.post(`${baseUrl}/users/registration/addNote`, {
        content: newNoteText,
        companyId: userDetail.companyId
      });
      setNewNoteText('');
      setNotes([
        { ...data },
        ...notes
      ]);
    } catch (err) {
      notiFailed(
        `There was a problem add new note ${err.message}`
      );
    } finally {
      setLoadingAddNote(false);
    }
  };


  const handleDownloadW9Image = async (assetUrl) => {
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common['Authorization'];
      const { data } = await instance.get(assetUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', assetUrl.substr(assetUrl.lastIndexOf('/') + 1));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      notiFailed(`There was a problem download w9 image ${err.message}`);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNote(null);
  };

  const handleOpen = id => {
    setOpen(true);
    setSelectedNote(id);
  };

  const handleDelete = async id => {
    setLoadingDel(true);
    try {
      await axios.delete(`${baseUrl}/users/registration/deleteNote`, {
        params: { id }
      });
      setNotes(notes.filter(n => n.id !== id));
      handleClose();
    } catch (err) {
      notiFailed(`There was a deleting the selected note ${err.message}`);
    }

    setLoadingDel(false);
  };

  const editNote = (id, content) => {
    setSelectedEditNote(id);
    setChangeNote(content);
    setOriginalNote(content);
  };

  async function handleResetPassword() {
    if(formik.values.masterId === -1) {
      return notiFailed('MasterId is required')
    }

    setLoadingResetPassword(true);
    try {
      await axios.patch(`${baseUrl}/users/registration/resetPassword`, {
        userId: formik.values.masterId
      });

      notiSuccess(`Change and E-mail Sended.`);
    } catch (err) {
      notiFailed(`Change and E-mail Failed.`);
    }
    setLoadingResetPassword(false);

  }

  const handleEditNote = async () => {
    try {
      const { data } = await axios.patch(`${baseUrl}/users/registration/editNote`, {
        noteId: selectedEditNote,
        content: changeNote
      });
      setNotes(notes.map(n => {
        if (n.id === selectedEditNote) {
          return {
            ...data
          };
        }

        return n;
      }));
      setSelectedEditNote(null);
      setChangeNote('');
      setOriginalNote('');
      setOpenEditNoteModal(false);
      notiSuccess(`You have successfully edited the note.`);
    } catch (err) {
      notiFailed(`There was a problem editing the note.`);
    }
  };


  function handleSelectImage(event) {

    formik.setFieldValue('titleImage', event.target.value);

    if (event.target.value === '') {
      formik.setFieldValue('w9', null);
    } else {
      formik.setFieldValue('w9', event.target.files[0]);
    }

  }

  function handleChangeArea(value) {

    if (value === null) {
      formik.setFieldValue('salesArea', '');
      formik.setFieldValue('listSalesArea', []);

    } else {
      formik.setFieldValue('listSalesArea', [...value]);
      formik.setFieldValue('salesArea', value.toString().replaceAll(',', '|'));
    }

  }
  function handleChangePrimaryProductEss(value) {
    if (value === null) {
      formik.setFieldValue('primaryProductsEss', []);

    } else {
      formik.setFieldValue('primaryProductsEss', [...value]);
    }
  }

  function handleChangePrimaryProductModule(value) {
    if (value === null) {
      formik.setFieldValue('primaryProductsModule', []);

    } else {
      formik.setFieldValue('primaryProductsModule', [...value]);
    }
  }

  function handleChangeMainPurchasing(value) {
    if (value === null) {
      formik.setFieldValue('purchaseChannel', []);
    } else {
      formik.setFieldValue('purchaseChannel', [value]);
    }
  }


  async function getInfoListBrandModule() {
    try {
      const {data} = await axios.get(`${baseUrl}/companies/register/product-brands` , {
        params : {
          productType : 1
        }
      });
      setListBrandModule(data);
    }catch (e) {

    }
  }

  function handleChangeTextDataModule(event) {


    if(formik.values.installationVolumeModule === 1 && event.target.value >= 250) {
      setIsModule(true)
    }else if(formik.values.installationVolumeModule === 10 && event.target.value <= 20) {
      setIsModule(true)
    }else {
      setIsModule(false)
    }
    formik.setFieldValue('textInstallationVolumeDataModule', event.target.value)


  }

  function handleChangeTextDataEss(event) {
    if(formik.values.installationVolumeEss === 11 && event.target.value >= 10) {
      setIsEss(true)
    }else if(formik.values.installationVolumeEss === 18 && event.target.value <= 100) {
      setIsEss(true);
    }else {
      setIsEss(false)
    }
    formik.setFieldValue('textInstallationVolumeDataEss', event.target.value)

  }

  async function getInfoListBrandEss() {
    try {
      const {data} = await axios.get(`${baseUrl}/companies/register/product-brands` , {
        params : {
          productType : 2
        }
      });

      setListBrandEss(data);
    }catch (e) {

    }
  }

  useEffect(() => {
    getUserDetail();
    getMainPurchasingChannel();
    getSaleRegions();
    getListInstallationModule();
    getListInstallationEss();
    getListTitle();
    getInfoListBrandModule();
    getInfoListBrandEss();
  }, []);

  return (
      <form>
        <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 10 }}>
          <BackButton component={Link} to={`/qpartners/registration/list/${isApprovedUser ? '1' : '0'}`}>
            List
          </BackButton>
        </Grid>
        <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 10 }}>
          <Text size="h2" style={{ lineHeight: '0px' }}>
            Q.PARTNER
          </Text>
        </Grid>
        <Paper className={classes.container}>
          <Grid style={{ padding: '40px 30px 15px' }} container>
            <Text size="h3" style={{ lineHeight: '0px' }}>
              Basic Information
            </Text>
          </Grid>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>ID(E-mail)</TableCell>
                  <TableCell align='left' colSpan={3}>
                    <Grid container xs={12}>
                      <Text className={classes.emailText}>{_.get(userDetail, 'email', '')} (Master ID)</Text>
                      {isApprovedUser && (
                        <>
                          <Grid item xs={3}>
                            <TextField
                              InputProps={{
                                classes: {
                                  input: classes.textInput
                                }
                              }}
                              fullWidth
                              onChange={formik.handleChange}
                              select
                              value={formik.values.masterId}
                              size="small"
                              name="masterId"
                              variant="outlined"
                              defaultValue={-1}
                            >
                              <MenuItem value={-1}>Select</MenuItem>
                              {_.get(userDetail, 'subUsers', '[]').map((item, index) => {
                                return <MenuItem key={index} value={`${item.id}`}>{item.fullName} ({item.email})</MenuItem>;
                              })}

                            </TextField>
                          </Grid>
                          <Grid item sm={2}>
                            <Button
                              onClick={handleShowModalChangeMasterId}
                              style={{
                                minWidth: '100%',
                                height: '40px',
                                borderRadius: '5px',
                                marginLeft: '10px',
                                padding: '.5rem',
                                fontSize: '14px'
                              }}
                            >
                              Save changed master ID
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Name <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left' style={{ minWidth: '300px' }}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputProps={{
                            classes: {
                              input: classes.textInput
                            }
                          }}
                          id="firstName"
                          name="firstName"
                          placeholder={'First Name'}
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputProps={{
                            classes: {
                              input: classes.textInput
                            }
                          }}
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>


                  </TableCell>
                  <TableCell align='center' className={classes.tableHeader}>Receive newsletter</TableCell>
                  <TableCell align='left'>
                    <Grid container xs={12} className={classes.newLetterRadio}>
                      <Grid item>
                        <RadioGroup id="receptionYn" onChange={formik.handleChange} value={formik.values.receptionYn}
                                    row aria-label="receptionYn" name="receptionYn" defaultValue="Y">
                          <FormControlLabel classes={{label : classes.textInput}} value="Y" control={<Radio color="primary"/>} label="Agree"/>
                          <FormControlLabel classes={{label : classes.textInput}} value="N" control={<Radio color="primary"/>} label="Deny"/>
                        </RadioGroup>
                      </Grid>
                      <Grid item>
                        <Text className={classes.newLetterText}>(Updated data : {`${moment(_.get(userDetail, 'receptionDate')).format('YYYY-MM-DD HH:mm')}`})</Text>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Partner Company <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        }
                      }}
                      id="companyName"
                      name="companyName"
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align='center' className={classes.tableHeader}>Survey</TableCell>
                  <TableCell align='left'>
                    <Grid container xs={12} className={classes.newLetterRadio}>
                      {getSurvey()}
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Main Business Type <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        }
                      }}
                      select
                      id="partnerType"
                      name="partnerType"
                      value={formik.values.partnerType}
                      onChange={formik.handleChange}
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder="Please select"
                      defaultValue={-1}
                    >
                      <MenuItem value={1}>Contractor/EPC</MenuItem>
                      <MenuItem value={2}>Sales Agent</MenuItem>
                      <MenuItem value={3}>Distributor</MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell align='center' className={classes.tableHeader}>
                    Company Size <em className={classes.required}>*</em>
                  </TableCell>
                  <TableCell align='left'>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        }
                      }}
                      id="size"
                      name="size"
                      select
                      disabled={formik.values.partnerType !== 1}
                      style={{background : formik.values.partnerType !== 1 && '#8080804f', width: 300}}
                      onChange={formik.handleChange}
                      error={isCompanySize}
                      helperText={isCompanySize && 'Company Size is required'}
                      value={formik.values.size}
                      variant="outlined"
                      size="small"
                    >
                      <MenuItem value={'Small'}>Small</MenuItem>
                      <MenuItem value={'Medium'}>Medium</MenuItem>
                      <MenuItem value={'Large'}>Large</MenuItem>
                    </TextField>
                  </TableCell>


                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Main Contact Number <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <Grid container xs={12} className={classes.newLetterRadio}>
                      <Grid item>
                        <RadioGroup id="mobileType" name='mobileType' onChange={formik.handleChange} value={formik.values.mobileType}
                                    row aria-label="position"  defaultValue={'2'}>
                          <FormControlLabel classes={{label : classes.textInput}} value="1" control={<Radio color="primary"/>} label="Mobile Number"/>
                          <FormControlLabel classes={{label : classes.textInput}} value="2" control={<Radio color="primary"/>} label="Office Number"/>
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          InputProps={{
                            classes: {
                              input: classes.textInput
                            }
                          }}
                          id="textNumber"
                          name="textNumber"
                          type="number"
                          value={formik.values.textNumber}
                          onChange={formik.handleChange}
                          fullWidth
                          placeholder={"Numbers only (e.g., 9497774444)"}
                          size="small"
                          variant="outlined"
                        />

                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='center' className={classes.tableHeader}>Title <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <Grid container spacing={1}>
                      <Grid item md={7} xs={12}>
                        <TextField
                          InputProps={{
                            classes: {
                              input: classes.textInput
                            }
                          }}
                          select
                          id="jobTitle"
                          name="jobTitle"
                          value={formik.values.jobTitle}
                          onChange={formik.handleChange}
                          fullWidth
                          size="small"
                          variant="outlined"
                          placeholder="Please select"
                          defaultValue={-1}
                        >
                          {listTitle?.map(pc => (
                            <MenuItem key={pc.id} value={pc.name}>{pc.name}</MenuItem>
                          ))}
                          <MenuItem value={-1}>Other</MenuItem>
                        </TextField>
                      </Grid>
                      {
                        formik.values.jobTitle === -1 &&
                        <Grid item md={5} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            id="textTitle"
                            name="textTitle"
                            value={formik.values.textTitle}
                            onChange={formik.handleChange}
                            onBlur={(event) => {
                              if(event.target.value === '') {
                                setIsTitle(true)
                              }else {
                                setIsTitle(false)
                              }
                            }}
                            helperText={!isTitle ? '' : 'Please input title is required'}
                            error={isTitle}
                            fullWidth
                            placeholder={"title other"}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                      }
                    </Grid>


                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>URL <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        }
                      }}
                      style={{background : formik.values.isNotWebSite && '#8080803d'}}
                      disabled={formik.values.isNotWebSite}
                      id="website"
                      name="website"
                      value={formik.values.website}
                      onChange={formik.handleChange}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />

                    <div className="wrap-not-url">
                      <Checkbox
                        name="isNotWebSite"
                        onChange={formik.handleChange}
                        checked={formik.values.isNotWebSite}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      I don't have a website


                    </div>



                  </TableCell>
                  <TableCell align='center' className={classes.tableHeader}>Main Purchasing Channel <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <ReactSelect
                          isMulti={false}
                          isClearable={true}
                          id="purchaseChannel"
                          name="purchaseChannel"
                          onChange={handleChangeMainPurchasing}
                          onBlur={formik.setFieldTouched}
                          options={mainPurchasingChannel}
                          value={formik.values.purchaseChannel}
                          className={classes.multiSelect}
                          classNamePrefix="select"
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.name}
                        />
                      </Grid>
                      {
                        formik.values.purchaseChannel[0]?.name === 'Other' &&
                        <Grid item md={6} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            placeholder={'Distributor name'}
                            fullWidth
                            size="small"
                            variant="outlined"
                            onBlur={(event) => {
                              if (event.target.value === '') {
                                setIsDistributor(true)
                              }else {
                                setIsDistributor(false)
                              }
                            }}
                            name="distributor"
                            id="distributor"
                            error={isDistributor}
                            helperText={`${!isDistributor ? '' : 'Please type in the distributor name'}`}
                            value={formik.values.distributor}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                      }

                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Sales Region(s) <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <ReactSelect
                      isMulti
                      id="listSalesArea"
                      name="listSalesArea"
                      onChange={handleChangeArea}
                      options={saleRegions}
                      value={formik.values.listSalesArea}
                      className={classes.multiSelect}
                      classNamePrefix="select"
                      getOptionLabel={option => option.split(':')[1]}
                      getOptionValue={option => option}
                    />
                  </TableCell>
                  <TableCell align='center' className={classes.tableHeader}>Password</TableCell>
                  <TableCell align='left'>
                    <Button
                      onClick={handleResetPassword}
                      style={{
                        height: '40px',
                        borderRadius: '5px',
                        padding: '.5rem 1rem'
                      }}
                    >
                      {loadingResetPassword ? <CustomLoading size={18}/> : 'Reset password'}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Business Address <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            id="address"
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="Business address"
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            id="addressCont"
                            name="addressCont"
                            value={formik.values.addressCont}
                            onChange={formik.handleChange}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="Business address2"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            id="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="City"
                            name="city"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <select
                            id="state"
                            name="state"
                            className={classes.selectState}
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            placeholder="State"
                          >
                            {saleRegions.map(pc => {
                              const [id, value] = pc.split(':');
                              return (
                                <option value={value} key={id}>{value}</option>
                              )
                            })}
                          </select>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            id="zipCode"
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="Zip Code"
                            name="zipCode"
                          />
                        </Grid>
                      </Grid>
                    </>
                  </TableCell>
                  <TableCell align='center' className={classes.tableHeader}>Shipping Address</TableCell>
                  <TableCell align='left'>
                    <>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="Shipping address"
                            name="shippingAddress"
                            id="shippingAddress"
                            value={formik.values.shippingAddress}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="Shipping address2"
                            name="shippingAddress2"
                            id="shippingAddress2"
                            onChange={formik.handleChange}
                            value={formik.values.shippingAddress2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="City"
                            name="shippingCity"
                            id="shippingCity"
                            onChange={formik.handleChange}
                            value={formik.values.shippingCity}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <select
                            id="shippingState"
                            name="shippingState"
                            className={classes.selectState}
                            value={formik.values.shippingState}
                            onChange={formik.handleChange}
                            placeholder="shipping State"
                          >
                            {saleRegions.map(pc => {
                              const [id, value] = pc.split(':');
                              return (
                                <option value={value} key={id}>{value}</option>
                              )
                            })}
                          </select>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="Zip Code"
                            name="shippingZipCode"
                            id="shippingZipCode"
                            onChange={formik.handleChange}
                            value={formik.values.shippingZipCode}
                          />
                        </Grid>
                      </Grid>
                    </>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Tax ID <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        }
                      }}
                      fullWidth
                      size="small"
                      onBlur={(event) => {
                        if(!/^[0-9.-]+$/.test(event.target.value)) {
                          return notiFailed('TaxId wrong is format')
                        }
                      }}
                      variant="outlined"
                      value={formik.values.taxId}
                      onChange={formik.handleChange}
                      name="taxId"
                      id="taxId"

                    />
                  </TableCell>
                  <TableCell align='center' className={classes.tableHeader}>W-9 <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left'>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          InputProps={{
                            classes: {
                              input: classes.textInput
                            }
                          }}
                          fullWidth
                          name={'w9'}
                          disabled
                          variant="outlined"
                          size="small"
                          value={formik.values.titleImage}
                        />
                        <input
                          name={'contentImage'}
                          accept="image/*"
                          className={classes.w9ImageInput}
                          id="raised-button-file"
                          type="file"
                          onChange={handleSelectImage}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <label htmlFor="raised-button-file">
                          <Button
                            variant="raised"
                            component="span"
                            style={{
                              minWidth: '100%',
                              height: '40px',
                              borderRadius: '5px',
                              padding: '.5rem 1rem'
                            }}
                          >
                            Browse...
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={12}>
                        {
                          _.get(userDetail, 'w9') && (
                            <Grid item xs={12}>
                              <a className={classes.w9Download}
                                 onClick={() => handleDownloadW9Image(userDetail.w9.assetUrl)}>
                                {/* {userDetail.w9.assetUrl.substr(userDetail.w9.assetUrl.lastIndexOf('/') + 1)} */}
                              </a>
                            </Grid>
                          )
                        }

                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Module Installation Information <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left' colSpan={3}>
                    <div className={classes.wrapModule}>
                      <Grid item lg={2} md={2} xs={2}>
                        <span>Installation volume (kW/ annual) <em className={classes.required}>*</em></span>
                      </Grid>
                      <Grid style={{marginLeft: 27}} container spacing={2}>
                      <Grid item lg={3} md={3} xs={3}>
                        <TextField
                          InputProps={{
                            classes: {
                              input: classes.textInput
                            }
                          }}
                          select
                          fullWidth
                          id="installationVolumeModule"
                          name="installationVolumeModule"
                          value={formik.values.installationVolumeModule}
                          onChange={formik.handleChange}
                          size="small"
                          variant="outlined"
                          placeholder="Please select"
                          defaultValue={-1}
                        >
                          {listModule.map(pc => (
                            <MenuItem key={pc.id} value={pc.id}>{pc.name}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={5} md={5} xs={5}>
                        {(formik.values.installationVolumeModule === 1 || formik.values.installationVolumeModule === 10) &&
                        <TextField
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{formik.values.installationVolumeModule === 1 ? 'kW' : 'MW'}</InputAdornment>,
                            classes: {
                              input: classes.textInput
                            }
                          }}
                          fullWidth
                          type="number"
                          name={'textInstallationVolumeDataModule'}
                          id="textInstallationVolumeDataModule"
                          onBlur={(event) => {
                            if(formik.values.installationVolumeModule === 1 && (parseInt(event.target.value) >= 250 || event.target.value < 0 )) {
                              setIsModule(true)
                              return notiFailed('Invalid installation volume value');
                            }

                            if(formik.values.installationVolumeModule === 10 && event.target.value <= 20) {
                              setIsModule(true)
                              return notiFailed('Invalid installation volume value');
                            }
                            setIsModule(false)
                          }}
                          error={isModule}
                          helperText={isModule && 'Value has to be within the selected range'}
                          variant="outlined"
                          size="small"
                          onChange={handleChangeTextDataModule}
                          value={formik.values.textInstallationVolumeDataModule}
                        />}
                      </Grid>
                      </Grid>
                    </div>
                    <div className={classes.wrapProduct}>
                      <Grid item lg={2} md={2} xs={2}>
                        <span>Primary products in use <em className={classes.required}>*</em></span>
                      </Grid>

                      <div style={{width: 300}}>
                        <ReactSelect
                          isMulti
                          isDisabled={formik.values.isNoneModule}
                          id="primaryProductsModule"
                          name="primaryProductsModule"
                          onChange={handleChangePrimaryProductModule}
                          onBlur={formik.setFieldTouched}
                          options={listBrandModule}
                          value={formik.values.primaryProductsModule}
                          className={classes.multiSelect}
                          classNamePrefix="select"
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.name}
                        />
                      </div>
                      <div className="wrap-none">
                        <Checkbox
                          name="isNoneModule"
                          onChange={formik.handleChange}
                          checked={formik.values.isNoneModule}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <span>None</span>
                      </div>

                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Battery Installation Information <em className={classes.required}>*</em></TableCell>
                  <TableCell align='left' colSpan={3}>
                    <div className={classes.wrapModule}>
                      <Grid item lg={2} md={2} xs={2}>
                        <span>Installation volume (kW/ annual) <em className={classes.required}>*</em></span>
                      </Grid>
                      <Grid style={{marginLeft:27}} container spacing={2}>
                        <Grid item lg={3} md={3} xs={3}>
                          <TextField
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            select
                            id="installationVolumeEss"
                            name="installationVolumeEss"
                            value={formik.values.installationVolumeEss}
                            onChange={formik.handleChange}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder="Please select"
                            defaultValue={-1}
                          >
                            {listEss.map(pc => (
                              <MenuItem key={pc.id} value={pc.id}>{pc.name}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item lg={5} md={5} xs={5}>
                          {(formik.values.installationVolumeEss === 11 || formik.values.installationVolumeEss === 18 ) &&
                          <TextField
                            InputProps={{
                              endAdornment: <InputAdornment position="end">SETS</InputAdornment>,
                              classes: {
                                input: classes.textInput
                              }
                            }}
                            type={"number"}
                            id="textInstallationVolumeDataEss"
                            onBlur={(event) => {

                              if(formik.values.installationVolumeEss === 11 && (event.target.value >= 10 || event.target.value < 0)) {
                                setIsEss(true)
                                return notiFailed('Invalid installation volume value');
                              }

                              if(formik.values.installationVolumeEss === 18 && event.target.value <= 100) {
                                setIsEss(true);
                                return notiFailed('Invalid installation volume value');
                              }


                            }}
                            helperText={isEss && 'Value has to be within the selected range'}
                            error={isEss}
                            name="textInstallationVolumeDataEss"
                            value={formik.values.textInstallationVolumeDataEss}
                            onChange={handleChangeTextDataEss}
                            fullWidth
                            size="small"
                            variant="outlined"
                          /> }

                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.wrapProduct}>
                      <Grid item lg={2} md={2} xs={2}>
                        <span>Primary products in use <em className={classes.required}>*</em></span>
                      </Grid>

                      <div style={{width: 300}}>
                        <ReactSelect
                          isMulti
                          isDisabled={formik.values.isNoneEss}
                          id="primaryProductsEss"
                          name="primaryProductsEss"
                          onChange={handleChangePrimaryProductEss}
                          onBlur={formik.setTouched}
                          options={listBrandEss}
                          value={formik.values.primaryProductsEss}
                          className={classes.multiSelect}
                          classNamePrefix="select"
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.name}
                        />
                      </div>

                      <div className="wrap-none">
                        <Checkbox
                          name="isNoneEss"
                          onChange={formik.handleChange}
                          checked={formik.values.isNoneEss}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <span>None</span>
                      </div>

                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Overview of Company/Business</TableCell>
                  <TableCell align='left' colSpan={3}>
                    {_.get(userDetail, 'description', '-')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Status Change</TableCell>
                  <TableCell align='left'>
                    <Grid container xs={12} className={classes.newLetterRadio}>
                      <Grid item>
                        <RadioGroup id="disabled" onChange={formik.handleChange} value={formik.values.disabled} row
                                    aria-label="disabled" name="disabled">
                          <FormControlLabel classes={{ label : classes.textInput}}  value={'false'} control={<Radio color="primary"/>} label="In use"/>
                          <FormControlLabel classes={{label : classes.textInput }}  value={'true'} control={<Radio color="primary"/>} label="Disabled"/>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center' className={classes.tableHeader}>Notes</TableCell>
                  <TableCell align='left' colSpan={3}>
                    <TextareaAutosize
                      className={classes.textArea}
                      rowsMin={6}
                      value={newNoteText}
                      onChange={e => setNewNoteText(e.target.value)}
                    />
                    <Grid item sm={1}>
                      <Button
                        style={{
                          minWidth: '100%',
                          height: '40px',
                          borderRadius: '5px',
                          padding: '.5rem 1rem'
                        }}
                        onClick={handleAddNewNote}
                        disabled={loadingAddNote}
                      >
                        {loadingAddNote ? <CustomLoading size={18}/> : 'Enter'}
                      </Button>
                    </Grid>
                    <TableContainer component={Paper}>
                      <Table className={classes.noteTable}>
                        <TableHead>
                          <TableRow>
                            <TableCell align='left' className={classes.noteTableHeader} colSpan={5}>History</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='left'>Created Date</TableCell>
                            <TableCell align='left'>Updated Date</TableCell>
                            <TableCell align='left'>Created By</TableCell>
                            <TableCell align='left'>Note</TableCell>
                            <TableCell align='left' style={{ width: '30%' }}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notes?.length > 0 ? (
                            <>
                              {
                                notes?.map(n => (
                                  <TableRow>
                                    <TableCell align='left'>
                                      <Moment format="MM/DD/YYYY">{n.createdAt}</Moment>
                                    </TableCell>
                                    <TableCell align='left'>
                                      <Moment format="MM/DD/YYYY">{n.updatedAt}</Moment>
                                    </TableCell>
                                    <TableCell align='left'>{n.fullName}</TableCell>
                                    <TableCell align='left'>
                                      {selectedEditNote === n.id ? (
                                        <TextareaAutosize
                                          autoFocus
                                          className={classes.textArea}
                                          rowsMin={6}
                                          value={changeNote}
                                          onChange={e => setChangeNote(e.target.value)}
                                          onBlur={() => {
                                            if (originalNote !== changeNote) {
                                              setOpenEditNoteModal(true);
                                            } else {
                                              setSelectedEditNote(null);
                                              setChangeNote('');
                                              setOriginalNote('');
                                            }
                                          }}
                                        />
                                      ) : n.content}
                                    </TableCell>
                                    <TableCell align='right'>
                                      <IconButton
                                        size="small"
                                        style={{ marginRight: '20px' }}
                                      >
                                        {selectedEditNote === n.id ? (
                                          <DoneIcon/>
                                        ) : (
                                          <CreateOutlinedIcon onClick={() => editNote(n.id, n.content)}/>
                                        )}
                                      </IconButton>
                                      <IconButton
                                        onClick={() => handleOpen(n.id)}
                                        size="small"
                                      >
                                        <DeleteOutlineOutlinedIcon/>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                              }
                            </>
                          ) : (
                            <TableRow className={classes.emptyNoteList}>
                              <TableCell align='center' colSpan={5}>No registered note.</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid style={{ padding: '40px 30px 15px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }} container
                justify="center">
            <Grid item sm={1}>
              {isApprovedUser ?
                <Button
                  style={{
                    minWidth: '100%',
                    height: '40px',
                    borderRadius: '5px',
                    marginLeft: '10px',
                    padding: '.5rem 1rem'
                  }}
                  onClick={handleShowModalUpdateInfo}
                  disabled={loadingApprove}
                >
                  {loadingApprove ? <CustomLoading size={18}/> : 'Confirm'}
                </Button> :
                <div style={{display : 'flex'}}>
                <Button
                  style={{
                    minWidth: '100%',
                    height: '40px',
                    borderRadius: '5px',
                    marginLeft: '10px',
                    padding: '.5rem 1rem'
                  }}
                  onClick={handleShowModalUpdateInfo}
                  disabled={loadingApprove}
                >
                  {loadingApprove ? <CustomLoading size={18}/> : 'Confirm'}
                </Button>
                <Button
                  style={{
                    minWidth: '100%',
                    height: '40px',
                    borderRadius: '5px',
                    marginLeft: '10px',
                    padding: '.5rem 1rem'
                  }}
                  onClick={handleApprove}
                  disabled={loadingApprove}
                >
                  {loadingApprove ? <CustomLoading size={18}/> : 'Approve'}
                </Button></div>}
            </Grid>
          </Grid>
        </Paper>
        <AlertDeleteComponent
          title="Delete this note?"
          content="Once you do this, it can't be undone."
          handleClose={() => {
            handleClose();
          }}
          handleDelete={() => {
            handleDelete(selectedNote);
          }}
          open={open}
          loading={loadingDel}
        />
        <EditNoteModal
          open={openEditNoteModal}
          handleClose={() => {
            setOpenEditNoteModal(false);
            setSelectedEditNote(null);
            setChangeNote('');
            setOriginalNote('');
          }}
          handleEdit={handleEditNote}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={isOpenFormUpdate}
          onClose={() => setIsOpenFormUpdate(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={isOpenFormUpdate}>
            <Alert>
              <Grid container justify="flex-end">
                <IconButton
                  onClick={() => setIsOpenFormUpdate(false)}
                  className={classes.approveRejectModuleModalCloseBtn}
                >
                  <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }}/>
                </IconButton>
              </Grid>
              <Text className={classes.textChangeMasterId}>
                Do you want to update?
              </Text>
              <Grid
                container
                justify="center"
                spacing={2}
                style={{ marginBottom: '20px' }}
              >
                <Grid item>
                  <CustomButton color="secondary" onClick={() => setIsOpenFormUpdate(false)}>
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomButton color="primary" onClick={() => handleUpdateInfo(1)}>
                    {loadingUpdateInfo ? <CustomLoading size={18}/> : 'OK'}
                  </CustomButton>
                </Grid>
              </Grid>
            </Alert>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={isOpenChangeMaster}
          onClose={() => setIsOpenChangeMaster(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={isOpenChangeMaster}>
            <Alert>
              <Grid container justify="flex-end">
                <IconButton
                  onClick={() => setIsOpenChangeMaster(false)}
                  className={classes.approveRejectModuleModalCloseBtn}
                >
                  <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }}/>
                </IconButton>
              </Grid>
              <Text className={classes.textChangeMasterId}>
                Do you want to assign it as Master ID?
              </Text>
              <Grid
                container
                justify="center"
                spacing={2}
                style={{ marginBottom: '20px' }}
              >
                <Grid item>
                  <CustomButton color="secondary" onClick={() => setIsOpenChangeMaster(false)}>
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomButton color="primary" onClick={changeMasterId}>
                    {loadingAssign ? <CustomLoading size={18}/> : 'OK'}
                  </CustomButton>
                </Grid>
              </Grid>
            </Alert>
          </Fade>
        </Modal>
      </form>
  );
}
