import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import Button from '../../../../common/components/button';
// import { contactService } from '../../../services';
import { useUserAdminDispatch, getListAdmin, useUserAdminState } from '../../../../contexts/admin';
import {useHistory} from 'react-router-dom';

function SearchUserAdmin({type}) {

  const dispatch = useUserAdminDispatch();
  const { condition } = useUserAdminState();
  const history = useHistory();

  const [listCategories, setListCategories] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [typeUser, setTypeUser] = useState('email,fullName');
  const [category, setCategory] = useState('category');
  const [status, setStatus] = useState('status');
  const [account, setAccount] = useState('1');
  const [keyword, setKeyWord] = useState('');

  useEffect(() => {
  }, []);


  async function handleSubmitValue() {

    await getListAdmin(dispatch, {

      size: 10,
      page : 1,
      ...condition,
      searchKey : typeUser,
      searchVal : keyword,
    })
  }



  function handleChangeTypeAdmin(event) {
    const {value} = event.target;
    setTypeUser(value);
  }

  function handleChangeKeyWord(event) {
    const {value} = event.target;
    setKeyWord(value);
  }


  return (
    <Grid style={{ padding: '40px 30px 15px' }} container spacing={1} justify="flex-end">

      <Grid item sm={2} xs={12}>
        <TextField
          fullWidth
          select
          size="small"
          variant="outlined"
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}
          onChange={handleChangeTypeAdmin}
          value={typeUser}
          defaultValue={'email,fullName'}
        >
          <MenuItem key={'all'} value={'email,fullName'}>All</MenuItem>
          <MenuItem key={'name'} value={'fullName'}>Name</MenuItem>
          <MenuItem key={'account'} value={'email'}>ID</MenuItem>
        </TextField>
      </Grid>
      <Grid item sm={2} xs={12}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}
          value={keyword}
          onChange={handleChangeKeyWord}
        />
      </Grid>
      <Grid item sm={1}>
        <Button
          onClick={handleSubmitValue}
          style={{
            height: '40px',
            minWidth: '100%',
            borderRadius: '5px',
            marginRight: '10px',
            padding: '.5rem 1rem'
          }}
        >
          Search
        </Button>
      </Grid>
      <Grid item sm={1}>
        <Button
          onClick={() => history.push('/admincontrols/management/add')}
          style={{
            height: '40px',
            minWidth: '100%',
            borderRadius: '5px',
            marginRight: '10px',
            padding: '.5rem 1rem'
          }}
        >
          Register
        </Button>
      </Grid>

    </Grid>
  );
}

export default React.memo(SearchUserAdmin);
