import axios from './../utils/axios';
import config  from "../config";


function getListUserAdmin(condition) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/users/adminManagement/search`,
    params : condition
  }

  return axios(request);
}

function addUserAdmin(payload) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/users/adminManagement/register`,
    data : payload
  }

  return axios(request);
}

function checkEmailDuplicate(emailCheck) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/companies/register/check-email`,
    params: {
      email : emailCheck
    }
  }

  return axios(request)
}

function updateStatus(formData) {
  const request = {
    method : 'PATCH',
    url : `${config.baseUrl}/users/registration/updateStatus`,
    data : formData
  }

  return axios(request);
}


function getUserDetail(id) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/users/adminManagement/getUserDetail`,
    params : {
      userId : id
    }
  }

  return axios(request);
}

function deleteUser(id) {
  const request = {
    method : 'DELETE',
    url : `${config.baseUrl}/users/registration/delete`,
    data : {
      userIds : [id]
    }
  }

  return axios(request);
}

function updateUserAdmin(id, formUser, formBranch) {
  const request = {
    method : 'PATCH',
    url : `${config.baseUrl}/users/adminManagement/editUserDetail`,
    data : {
      userId : id,
      user : formUser,
      branch : formBranch
    }
  }

  return axios(request);
}

function resetPassword(id) {
  const request = {
    method : 'PATCH',
    url : `${config.baseUrl}/users/registration/resetPassword`,
    data : {
      userId : id
    }
  }

  return axios(request);
}

export {getListUserAdmin, addUserAdmin, checkEmailDuplicate, updateStatus, getUserDetail, deleteUser, updateUserAdmin, resetPassword};


