import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { ExcelRenderer } from 'react-excel-renderer';
import axios from '../../utils/axios';
import config from '../../config';
import Text from '../../common/components/text';
import TextButton from '../../common/components/button/TextButton';
import CustomButton from '../../common/components/button';
import { Link } from 'react-router-dom';
import Alert from './Alert';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Grid,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { notiFailed } from './../../utils/helpers';

import ExcelIcon from '../../icons/excel_icon.png';
import TrashIcon from '../../icons/trash_icon.png';
import UploadIcon from '../../icons/upload_icon.png';
import CustomLoading from './../../common/components/CustomLoading';
import { ExcelCustomComponent } from '../../utils/components';
import classNames from 'classnames';
import { castArray } from 'lodash-es';

// Hard code template
const articleCodeUploadTemplateDataSheet = [
  {
    articleCode: 12345678,
    productName: 'Q.PEAK DUO-G5',
    volume: 335,
    configurationType: 'modules'
  },
  {
    articleCode: 'SRWEIH1016001-U000',
    productName: 'Q.HOME+ ESS HYB-G1',
    volume: 6000,
    configurationType: 'ess'
  },
  {
    articleCode: 'SRWEIH1017001-U000',
    productName: 'Q.HOME+ ESS HYB-G1',
    volume: 7000,
    configurationType: 'ess'
  },
  {
    articleCode: 'SRWEIH1017601-U000',
    productName: 'Q.HOME+ ESS HYB-G1',
    volume: 7600,
    configurationType: 'ess'
  },
  {
    articleCode: 'SRWEIH1018601-U000',
    productName: 'Q.HOME+ ESS HYB-G1',
    volume: 8600,
    configurationType: 'ess'
  }
];

const { baseUrl } = config;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  header: {
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  submitButtons: {
    backgroundColor: '#002D56',
    color: '#ffffff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  uploadArticleCodeSuccess: {
    width: '32rem !important',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important'
    }
  },
  uploadArticleCodeDuplicateOrInvalid: {
    width: '57rem !important',
    [theme.breakpoints.down('xs')]: {
      width: '85% !important'
    }
  },
  resultArticleCodeUploadTitle: {
    fontSize: '2.5rem',
    marginTop: '20px',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem'
    }
  },
  resultArticleCodeUploadSubtitle: {
    fontSize: '20px',
    marginTop: 0,
    marginBottom: 0
  },
  resultArticleCodeUploadCloseBtn: {
    marginTop: '10px',
    marginRight: '-15px'
  },
  resultArticleCodeUploadSuccessItem: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  resultArticleCodeUploadSuccessBox: {
    backgroundColor: '#e5f3fb !important',
    height: '130px',
    width: '360px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  resultArticleCodeUploadBoxes: {
    margin: 'auto',
    textAlign: 'center',
    width: '75%',
    marginTop: '20px',
    "& .MuiPaper-root": {
      paddingTop: '25px'
    },
    [theme.breakpoints.down('xs')]: {
      "& .MuiGrid-item": {
        marginBottom: '10px'
      }
    }
  },
  resultArticleCodeUploadDownloadBtn: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '0.75rem 2rem !important'
  },
  resultResponseTitle: {
    marginTop: '20px',
    fontSize: '16px',
    textAlign: 'center'
  },
  resultResponseSubtitle: {
    width: '80%',
    margin: 'auto',
    textAlign: 'center',
    fontSize: '16px'
  }
}));

const StyledCircularProgress = withStyles(theme => ({
  root: {
    '&.MuiCircularProgress-colorPrimary': {
      color: '#FFFFFF'
    }
  }
}))(CircularProgress);

const UploaderInput = ({
  className,
  labelClassName,
  labelWithFilesClassName,
  style,
  labelStyle,
  labelWithFilesStyle,
  getFilesFromEvent,
  accept,
  multiple,
  disabled,
  content,
  withFilesContent,
  onFiles,
  files
}) => {
  return (
    <label className={labelClassName} style={labelStyle}>
      <p
        style={{
          textAlign: 'center',
          fontSize: '15px',
          color: '#000',
          fontWeight: 400,
          display: 'block'
        }}
      >
        <img src={UploadIcon} alt="" />{' '}
        <p
          style={{
            fontSize: '15px',
            color: '#8c8c8c',
            fontWeight: 400,
            display: 'block'
          }}
        >
          Drag and drop files or click to browse.
        </p>
      </p>

      <input
        className={className}
        style={style}
        type="file"
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        onChange={async e => {
          const target = e.target;
          const chosenFiles = await getFilesFromEvent(e);
          onFiles(chosenFiles);
          //@ts-ignore
          target.value = null;
        }}
      />
    </label>
  );
};

export default function ArticleCodeUpload() {
  const classes = useStyles();
  const [totalRows, setTotalRows] = useState(0);
  const [newArticleCodes, setNewArticleCodes] = useState([]);
  const [duplicateArticleCodes, setDuplicateArticleCodes] = useState([]);
  const [failed, setFailed] = useState([]);
  const [articleCodes, setArticleCodes] = useState({});
  const [modules, setModules] = useState({});
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [computingFiles, setComputingFiles] = useState(false);
  const [articleCodesDataSheet, setArticleCodesDataSheet] = useState([]);

  const getSetup = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/articlecodes/distinct`);
      setArticleCodesDataSheet(data.articleCodes);
      let articleCodeMap = {};
      data.articleCodes.forEach(c => {
        if (!articleCodeMap[c.code]) {
          articleCodeMap[c.code] = c.productName;
        }
      });
      let modulesMap = {};
      data.modules.forEach(c => {
        if (!modulesMap[(c.productName + c.configurationType).toLowerCase()]) {
          modulesMap[(c.productName + c.configurationType).toLowerCase()] = c.id;
        }
      });
      setArticleCodes(articleCodeMap);
      setModules(modulesMap);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSetup();
  }, []);

  const handleChangeStatus = (file, status) => {
    let newFiles = [...files];
    if (status === 'done') {
      newFiles = [...files, file];
    } else if (status === 'removed') {
      newFiles = files.filter(f => f.meta.id !== file.meta.id);
    }
    setFiles(newFiles);
  };

  const allhandleChangeStatus = fileObj => {
    const validateArticleCode = r => {
      const isContainsOnlyNumberOrLetterOrHyphen = value => {
        if (value.replace(/[^-]/g, '').length !== 1) {
          return false;
        }

        return /^[A-Za-z0-9-]+$/.test(value);
      };

      if (
        String(r[0]).trim().length === 8 &&
        !isNaN(r[2]) &&
        !isNaN(r[0]) &&
        r[3].toLowerCase() === 'modules'
      ) {
        return true;
      }

      if (
        String(r[0]).trim().length === 18 &&
        isContainsOnlyNumberOrLetterOrHyphen(String(r[0])) &&
        !isNaN(r[2]) &&
        r[3].toLowerCase() === 'ess'
      ) {
        return true;
      }

      return false;
    };

    return new Promise((resolve, reject) => {
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          const createNewArticleCodes = [];
          const createNewConfigurations = [];
          const duplicates = [];
          const failedUploads = [];
          const alreadyAdded = [];
          resp.rows
            .filter(e => {
              return e.length;
            })
            .forEach((r, idx) => {
              if (idx === 0) {
                return;
              }
              r[3] = String(r[3]).toLowerCase();
              if (!r[0] || !r[1] || !r[2] || !r[3]) {
                failedUploads.push({
                  code: r[0],
                  productName: r[1],
                  volume: r[2],
                  configurationType: r[3],
                  msg: 'invalid',
                  fileName: fileObj.name
                });
                return;
              } else if (!validateArticleCode(r)) {
                failedUploads.push({
                  code: r[0],
                  productName: r[1],
                  volume: r[2],
                  configurationType: r[3],
                  msg: 'invalid',
                  fileName: fileObj.name
                });
                return;
              }
              if (['ess', 'modules'].indexOf(r[3].toLowerCase()) === -1) {
                failedUploads.push({
                  code: r[0],
                  productName: r[1],
                  volume: r[2],
                  configurationType: r[3],
                  msg: 'invalid',
                  fileName: fileObj.name
                });
                return;
              }

              if (!modules[r[1].toLowerCase() + r[3].toLowerCase()]) {
                failedUploads.push({
                  code: r[0],
                  productName: r[1],
                  volume: r[2],
                  configurationType: r[3],
                  msg: 'invalid',
                  fileName: fileObj.name
                });
                return;
              }

              // we have an article code
              if (articleCodes[r[0]] || alreadyAdded.indexOf(r[0]) >= 0) {
                duplicates.push({
                  code: r[0],
                  productName: r[1],
                  volume: r[2],
                  configurationType: r[3],
                  msg: 'duplicate',
                  fileName: fileObj.name
                });
              } else {
                if (alreadyAdded.indexOf(r[0]) >= 0) {
                  return;
                }

                if (!modules[r[1].toLowerCase() + r[3].toLowerCase()]) {
                  failedUploads.push({
                    code: r[0],
                    productName: r[1],
                    volume: r[2],
                    configurationType: r[3],
                    msg: 'invalid',
                    fileName: fileObj.name
                  });
                  return;
                }
                // we have an article code
                if (articleCodes[r[0]] || alreadyAdded.indexOf(r[0]) >= 0) {
                  duplicates.push({
                    code: r[0],
                    productName: r[1],
                    volume: r[2],
                    configurationType: r[3],
                    msg: 'duplicate',
                    fileName: fileObj.name
                  });
                } else {
                  if (alreadyAdded.indexOf(r[0]) >= 0) {
                    return;
                  }
                  if (modules[(r[1] + r[3]).toLowerCase()]) {
                    createNewArticleCodes.push({
                      code: String(r[0]),
                      productName: r[1],
                      volume: parseInt(r[2]),
                      msg: 'uploaded',
                      fileName: fileObj.name
                    });
                    createNewConfigurations.push({
                      productName: r[1],
                      configurationType: r[3],
                      rate: 0
                    });
                    alreadyAdded.push(r[0]);
                  } else {
                    failedUploads.push({
                      code: r[0],
                      productName: r[1],
                      volume: r[2],
                      configurationType: r[3],
                      msg: 'invalid',
                      fileName: fileObj.name
                    });
                    return;
                  }
                }
              }
            });

          return resolve({
            createNewArticleCodes,
            createNewConfigurations,
            duplicates,
            failedUploads
          });
        }
      });
    });
  };

  const getArticleCodes = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/configurations`);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = async () => {
    setComputingFiles(true);
    setOpen(true);

    const allRenderExcelFilePromise = [];
    for (let i = 0; i < files.length; i++) {
      allRenderExcelFilePromise.push(allhandleChangeStatus(files[i].file));
    }

    const allExcelData = await Promise.all(allRenderExcelFilePromise);
    const newArticleCodesData = allExcelData[0].createNewArticleCodes;
    const newConfigurationsData = allExcelData[0].createNewConfigurations;
    const duplicateArticleCodesData = allExcelData[0].duplicates;
    const failedData = allExcelData[0].failedUploads;
    setNewArticleCodes(newArticleCodesData);
    setDuplicateArticleCodes(duplicateArticleCodesData);
    setFailed(failedData);
    setTotalRows(
      allExcelData[0].duplicates.length +
        allExcelData[0].failedUploads.length +
        allExcelData[0].createNewArticleCodes.length
    );
    try {
      setLoading(true);
      await axios.post(`${baseUrl}/articleCodes/batchUploads`, {
        configurations: newConfigurationsData,
        articleCodes: newArticleCodesData,
        total: newConfigurationsData.length,
        invalid: failedData.length
      });
      files.forEach(f => f.remove());
      setFiles([]);
      getSetup();
    } catch (err) {
      notiFailed(`There was an error uploading new article codes`);
    } finally {
      setComputingFiles(false);
      setLoading(false);
    }
  };

  const downloadFailedArticleCodeUploads = () => {
    setOpen(false);
    document
      .getElementById('failed-article-code-upload-template-data-sheet')
      .click();
  };

  const downloadArticleCodeDataSheet = () => {
    document
      .getElementById('articles-codes-data-sheet')
      .click();
  };

  const ResultResponse = () => {
    return (
      <>
        {duplicateArticleCodes.length > 0 && failed.length > 0 ? (
          <>
            <Text weight="bold" className={classes.resultResponseTitle}>Duplicate & Invalid Serial Numbers</Text>
            <Text className={classes.resultResponseSubtitle} color="secondaryDark">
              Some of the numbers you entered are not 14 or 18 digits long and a few are
              already registered.
            </Text>
            <Text className={classes.resultResponseSubtitle} color="secondaryDark">
              Please download the list to double check if you input the correct serial numbers.
            </Text>
          </>
        ) : duplicateArticleCodes.length > 0 ? (
          <>
            <Text weight="bold" className={classes.resultResponseTitle}>Duplicate Serial Numbers</Text>
            <Text className={classes.resultResponseSubtitle} color="secondaryDark">
              Some of the serial numbers you entered have already been registered.
            </Text>
          </>
        ) : failed.length > 0 ? (
          <>
            <Text weight="bold" className={classes.resultResponseTitle}>
              Some of your article codes are invalid. Please double-check below:
            </Text>
            <Text style={{textAlign: 'left'}} className={classes.resultResponseSubtitle} color="secondaryDark">
              - Article code has to be either '8-digit numbers (for modules)' or '18-digit letters/numbers' ('LLLLLLNNNNNNN-LNNN' for ESS).<br></br>
              - Product name must be added to the configuration list.<br></br>
              - All fields have to be filled out<br></br>
              - Configuration type must be either 'Modules' or 'ESS'
            </Text>
          </>
        ) : (
          false
        )}
      </>
    );
  };

  return (
    <>
      <Text size="h2" style={{ lineHeight: '0px' }}>
        Uploads
      </Text>
      <Grid container spacing={1} style={{ marginBottom: '16px' }}>
        <Grid item>
          <TextButton 
          onClick={downloadArticleCodeDataSheet}
          >Download Article Code List</TextButton>
          <ExcelCustomComponent
            filename={`articles-codes-data-sheet-${new Date()}.csv`}
            id="articles-codes-data-sheet"
            data={articleCodesDataSheet}
          />
        </Grid>
      </Grid>
      <Paper>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto'
            }}
            spacing={2}
          >
            <Grid
              item
              style={{
                borderBottom: 'solid',
                borderColor: '#00ADEF'
              }}
            >
              <Text color="primaryLight" size="h4">
                Article Code
              </Text>
            </Grid>
            <Grid
              item
              component={Link}
              to="/rewards/serialnumber-upload"
              style={{ textDecoration: 'none' }}
            >
              <Text size="h4" color="dark">
                Serial Number
              </Text>
            </Grid>
            <Grid
              item
              component={Link}
              to="/rewards/upload-list"
              style={{ textDecoration: 'none' }}
            >
              <Text size="h4" color="dark">
                Upload List
              </Text>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              maxWidth: '75vw',
              margin: '5px auto'
            }}
          >
            <Text size="h2" style={{ margin: 0 }} className="page_title">
              Article Code Upload
            </Text>

            <Grid item>
              <a style={{textDecoration: 'none'}} href={config.urlTemplateArticle}>
                <CustomButton color="secondary">
                  Template
                </CustomButton>
              </a>

              <ExcelCustomComponent
                filename={`article-code-upload-template${new Date()}`}
                id="article-code-upload-template-data-sheet"
                data={articleCodeUploadTemplateDataSheet}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              marginTop: '20px',
              marginBottom: '20px'
            }}
          >
            <Dropzone
              InputComponent={UploaderInput}
              onChangeStatus={handleChangeStatus}
              PreviewComponent={null}
              accept=".xls, .xlsx"
              maxFiles={1}
              multiple={false}
              styles={{
                dropzone: {
                  overflow: 'hidden',
                  borderRadius: 0,
                  border: '2px dashed #ACACAC',
                  padding: 0,
                  color: '#000000',
                  backgroundColor: '#f7f7f7',
                  height: '250px'
                },
                preview: {
                  margin: '10px'
                }
              }}
            />
            <div style={{ marginTop: '20px' }}>
              {files.length > 0 &&
                files.map(file => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={TrashIcon}
                      style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                      onClick={file.remove}
                      alt=""
                    />
                    <img
                      src={ExcelIcon}
                      alt="Excel Icon"
                      style={{
                        width: '30px',
                        height: '30px',
                        marginLeft: '8px',
                        marginRight: '5px'
                      }}
                    />
                    <p>{file.file.name}</p>
                  </div>
                ))}
            </div>

            {files.length === 0 ? (
              false
            ) : (
              <Grid
                container
                item
                justify="left"
                xs={12}
                style={{ paddingTop: '20px' }}
              >
                <CustomButton
                  disabled={files.length === 0 || loading}
                  color="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                  style={{ height: '50px' }}
                  className="AddSerialNumbers_SubmitButton"
                >
                  {loading ? <StyledCircularProgress size={16} /> : 'Submit'}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </div>
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div
            style={{
              height: '85vh',
              borderRadius: '6px',
              overflowY: 'auto',
              outline: 'none'
            }}
          >
            <Alert
              style={{ margin: '0 auto' }}
              className={classNames('',
              { [classes.uploadArticleCodeSuccess]: !computingFiles && failed.length === 0 && duplicateArticleCodes.length === 0,
                [classes.uploadArticleCodeDuplicateOrInvalid]: computingFiles || failed.length !== 0 || duplicateArticleCodes.length !== 0 })}
            >
              <Grid container justify="flex-end">
                <IconButton
                  className={classes.resultArticleCodeUploadCloseBtn}
                  onClick={() => setOpen(false)}
                >
                  <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
                </IconButton>
              </Grid>
              <Text className={classes.resultArticleCodeUploadTitle}>Article Code Uploads</Text>

              {computingFiles ? (
                <div className="wr_uploading">
                  <CustomLoading size={40} color="#00adef"></CustomLoading>
                </div>
              ) : (
                <React.Fragment>
                  <Text size="h3" className={classes.resultArticleCodeUploadSubtitle}>
                    Out of <span style={{ color: '#00ADEF' }}>{totalRows}</span> pcs
                    of products:
                  </Text>
                  {failed.length === 0 && duplicateArticleCodes.length === 0 ? (
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: '20px', paddingBottom: '20px' }}
                    >
                      <Grid item className={classes.resultArticleCodeUploadSuccessItem}>
                        <Paper className={classes.resultArticleCodeUploadSuccessBox} elevation={0}>
                          <Text
                            size="h1"
                            color="primaryLight"
                            weight="medium"
                            className="number1"
                          >
                            {newArticleCodes.length}
                          </Text>
                          <Text
                            weight="light"
                            color="primaryLight"
                            className="content1"
                          >
                            Were successfully uploaded
                          </Text>
                        </Paper>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        container
                        justify="space-around"
                        className={classes.resultArticleCodeUploadBoxes}
                      >
                        <Grid item>
                          <Paper className="box1" elevation={0}>
                            <Text
                              size="h3"
                              weight="bold"
                              color="primaryLight"
                              className="number1"
                            >
                              {newArticleCodes.length}
                            </Text>
                            <Text
                              weight="light"
                              color="primaryLight"
                              className="content1"
                            >
                              Were successfully uploaded
                            </Text>
                          </Paper>
                        </Grid>
                        <Grid item>
                          <Paper elevation={0} className="box1">
                            <Text size="h3" weight="bold" className="number1">
                              {duplicateArticleCodes.length}
                            </Text>
                            <Text weight="light" className="content1">
                              Are duplicates
                            </Text>
                          </Paper>
                        </Grid>
                        <Grid item>
                          <Paper elevation={0} className="box1">
                            <Text size="h3" weight="bold" className="number1">
                              {failed.length > 0 ? failed.length : 0}
                            </Text>
                            <Text weight="light" className="content1">
                              Are invalid
                            </Text>
                          </Paper>
                        </Grid>
                      </Grid>
                      <ResultResponse />
                      {duplicateArticleCodes.length > 0 && failed.length === 0 ? (
                        false
                      ) : (
                        <>
                          <CustomButton
                            color="secondary"
                            onClick={downloadFailedArticleCodeUploads}
                            className={classes.resultArticleCodeUploadDownloadBtn}
                          >
                            Download
                          </CustomButton>
                          <ExcelCustomComponent
                            filename={`failed-article-code-upload-template${new Date()}`}
                            id="failed-article-code-upload-template-data-sheet"
                            data={[...failed, ...duplicateArticleCodes, ...newArticleCodes]}
                          />
                        </>
                      )}
                    </>
                  )}
                </React.Fragment>
              )}
            </Alert>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
