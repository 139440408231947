import React, { useEffect, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import Text from '../../../../common/components/text';
import { Button, Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import BackButton from '../../../../common/components/button/BackButton';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../common/components/button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { newsService } from '../../../../services';
import { notiSuccess, notiFailed } from '../../../../utils/helpers';
import { useHistory } from 'react-router-dom';
import CustomLoading from '../../../../common/components/CustomLoading';

const config = {
  readonly: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  uploader: {
    insertImageAsBase64URI: true
  },
};

const useStyle = makeStyles(them => ({
  input: {
    display: 'none'
  },
  input1: {
    display: 'none'
  },
  input2: {
    display: 'none'
  },
  button: {
    height: 56,
    border: '1px solid gray',
    marginLeft: 10
  },
  buttonSubmit: {
    height: 32,
    width: 182,
    backgroundColor: '#00ADEF',
    border: 'none',
    color: 'white',
    borderRadius: 34
  },
  txtPoint : {
    color : 'red'
  }
}));


const schema = yup.object().shape({
  title: yup.string().required(),
  content: yup.string().required()
});

function FormAddNew() {

  const history = useHistory();
  const [linkImage, setLinkImage] = useState('');
  const [attackOne, setAttackOne] = useState('');
  const [attackTow, setAttackTow] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false
  });
  console.log(errors);

  useEffect(() => {
    if(errors?.title?.message && errors?.content?.message) {
      setError(true);
      notiFailed(`Title can't blank`)
    }else if(errors?.title?.message) {
      setError(true);
      notiFailed(`Title can't blank`)
    }else if(errors?.content?.message) {
      notiFailed(`Content can't blank`)
    }
  },[errors])

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('content', data.content);
    formData.append('video', data.video);
    // if(!/(ftp:\/\/|www\.|https?:\/\/){1}[a-zA-Z0-9u00a1-\uffff0-]{2,}\.[a-zA-Z0-9u00a1-\uffff0-]{2,}(\S*)/.test(data.video)) {
    //   notiFailed('Not in video URL format.');
    //   return;
    // }
    data.image.length !== 0 && formData.append('image', data.image[0]);
    data.attachment1.length !== 0 && formData.append('attachment1', data.attachment1[0]);
    data.attachment2.length !== 0 && formData.append('attachment2', data.attachment2[0]);
    setLoading(true);
    await newsService.createNew(formData).then(() => {
      notiSuccess('Registered successfully!');
      history.push('/admincontrols/news');
    }).catch(() => {
      notiFailed('Registered failed!');
    });
    setLoading(false);

  };

  const classes = useStyle();

  function pickImageContent(event) {
    setLinkImage(event.target.value);
  }

  function pickImageAttackTow(event) {
    setAttackTow(event.target.value);
  }

  function pickImageAttackOne(event) {
    setAttackOne(event.target.value);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          marginBottom: 10
        }}
      >
        <div>
          <BackButton component={Link} to="/admincontrols/news">
            List news
          </BackButton>
          <Text size="h2" style={{ lineHeight: '0px' }}>
            Notice
          </Text>
        </div>
      </Grid>
      <Paper>

        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Title
              <em className={classes.txtPoint}>*</em>
            </Text>
            <TextField
              inputRef={register}
              name="title"
              fullWidth
              variant="outlined"
              placeholder="Title"
              error={error}
              onChange={(event) => {
                const {value} = event.target;
                if(value !== "") {
                  setError(false);
                }
              }}
              helperText={errors.title?.message && <p style={{color : 'red'}}>{`Title can't blank`}</p>}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Content Image
            </Text>
            <div>
              <TextField
                name={'content_image'}
                disabled
                value={linkImage}
                variant="outlined"
                placeholder="Please choose file"
                onChange={e => {

                }}
              />
              <input
                onChange={pickImageContent}
                ref={register}
                name={'image'}
                accept="image/*"
                className={classes.input}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>

          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Video
            </Text>
            <TextField
              fullWidth
              inputRef={register}
              name="video"
              variant="outlined"
              placeholder="Video"
              onChange={e => {

              }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Content
              <em className={classes.txtPoint}>*</em>
              {' '}<span style={{color: '#000000', fontSize: '10px'}}>(When uploading images make sure image size is not greater than 3mb)</span>
            </Text>
            <JoditEditor
              ref={register}
              name={'content'}
              // value={descHtml}
              config={config}
              tabIndex={1}
              onChange={newContent => {
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Attachment1
            </Text>
            <div>
              <TextField
                value={attackOne}
                variant="outlined"
                disabled
                placeholder="Please choose file"
              />
              <input
                ref={register}
                name={'attachment1'}
                onChange={pickImageAttackOne}
                className={classes.input2}
                id="raised-button-file-2"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file-2">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>

          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text weight="medium" style={{ color: '#8c8c8c', marginBottom: '1px' }}>
              Attachment2
            </Text>
            <div>
              <TextField value={attackTow} disabled variant="outlined" placeholder="Please choose file" onChange={e => {
              }}/>
              <input
                name={'attachment2'}
                ref={register}
                onChange={pickImageAttackTow}
                className={classes.input2}
                id="raised-button-file-3"
                type="file"
              />
              <label htmlFor="raised-button-file-3">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>

          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              marginBottom: 10
            }}
          >
            {loading ?
              <CustomButton color="secondary">

                <CustomLoading color="#00adef"/>
              </CustomButton> :
              <input style={{cursor: 'pointer'}} className={classes.buttonSubmit} value="Confirm" type="submit"/>}
            <CustomButton onClick={() => history.push('/admincontrols/news')}
                          style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, marginTop: 5 }} color="gray">
              Cancel
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
}

export default FormAddNew;
