import React from 'react';
import { Grid } from '@material-ui/core';
import ListNew from './components/ListNew';
import CustomButton from '../../common/components/button';
import Text from '../../common/components/text';
import {useHistory} from 'react-router-dom';

function NewPage() {

  const history  = useHistory();

  function handleNavigateAddNew() {
    history.push('/admincontrols/news/add');
  }

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          marginBottom: 10
        }}
      >
        <div>
          <Text size="h2" style={{ lineHeight: '0px' }}>
            Notice
          </Text>
        </div>
      </Grid>
      <div style={{background: 'white', border: '1px solid #80808038'}}>

        <Grid container>
          <Grid
            style={{paddingRight: 10, marginTop: 35, marginBottom: 30}}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            xs={12} md={12} lg={12} sm={12}
          >
            <CustomButton onClick={handleNavigateAddNew} color="primary">
              Register
            </CustomButton>
          </Grid>

        </Grid>

      </div>
      <ListNew/>
    </>

  );
}

export default NewPage;
