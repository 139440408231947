import React, { useState, useEffect } from 'react';
import {
    TableContainer,
    Grid,
    Paper,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    makeStyles,
    Checkbox,
    MenuItem,
    Box,
    Tabs,
    Tab
} from '@material-ui/core';
import Text from '../../common/components/text';
import Button from '../../common/components/button';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import { baseUrl } from '../../config';
import axios from '../../utils/axios';
import { notiFailed, decodeStringHtml, notiSuccess } from './../../utils/helpers';
import Moment from 'react-moment';
import CustomLoading from './../../common/components/CustomLoading';
import { APPROVAL_STEP } from './../../utils/constants';
import _ from 'lodash';
import { ExcelCustomComponent } from '../../utils/components';
import { convertHTMLEntity } from '../../utils/helpers';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    tableHeaderCell: {
        color: '#9ca4ab',
        fontFamily: 'Actual',
        fontWeight: '400'
    },
    tableContainer: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        marginTop: '20px',
        borderRadius: 0,
        '& td': {
            fontFamily: 'Actual'
        },
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        }
    },
    container: {
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: 0,
        borderRadius: 0,
        boxShadow: 'none',
        marginBottom: '30px',
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    paginationContainer: {
        marginTop: '20px',
        marginBottom: '20px',
        '& .Mui-selected': {
            backgroundColor: '#00ADEF',
            color: '#fff'
        }
    },
    tableRow: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            cursor: 'pointer'
        }
    },
    selectCheckbox: {
        '& .Mui-checked .MuiSvgIcon-root': {
            fill: '#009ee3'
        }
    },
    tabs: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        '& .MuiTabs-root': {
            maxWidth: '75vw',
            margin: 'auto',
            padding: '0'
        },
        '& .MuiTab-root': {
            minWidth: '60px',
            borderBottom: '3px solid transparent'
        },
        '& .MuiTab-wrapper': {
            fontFamily: 'Actual',
            textTransform: 'none',
            fontSize: '18px',
            fontWeight: '400'
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: '#00ADEF',
            borderBottom: '3px solid #00ADEF'
        },
        '& span.MuiTabs-indicator': {
            backgroundColor: 'transparent'
        }
    },
    loadingIndicator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    noUserContainer: {
        width: '95%',
        margin: 'auto',
        textAlign: 'center',
        backgroundColor: '#e5f3fb',
        paddingTop: '50px',
        paddingBottom: '50px',
        marginTop: '50px',
        marginBottom: '50px'
    }
}));

export default function RegistrationList() {
    const history = useHistory();
    const {tab} = useParams();
    const classes = useStyles();
    const [selectedNewLetter, setSelectedNewLetter] = useState('all');
    const [searchBy, setSearchBy] = useState('searchUsers.email');
    const [searchVal, setSearchVal] = useState('');
    const [status, setStatus] = useState('A');
    const [statusApproved, setStatusApproved] = useState(true);
    const [users, setUsers] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [filters, setFilters] = useState({ size, steps: _.join(APPROVAL_STEP.PENDING, ',') , page: currentPage });
    const [loading, setLoading] = useState(false);
    const [loadingApproveDeleteUser, setLoadingApproveDeleteUser] = useState(false);
    const [loadingChangeStatusUser, setLoadingChangeStatusUser] = useState(false);
    const [countUsers, setCountUsers] = useState(0);
    const [countPage, setCountPage] = useState(1);
    const [registrationReportFileName, setRegistrationReportFileName] = useState('');
    const [registrationReportDataSheet, setRegistrationReportDataSheet] = useState([]);
    const [loadingExport, setLoadingExport] = useState(false);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        const newFilters = {
            ...filters,
            page: 1,
            searchKey: 'searchUsers.email',
            searchVal: ''
        };
        delete newFilters.newsLetter;
        if (newValue === 0) {
            newFilters.steps = _.join(APPROVAL_STEP.PENDING, ',');
        } else {
            newFilters.steps = _.join(APPROVAL_STEP.APPROVE, ',');
        }
        setFilters(newFilters);
        // setSelectedNewLetter('all');
        // setCurrentPage(1);
        // setSearchBy('searchUsers.email');
        // setSearchVal('');
        getUsers(newFilters);
    };

    const getUsers = async (filters = {}) => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${baseUrl}/users/registration/search`, { params: filters });
            setUsers(data.data);
            setCountUsers(data.total);
            const numberPage = Math.ceil(data.total / size);
            setCountPage(numberPage);
        } catch (err) {
            notiFailed(
                `There was a problem getting companies ${err.message}`
            );
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event, currentPage) => {
        setCurrentPage(currentPage);
        const newFilters = {
            ...filters,
            page: currentPage
        };
        setFilters(newFilters);
        getUsers(newFilters);
    };

    const handleSearch = () => {
        const newFilter = {
            ...filters,
            page: 1,
            searchKey: searchBy,
            searchVal: searchVal
        };
        if (selectedNewLetter !== 'all') {
            newFilter.newsLetter = selectedNewLetter;
        } else {
            delete newFilter.newsLetter;
        }
        setFilters(newFilter);
        setCurrentPage(1);
        getUsers(newFilter);
    };

    const handleCheck = (checked, id) => {
        setUsers(
            users.map(u => {
                if (u.id === id) {
                    return {
                        ...u,
                        checked
                    }
                }

                return u;
            })
        )
    };

    const handleApproveDeleteUsers = async () => {
        const ids = users.filter(u => u.checked).map(u => u.id);

        if (ids.length === 0) {
            return notiFailed('Please select a target to change.');
        }

        setLoadingApproveDeleteUser(true);
        if (status === 'A') {
            try {
                await axios.patch(`${baseUrl}/users/registration/approve`, {
                    userIds: ids,
                    step: 2
                });
                notiSuccess('Approve successfully');
                getUsers(filters);
            } catch (err) {
                notiFailed(
                    `There was a problem approve user ${err.message}`
                );
            } finally {
                setLoadingApproveDeleteUser(false);
            }
        } else {
            try {
                await axios.delete(`${baseUrl}/users/registration/delete`, {
                    data: {
                        userIds: ids
                    }
                });
                notiSuccess('Delete successfully')
                getUsers(filters);
            } catch (err) {
                notiFailed(
                    `There was a problem delete user ${err.message}`
                );
            } finally {
                setLoadingApproveDeleteUser(false);
            }
        }
    };

    const handleChangeStatusUsers = async () => {
        const ids = users.filter(u => u.checked).map(u => u.id);

        if (ids.length === 0) {
            return notiFailed('Please select a target to change.');
        }

        try {
            setLoadingChangeStatusUser(true);
            await axios.patch(`${baseUrl}/users/registration/updateStatus`, {
                userIds: ids,
                disable: statusApproved
            });
            getUsers(filters);
        } catch (err) {
            notiFailed(
                `There was a problem when update status ${err.message}`
            );
        } finally {
            setLoadingChangeStatusUser(false);
        }
    };

    const exportReport = async () => {
        setLoadingExport(true);
        try {
            const { data } = await axios.get(`${baseUrl}/users/registration/search`, {
              params: {
                  steps: tabValue === 0 ? _.join(APPROVAL_STEP.PENDING, ',') :
                    _.join(APPROVAL_STEP.APPROVE, ',')
              }
            });

            let registrations = [];
            await data.data.forEach((r, index) => {
              registrations.push({
                Num: index + 1,
                'QP User ID': r.id,
                Name: r.fullName,
                'E-mail': r.email,
                'Company ID': r.companyId,
                Company: r.companyName ? decodeStringHtml(r.companyName) : '',
                'Mobile Number': r.mobileNumber,
                'Office Number': r.officeNumber,
                City: r.city,
                Newsletter: r.receptionYn,
                'Approved Date': r.approvalDate,
                'Main Purchasing Channel': r.purchaseChannel,
                'Account Status': r.disabled ? 'Disabled' : 'In Use',
                'Approval Status': r.approvalDate ? 'Approved' : 'Pending Approval'
              });
            });

            setRegistrationReportFileName(
                tabValue === 0 ? `Pending_Q.PARTNER_List_${new Date()}`
                    : `Q.PARTNER_List_${new Date()}`
            )
            setRegistrationReportDataSheet(registrations);
            setLoadingExport(false);
            document.getElementById('registration-report-data-sheet').click();
        } catch (error) {
            setLoadingExport(false);
            notiFailed(`There was a problem getting registration.`);
        }
    };

    function handleCheckNewRecord(registrationDate, start_date, end_date) {
        const compareDate = moment(registrationDate, "DD/MM/YYYY");
        const startDate = moment(start_date, 'DD/MM/YYYY');
        const endDate = moment(end_date, 'DD/MM/YYYY');

        return moment(registrationDate).isAfter(start_date);
    }

    useEffect(() => {
        let condition = {};
        if(tab === '1') {
            condition = {
                ...filters,
                steps : 2
            }
            setFilters(prevState => ({
                ...prevState,
                steps : 2
            }))
        }else {
            condition = {
                ...filters
            }

        }
        setTabValue(parseInt(tab))
        getUsers(condition);
    }, [tab]);

    return (
        <>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{
                    marginBottom: 10
                }}
            >
                <Text size="h2" style={{ lineHeight: '0px' }}>
                    Q.PARTNER
                </Text>
            </Grid>

            <Paper className={classes.container}>
                <Paper className={classes.tabs} square>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                    >
                        <Tab label="Pending Approval" />
                        <Tab label="Approved" />
                    </Tabs>
                </Paper>
                <Grid className={"wrap-search"} alignItems="center" style={{ padding: '40px 30px 15px' }} container spacing={1}>
                    <Grid sm={1} xs={12}>
                        <Text size="p" style={{ lineHeight: '0px' }}>Newsletter :</Text>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            variant="outlined"
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                }
                            }}
                            value={selectedNewLetter}
                            onChange={e => setSelectedNewLetter(e.target.value)}
                        >
                            <MenuItem key={'all'} value={'all'}>All</MenuItem>
                            <MenuItem key={'Y'} value={'Y'}>Agree with newsletter</MenuItem>
                            <MenuItem key={'N'} value={'N'}>Disagree with newsletter</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={1} xs={12}>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            variant="outlined"
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                }
                            }}
                            value={searchBy}
                            onChange={e => setSearchBy(e.target.value)}
                        >
                            <MenuItem key={'searchUsers.email'} value={'searchUsers.email'}>ID</MenuItem>
                            <MenuItem key={'searchUsers.fullName'} value={'searchUsers.fullName'}>Name</MenuItem>
                            <MenuItem key={'companies.name'} value={'companies.name'}>Company</MenuItem>
                            <MenuItem key={'branches.city'} value={'branches.city'}>City</MenuItem>
                            <MenuItem key={'companies.purchaseChannel'} value={'companies.purchaseChannel'}>Main Purchasing Channel</MenuItem>
                            <MenuItem key={'searchUsers.disabled'} value={'searchUsers.disabled'}>Account Status</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                }
                            }}
                            value={searchVal}
                            onChange={e => setSearchVal(e.target.value)}
                            onKeyDown={(ev) => { if (ev.key == 'Enter') {handleSearch()}}}
                        />
                    </Grid>
                    <Grid item sm={1}>
                        <Button
                            style={{
                                height: '40px',
                                minWidth: '100%',
                                borderRadius: '5px',
                                marginRight: '10px',
                                padding: '.5rem 1rem'
                            }}
                            onClick={handleSearch}
                        >

                            Search
                        </Button>
                    </Grid>
                    {tabValue === 1 && (
                        <Grid item sm={2}>
                            <Button
                                onClick={() => history.push('/qpartners/registration/survey/admin')}
                                style={{
                                    height: '40px',
                                    minWidth: '100%',
                                    borderRadius: '5px',
                                    marginRight: '10px',
                                    padding: '.5rem .75rem'
                                }}
                            >
                                Survey answer
                            </Button>
                        </Grid>
                    )}
                    <Grid item sm={2}>
                        <Button
                            style={{
                                height: '40px',
                                minWidth: '100%',
                                borderRadius: '5px',
                                padding: '.5rem 1rem'
                            }}
                            color="secondary"
                            onClick={exportReport}
                        >
                            {loadingExport ? <CustomLoading size={18} color="#00adef"/> : 'Download as Excel'}
                        </Button>
                        <ExcelCustomComponent
                            filename={registrationReportFileName}
                            id="registration-report-data-sheet"
                            data={registrationReportDataSheet}
                        />
                    </Grid>
                </Grid>

                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeaderCell} align='center'>No.</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>New</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>Account Status</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>Name</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>ID(E-mail)</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>Company</TableCell>
                                {/* <TableCell className={classes.tableHeaderCell} align='center'>Main Contact Number</TableCell> */}
                                <TableCell className={classes.tableHeaderCell} align='center'>Registration Date</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>Update Date</TableCell>
                                {tabValue === 1 && (
                                    <>
                                        <TableCell className={classes.tableHeaderCell} align='center'>Status</TableCell>
                                        <TableCell className={classes.tableHeaderCell} align='center'>Approved Date</TableCell>
                                    </>
                                )}
                                <TableCell className={classes.tableHeaderCell} align='center'>Notes</TableCell>
                                <TableCell className={classes.tableHeaderCell} align='center'>Select</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan="12">
                                        <div className={classes.loadingIndicator}>
                                            <CustomLoading size={40} color="#00adef"></CustomLoading>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {
                                        countUsers === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan="12">
                                                    <div className={classes.noUserContainer}>
                                                        <Text color="primaryLight" weight="bold">
                                                            No users.
                                                        </Text>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : users.map((u, idx) => (
                                            <TableRow
                                                 key={u.id}
                                                 className={classes.tableRow}
                                                 onClick={() => history.push(`/qpartners/registration/${u.id}`)}
                                             >
                                                 <TableCell align='center'>{countUsers - (currentPage - 1) * size - idx}</TableCell>
                                                 <TableCell align='center'>{u?.isNew ? 'Y' : 'N'}</TableCell>
                                                 <TableCell align='center'>{u?.disabled ? 'Disabled' : 'In Use'}</TableCell>
                                                 <TableCell align='center'>{u.firstName} {u.lastName}</TableCell>
                                                 <TableCell align='center'>{u.email}</TableCell>
                                                 <TableCell align='center'>{decodeStringHtml(u.companyName)}</TableCell>
                                                 {/* <TableCell align='center'>{u.officeNumber ? u.officeNumber : u.mobileNumber}</TableCell> */}
                                                 <TableCell align='center'>
                                                     <Moment format="MM/DD/YYYY">{u.createdAt}</Moment>
                                                 </TableCell>
                                                <TableCell align='center'>
                                                    <Moment format="MM/DD/YYYY">{u.updatedAt}</Moment>
                                                </TableCell>
                                                 {tabValue === 1 && (
                                                     <>
                                                         <TableCell align='center'>{u.disabled ? 'Disabled' : 'In Use'}</TableCell>
                                                         <TableCell align='center'>
                                                             {u.approvalDate ? (
                                                                 <Moment format="MM/DD/YYYY">
                                                                     {u.approvalDate}
                                                                 </Moment>
                                                             ) : ''}
                                                         </TableCell>
                                                     </>
                                                 )}
                                                 <TableCell align='center'>{!!u.lastUpdate && moment(u.lastUpdate).format('MM/DD/YYYY')}</TableCell>
                                                 <TableCell align='center'>
                                                     <IconButton size="small" className={classes.selectCheckbox} onClick={e => e.stopPropagation()}>
                                                         <Checkbox
                                                             color="primary"
                                                             checked={u.checked}
                                                             onChange={e => handleCheck(e.target.checked, u.id)}
                                                         />
                                                     </IconButton>
                                                 </TableCell>
                                             </TableRow>
                                        ))
                                    }
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid className={'container-bottom-registration'} style={{  borderBottom: '1px solid rgba(224, 224, 224, 1)' }} container>
                    <Grid item sm={12}>
                        {tabValue === 0 ? (
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Text size="p" style={{ lineHeight: '37px', margin: 0, marginRight: '10px' }}>*</Text>
                                    <TextField
                                        select
                                        size="small"
                                        variant="outlined"
                                        SelectProps={{
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                },
                                                getContentAnchorEl: null
                                            }
                                        }}
                                        value={status}
                                        onChange={e => setStatus(e.target.value)}
                                    >
                                        <MenuItem key={'A'} value={'A'}>Approve</MenuItem>
                                        <MenuItem key={'D'} value={'D'}>Delete</MenuItem>
                                    </TextField>
                                    <Text className={"text-bottom-register"} size="p" style={{ lineHeight: '37px', margin: 0, marginLeft: '10px', marginRight: '10px' }}>the selected items</Text>
                                <Grid item sm={1}>
                                    <Button
                                        style={{
                                            minWidth: '100%',
                                            height: '40px',
                                            borderRadius: '5px',
                                            marginRight: '10px',
                                            padding: '.5rem 1rem'
                                        }}
                                        onClick={handleApproveDeleteUsers}
                                        disabled={loadingApproveDeleteUser}
                                    >
                                        { loadingApproveDeleteUser ? <CustomLoading size={18}></CustomLoading> : 'Confirm' }
                                    </Button>
                                </Grid>
                            </Box>
                        ) : (
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Text size="p" style={{ lineHeight: '37px', margin: 0, marginRight: '10px' }}>* Changes the status of the selected item to</Text>
                                    <TextField
                                        select
                                        size="small"
                                        variant="outlined"
                                        SelectProps={{
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                },
                                                getContentAnchorEl: null
                                            }
                                        }}
                                        value={statusApproved}
                                        onChange={e => setStatusApproved(e.target.value)}
                                    >
                                        <MenuItem value={true}>Disabled</MenuItem>
                                        <MenuItem value={false}>In use</MenuItem>
                                    </TextField>
                                <Grid item sm={1}>
                                    <Button
                                        style={{
                                            minWidth: '100%',
                                            height: '40px',
                                            borderRadius: '5px',
                                            marginLeft: '10px',
                                            padding: '.5rem 1rem'
                                        }}
                                        onClick={handleChangeStatusUsers}
                                        disabled={loadingChangeStatusUser}
                                    >
                                        { loadingChangeStatusUser ? <CustomLoading size={18}></CustomLoading> : 'Confirm' }
                                    </Button>
                                </Grid>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Paper>

            {!loading && countPage > 1 && (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.paginationContainer}
                >
                    <Pagination count={countPage} page={currentPage} onChange={handleChangePage} />
                </Grid>
            )}
        </>
    )
}
