import React, { useEffect, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import Text from '../../../../common/components/text';
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import BackButton from '../../../../common/components/button/BackButton';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../common/components/button';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { academyService, newsService } from '../../../../services';
import { notiSuccess, notiFailed } from '../../../../utils/helpers';
import { useHistory, useParams } from 'react-router-dom';
import CustomLoading from '../../../../common/components/CustomLoading';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../../../../utils/axios';

const config = {
  readonly: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false
};

const useStyle = makeStyles(them => ({
  input: {
    display: 'none'
  },
  input1: {
    display: 'none'
  },
  input2: {
    display: 'none'
  },
  button: {
    height: 56,
    border: '1px solid gray',
    marginLeft: 10
  },
  buttonSubmit: {
    height: 32,
    width: 182,
    backgroundColor: '#00ADEF',
    border: 'none',
    color: 'white',
    borderRadius: 34
  },
  txtPoint: {
    color: 'red'
  },
  selectProduct: {
    width: 300
  },
  uploadedContentImage: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '10px'
  },
  uploadedContentImageDownload: {
    marginTop: 0,
    marginBottom: 0,
    boxSizing: 'border-box',
    height: '26px',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: '1px solid'
    }
  },
}));


const schema = yup.object().shape({

});

function FormEditProduct() {

  const history = useHistory();
  const {id} = useParams()
  const [linkImage, setLinkImage] = useState('');
  const [attackOne, setAttackOne] = useState('');
  const [attackTow, setAttackTow] = useState('');
  const [linkThumb, setLinkThumb] = useState('');
  const [type, setType] = useState('2');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [product, setProduct] = useState({});
  const [academyType, setAcademyType] = useState([]);
  const { register, handleSubmit, watch, errors, control, setValue, formState, getValues } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false
  });

  useEffect(() => {
    getListTypeOnline();
    getDetailProduct();
  }, []);

  async function getDetailProduct() {
    const infoProduct = await academyService.getDetailProduct(id);

    if(infoProduct && infoProduct.data) {
      setProduct(infoProduct.data);
      setType(infoProduct?.data?.academyTypeId);
      setValue('title', infoProduct.data?.title);
      setValue('video', infoProduct.data?.video);
      setLinkThumb(infoProduct.data?.thumbnail?.assetUrl);
      setLinkImage(infoProduct.data?.image?.assetUrl);
      setAttackOne(infoProduct.data?.attachment1?.assetUrl);
      setAttackTow(infoProduct.data?.attachment2?.assetUrl);
    }
  }


  async function getListTypeOnline() {
    const types = await academyService.getListTypeOnline({ categoryId: 1 });
    setAcademyType(types.data);
  }

  const onSubmit = async data => {
    const formData = new FormData();
    if (!linkThumb && !data.title && !data.content) {
      notiFailed(`Thumbnail Image can't blank`);
      return;
    } else if (!linkThumb && !data.title) {
      notiFailed(`Thumbnail Image can't blank`);
      return;
    } else if (!linkThumb && !data.content) {
      notiFailed(`Thumbnail Image can't blank`);
      return;
    } else {
      if (!data.title && !data.content) {
        setError(true);
        notiFailed(`Title can't blank`);
        return;
      } else if (!data?.title) {
        setError(true);
        notiFailed(`Title can't blank`);
        return;
      } else if (!data?.content) {
        notiFailed(`Content can't blank`);
        return;
      }
    }
    formData.append('id', id);
    formData.append('categoryId', 1);
    formData.append('academyTypeId', type);
    formData.append('title', data.title);
    formData.append('content', data.content);
    formData.append('video', data.video);
    data.thumbnail.length !== 0 && formData.append('thumbnail', data.thumbnail[0]);

    if(data.image.length !== 0) {
      formData.append('image', data.image[0])
    }else if (!linkImage){
      formData.append('image', 'delete')
    }

    if(data.attachment1.length !== 0) {
      formData.append('attachment1', data.attachment1[0])
    }else if (!attackOne){
      formData.append('attachment1', 'delete')
    }

    if(data.attachment2.length !== 0) {
      formData.append('attachment2', data.attachment2[0])
    }else if (!attackTow){
      formData.append('attachment2', 'delete')
    }
    setLoading(true);


    academyService.updateProduct(formData).then(() => {
      setLoading(false);
      notiSuccess('Updated successfully!');
      history.push('/admincontrols/configurations/list');
    }).catch(() => {
      setLoading(false);
      notiFailed('Failed Updated!');
    });

  };

  const classes = useStyle();

  function pickImageContent(event) {
    const { files } = event.target;
    setLinkImage(files[0].name);
  }

  function pickImageThumbNail(event) {
    const { files } = event.target;
    setLinkThumb(files[0].name);
    setValue('thumbnailContent', files[0].name);
  }

  function pickImageAttackTow(event) {
    const { files } = event.target;
    setAttackTow(files[0].name);
  }

  function pickImageAttackOne(event) {
    const { files } = event.target;
    setAttackOne(files[0].name);
  }

  async function handleDownloadUploadContentImage(linkImage) {
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common["Authorization"];
      const { data } = await instance.get(linkImage, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', linkImage.substr(linkImage.lastIndexOf('/') + 1));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      notiFailed(`There was a problem download upload content image ${err.message}`);
    }
  }

  function handleDeleteImage() {
    setProduct(prevState => ({
      ...prevState,
      image : {
        assetUrl : ''
      }
    }))
    setLinkImage('');
    setValue('image', null);
    notiSuccess('File delete.');
  }

  function handleDeleteImageAttackOne() {
    setProduct(prevState => ({
      ...prevState,
      attachment1 : {
        assetUrl : ''
      }
    }))
    setAttackOne('');
    setValue('attachment1', null);
    notiSuccess('File delete.');
  }

  function handleDeleteImageAttackTow() {
    setProduct(prevState => ({
      ...prevState,
      attachment2 : {
        assetUrl : ''
      }
    }))
    setAttackTow('');
    setValue('attachment2', null);
    notiSuccess('File delete.');
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          marginBottom: 10
        }}
      >
        <div>
          <BackButton component={Link} to="/admincontrols/configurations/list">
            List online
          </BackButton>
          <Text size="h2" style={{ lineHeight: '0px' }}>
            Academy
          </Text>
        </div>
      </Grid>
      <Paper>

        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Type
              <em className={classes.txtPoint}>*</em>
            </Text>
            <TextField
              name="academyId"
              select
              className={classes.selectProduct}
              onChange={(e) => setType(e.target.value)}
              margin="normal"
              value={type}
              variant="outlined"
              defaultValue={'1'}
            >
              {academyType?.map((item, index) => {
                return <MenuItem key={index} value={`${item.id}`}>{item.name}</MenuItem>;

              })}
            </TextField>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Thumbnail Image
              <em className={classes.txtPoint}>*</em>
            </Text>
            <div>
              <TextField
                name={'thumbnailContent'}
                disabled
                inputRef={register}
                value={linkThumb}
                variant="outlined"
                placeholder="Please choose file"
              />

              <input
                onChange={pickImageThumbNail}
                ref={register}
                name={'thumbnail'}
                accept="image/*"
                className={classes.input}
                id="raised-button-file-thumbnail"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file-thumbnail">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

              <em style={{ marginLeft: 5 }}>Image size : <em style={{ color: '#ed7100' }}>285 x 280</em> px</em>
            </div>
            {!!product?.thumbnail?.assetUrl && (
              <div className={classes.uploadedContentImage}>
                <p
                  onClick={() => handleDownloadUploadContentImage(product.thumbnail?.assetUrl)}
                  className={classes.uploadedContentImageDownload}
                >
                  {product?.thumbnail?.assetUrl.substr(product?.thumbnail?.assetUrl.lastIndexOf('/') + 1)}
                </p>
              </div>
            )}


          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Title
              <em className={classes.txtPoint}>*</em>
            </Text>
            <TextField
              inputRef={register}
              name="title"
              fullWidth
              variant="outlined"
              placeholder="Title"
              error={error}
              onChange={(event) => {
                const { value } = event.target;
                if (value !== '') {
                  setError(false);
                }
              }}
              helperText={errors.title?.message && <p style={{ color: 'red' }}>{`Title can't blank`}</p>}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Content Image
            </Text>
            <div>
              <TextField
                name={'content_image'}
                disabled
                value={linkImage}
                variant="outlined"
                placeholder="Please choose file"
                onChange={e => {

                }}
              />
              <input
                onChange={pickImageContent}
                ref={register}
                name={'image'}
                accept="image/*"
                className={classes.input}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>
            {!!product?.image?.assetUrl && (
              <div className={classes.uploadedContentImage}>
                <p
                  className={classes.uploadedContentImageDownload}
                  onClick={() => handleDownloadUploadContentImage(product.image?.assetUrl)}
                >
                  {product?.image?.assetUrl.substr(product?.image?.assetUrl.lastIndexOf('/') + 1)}
                </p>
                <CloseIcon className={classes.uploadedContentImageIcon} onClick={handleDeleteImage}/>
              </div>
            )}

          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Video
            </Text>
            <TextField
              fullWidth
              inputRef={register}
              name="video"
              variant="outlined"
              placeholder="Video"
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Content
              <em className={classes.txtPoint}>*</em>
            </Text>
            <JoditEditor
              ref={register}
              name={'content'}
              value={product?.content}
              config={config}
              tabIndex={1}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text
              weight="medium"
              style={{ color: '#8c8c8c', marginBottom: '1px' }}
            >
              Attachment1
            </Text>
            <div>
              <TextField
                value={attackOne}
                variant="outlined"
                disabled
                placeholder="Please choose file"
              />
              <input
                ref={register}
                name={'attachment1'}
                onChange={pickImageAttackOne}
                className={classes.input2}
                id="raised-button-file-2"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file-2">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>
            {!!product?.attachment1?.assetUrl && (
              <div className={classes.uploadedContentImage}>
                <p
                  className={classes.uploadedContentImageDownload}
                  onClick={() => handleDownloadUploadContentImage(product.attachment1?.assetUrl)}
                >
                  {product?.attachment1?.assetUrl.substr(product?.attachment1?.assetUrl.lastIndexOf('/') + 1)}
                </p>
                <CloseIcon className={classes.uploadedContentImageIcon} onClick={handleDeleteImageAttackOne}/>
              </div>
            )}

          </Grid>

          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Text weight="medium" style={{ color: '#8c8c8c', marginBottom: '1px' }}>
              Attachment2
            </Text>
            <div>
              <TextField value={attackTow} disabled variant="outlined" placeholder="Please choose file" onChange={e => {
              }}/>
              <input
                name={'attachment2'}
                ref={register}
                onChange={pickImageAttackTow}
                className={classes.input2}
                id="raised-button-file-3"
                type="file"
              />
              <label htmlFor="raised-button-file-3">
                <Button variant="raised" component="span" className={classes.button}>
                  Upload
                </Button>
              </label>

            </div>

            {!!product?.attachment2?.assetUrl && (
              <div className={classes.uploadedContentImage}>
                <p
                  className={classes.uploadedContentImageDownload}
                  onClick={() => handleDownloadUploadContentImage(product.attachment2?.assetUrl)}
                >
                  {product?.attachment2?.assetUrl.substr(product?.attachment2?.assetUrl.lastIndexOf('/') + 1)}
                </p>
                <CloseIcon className={classes.uploadedContentImageIcon} onClick={handleDeleteImageAttackTow}/>
              </div>
            )}

          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              marginBottom: 10
            }}
          >
            {loading ?
              <CustomButton color="secondary">
                <CustomLoading color="#00adef"/>
              </CustomButton> :
              <input className={classes.buttonSubmit} value="Confirm" type="submit"/>}
            <CustomButton onClick={() => history.push('/admincontrols/configurations/list')}
                          style={{ marginLeft: 5, marginRight: 5, marginBottom: 5, marginTop: 5 }} color="gray">
              Cancel
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
}

export default FormEditProduct;
