import React, { useState, useEffect } from 'react';
import { baseUrl } from '../config';
import axios from '../utils/axios';
import Products from '../routes/marketingShop/Products';
import Orders from '../routes/marketingShop/Orders';
import AddProducts from '../routes/marketingShop/AddProducts';
import ConfigurationsModules from '../routes/rewards/configurations.modules';
import ConfigurationsForecasts from '../routes/rewards/configurations.forecasts';
import ApprovalsModules from '../routes/rewards/approvals.modules';
import ApprovalsForecasts from '../routes/rewards/approvals.forecasts';
import ArticleCodeUpload from '../routes/rewards/upload.articleCode';
import SerialNumberUpload from '../routes/rewards/upload.serialNumber';
import QpointManagement from '../routes/rewards/QpointManagement';
import { ADMIN_ROLE_ID } from './../utils/constants';
import CustomLoading from './../common/components/CustomLoading';
import { makeStyles } from '@material-ui/core/styles';
import Categories from '../routes/marketingShop/Categories';
import { Redirect } from 'react-router-dom';
import UpdateModules from '../routes/rewards/approvals.updateModules';
import UploadList from '../routes/rewards/upload.list';

const useStyles = makeStyles(theme => ({
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
}));

const allMarketingShopRoutes = [
  {
    type: 'ProductsManagement',
    component: Products,
    path: '/marketing-shop/products'
  },
  {
    type: 'ProductsManagement',
    component: Products,
    path: '/marketing-shop/products/:pagenumber'
  },
  {
    type: 'OrdersManagement',
    component: Orders,
    path: '/marketing-shop/orders'
  },
  {
    type: 'OrdersManagement',
    component: Orders,
    path: '/marketing-shop/orders/:orderstatus?/:pagenumber'
  },
  {
    type: 'ProductsManagement',
    component: AddProducts,
    path: '/marketing-shop/add-products'
  },
  {
    type: 'ProductsManagement',
    component: AddProducts,
    path: '/marketing-shop/add-products/:id'
  },
  {
    type: 'CategoriesManagement',
    component: Categories,
    path: '/marketing-shop/categories'
  }
];

const allRewardsRoutes = [
  {
    type: 'ConfigurationsManagement',
    component: ConfigurationsModules,
    path: '/rewards/configurations/modules'
  },
  {
    type: 'ConfigurationsManagement',
    component: ConfigurationsForecasts,
    path: '/rewards/configurations/forecasts'
  },
  {
    type: 'ApprovalsManagement',
    component: ApprovalsModules,
    path: '/rewards/module-approvals'
  },
  {
    type: 'ApprovalsManagement',
    component: ApprovalsForecasts,
    path: '/rewards/forecast-approvals'
  },
  {
    type: 'UploadsManagement',
    component: ArticleCodeUpload,
    path: '/rewards/articlecode-upload'
  },
  {
    type: 'UploadsManagement',
    component: SerialNumberUpload,
    path: '/rewards/serialnumber-upload'
  },
  {
    type: 'QpointManagement',
    component: QpointManagement,
    path: '/rewards/qpoint-management'
  },
  {
    type: 'ApprovalsManagement',
    component: UpdateModules,
    path: '/rewards/module-awaiting-update'
  },
  {
    type: 'UploadsManagement',
    component: UploadList,
    path: '/rewards/upload-list'
  }
];

export const RoleContext = React.createContext(null);

export function RoleProvider(props) {
  const classes = useStyles();
  const [accessControlList, setAccessControlList] = useState(null);
  const [marketingShopRoutes, setMarketingShopRoutes] = useState([]);
  const [rewardRoutes, setRewardRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userLoggedIn = JSON.parse(localStorage.getItem('qpartner_user'));

  const getAccessControlList = async () => {
    try {
        setIsLoading(true);
        const { data } = await axios.get(`${baseUrl}/access-control-list`);
        setAccessControlList(data);
        setMarketingShopRoutes(allMarketingShopRoutes.filter(route => data[route.type].roles.includes(userLoggedIn.user.roleId)));
        setRewardRoutes(allRewardsRoutes.filter(route => data[route.type].roles.includes(userLoggedIn.user.roleId)));
    } catch (err) {
        console.error(
            `There was a problem getting access control list ${err.message}`
        );
    } finally {
        setIsLoading(false);
    }
  };

  const checkShowLegacyMenuItem = () => {
    return userLoggedIn.user.roleId === ADMIN_ROLE_ID.ADMIN;
  }

  const checkShowMenuItem = types => {
    if (!accessControlList) return;
    return types.some(type => accessControlList[type].roles.includes(userLoggedIn.user.roleId));
  };

  useEffect(() => {
    getAccessControlList();
  }, []);

  return (
    <RoleContext.Provider
      value={{
        accessControlList,
        marketingShopRoutes,
        rewardRoutes,
        checkShowLegacyMenuItem,
        checkShowMenuItem
      }}
    >
      {!userLoggedIn ? <Redirect path="/" /> :
       isLoading ? (
        <div
          className={classes.loadingIndicator}
        >
          <CustomLoading color="#00adef" size={40}></CustomLoading>
        </div>
      ): props.children}
    </RoleContext.Provider>
  )
}
