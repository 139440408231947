import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import ShirtImg from '../../config/images/shirt.png';
import { Grid, Box } from '@material-ui/core';
import Text from '../../common/components/text';
import { makeStyles } from '@material-ui/core/styles';
import 'react-dropzone-uploader/dist/styles.css';
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        width: '169px',
        height: '169px',
        '&:hover div[role=overlay]': {
            opacity: 1
        }
    },
    overlay: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.1)',
        width: '90%',
        height: '90%',
        position: 'absolute',
        top: '9px',
        bottom: 0,
        opacity: 0,
        transition: 'opacity 0.5s ease'
    },
    delete: {
        backgroundColor: '#FFFFFF',
        color: '#A9A9A9',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '5px 15px',
        cursor: 'pointer',
        border: 0
    }
}));

const ProductUploaderInput = ({
    className,
    labelClassName,
    labelWithFilesClassName,
    style,
    labelStyle,
    labelWithFilesStyle,
    getFilesFromEvent,
    accept,
    multiple,
    disabled,
    content,
    withFilesContent,
    onFiles,
    files
}) => {
    return (
        <label
            className={labelClassName}
            style={labelStyle}
        >
            <p>
                <span
                    style={{
                        color: "#52B7E8",
                        textDecoration: "underline",
                        fontSize: "16px",
                        fontWeight: "normal"
                    }}
                >
                    Add Images
                </span>{" "}
                <br />
                <span style={{ fontSize: "12px", color: "#000", fontWeight: 300 }}>
                    or drop files to upload
                </span>
            </p>
            <input
                className={className}
                style={style}
                type="file"
                accept={accept}
                multiple={multiple}
                disabled={disabled}
                onChange={async e => {
                    const target = e.target;
                    const chosenFiles = await getFilesFromEvent(e);
                    onFiles(chosenFiles);
                    //@ts-ignore
                    target.value = null;
                }}
            />
        </label>
    );
};
  

const ProductUploaderLayout = ({
    input,
    previews,
    dropzoneProps,
    files,
    extra: { maxFiles }
}) => {
    return (
        <>
            {files.length < maxFiles && <Grid item md={3} xs={12} {...dropzoneProps}>
                { input }
            </Grid>}
        </>    
    )
}

const ProductUploaderPreview = SortableElement(({ meta, fileWithMeta }) => {
    const classes = useStyles();
    const { name, previewUrl } = meta;

    return (
        <Grid item md={3} xs={12} className={classes.container}>
            <img src={previewUrl} alt={name} style={{ width: '100%', height: '100%', border: '1px solid #EDEDED' }}/>
            <div className={classes.overlay} role="overlay">
                <button onClick={fileWithMeta.remove} className={classes.delete}>Delete</button>
            </div>
        </Grid>
    )
});

const Container = SortableContainer(props => {
    const { files, handleChangeStatus, ...restProps } = props;

    return (
        <Grid
            container
            item
            xs={12}
            style={{
                backgroundColor: '#F6F6F6',
                textAlign: 'center',
                padding: '20px',
            }}
            spacing={2}
        >
            {files.map((f, index) => (
                <ProductUploaderPreview
                    key={f.meta.id}
                    index={index}
                    fileWithMeta={f}
                    meta={{ ...f.meta }}
                    {...restProps}
                />
            ))}
            <Dropzone
                onChangeStatus={handleChangeStatus}
                LayoutComponent={ProductUploaderLayout}
                PreviewComponent={null}
                InputComponent={ProductUploaderInput}
                SubmitButtonComponent={null}
                accept="image/*"
                maxFiles={10}
                multiple={true}
                styles={{
                    dropzone: {
                        width: '169px',
                        height: '169px',
                        overflow: 'hidden',
                        borderRadius: 0,
                        border: "2px dashed #ACACAC",
                        padding: 0
                    },
                    preview: {
                        margin: '10px',
                    }
                }}
            />
      </Grid>
    );
});

export default function ProductUploader({ handleChange }) {
    const [files, setFiles] = useState([]);

    const handleChangeStatus = (fileWithMeta, status, filesWithMeta) => {
        let newFiles = [];
        if (status === 'done') {
            newFiles = [...files, fileWithMeta];
            setFiles(newFiles);
        } else if (status === 'removed') {
            newFiles = files.filter(f => f.meta.id !== fileWithMeta.meta.id);
            setFiles(newFiles);
        }
        handleChange(newFiles);
    };

    const arrayMoveMutate = (array, from, to) => {
        const startIndex = to < 0 ? array.length + to : to;
        const item = array.splice(from, 1)[0];
        array.splice(startIndex, 0, item);
    };
    
    const arrayMove = (array, from, to) => {
        array = array.slice();
        arrayMoveMutate(array, from, to);
        return array;
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setFiles(arrayMove(files, oldIndex, newIndex));
    };

    useEffect(() => {
        handleChange(files);
    }, [files]);

    return (
        <Grid
            container
            style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
        >
            <Grid item xs={12}>
                <Box style={{ display: "flex", flexDirection: "row", marginBottom: '10px' }}>
                    <Text size="h3" weight="medium" style={{ flexGrow: 1 }}>Images</Text>
                    <Text size="subtitle" style={{ lineHeight: '58px', color: '#666666' }}>Drag Images to Rearrange</Text>
                </Box>
            </Grid>
            
            <Container 
                files={files}
                axis="xy"
                onSortEnd={onSortEnd}
                handleChangeStatus={handleChangeStatus}
            />
        </Grid>
    )
}