import React, { useState, useEffect } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import { ExcelRenderer } from 'react-excel-renderer';
import Dropzone from 'react-dropzone-uploader';
import axios from '../../utils/axios';
import config from '../../config';
import Text from '../../common/components/text';
import CustomButton from '../../common/components/button';
import { Link } from 'react-router-dom';
import Alert from './Alert';

import { makeStyles } from '@material-ui/core/styles';

import { Modal, Backdrop, Fade, Paper, Grid, IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import _ from 'lodash';

import ExcelIcon from '../../icons/excel_icon.png';
import TrashIcon from '../../icons/trash_icon.png';
import UploadIcon from '../../icons/upload_icon.png';
import CustomLoading from './../../common/components/CustomLoading';
import { ExcelCustomComponent } from '../../utils/components';
import classNames from 'classnames';

const serialNumberTemplate = [
  {
    articleCode: '',
    serialNumber: ''
  }
];

const { baseUrl } = config;
const SPECIAL_ARTICLE_CODE = ['3035604', '3035605'];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  header: {
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  submitButtons: {
    backgroundColor: '#002D56',
    color: '#ffffff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  uploadSerialNumberSuccess: {
    width: '32rem !important',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important'
    }
  },
  uploadSerialNumberDuplicateOrInvalid: {
    width: '57rem !important',
    [theme.breakpoints.down('xs')]: {
      width: '85% !important'
    }
  },
  resultSerialNumberUploadSuccess: {
    backgroundColor: '#e5f3fb',
    height: '150px',
    width: '360px',
    textAlign: 'center'
  },
  resultSerialNumberUploadTitle: {
    fontSize: '2.5rem',
    marginTop: '20px',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem'
    }
  },
  resultSerialNumberUploadSubtitle: {
    fontSize: '20px',
    marginTop: 0,
    marginBottom: 0
  },
  resultSerialNumberUploadCloseBtn: {
    marginTop: '10px',
    marginRight: '-15px'
  },
  resultSerialNumberUploadSuccessItem: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  resultSerialNumberUploadSuccessBox: {
    backgroundColor: '#e5f3fb !important',
    height: '130px',
    width: '360px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  resultSerialNumberUploadSuccessTitle: {
    marginBottom: '10px',
    marginTop: 0
  },
  resultSerialNumberUploadSuccessContent: {
    width: '82%',
    margin: 'auto',
    marginTop: '-15px'
  },
  resultSerialNumberUploadBoxes: {
    margin: 'auto',
    textAlign: 'center',
    width: '75%',
    marginTop: '20px',
    "& .MuiPaper-root": {
      paddingTop: '25px'
    },
    [theme.breakpoints.down('xs')]: {
      "& .MuiGrid-item": {
        marginBottom: '10px'
      }
    }
  },
  resultSerialNumberUploadDownloadBtn: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '0.75rem 2rem !important'
  },
  resultResponseTitle: {
    marginTop: '20px',
    fontSize: '16px',
    textAlign: 'center'
  },
  resultResponseSubtitle: {
    width: '80%',
    margin: 'auto',
    textAlign: 'center',
    fontSize: '16px'
  }
}));

const UploaderInput = ({
  className,
  labelClassName,
  labelWithFilesClassName,
  style,
  labelStyle,
  labelWithFilesStyle,
  getFilesFromEvent,
  accept,
  multiple,
  disabled,
  content,
  withFilesContent,
  onFiles,
  files
}) => {
  return (
    <label className={labelClassName} style={labelStyle}>
<p
        style={{
          textAlign: 'center',
          fontSize: '15px',
          color: '#000',
          fontWeight: 400,
          display: 'block'
        }}
      >
        <img src={UploadIcon} alt="" />
      <p
          style={{
            fontSize: '15px',
            color: '#8c8c8c',
            fontWeight: 400,
            display: 'block'
          }}
        >
          Drag and drop up to 5 files at a time or click to browse.
        </p>
          
          </p>
      <input
        className={className}
        style={style}
        type="file"
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        onChange={async e => {
          const target = e.target;
          const chosenFiles = await getFilesFromEvent(e);
          onFiles(chosenFiles);
          //@ts-ignore
          target.value = null;
        }}
      />
    </label>
  );
};

export default function SerialNumberUpload() {
  const classes = useStyles();
  const [uploadFile, setUploadFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [willSave, setWillSave] = useState([]);
  const [notSave, setNotSave] = useState([]);
  const [articleCodes, setArticleCodes] = useState({});
  const [serialNumberErr, setSerialNumberErr] = useState([]);
  const [duplicateSNServer, setDuplicateSNServer] = useState([]);
  const [serialNumberSuccessServer, setSerialNumberSuccessServer] = useState([]);
  const [duplicateSN, setDuplicateSN] = useState([]);
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState({
    createdModules: [],
    duplicateModules: [],
    updatedModules: []
  });
  const [files, setFiles] = useState([]);
  const [
    failedSerialNumberUploadsDataSheet,
    setFailedSerialNumberUploadsDataSheet
  ] = useState([]);
  let isUploadSerialNumberAllSuccess = !uploading && notSave.length === 0 && results.duplicateModules.length === 0
    && results.invalid === 0 && duplicateSN.length === 0;

  const getSetup = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/articlecodes/distinct`);
      let articleCodeMap = {};
      data.articleCodes.forEach(c => {
        if (!articleCodeMap[c.code]) {
          articleCodeMap[c.code] = c.id;
        }
      });
      let modulesMap = {};
      data.modules.forEach(c => {
        if (!modulesMap[c.moduleType]) {
          modulesMap[c.moduleType] = true;
        }
      });
      setArticleCodes(articleCodeMap);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSetup();
  }, []);

  const handleChangeStatus = (file, status) => {
    let newFiles = [...files];
    if (status === 'done') {
      newFiles = [...files, file];
    } else if (status === 'removed') {
      newFiles = files.filter(f => f.meta.id !== file.meta.id);
    }
    setFiles(newFiles);
  };

  const allhandleChangeStatus = file => {
    let fileObj = file;
    const validateArticleCode = articleCode => {
      const isContainsOnlyNumberOrLetterOrHyphen = value => {
        if (value.replace(/[^-]/g, '').length !== 1) {
          return false;
        }

        return /^[A-Za-z0-9-]+$/.test(value);
      };

      if (SPECIAL_ARTICLE_CODE.includes(articleCode)) {
        return true;
      }

      if (articleCode.length === 8 && !isNaN(articleCode)) {
        return true;
      }

      if (
        articleCode.length === 18 &&
        isContainsOnlyNumberOrLetterOrHyphen(articleCode)
      ) {
        return true;
      }

      return false;
    };

    const validateSerialNumber = serialNumber => {
      return /(^(AH|AF|QH|QA|BF)(0600|0700|0760|0860)([A-Z])([1-9]|[A-C])(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9]|[A-Z]0[1-9]|[A-Z][1-9][0-9])(00[1-9]|0[1-9][0-9]|[1-9][0-9][0-9])$)|([0-9]){18}$/.test(
        serialNumber
      );
    };
    return new Promise((resolve, reject) => {
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          const notSave = [];
          const willSave = [];
          const duplicates = [];
          const alreadyAddedObj = {};
          const uploads = [];

          resp.rows.forEach((row, idx) => {
            const uploadSerialNumber = String(row[1]);
            const uploadArticleCode = String(row[0]);
            if (idx === 0 || !row.length || !row[0] || !row[1]) {
              return;
            }
            uploads.push(row);
            if (
              alreadyAddedObj[uploadSerialNumber] &&
              uploadSerialNumber !== 'undefined'
            ) {
              duplicates.push({
                articleCode: row[0],
                serialNumber: uploadSerialNumber,
                msg: 'duplicate',
                fileName: fileObj.name
              });
              return;
            }

            alreadyAddedObj[uploadSerialNumber] = true;
            if (
              !articleCodes[uploadArticleCode] ||
              !validateSerialNumber(uploadSerialNumber) ||
              !validateArticleCode(uploadArticleCode)
            ) {
              notSave.push({
                articleCode: row[0],
                serialNumber: uploadSerialNumber,
                msg: 'invalid',
                fileName: fileObj.name
              });
              return;
            }

            if (
              articleCodes[row[0]] &&
              [14, 18].includes(uploadSerialNumber.length)
            ) {
              willSave.push({
                articleCodeId: articleCodes[row[0]],
                serialNumber: uploadSerialNumber,
                source: 'web',
                productType: uploadSerialNumber.length === 18 ? 1 : 2,
                fileName: fileObj.name
              });
              return;
            } else {
              notSave.push({
                articleCode: row[0],
                serialNumber: uploadSerialNumber,
                msg: 'invalid',
                fileName: fileObj.name
              });
              return;
            }
          });
          return resolve({
            totalUpload: resp.rows.filter(e => e.length).length - 1,
            willSave,
            notSave,
            uploadFile: uploads,
            duplicateSN: duplicates
          });
        }
      });
    });
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }
  
  function getKeyByValueFileName(array, serialNumber) {
    const fileNameValue = array.find(obj => {
      if (obj.serialNumber === serialNumber) return obj.fileName;
    })
    return fileNameValue.fileName;
  }

  const handleSubmit = async () => {
    setUploading(true);
    setOpen(true);
    const allRenderExcelFilePromise = [];
    for (let i = 0; i < files.length; i++) {
      allRenderExcelFilePromise.push(allhandleChangeStatus(files[i].file));
    }

    const allExcelData = await Promise.all(allRenderExcelFilePromise);
    const willSave = [];
    const notSave = [];
    const totalUploadFile = [];
    const totalDuplicateFile = [];
    const alreadyChecked = [];
    let totalUpload = 0;
    
    allExcelData.forEach(afc => {
      totalUploadFile.push(...afc.uploadFile);
      totalUpload += afc.totalUpload;
      notSave.push(...afc.notSave);

      afc.willSave.forEach(afcWS => {
        if (alreadyChecked.indexOf(afcWS.serialNumber) > -1 ) {
          totalDuplicateFile.push(afcWS);
        } else {
          alreadyChecked.push(afcWS.serialNumber);
          willSave.push(afcWS);
        }
      })
      afc.duplicateSN.forEach(afcDupes => {
        if (alreadyChecked.indexOf(afcDupes.serialNumber) > -1 ) {
          totalDuplicateFile.push(afcDupes);
        } else {
          alreadyChecked.push(afcDupes.serialNumber);
        }
      })
    })

    setWillSave(willSave);
    setNotSave(notSave);
    setUploadFile(totalUploadFile);
    setDuplicateSN(totalDuplicateFile);

    const filterOutFileName = willSave.map(ws => {
      return {
        articleCodeId: ws.articleCodeId,
        serialNumber: ws.serialNumber,
        source: ws.source,
        productType: ws.productType
      }
    })

    try {
      const { data } = await axios.post(`${baseUrl}/modules/batchuploadadmin`, {
        modules: filterOutFileName,
        total: totalUpload,
        invalid: notSave.length
      });
      files.forEach(f => f.remove());
      setFiles([]);
      setResults(data);
      const dupSNServer = data.duplicateModules.map(function(v) {
        return {
          articleCode: getKeyByValue(articleCodes, v.articleCodeId),
          serialNumber: v.serialNumber,
          msg: 'duplicate',
          fileName: getKeyByValueFileName(willSave, v.serialNumber)
        };
      });
      const serialNumberCreated = data.createdModules.map(function(v) {
        return {
          articleCode: getKeyByValue(articleCodes, v.articleCodeId),
          serialNumber: v.serialNumber,
          msg: 'uploaded',
          fileName: getKeyByValueFileName(willSave, v.serialNumber)
        };
      });
      setDuplicateSNServer(dupSNServer);
      setSerialNumberSuccessServer(serialNumberCreated);
      const invalidModules = data.invalidModules.map(function(v) {
        return {
          articleCode: getKeyByValue(articleCodes, v.articleCodeId),
          serialNumber: v.serialNumber,
          msg: 'invalid',
          fileName: getKeyByValueFileName(willSave, v.serialNumber)
        };
      });

      setSerialNumberErr(invalidModules);
    } catch (err) {
      console.log(err);
    } finally {
      setUploading(false);
    }
  };

  const ResultResponse = () => {
    return (
      <>
        {duplicateSN.length > 0 &&
        notSave.length > 0 &&
        results.invalid > 0 &&
        results.createdModules === 0 ? (
          <>
            <Text weight="bold" className={classes.resultResponseTitle}>Duplicate & Invalid Serial Numbers</Text>
            <Text className={classes.resultResponseSubtitle} color="secondaryDark">
              Some of the numbers you entered are not 18 digits long and a few are
              already registered.
            </Text>
            <Text className={classes.resultResponseSubtitle} color="secondaryDark">
              Please download the list to double check if you input the correct serial numbers.
            </Text>
          </>
        ) : (duplicateSN.length > 0 && results.invalid === 0) ||
          (results.duplicateModules.length && results.invalid === 0) ? (
          <>
            <Text weight="bold" className={classes.resultResponseTitle}>Duplicate Serial Numbers</Text>
            <Text className={classes.resultResponseSubtitle} color="secondaryDark">
              Some of the serial numbers you entered have already been registered.
            </Text>
            <Text className={classes.resultResponseSubtitle} color="secondaryDark">
              Please download the list to check which are duplicate serial numbers.
            </Text>
          </>
        ) : (
          <>
            <Text weight="bold" className={classes.resultResponseTitle}>
              Out of{' '}
              {results.invalid +
                results.duplicateModules.length +
                duplicateSN.length}{' '}
              failed, {results.invalid} have invalid article codes.
            </Text>
            <Text className={classes.resultResponseSubtitle} color="secondaryDark">
              Please upload the article code information first in order to resgister
              these modules or check that serial numbers are valid as well.
            </Text>
          </>
        )}
      </>
    );
  };

  const downloadFailedSerialNumberUploads = async () => {
    setOpen(false);
    const dataDownload = [
      ...notSave,
      ...duplicateSN,
      ...serialNumberErr,
      ...serialNumberSuccessServer,
      ...duplicateSNServer
    ];
    
    const exportFile = await dataDownload.map(upload => ({
      articleCode: upload.articleCode,
      serialNumber: upload.serialNumber,
      invalidSerialNumber: upload.msg,
      fileName: upload.fileName
    }));

    setFailedSerialNumberUploadsDataSheet(exportFile);
    document.getElementById('result-serial-number-uploads-data-sheet').click();
  };

  const downloadSerialNumberUploadTemplate = () => {
    document.getElementById('serial-number-upload-template').click();
  };

  return (
    <>
      <Text size="h2" style={{ lineHeight: '0px' }}>
        Uploads
      </Text>
      <Paper>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto'
            }}
            spacing={2}
          >
            <Grid
              item
              component={Link}
              to="/rewards/articlecode-upload"
              style={{ textDecoration: 'none' }}
            >
              <Text size="h4" color="dark">
                Article Code
              </Text>
            </Grid>
            <Grid item style={{ borderBottom: 'solid', borderColor: '#00ADEF' }}>
              <Text color="primaryLight" size="h4">
                Serial Number
              </Text>
            </Grid>
            <Grid
              item
              component={Link}
              to="/rewards/upload-list"
              style={{ textDecoration: 'none' }}
            >
              <Text size="h4" color="dark">
                Upload List
              </Text>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              maxWidth: '75vw',
              margin: '5px auto'
            }}
          >
            <Text size="h2" style={{ margin: 0 }} className="page_title">
              Serial Number Upload
            </Text>

            <Grid item>
              <a style={{textDecoration: 'none'}} href={config.urlTemplateSerialNumber}>
                <CustomButton
                  color="secondary"
                >
                  Template
                </CustomButton>
              </a>

              <ExcelCustomComponent
                filename={`serial-number-upload-template${new Date()}`}
                id="serial-number-upload-template"
                data={serialNumberTemplate}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              marginTop: '20px',
              marginBottom: '20px'
            }}
          >
            <Dropzone
              InputComponent={UploaderInput}
              onChangeStatus={handleChangeStatus}
              accept=".xls, .xlsx"
              maxFiles={5}
              multiple={true}
              PreviewComponent={null}
              styles={{
                dropzone: {
                  overflow: 'hidden',
                  borderRadius: 0,
                  border: '2px dashed #ACACAC',
                  padding: 0,
                  color: '#000000',
                  backgroundColor: '#f7f7f7',
                  height: '250px'
                },
                preview: {
                  margin: '10px'
                }
              }}
            />
            <div style={{ marginTop: '20px' }}>
              {files.length > 0 &&
                files.map(file => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={TrashIcon}
                      style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                      onClick={file.remove}
                      alt=""
                    />
                    <img
                      src={ExcelIcon}
                      alt="Excel Icon"
                      style={{
                        width: '30px',
                        height: '30px',
                        marginLeft: '8px',
                        marginRight: '5px'
                      }}
                    />
                    <p>{file.file.name}</p>
                  </div>
                ))}
            </div>

            {files.length === 0 ? (
              false
            ) : (
              <Grid
                container
                item
                justify="left"
                xs={12}
                style={{ paddingTop: '20px' }}
              >
                <CustomButton
                  disabled={files.length === 0 || uploading}
                  color="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                  style={{ height: '50px' }}
                  className="AddSerialNumbers_SubmitButton"
                >
                  {uploading ? <CustomLoading /> : 'Submit'}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </div>
      </Paper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div
            style={{
              height: '85vh',
              borderRadius: '6px',
              overflowY: 'auto',
              outline: 'none'
            }}
          >
            <Alert
              style={{ margin: '0 auto' }}
              className={classNames('',
              { [classes.uploadSerialNumberSuccess]: isUploadSerialNumberAllSuccess,
                [classes.uploadSerialNumberDuplicateOrInvalid]: !isUploadSerialNumberAllSuccess })}
            >
              <Grid container justify="flex-end">
                <IconButton
                  className={classes.resultSerialNumberUploadCloseBtn}
                  onClick={() => setOpen(false)}
                >
                  <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
                </IconButton>
              </Grid>
              <Text className={classes.resultSerialNumberUploadTitle}>Serial Number Uploads</Text>
              {uploading ? (
                <div className="wr_uploading">
                  <CustomLoading size={40} color="#00adef"></CustomLoading>
                </div>
              ) : (
                <React.Fragment>
                  <Text size="h3" className={classes.resultSerialNumberUploadSubtitle}>
                    Out of{' '}
                    <span style={{ color: '#00ADEF' }}>
                      {uploadFile ? uploadFile.length : 0}
                    </span>{' '}
                    pcs of products:
                  </Text>
                  {notSave.length === 0 &&
                  results.duplicateModules.length === 0 &&
                  results.invalid === 0 &&
                  duplicateSN.length === 0 ? (
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: '20px', paddingBottom: '20px' }}
                    >
                      <Grid item className={classes.resultSerialNumberUploadSuccessItem}>
                        <Paper
                          className={classes.resultSerialNumberUploadSuccessBox}
                          elevation={0}
                        >
                          <Text
                            size="h1"
                            color="primaryLight"
                            weight="medium"
                            className={classes.resultSerialNumberUploadSuccessTitle}
                          >
                            {willSave.length}
                          </Text>
                          <Text
                            weight="light"
                            color="primaryLight"
                            className={classes.resultSerialNumberUploadSuccessContent}
                          >
                            Were successfully uploaded
                          </Text>
                        </Paper>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        container
                        justify="space-around"
                        className={classes.resultSerialNumberUploadBoxes}
                      >
                        <Grid item>
                          <Paper className="box1" elevation={0}>
                            <Text
                              size="h3"
                              weight="bold"
                              color="primaryLight"
                              className="number1"
                            >
                              {results.createdModules.length}
                            </Text>
                            <Text
                              weight="light"
                              color="primaryLight"
                              className="content1"
                            >
                              Were successfully uploaded
                            </Text>
                          </Paper>
                        </Grid>
                        <Grid item>
                          <Paper className="box1" elevation={0}>
                            <Text size="h3" weight="bold" className="number1">
                              {results.duplicateModules.length + duplicateSN.length}
                            </Text>
                            <Text weight="light" className="content1">
                              Are duplicates
                            </Text>
                          </Paper>
                        </Grid>
                        <Grid item>
                          <Paper className="box1" elevation={0}>
                            <Text size="h3" weight="bold" className="number1">
                              {notSave.length > 0 || results.invalid > 0
                                ? results.invalid
                                : 0}
                            </Text>
                            <Text weight="light" className="content1">
                              Are invalid
                            </Text>
                          </Paper>
                        </Grid>
                      </Grid>
                      <ResultResponse />
                      <CustomButton
                        color="secondary"
                        style={{ marginTop: '20px', marginBottom: '20px' }}
                        onClick={downloadFailedSerialNumberUploads}
                        className={classes.resultSerialNumberUploadDownloadBtn}
                      >
                        Download
                      </CustomButton>
                      <ExcelCustomComponent
                        filename={`result-serial-number-uploads${new Date()}.csv`}
                        id="result-serial-number-uploads-data-sheet"
                        data={failedSerialNumberUploadsDataSheet}
                      />
                    </>
                  )}
                </React.Fragment>
              )}
            </Alert>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
