import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from '../../../utils/axios';
import { baseUrl } from '../../../config';
import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import Text from '../../../common/components/text';
import Button from '../../../common/components/button';
import { ExcelCustomComponent } from '../../../utils/components';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

export default function ClaimManagement() {
  const [claims, setClaims] = useState([]);
  const [claimLabelInput, setClaimLabelInput] = useState('');
  const [allClaims, setAllClaims] = useState([]);
  const [sortDirection, setSortDirection] = useState('desc');
  const [selectedColumn, setSelectedColumn] = useState('createdAt');

  const setSystemType = (claimTypeId, systemTypeId) => {
    const batteryTypes = {
      1: 'Autotransformer',
      2: 'Battery Module',
      3: 'BMS',
      4: 'ESS Alarm',
      5: 'Hardware Error',
      6: 'Inverter',
      7: 'Meter',
      8: 'Other',
      9: 'Software'
    };
    const panelTypes = {
      1: 'Power Loss/Under Performing Module',
      2: 'Hot Spot',
      3: 'Broken Glass',
      4: 'Connector Damage',
      5: 'Detached Cable/Junction Box Damage',
      6: 'Detached Frame',
      7: 'Other'
    };
    if (claimTypeId == 1) {
      return panelTypes[systemTypeId];
    } else {
      return batteryTypes[systemTypeId];
    }
  };

  const getClaims = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/claims/all-claims`);
      const filteredClaims = data.claims
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter(claim => claim.isDraft === false)
        .map(
          ({
            isDraft,
            folderId,
            companyId,
            state,
            fullName,
            claimStatus,
            claimId,
            createdAt,
            claimLabel,
            companyName,
            claimsType,
            systemtype,
            description,
            productName,
            quantity,
            affectedProductSerialNumbers,
            nameOfPurchasingChannel,
            installationDate,
            defectDiscoveryDate,
            ptoDate,
            projectRegistrationDate,
            projectRegisteredDate,
            qualifyLaborWarranty,
            projectId,
            projectName,
            projectOwnerFirstName,
            projectOwnerLastName,
            projectAddress1,
            projectAddress2,
            projectCity,
            projectState,
            projectZipcode,
            attachments,
            w9
          }) => ({
            isDraft,
            folderId,
            companyId,
            state,
            fullName,
            claimStatus,
            claimId,
            createdAt,
            claimLabel,
            companyName,
            claimsType,
            systemtype: setSystemType(claimsType, +systemtype),
            description,
            productName,
            quantity,
            affectedProductSerialNumbers,
            nameOfPurchasingChannel,
            installationDate,
            defectDiscoveryDate,
            ptoDate,
            projectRegistrationDate,
            projectRegisteredDate,
            qualifyLaborWarranty,
            projectId,
            projectName: projectName ? projectName : '',
            projectOwnerFirstName,
            projectOwnerLastName,
            projectAddress1,
            projectAddress2,
            projectCity,
            projectState,
            projectZipcode,
            attachments,
            w9
          })
        );
      setAllClaims(data.claims);
      setClaims(filteredClaims);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClaimLabelInput = async () => {
    try {
      if (claimLabelInput.length === 0) {
        const { data } = await axios.get(`${baseUrl}/claims/all-claims`);
        const filteredClaims = data.claims
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .filter(claim => claim.isDraft === false)
          .map(
            ({
              isDraft,
              folderId,
              companyId,
              state,
              fullName,
              claimStatus,
              claimId,
              createdAt,
              claimLabel,
              companyName,
              claimsType,
              systemtype,
              description,
              productName,
              quantity,
              affectedProductSerialNumbers,
              nameOfPurchasingChannel,
              installationDate,
              defectDiscoveryDate,
              ptoDate,
              projectRegistrationDate,
              projectRegisteredDate,
              qualifyLaborWarranty,
              projectId,
              projectName,
              projectOwnerFirstName,
              projectOwnerLastName,
              projectAddress1,
              projectAddress2,
              projectCity,
              projectState,
              projectZipcode,
              attachments,
              w9
            }) => ({
              isDraft,
              folderId,
              companyId,
              state,
              fullName,
              claimStatus,
              claimId,
              createdAt,
              claimLabel,
              companyName,
              claimsType,
              systemtype: setSystemType(claimsType, +systemtype),
              description,
              productName,
              quantity,
              affectedProductSerialNumbers,
              nameOfPurchasingChannel,
              installationDate,
              defectDiscoveryDate,
              ptoDate,
              projectRegistrationDate,
              projectRegisteredDate,
              qualifyLaborWarranty,
              projectId,
              projectName: projectName ? projectName : '',
              projectOwnerFirstName,
              projectOwnerLastName,
              projectAddress1,
              projectAddress2,
              projectCity,
              projectState,
              projectZipcode,
              attachments,
              w9
            })
          );
        setClaims(filteredClaims);
      } else {
        let filteredClaims = claims.filter(
          claim => claim.claimLabel == claimLabelInput
        );
        setClaims(filteredClaims);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const download = url => {
    return fetch(url).then(resp => resp.blob());
  };

  const downloadByGroup = (urls, files_per_group = 5) => {
    return Promise.map(
      urls,
      async url => {
        return await download(url);
      },
      { concurrency: files_per_group }
    );
  };

  const exportZip = (blobs, folderId, attachments, claimLabel) => {
    const zip = JsZip();
    blobs.forEach((blob, i) => {
      let attachment =
        attachments[i].split(`/claims/${folderId}/`)[1] ||
        attachments[i].split(`/upload/`)[1] ||
        attachments[i].split(`/media/`)[1] ||
        'w9.pdf';
      zip.file(`${i}-${attachment}`, blob);
    });
    zip.generateAsync({ type: 'blob' }).then(zipFile => {
      const fileName = `claims-number-${claimLabel}.zip`;
      return FileSaver.saveAs(zipFile, fileName);
    });
  };

  const downloadAndZip = (folderId, claimLabel, urls, w9) => {
    const attachments = urls.split(',');
    attachments.push(w9);
    return downloadByGroup(attachments, 5).then(response => {
      exportZip(response, folderId, attachments, claimLabel);
    });
  };

  async function exportCsvModulesAwaitingApproval() {
    try {
      await setAllClaims(
        allClaims.map(
          ({
            isDraft,
            folderId,
            companyId,
            state,
            fullName,
            claimStatus,
            claimId,
            createdAt,
            claimLabel,
            companyName,
            claimsType,
            systemtype,
            description,
            productName,
            quantity,
            affectedProductSerialNumbers,
            nameOfPurchasingChannel,
            installationDate,
            defectDiscoveryDate,
            ptoDate,
            projectRegistrationDate,
            projectRegisteredDate,
            qualifyLaborWarranty,
            projectId,
            projectName,
            projectOwnerFirstName,
            projectOwnerLastName,
            projectAddress1,
            projectAddress2,
            projectCity,
            projectState,
            projectZipcode,
            attachments
          }) => ({
            isDraft,
            folderId,
            companyId,
            state,
            fullName,
            claimStatus,
            claimId,
            createdAt,
            claimLabel,
            companyName,
            claimsType: claimsType == '1' ? 'Panel' : 'Battery',
            systemtype: setSystemType(claimsType, systemtype),
            description,
            productName,
            quantity,
            affectedProductSerialNumbers,
            nameOfPurchasingChannel,
            installationDate,
            defectDiscoveryDate,
            ptoDate,
            projectRegistrationDate,
            projectRegisteredDate,
            qualifyLaborWarranty,
            projectId,
            projectName,
            projectOwnerFirstName,
            projectOwnerLastName,
            projectAddress1,
            projectAddress2,
            projectCity,
            projectState,
            projectZipcode,
            attachments
          })
        )
      );
      document.getElementById(`claims-report`).click();
    } catch (err) {
      console.log(err);
    }
  }

  const sortTableBySelectedColumn = column => {
    const newSortedData = [...claims];
    setSelectedColumn(column);
    if (sortDirection === 'asc') {
      newSortedData.sort((a, b) => new Date(b[column]) - new Date(a[column]));
      setClaims(newSortedData);
      setSortDirection('desc');
    } else {
      newSortedData.sort((a, b) => new Date(a[column]) - new Date(b[column]));
      setClaims(newSortedData);
      setSortDirection('asc');
    }
  };

  const sortTableBySelectedColumnStrings = column => {
    const newSortedData = [...claims];
    setSelectedColumn(column);
    if (sortDirection === 'asc') {
      newSortedData.sort((a, b) => {
        if (a[column]?.toUpperCase() < b[column]?.toUpperCase()) {
          return -1;
        }
        if (a.name?.toUpperCase() > b.name?.toUpperCase()) {
          return 1;
        }
        return 0;
      });
      setClaims(newSortedData);
      setSortDirection('desc');
    } else {
      newSortedData.sort((a, b) => {
        if (a[column]?.toUpperCase() < b[column]?.toUpperCase()) {
          return 1;
        }
        if (a[column]?.toUpperCase() > b[column]?.toUpperCase()) {
          return -1;
        }
        return 0;
      });
      setClaims(newSortedData);
      setSortDirection('asc');
    }
  };

  useEffect(() => {
    getClaims();
  }, []);

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Text size="h2" style={{ lineHeight: '0px' }}>
          CLAIM MANAGEMENT
        </Text>
      </Grid>

      <Paper>
        <Grid
          style={{ padding: '40px 30px 15px' }}
          container
          spacing={1}
          justify="space-between"
        >
          <Grid item sm={2} xs={12}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label="Claim #"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleClaimLabelInput();
                }
              }}
              value={claimLabelInput}
              onChange={e => setClaimLabelInput(e.target.value)}
            />
          </Grid>
          <Grid item sm={1}>
            <Button
              onClick={exportCsvModulesAwaitingApproval}
              style={{
                height: '40px',
                minWidth: '100%',
                borderRadius: '5px',
                marginRight: '10px',
                padding: '.5rem 1rem',
                borderRadius: '100px'
              }}
            >
              Report
            </Button>
            <ExcelCustomComponent
              filename={`claims-report-${new Date()}.csv`}
              id="claims-report"
              data={allClaims}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
         <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Count #</TableCell>
              <TableCell align='center' style={{cursor: 'pointer'}} onClick={() => sortTableBySelectedColumn('createdAt')}>Submitted Date {selectedColumn == 'createdAt' ? sortDirection == 'asc' ? <ArrowUpward fontSize='5px'/> : <ArrowDownward /> : false}</TableCell>
              <TableCell align='center' style={{cursor: 'pointer'}} onClick={() => sortTableBySelectedColumn('claimLabel')}>PTI Claim # {selectedColumn == 'claimLabel' ? sortDirection === 'asc' ? <ArrowUpward/> : <ArrowDownward /> : false}</TableCell>
              <TableCell align='center' style={{cursor: 'pointer'}} onClick={() => sortTableBySelectedColumnStrings('systemtype')}>Claim Subtype {selectedColumn == 'systemtype' ? sortDirection === 'asc' ? <ArrowUpward/> : <ArrowDownward /> : false}</TableCell>
              <TableCell align='center' style={{cursor: 'pointer'}} onClick={() => sortTableBySelectedColumn('qualifyLaborWarranty')}>LW Qualifying {selectedColumn == 'qualifyLaborWarranty' ? sortDirection === 'asc' ? <ArrowUpward/> : <ArrowDownward /> : false}</TableCell>
              <TableCell align='center' style={{cursor: 'pointer'}} onClick={() => sortTableBySelectedColumnStrings('projectName')}>Project Name {selectedColumn == 'projectName' ? sortDirection === 'asc' ? <ArrowUpward/> : <ArrowDownward /> : false}</TableCell>
              <TableCell align='center' style={{cursor: 'pointer'}} onClick={() => sortTableBySelectedColumn('projectRegistrationDate')}>Project Registration Date {selectedColumn == 'projectRegistrationDate' ? sortDirection === 'asc' ? <ArrowUpward/> : <ArrowDownward /> : false}</TableCell>
              <TableCell align='center' style={{cursor: 'pointer'}} onClick={() => sortTableBySelectedColumn('ptoDate')}>PTO Date {selectedColumn == 'ptoDate' ? sortDirection === 'asc' ? <ArrowUpward/> : <ArrowDownward /> : false}</TableCell>
              <TableCell align='center'>Download</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              <React.Fragment>
                {claims.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="12">
                      <div>
                        <Text color="primaryLight" weight="bold">
                          Claim number does not exist.
                        </Text>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {claims?.map((claim, index) => (
                  <TableRow key={index}>
                    <TableCell align="center"> {claims.length - index}</TableCell>
                    <TableCell align='center'>
                      {moment(claim.createdAt).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell align='center'>{claim.claimLabel}</TableCell>
                    <TableCell align='center'>{claim.systemtype}</TableCell>
                    <TableCell align='center'>{claim.qualifyLaborWarranty ? 'Qualifying' : 'Non-Qualifying'}</TableCell>
                    <TableCell align='center'>{claim.projectName}</TableCell>
                    <TableCell align='center'>{claim.projectRegistrationDate ? moment(claim.projectRegistrationDate).format('MM-DD-YYYY') : null}</TableCell>
                    <TableCell align='center'>{claim.ptoDate ? moment(claim.ptoDate).format('MM-DD-YYYY') : null}</TableCell>
                    <TableCell align='center'>
                      {claim.attachments === null ?
                        false : 
                        <button onClick={() => downloadAndZip(claim.folderId, claim.claimLabel, claim.attachments, claim.w9.assetUrl)}>Evidence Download</button>}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
