import React, { useState, useEffect, useRef } from 'react';
import Text from '../../common/components/text';
import { Link } from 'react-router-dom';
import Alert from './Alert';
import CustomButton from '../../common/components/button';
import moment from 'moment';
import axios from '../../utils/axios';
import { baseUrl } from '../../config';
import TextButton from '../../common/components/button/TextButton';

import {
  Paper,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  TableContainer
} from '@material-ui/core';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { notiSuccess, decodeHtml, warningError, downloadReport } from './../../utils/helpers';
import _ from 'lodash';
import CustomLoading from './../../../src/common/components/CustomLoading';
import useUpdatedToast from './../../common/customHooks/useUpdatedToast';
import { ExcelCustomComponent } from '../../utils/components';

const SUBMISSION_TYPE = 'submission';
const ACHIEVEMENT_TYPE = 'achievement';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    width: '93%',
    margin: 'auto',
    minWidth: 650
  },
  popupTableContainer: {
    maxHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '200px'
    }
  },
  popupTable: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 650
    }
  },
  tableContainer: {
    boxShadow: 'none'
  },
  headerItem: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1rem'
  },
  resultForecastRejectedForecastTitle: {
    textAlign: 'center',
    marginTop: '15px',
    fontSize: '40px'
  },
  resultForecastRejectedForecastSubtitle: {
    marginTop: '-40px',
    fontSize: '20px !important'
  },
  resultForecastRejectedForecastDownloadBtn: {
    padding: '.75rem 2rem !important'
  },
  resultForecastRejectedForecastCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  },
  approveRejectForecastModalTitle: {
    textAlign: 'center',
    marginTop: '15px',
    fontSize: '40px',
    lineHeight: '45px'
  },
  approveRejectForecastModalSubtitle: {
    marginTop: '-32px',
    marginBottom: '20px',
    fontSize: '20px !important'
  },
  approveRejectForecastModalCancelBtn: {
    padding: '.75rem 2rem !important'
  },
  approveRejectForecastModalApproveBtn: {
    padding: '.75rem 2rem !important'
  },
  approveRejectForecastModalCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  }
}));

export default function ApprovalsForecasts() {
  const classes = useStyles();
  const [forecastsAwaitingApproval, setForecastsAwaitingApproval] = useState([]);
  const [forecastsAwaitingRejection, setForecastsAwaitingRejection] = useState([]);
  const [open, setOpen] = useState(false);
  const [resultsModalOpen, setResultsModalOpen] = useState(false);
  const [forecastApprovalDetails, setForecastApprovalDetails] = useState(false);
  const [checked, setChecked] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedForecast, setSelectedForecast] = useState([]);
  const [loadingDownloadRewards, setLoadingDownloadRewards] = useState(false);
  const [isLoadingApprovalForecasts, setLoadingApprovalForecasts] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const forecastConfig = useRef({ data: {} }).current;
  const [notifyInit, notifySuccess, notifyFailed, notifyUpdateProgress, notifyDismiss] = useUpdatedToast();
  const [rejectedForecastsDataSheet, setRejectedForecastsDataSheet] = useState([]);
  const [forecastStatusDataSheet, setForecastStatusDataSheet] = useState([]);
  const [rewardsStatusDataSheet, setRewardsStatusDataSheet] = useState([]);

  const getForecastsAwaitingApproval = async () => {
    try {
      setLoadingApprovalForecasts(true);
      const { data } = await axios.get(`${baseUrl}/forecasts/approvals`);
      const config = forecastConfig.data;

      data.forEach(d => {
        d.checked = false;
      });

      setForecastsAwaitingApproval(
        data.filter(d => {
          let percentage = Math.round(d.percentage * 100) / 100;
          return (
            d.forecastType === 'submission' ||
            (d.forecastType === 'achievement' &&
              percentage >= 1 &&
              percentage <= 1 + config.percentage / 100 &&
              d.forecastedWatts >= config.achievedVolume)
          );
        })
      );
      setForecastsAwaitingRejection(
        data.filter(d => {
          let percentage = Math.round(d.percentage * 100) / 100;
          return (
            d.forecastType === 'submission' &&
            (percentage < 1 ||
              percentage > 1 + config.percentage / 100 ||
              d.forecastedWatts < config.achievedVolume)
          );
        })
      );
    } catch (err) {
      warningError(
        err,
        `There was a problem getting forecasts awaiting approval ${err.message}`
      );
    } finally {
      setLoadingApprovalForecasts(false);
    }
  };

  const getCurrentForecastConfig = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/configurations/forecasts`);
      forecastConfig.data = data;
    } catch (err) {
      warningError(
        err,
        `There was a problem getting forecast config ${err.message}`
      );
    }
  };

  const getCurrentPromotionForecastConfig = async () => {
    const subtractOneMonthAgo = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    const result = await axios.get(
      `${baseUrl}/promotions/month/${subtractOneMonthAgo}`
    );

    if (result && result.data && !_.get(result, 'data.statusCode')) {
      forecastConfig.data = result.data;
    } else {
      await getCurrentForecastConfig();
    }
  };

  const selectAll = e => {
    const forecastsAwaitingApprovalClone = [...forecastsAwaitingApproval];
    forecastsAwaitingApprovalClone.forEach(forecast => {
      forecast.checked = e.target.checked;
    });
    setForecastsAwaitingApproval(forecastsAwaitingApprovalClone);
    setChecked([...forecastsAwaitingApprovalClone.filter(c => c.checked)]);
    setIsSelectAllChecked(e.target.checked);
  };

  const handleCheck = (e, index) => {
    setForecastApprovalDetails(false);
    setSelectedForecast([]);
    const forecastsAwaitingApprovalClone = [...forecastsAwaitingApproval];
    forecastsAwaitingApprovalClone[index].checked = e.target.checked;
    setForecastsAwaitingApproval(forecastsAwaitingApprovalClone);
    setChecked([...forecastsAwaitingApprovalClone.filter(c => c.checked)]);
    setIsSelectAllChecked(
      forecastsAwaitingApprovalClone.every(forecast => forecast.checked)
    );
  };

  const rejectCompanyForecasts = async () => {
    setIsLoadingReject(true);
    try {
      const forecastsToReject = checked
        .map(c => c.forecastIds)
        .flat()
        .filter(current => current);
      const submissionIds = checked
        .filter(current => current.submissionId)
        .map(c => c.submissionId);
      await axios.post(`${baseUrl}/approvals/approve`, {
        types: ['forecasts', 'submission'],
        submission: submissionIds,
        data: forecastsToReject,
        forecastsAwaitingRejection,
        rejected: true
      });
      getForecastsAwaitingApproval();
      setResultsModalOpen(true);
    } catch (err) {
      warningError(
        err,
        `There was a problem rejecting forecasts awaiting approval ${err.message}`
      );
    } finally {
      setOpen(false);
      setIsLoadingReject(false);
    }
  };

  const approveCompanyForecasts = async () => {
    setIsLoadingApprove(true);
    try {
      const forecastsToApprove = checked
        .map(c => c.forecastIds)
        .flat()
        .filter(current => current);
      const submissionIds = checked
        .filter(current => current.submissionId)
        .map(c => c.submissionId);
      const { data } = await axios.post(`${baseUrl}/approvals/approve`, {
        types: ['forecasts', 'submission'],
        submission: submissionIds,
        data: forecastsToApprove,
        forecastsAwaitingRejection,
        forecasts: checked
      });

      getForecastsAwaitingApproval();
      setChecked([]);
      setIsSelectAllChecked(false);
      const Qpoint = () => {
        return (
          <div>
            {`You have successfully approved ${
              checked.length
            } forecasts for a total of 
            ${Number(
              data.approved.reduce((acc, curr) => acc + curr.amount, 0)
            ).toLocaleString()}Q`}
          </div>
        );
      };
      notiSuccess(<Qpoint />);
    } catch (err) {
      warningError(
        err,
        `There was a problem getting forecasts awaiting approval ${err.message}`
      );
    } finally {
      setIsLoadingApprove(false);
    }
  };

  const openForecastApprovalModal = forecast => {
    if(forecast.forecastType === 'achievement') {
      setForecastApprovalDetails(true);
      setSelectedForecast(forecast.codes);
    }

  };

  const closeForecastApprovalModal = () => {
    setForecastApprovalDetails(false);
    setSelectedForecast([]);
  };

  const downloadRejectedForecasts = async () => {
    setResultsModalOpen(false);
    const filtered = await checked.map(r => ({
      company: r.name,
      month: moment(r.month + 1, 'MM').format('MM'),
      year: r.year,
      forecastType: r.forecastType,
      qpoint:
        r.forecastType === ACHIEVEMENT_TYPE
          ? Number(forecastConfig.data.rate).toLocaleString() + ' Q'
          : Number(forecastConfig.data.submissionRate).toLocaleString() + ' Q',
      totalScannedVolume:
        Number(r.totalScannedWattage) >= 0
          ? Number(r.totalScannedWattage).toLocaleString() + ' W'
          : 'N/A',
      forecastedVolume: Number(r.forecastedWatts).toLocaleString() + ' W',
      percentage: `${r.percentage ? Math.round(r.percentage * 100) + '%' : 'N/A'}`,
      rejectedDate: moment().format(),
      forecastsRejected:
        r.forecastIds && r.forecastIds.length ? r.forecastIds.length : 'N/A'
    }));

    setRejectedForecastsDataSheet(filtered);
    document.getElementById('rejected-forecasts-data-sheet').click();
    setChecked([]);
    setIsSelectAllChecked(false);
  };

  const convertHTMLEntity = str => {
    return String(str)
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"');
  };

  const downloadRewardsStatus = async () => {
    notifyInit('Downloading...');
    const { data } = await axios.get(`${baseUrl}/rewards/rewardreport`, {
      onDownloadProgress: function(progressEvent) {
        const progress = progressEvent.loaded / progressEvent.total;
        notifyUpdateProgress(progress);
      }
    });

    try {
      const rewardsData = data.data.map(d => ({
        registrationDate: `${moment(d.createdAt).format('MM[-]DD[-]YYYY')} ${moment(
          d.createdAt
        ).format('hh:mm:ss')}`,
        approvedDate:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.approvedDate')
              ? `${moment(_.get(d, 'module.approvedDate')).format('MM[-]DD[-]YYYY')} ${moment(
                  d.createdAt
                ).format('hh:mm:ss')}`
              : ''
            : d.rewardType === 'orders'
            ? `${moment(_.get(d, 'order.createdAt')).format('MM[-]DD[-]YYYY')} ${moment(
                d.createdAt
              ).format('hh:mm:ss')}`
            : (d.rewardType === 'forecasts' && d.submissionStatus === 'Approved') ||
              (d.rewardType === 'forecasts' && d.status === 'Approved')
            ? `${moment(d.updatedAt).format('MM[-]DD[-]YYYY hh:mm:ss')}`
            : '',
        month:
          d.rewardType === 'forecasts'
            ? d.submissionStatus
              ? moment(d.forecastedAt).month() + 1
              : _.get(d, 'forecast.month') + 1
            : moment(d.updatedAt).month() + 1,
        quarter:
          d.rewardType === 'forecasts'
            ? d.submissionStatus
              ? moment(d.forecastedAt).quarter()
              : _.get(d, 'forecast.quarter')
            : moment(d.updatedAt).quarter(),
        year:
          d.rewardType === 'forecasts'
            ? d.submissionStatus
              ? moment(d.forecastedAt).year()
              : _.get(d, 'forecast.year')
            : moment(d.updatedAt).year(),
        rewardsType: d.rewardType,
        rewardsStatus: d.submissionStatus ? d.submissionStatus : d.status,
        company: convertHTMLEntity(_.get(d, 'company.name', '')),
        state: _.get(d, 'branch.state', ''),
        qpoint: d.qpoints,
        powerClass:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'articleCode.volume', '')
            : '',
        articleCode:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'articleCode.code', '')
            : '',
        productType:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'articleCode.productName', '')
            : '',
        zipCode:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.installationZipCode', '')
            : '',
        purchasingChannel:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.purchasingChannel', '')
            : '',
        nameOfPurchasingChannel:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.purchasingChannel') === 'QCELLS'
              ? 'Q CELLS'
              : _.get(d, 'module.purchasingChannel') === 'other'
              ? 'Other'
              : d.distributor
              ? _.get(d, 'distributor.name', '')
              : ''
            : '',
        source:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.source', '')
            : '',
        registeredBy: decodeHtml(`${_.get(d, 'user.firstName', '')} ${_.get(d, 'user.lastName', '')}`)
      }));

      setRewardsStatusDataSheet(rewardsData);
      document.getElementById('rewards-report-data-sheet').click();
      notifyDismiss();
    } catch (err) {
      notifyFailed(`Failed to download rewards report.`);
    }
  };

  const downloadForecastStatus = async () => {
    const { data } = await axios.get(`${baseUrl}/rewards/forecastreport`);
    const forecastData = data.map(d => ({
      registrationDate: moment(d.createdAt).format('l'),
      lastUpdated: moment(d.updatedAt).format('l'),
      month: +d.month + 1,
      quarter: d.quarter,
      year: d.year,
      company: d.company.name,
      state: _.get(d, 'branch.state', ''),
      powerClass: d.articleCode.volume,
      moduleType: d.articleCode.productName,
      forecastedVolumeW: d.quantity * d.articleCode.volume,
      forecastedQuantityPCS: d.quantity
    }));

    setForecastStatusDataSheet(forecastData);
    document.getElementById('forecasts-report-data-sheet').click();
  };

  const downloadAuditReport = async () => {
    const curent = moment().format('YYYY-MM-DD');
    const fileName = `auditReport_${curent}.xlsx`
    try {
      notifyInit('Downloading......');
      await axios.get(`${baseUrl}/companies/download-audit`, {
        responseType: 'blob',
      }).then((response) => {
        downloadReport(response.data, fileName)
      })
      notifyDismiss()
    } catch (error) {
      notifyFailed(`Failed to download audit report.`);
    }
  }

  const downloadWeeklyReport = async () => {
    const curent = moment().week();
    const fileName = `report_${curent}.xlsx`;
    try {
      notifyInit('Exporting report......');
      await axios.get(`${baseUrl}/companies/check-report-file`)
        .then( async (response) => {
          notifyDismiss()
          notiSuccess(response.data.message);
          const responseGenerate = await axios.get(`${baseUrl}/companies/download-weekly`, {responseType: 'blob'})
          downloadReport(responseGenerate.data, fileName)
        })
    } catch (error) {
      notifyFailed(`Failed to download weekly report.`);
    }
  }

  const decodeRenderHTML = rawHTML =>
    React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

  useEffect(() => {
    const fetchData = async () => {
      setLoadingApprovalForecasts(true);
      await getCurrentPromotionForecastConfig();
      await getForecastsAwaitingApproval();
    };

    fetchData();
  }, []);

  return (
    <>
      <Text size="h2" style={{ lineHeight: '0px' }}>
        Approvals
      </Text>
      <Grid container spacing={1} style={{ marginBottom: '16px' }}>
        <Grid item>
          <TextButton
            onClick={downloadRewardsStatus}
            disabled={loadingDownloadRewards}
          >
            Rewards Report
          </TextButton>
          <ExcelCustomComponent
            filename={`rewards-report-${new Date()}.csv`}
            id="rewards-report-data-sheet"
            data={rewardsStatusDataSheet}
          />
        </Grid>
        <Grid item>
          <TextButton onClick={downloadForecastStatus}>Forecast Report</TextButton>
          <ExcelCustomComponent
            filename={`forecasts-report-${new Date()}.csv`}
            id="forecasts-report-data-sheet"
            data={forecastStatusDataSheet}
          />
        </Grid>
        <Grid item>
          <TextButton onClick={downloadAuditReport}>Audit Report</TextButton>
        </Grid>
        <Grid item>
          <TextButton onClick={downloadWeeklyReport}>Weekly Report</TextButton>
        </Grid>
      </Grid>
      <Paper square style={{ paddingBottom: '20px' }}>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              height: '55px'
            }}
          >
            <Grid
              item
              component={Link}
              to="/rewards/module-approvals"
              style={{
                textDecoration: 'none',
                display: 'inline-block',
                cursor: 'pointer',
                marginTop: '-5px',
                paddingRight: '7px',
                fontWeight: '100'
              }}
            >
              <Text size="h4" color="dark" weight="regular">
                Modules
              </Text>
            </Grid>
            <Grid
              item
              style={{
                display: 'inline-block',
                cursor: 'pointer',
                marginLeft: '25px',
                marginTop: '-5px',
                paddingRight: '7px',
                fontWeight: '100'
              }}
            >
              <Text
                color="primaryLight"
                size="h4"
                weight="regular"
                style={{
                  borderBottom: 'solid',
                  borderColor: '#00ADEF',
                  paddingBottom: '10px'
                }}
              >
                Forecasts
              </Text>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              marginBottom: '2px',
              marginTop: '2px'
            }}
          >
            <Text size="h3" className="page_title">
              <span style={{ color: '#00ADEF' }}>
                {forecastsAwaitingApproval.length}
              </span>{' '}
              Forecasts Awaiting Approval
            </Text>
          </Grid>
        </div>
        {isLoadingApprovalForecasts ? (
          <div className="wr_loading_approval_modules">
            <CustomLoading size={40} color="#00adef"></CustomLoading>
          </div>
        ) : (
          <React.Fragment>
            {forecastsAwaitingApproval.length === 0 ? (
              <div className="wr_loading">No forecasts to approve</div>
            ) : (
              <div>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table size="small" className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headerItem}>#</TableCell>
                        <TableCell className={classes.headerItem}>Company</TableCell>
                        <TableCell className={classes.headerItem}>Month</TableCell>
                        <TableCell className={classes.headerItem}>
                          Forecast Type
                        </TableCell>
                        <TableCell className={classes.headerItem}>
                          Total Scanned (W)
                        </TableCell>
                        <TableCell className={classes.headerItem}>
                          Forecasted (W)
                        </TableCell>
                        <TableCell className={classes.headerItem}>Q.POINT</TableCell>
                        <TableCell className={classes.headerItem}>
                          Achieved
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            onChange={e => selectAll(e)}
                            style={{ color: '#9ca4ab' }}
                            checked={isSelectAllChecked}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {forecastsAwaitingApproval.map((f, idx) => (

                        <TableRow onClick={() => openForecastApprovalModal(f)} style={{cursor: `${f.forecastType === 'achievement' ? 'pointer' : 'default'}`}} key={idx}>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {forecastsAwaitingApproval.length - idx}
                          </TableCell>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {decodeRenderHTML(f.name)}
                          </TableCell>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {moment(f.month + 1, 'MM').format('MM')}, {f.year}
                          </TableCell>
                          <TableCell
                            style={{
                              fontFamily: 'Actual',
                              textTransform: 'capitalize'
                            }}
                          >
                            {f.forecastType}
                          </TableCell>

                          {Number(f.totalScannedWattage) >= 0 ? (
                            <TableCell
                              style={{ fontFamily: 'Actual' }}
                            >
                              {f.totalScannedWattage.toLocaleString()} W
                            </TableCell>
                          ) : (
                            <TableCell style={{ fontFamily: 'Actual' }}>
                              N/A
                            </TableCell>
                          )}

                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {Number(f.forecastedWatts) >= 0
                              ? Number(f.forecastedWatts).toLocaleString() + ' W'
                              : 'N/A'}
                          </TableCell>

                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {f.forecastType === ACHIEVEMENT_TYPE &&
                              Number(forecastConfig.data.rate).toLocaleString() +
                                ' Q'}
                            {f.forecastType === SUBMISSION_TYPE &&
                              Number(f.qpoints).toLocaleString() + ' Q'}
                          </TableCell>
                          <TableCell style={{ fontFamily: 'Actual' }}>
                            {Number(f.percentage) >= 0
                              ? Math.round(f.percentage * 100) + '%'
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            <Checkbox
                              onChange={e => handleCheck(e, idx)}
                              checked={f.checked}
                              style={{ color: '#9ca4ab' }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </React.Fragment>
        )}

        {checked.length > 0 ? (
          <div
            style={{
              width: '90%',
              margin: 'auto',
              marginTop: '20px',
              paddingBottom: '20px'
            }}
          >
            <Grid container justify="space-between" spacing={2}>
              <Grid item>
                <CustomButton color="secondary" onClick={() => setOpen(true)}>
                  Reject
                </CustomButton>
              </Grid>
              <Grid item>
                {isLoadingApprove ? (
                  <CustomButton disabled color="primary">
                    <CustomLoading color="#fff"></CustomLoading>
                  </CustomButton>
                ) : (
                  <CustomButton color="primary" onClick={approveCompanyForecasts}>
                    Approve
                  </CustomButton>
                )}
              </Grid>
            </Grid>
          </div>
        ) : (
          false
        )}
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={() => setOpen(false)}
                className={classes.approveRejectForecastModalCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.approveRejectForecastModalTitle}>
              Are you sure you want to reject{' '}
              <span style={{ color: '#00ADEF' }}>{checked.length}</span> forecasts?
            </Text>
            <Text
              color="secondaryDark"
              className={classes.approveRejectForecastModalSubtitle}
            >
              Once you do this, it can't be undone.
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={() => setOpen(false)}
                  className={classes.approveRejectForecastModalCancelBtn}
                >
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                {isLoadingReject ? (
                  <CustomButton color="alert" disabled style={{ height: '40px' }}>
                    <CustomLoading color="#fff"></CustomLoading>
                  </CustomButton>
                ) : (
                  <CustomButton
                    color="alert"
                    onClick={rejectCompanyForecasts}
                    className={classes.approveRejectForecastModalApproveBtn}
                  >
                    Reject Forecasts
                  </CustomButton>
                )}
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={resultsModalOpen}
        onClose={() => setResultsModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={resultsModalOpen}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={() => {
                  setResultsModalOpen(false);
                  setChecked([]);
                  setIsSelectAllChecked(false);
                }}
                className={classes.resultForecastRejectedForecastCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.resultForecastRejectedForecastTitle}>
              <span style={{ color: '#00ADEF' }}>{checked.length}</span> Forecasts
              Rejected
            </Text>
            <Text size="h4" color="secondaryDark" className={classes.resultForecastRejectedForecastSubtitle}>
              Download reject list?
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={downloadRejectedForecasts}
                  className={classes.resultForecastRejectedForecastDownloadBtn}
                >
                  Download
                </CustomButton>
                <ExcelCustomComponent
                  filename={`rejected-forecasts-${new Date()}.csv`}
                  id="rejected-forecasts-data-sheet"
                  data={rejectedForecastsDataSheet}
                />
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={forecastApprovalDetails}
        onClose={closeForecastApprovalModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={forecastApprovalDetails}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton onClick={closeForecastApprovalModal}>
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text size="h2">Module Types</Text>
            <TableContainer
              component={Paper}
              className={`${classes.tableContainer} ${classes.popupTableContainer}`}
            >
              <Table class={classes.popupTable}>
                <TableHead style={{ backgroundColor: '#f7f7f7' }}>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Module Type</TableCell>
                    <TableCell>Power Class (W)</TableCell>
                    <TableCell>Volume (W)</TableCell>
                    <TableCell>Quantity (pcs)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedForecast.map((f, idx) => (
                    <TableRow key={f.id}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{f.productName}</TableCell>
                      <TableCell>{Number(f.volume).toLocaleString()}</TableCell>
                      <TableCell>{Number(f.wattage).toLocaleString()} W</TableCell>
                      <TableCell>{Number(f.count).toLocaleString()} pcs</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <Text weight="bold">Total</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="primaryLight">
                        {selectedForecast
                          .reduce((a, b) => a + +b.wattage, 0)
                          .toLocaleString()}{' '}
                        W
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text color="primaryLight">
                        {selectedForecast
                          .reduce((a, b) => a + +b.count, 0)
                          .toLocaleString()}{' '}
                        pcs
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Alert>
        </Fade>
      </Modal>
    </>
  );
}
