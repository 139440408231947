import React, { useState, useEffect } from 'react';
import Text from '../../common/components/text';
import TextButton from '../../common/components/button/TextButton';
import 'react-dropzone-uploader/dist/styles.css';
import ChipInput from 'material-ui-chip-input';

import { TextField, Grid, InputLabel, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chip: {
    borderRadius: '3px'
  },
  subtitle: {
    color: '#8c8c8c'
  }
});

export default function ProductOption({
  id,
  index,
  isShowRemove,
  options,
  value,
  colors,
  sizes,
  customs,
  handleAddValue,
  handleDeleteValue,
  handleRemoveOption,
  handleChangeOption,
  handleChangeHex
}) {
  const classes = useStyles();
  const [values, setValues] = useState([]);

  const handleAdd = v => {
    const newValues = [...values, v];
    setValues(newValues);
    handleAddValue(value, v);
  };

  const handleDelete = (v, index) => {
    const newValues = values.filter((v, i) => i !== index);
    setValues(newValues);
    handleDeleteValue(value, v);
  };

  useEffect(() => {
    if (value === 'Size') {
      setValues(sizes);
    } else if (value === 'Color') {
      setValues(colors.map(c => c.name));
    } else if (value === 'Custom') {
      setValues(customs);
    }
  }, []);

  return (
    <Grid container key={id}>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Text weight="medium" className={classes.subtitle}>Option {index + 1}</Text>
          {isShowRemove && (
            <TextButton onClick={() => handleRemoveOption(id, value)}>
              Remove
            </TextButton>
          )}
        </Grid>
        <Grid container item spacing={1}>
          <Grid item md={2} xs={4}>
            <TextField
              select
              fullWidth
              variant="outlined"
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }
              }}
              placeholder="-Select-"
              style={{ marginBottom: '20px' }}
              value={value}
              onChange={e => handleChangeOption(index, e.target.value)}
            >
              {options.map(o => (
                <MenuItem className="filter_item" value={o.value}>
                  {o.option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={10} xs={8}>
            <ChipInput
              fullWidth
              variant="outlined"
              placeholder="Separate options with a comma"
              value={values}
              newChipKeys={['Enter', ',']}
              classes={{
                chip: classes.chip
              }}
              onAdd={v => handleAdd(v)}
              onDelete={(v, index) => handleDelete(v, index)}
            />
          </Grid>
        </Grid>
        {value === 'Color' && values.length > 0 && (
          <Grid container>
            <Grid item md={2} xs={0}></Grid>
            <Grid container item md={10} xs={12} spacing={2}>
              {colors.map((c, index) => (
                <Grid key={index} item md={2} xs={4}>
                  <InputLabel style={{ textAlign: 'left', marginBottom: '5px' }}>
                    {c.name}
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={c.hex}
                    onChange={e => handleChangeHex(index, e.target.value)}
                    variant="outlined"
                    placeholder="Hex Code"
                  ></TextField>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
