import React from 'react';

export const HIDE = 'hide';
export const SHOW = 'show';

export const NavbarStore = React.createContext(null);

export const initialState = {
  isShow: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        isShow: true
      };
    case HIDE:
      return {
        ...state,
        isShow: false
      };
    default:
      break;
  }
};

export function NavbarProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <NavbarStore.Provider value={value}>{props.children}</NavbarStore.Provider>;
}
