import React, { useState, useEffect } from 'react';
import Text from '../../common/components/text';
import Price from '../../common/components/price';
import CustomButton from '../../common/components/button';
import { Link } from 'react-router-dom';
import axios from '../../utils/axios';
import { baseUrl } from '../../config';
import Alert from './Alert';
import TextButton from '../../common/components/button/TextButton';
import moment from 'moment';

import {
  Paper,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Badge,
  TableContainer
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { notiSuccess, notiFailed, decodeHtml, downloadReport } from './../../utils/helpers';
import _ from 'lodash';
import CustomLoading from './../../../src/common/components/CustomLoading';
import useUpdatedToast from './../../common/customHooks/useUpdatedToast';
import { ExcelCustomComponent } from '../../utils/components';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    width: '93%',
    margin: 'auto',
    minWidth: 650,
    "& td, th": {
      fontFamily: 'Actual',
      margin: 0
    },
    "& th": {
      fontSize: '16px',
      fontWeight: 400
    },
    "& p": {
      margin: 0,
      fontSize: '14px'
    }
  },
  tableContainer: {
    boxShadow: 'none'
  },
  productTypeTable: {
    width: '85%'
  },
  tableWrapper: {
    overflowX: 'auto',
    width: 'inherit'
  },
  tableRow: {
    "&:hover": {
        cursor: 'pointer',
    }
  },
  downloadListBtn: {
    width: '50px',
    fontSize: '14px !important',
    padding: '.5rem !important',
    fontFamily: 'Actual',
    fontWeight: 400
  },
  productTypesModal: {
    "& td div": {
      fontWeight: 400,
      fontSize: '1rem'
    }
  },
  productTypesModalLastRow: {
    "& td p": {
      fontSize: "1rem"
    }
  },
  productTypesModalRows: {
    "& td": {
      fontWeight: 100
    }
  },
  navTab: {
    display: 'inline-block',
    cursor: 'pointer',
    marginTop: '-5px',
    paddingRight: '7px',
    fontWeight: '100'
  },
  resultModuleRejectedModalTitle: {
    fontSize: '40px',
    textAlign: 'center',
    marginBottom: '40px',
    marginTop: '15px'
  },
  resultModuleRejectedModalSubtitle: {
    marginTop: '-40px',
    fontSize: '20px !important'
  },
  resultModuleRejectModalDownloadBtn: {
    padding: '.75rem 2rem !important'
  },
  resultModuleRejectModalCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  },
  approveRejectModuleModalTitle: {
    textAlign: 'center',
    marginTop: '15px',
    fontSize: '40px',
    lineHeight: '45px'
  },
  approveRejectModuleModalSubtitle: {
    marginTop: '-32px',
    marginBottom: '20px',
    fontSize: '20px !important'
  },
  approveRejectModuleModalCancelBtn: {
    padding: '.75rem 2rem !important'
  },
  approveRejectModuleModalApproveBtn: {
    padding: '.75rem 2rem !important'
  },
  approveRejectModuleModalCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  }
}));

export default function ApprovalsModules() {
  const classes = useStyles();
  const [modulesAwaitingApproval, setModulesAwaitingApproval] = useState([]);
  const [checked, setChecked] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [modulesNeedAttention, setModulesNeedAttention] = useState(0);
  const [rejectedModules, setRejectedModules] = useState([]);
  const [open, setOpen] = useState(false);
  const [resultsModalOpen, setResultsModalOpen] = useState(false);
  const [moduleApprovalDetails, setModuleApprovalDetails] = useState(false);
  const [approvalDetails, setApprovalDetails] = useState([]);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isLoadingApprovalModules, setIsLoadingApprovalModules] = useState(false);
  const [loadingApprovalDetails, setLoadingApprovalDetails] = useState(false);
  const [
    notifyInit,
    notifySuccess,
    notifyFailed,
    notifyUpdateProgress,
    notifyDismiss
  ] = useUpdatedToast();
  const [
    notifyInitDownloadList,
    notifySuccessDownloadList,
    notifyFailedDownloadList,
    notifyUpdateProgressDownloadList,
    notifyDismissDownloadList
  ] = useUpdatedToast();
  const [
    modulesAwaitingApprovalDataSheet,
    setModulesAwaitingApprovalDataSheet
  ] = useState([]);
  const [forecastStatusDataSheet, setForecastStatusDataSheet] = useState([]);
  const [rewardsStatusDataSheet, setRewardsStatusDataSheet] = useState([]);
  const [rejectedModulesDataSheet, setRejectedModulesDataSheet] = useState([]);

  const getModulesAwaitingApproval = async () => {
    try {
      setIsLoadingApprovalModules(true);
      const { data } = await axios.get(`${baseUrl}/approvals/modules`);
      setModulesAwaitingApproval(data);
    } catch (err) {
      notiFailed(
        `There was a problem getting modules awaiting approval ${err.message}`
      );
    } finally {
      setIsLoadingApprovalModules(false);
    }
  };

  const getApprovalDetails = async id => {
    setModuleApprovalDetails(true);
    setLoadingApprovalDetails(true);
    try {
      const { data } = await axios.get(
        `${baseUrl}/approvals/companies/${id}/modules`
      );
      setLoadingApprovalDetails(false);
      setApprovalDetails(data);
    } catch (err) {
      setLoadingApprovalDetails(false);
      notiFailed(
        `There was a problem getting company module details ${err.message}`
      );
    }
  };

  const getModulesThatNeedAttention = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/approvals/download?count=1`);
      setModulesNeedAttention(data);
    } catch (err) {
      notiFailed(
        `There was a problem getting modules that needs attention ${err.message}`
      );
    }
  };

  const selectAll = () => {
    let currentChecked = [];
    if (!isSelectAllChecked) {
      currentChecked = [...modulesAwaitingApproval];
    }
    setChecked(currentChecked);
    setIsSelectAllChecked(currentChecked.length === modulesAwaitingApproval.length);
  };

  const handleCheck = value => () => {
    const doesExist = checked.some(c => c.companyId === value.companyId);
    let newCheck = [...checked];
    if (doesExist) {
      newCheck = checked.filter(c => c.companyId !== value.companyId);
    } else {
      newCheck.push(value);
    }
    setChecked(newCheck);
    setIsSelectAllChecked(newCheck.length === modulesAwaitingApproval.length);
  };

  const approveCompanyModules = async () => {
    try {
      setIsApproving(true);
      let list = [];
      checked.forEach(module => {
        module.configurations.forEach(c => {
          list.push(c.configurationType);
        });
      });
      let list2 = _.uniq(list);
      let body = {
        types: list2,
        data: checked.map(c => c.companyId),
        modules: checked
      };
      const { data } = await axios.post(`${baseUrl}/approvals/approve`, body);
      getModulesAwaitingApproval();
      setChecked([]);
      setIsSelectAllChecked(false);
      let sumAmount = 0;
      data.approved.forEach(o => {
        sumAmount += _.get(o, 'amount', 0);
      });
      notiSuccess(
        `Successfully approved all modules for the amount of ${Number(
          sumAmount
        ).toLocaleString()}Q qpoints.`
      );
    } catch (err) {
      notiFailed('Failed to approve company modules');
    } finally {
      setIsApproving(false);
    }
  };

  const rejectCompanyModules = async () => {
    setOpen(false);
    try {
      setIsRejecting(true);
      let list = [];
      checked.forEach(module => {
        module.configurations.forEach(c => {
          list.push(c.configurationType);
        });
      });
      let list2 = _.uniq(list);
      let body = {
        rejected: true,
        types: list2,
        data: checked.map(c => c.companyId),
        modules: checked
      };
      const { data } = await axios.post(`${baseUrl}/approvals/approve`, body);
      setResultsModalOpen(true);
      setRejectedModules(data);
      getModulesAwaitingApproval();
      setChecked([]);
      setIsSelectAllChecked(false);
    } catch (err) {
      notiFailed('Failed to reject company modules');
    } finally {
      setIsRejecting(false);
    }
  };

  const exportCsvModulesAwaitingApproval = async () => {
    notifyInitDownloadList('Downloading...');
    try {
      const { data } = await axios.get(`${baseUrl}/approvals/download`, {
        onDownloadProgress: function(progressEvent) {
          const progress = progressEvent.loaded / progressEvent.total;
          notifyUpdateProgressDownloadList(progress);
        }
      });

      setModulesAwaitingApprovalDataSheet(
        data.map(
          ({
            registrationDate,
            serialNumber,
            installationZipCode,
            configurationType,
            firstName,
            lastName,
            companyName,
            nameOfPurchasingChannel,
            purchasingChannel,
            source
          }) => ({
            registrationDate,
            serialNumber: serialNumber,
            configurationType,
            nameOfPurchasingChannel: nameOfPurchasingChannel
              ? nameOfPurchasingChannel
              : purchasingChannel,
            installationZipCode,
            firstName: decodeHtml(firstName),
            lastName: decodeHtml(lastName),
            companyName: decodeHtml(companyName),
            purchasingChannel,
            source
          })
        )
      );

      document.getElementById('modules-awaiting-approval-data-sheet').click();
      notifyDismissDownloadList();
    } catch (err) {
      notifyFailedDownloadList(
        `There was a problem getting modules that needs attention ${err.message}`
      );
    }
  };

  const exportRejectedModules = async () => {
    setResultsModalOpen(false);
    const filtered = await rejectedModules.approved.map(r => {
      let { rate, id, userId, companyId, ...rest } = r;
      return rest;
    });

    setRejectedModulesDataSheet(filtered);
    document.getElementById('rejected-modules-data-sheet').click();
  };

  const checkedModuleQuantity = () => {
    let total = 0;

    checked.forEach(c => {
      total += c.moduleQuantity ? +c.moduleQuantity : 0;
    });

    return total;
  };

  const convertHTMLEntity = str => {
    return String(str)
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"');
  };

  const downloadRewardsStatus = async () => {
    notifyInit('Downloading...');
    const { data } = await axios.get(`${baseUrl}/rewards/rewardreport`, {
      onDownloadProgress: function(progressEvent) {
        const progress = progressEvent.loaded / progressEvent.total;
        notifyUpdateProgress(progress);
      }
    });

    try {
      const rewardsData = data.data.map(d => ({
        registrationDate: `${moment(d.createdAt).format('MM[-]DD[-]YYYY')} ${moment(
          d.createdAt
        ).format('hh:mm:ss')}`,
        approvedDate:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.approvedDate')
              ? `${moment(_.get(d, 'module.approvedDate')).format('MM[-]DD[-]YYYY')} ${moment(
                  d.createdAt
                ).format('hh:mm:ss')}`
              : ''
            : d.rewardType === 'orders'
            ? `${moment(_.get(d, 'order.createdAt')).format('MM[-]DD[-]YYYY')} ${moment(
                d.createdAt
              ).format('hh:mm:ss')}`
            : (d.rewardType === 'forecasts' && d.submissionStatus === 'Approved') ||
              (d.rewardType === 'forecasts' && d.status === 'Approved')
            ? `${moment(d.updatedAt).format('MM[-]DD[-]YYYY hh:mm:ss')}`
            : '',
        month:
          d.rewardType === 'forecasts'
            ? d.submissionStatus
              ? moment(d.forecastedAt).month() + 1
              : _.get(d, 'forecast.month') + 1
            : moment(d.updatedAt).month() + 1,
        quarter:
          d.rewardType === 'forecasts'
            ? d.submissionStatus
              ? moment(d.forecastedAt).quarter()
              : _.get(d, 'forecast.quarter')
            : moment(d.updatedAt).quarter(),
        year:
          d.rewardType === 'forecasts'
            ? d.submissionStatus
              ? moment(d.forecastedAt).year()
              : _.get(d, 'forecast.year')
            : moment(d.updatedAt).year(),
        rewardsType: d.rewardType,
        rewardsStatus: d.submissionStatus ? d.submissionStatus : d.status,
        company: convertHTMLEntity(_.get(d, 'company.name', '')),
        state: _.get(d, 'branch.state', ''),
        qpoint: (+d.qpoints).toLocaleString(),
        powerClass:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? (+_.get(d, 'articleCode.volume', '')).toLocaleString()
            : '',
        articleCode:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'articleCode.code', '')
            : '',
        productType:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'articleCode.productName', '')
            : '',
        zipCode:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.installationZipCode', '')
            : '',
        purchasingChannel:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.purchasingChannel', '')
            : '',
        nameOfPurchasingChannel:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.purchasingChannel') === 'QCELLS'
              ? 'Q CELLS'
              : _.get(d, 'module.purchasingChannel') === 'other'
              ? 'Other'
              : d.distributor
              ? _.get(d, 'distributor.name', '')
              : ''
            : '',
        source:
          d.rewardType === 'modules' || d.rewardType === 'ess'
            ? _.get(d, 'module.source', '')
            : '',
        registeredBy: decodeHtml(`${_.get(d, 'user.firstName', '')} ${_.get(d, 'user.lastName', '')}`)
      }));

      setRewardsStatusDataSheet(rewardsData);
      document.getElementById('rewards-report-data-sheet').click();
      notifyDismiss();
    } catch (err) {
      notifyFailed(`Failed to download rewards report.`);
    }
  };

  const downloadForecastStatus = async () => {
    const { data } = await axios.get(`${baseUrl}/rewards/forecastreport`);

    const forecastData = data.map(d => ({
      registrationDate: moment(d.createdAt).format('l'),
      lastUpdated: moment(d.updatedAt).format('l'),
      month: +d.month + 1,
      quarter: d.quarter,
      year: d.year,
      company: d.company.name,
      state: _.get(d, 'branch.state', ''),
      powerClass: (+d.articleCode.volume).toLocaleString(),
      moduleType: d.articleCode.productName,
      forecastedVolumeW: (d.quantity * d.articleCode.volume).toLocaleString(),
      forecastedQuantityPCS: (+d.quantity).toLocaleString()
    }));

    setForecastStatusDataSheet(forecastData);
    document.getElementById('forecasts-report-data-sheet').click();
  };

  const downloadAuditReport = async () => {
    const curent = moment().format('YYYY-MM-DD');
    const fileName = `auditReport_${curent}.xlsx`
    try {
      notifyInit('Downloading......');
      await axios.get(`${baseUrl}/companies/download-audit`, {
        responseType: 'blob',
      }).then((response) => {
        downloadReport(response.data, fileName)
      })
      notifyDismiss()
    } catch (error) {
      notifyFailed(`Failed to download audit report.`);
    }
  }

  const downloadWeeklyReport = async () => {
    const curent = moment().week();
    const fileName = `report_${curent}.xlsx`;
    try {
      notifyInit('Exporting report......');
      await axios.get(`${baseUrl}/companies/check-report-file`)
        .then( async (response) => {
          notifyDismiss()
          notiSuccess(response.data.message);
          const responseGenerate = await axios.get(`${baseUrl}/companies/download-weekly`, {responseType: 'blob'})
          downloadReport(responseGenerate.data, fileName)
        })
    } catch (error) {
      notifyFailed(`Failed to download weekly report.`);
    }
  }

  useEffect(() => {
    getModulesAwaitingApproval();
    getModulesThatNeedAttention();
  }, []);

  const calculatePrice = a => {
    return a.qpoints;
  };

  return (
    <>
      <Text size="h2" style={{ lineHeight: '0px' }}>
        Approvals
      </Text>
      <Grid container spacing={1} style={{ marginBottom: '16px' }}>
        <Grid item>
          <TextButton onClick={downloadRewardsStatus}>Rewards Report</TextButton>
          <ExcelCustomComponent
            filename={`rewards-report-${new Date()}.csv`}
            id="rewards-report-data-sheet"
            data={rewardsStatusDataSheet}
          />
        </Grid>
        <Grid item>
          <TextButton onClick={downloadForecastStatus}>Forecast Report</TextButton>
          <ExcelCustomComponent
            filename={`forecasts-report-${new Date()}.csv`}
            id="forecasts-report-data-sheet"
            data={forecastStatusDataSheet}
          />
        </Grid>
        <Grid item>
          <TextButton onClick={downloadAuditReport}>Audit Report</TextButton>
        </Grid>
        <Grid item>
          <TextButton onClick={downloadWeeklyReport}>Weekly Report</TextButton>
        </Grid>
      </Grid>

      <Paper square style={{ paddingBottom: '20px' }}>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              height: '55px'
            }}
          >
            <Grid
              item
              className={classes.navTab}
            >
              <Text
                color="primaryLight"
                size="h4"
                weight="regular"
                style={{
                  borderBottom: 'solid',
                  borderColor: '#00ADEF',
                  paddingBottom: '10px'
                }}
              >
                Modules
              </Text>
            </Grid>
            <Grid
              item
              component={Link}
              to="/rewards/forecast-approvals"
              className={classes.navTab}
              style={{
                textDecoration: 'none'
              }}
            >
              <Text size="h4" color="dark" weight="regular">
                Forecasts
              </Text>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              maxWidth: '75vw',
              margin: '10px auto'
            }}
          >
            <Text size="h3" className="page_title">
              <span style={{ color: '#00ADEF' }}>
                {modulesAwaitingApproval.length}
              </span>{' '}
              Module Awaiting Approval
            </Text>
            <Grid item component={Link} to={'/rewards/module-awaiting-update'} style={{
              marginLeft: '25px',
              textDecoration: 'none'
            }}>
              <Badge badgeContent={modulesNeedAttention} color="primary">
                <CustomButton
                  className={classes.downloadListBtn}
                  color="secondary"
                >
                  Update List
                </CustomButton>
                <ExcelCustomComponent
                  filename={`modules-awaiting-approval-${new Date()}.csv`}
                  id="modules-awaiting-approval-data-sheet"
                  data={modulesAwaitingApprovalDataSheet}
                />
              </Badge>
            </Grid>
          </Grid>
        </div>

        {isLoadingApprovalModules ? (
          <div className="wr_loading_approval_modules">
            <CustomLoading size={40} color="#00adef"/>
          </div>
        ) : (
          <React.Fragment>
            {modulesAwaitingApproval.length === 0 ? (
              <div
                style={{
                  width: '90%',
                  margin: 'auto',
                  textAlign: 'center',
                  backgroundColor: '#e5f3fb',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  marginTop: '40px',
                  marginBottom: '20px'
                }}
              >
                <Text color="primaryLight" weight="bold">
                  No modules to approve.
                </Text>
              </div>
            ) : (
              <div>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table size="small" className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ color: '#9ca4ab' }}>#</TableCell>
                        <TableCell align="left" style={{ color: '#9ca4ab' }}>
                          Company
                        </TableCell>
                        <TableCell align="left" style={{ color: '#9ca4ab' }}>
                          Q.POINT
                        </TableCell>
                        <TableCell align="left" style={{ color: '#9ca4ab' }}>
                          Quantity (pcs)
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="#000000"
                            onChange={selectAll}
                            style={{ color: '#9ca4ab' }}
                            checked={isSelectAllChecked}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modulesAwaitingApproval.map((m, index) => (
                        <TableRow
                          hover
                          key={m.compandyId}
                          className={classes.tableRow}
                          onClick={() => getApprovalDetails(m.companyId)}
                        >
                          <TableCell>
                            {modulesAwaitingApproval.length - index}
                          </TableCell>
                          <TableCell align="left">{m.name}</TableCell>
                          <TableCell align="left">
                            <Price color="dark">{m.qpoints}</Price>
                          </TableCell>
                          <TableCell align="left">{m.moduleQuantity}</TableCell>
                          <TableCell align="right">
                            <Checkbox
                              color="#000000"
                              onChange={handleCheck(m)}
                              checked={checked.some(
                                c => c.companyId === m.companyId
                              )}
                              style={{ color: '#9ca4ab' }}
                              onClick={e => e.stopPropagation()}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </React.Fragment>
        )}

        {checked.length > 0 ? (
          <div
            style={{
              width: '90%',
              margin: 'auto',
              marginTop: '20px',
              paddingBottom: '20px'
            }}
          >
            <Grid container justify="space-between" spacing={2}>
              <Grid item>
                <CustomButton
                  disabled={isRejecting}
                  color="secondary"
                  onClick={() => setOpen(true)}
                >
                  {isRejecting ? (
                    <CustomLoading color="#00adef"/>
                  ) : (
                    'Reject'
                  )}
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  disabled={isApproving}
                  color="primary"
                  onClick={approveCompanyModules}
                >
                  {isApproving ? <CustomLoading/> : 'Approve'}
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        ) : (
          false
        )}
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={() => setOpen(false)}
                className={classes.approveRejectModuleModalCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.approveRejectModuleModalTitle}>
              Are you sure you want to reject{' '}
              <span style={{ color: '#00ADEF' }}>{checkedModuleQuantity()}</span>{' '}
              modules?
            </Text>
            <Text
              size="h4"
              color="secondaryDark"
              className={classes.approveRejectModuleModalSubtitle}
            >
              Once you do this, it can't be undone.
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton color="secondary" onClick={() => setOpen(false)} className={classes.approveRejectModuleModalCancelBtn}>
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton color="alert" onClick={rejectCompanyModules} className={classes.approveRejectModuleModalApproveBtn}>
                  Reject Modules
                </CustomButton>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={resultsModalOpen}
        onClose={() => setResultsModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={resultsModalOpen}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={() => setResultsModalOpen(false)}
                className={classes.resultModuleRejectModalCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.resultModuleRejectedModalTitle}>
              <span style={{ color: '#00ADEF' }}>{rejectedModules.length}</span>{' '}
              Modules Rejected
            </Text>
            <Text size="h4" color="secondaryDark" className={classes.resultModuleRejectedModalSubtitle}>
              Download reject list?
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton color="secondary" onClick={exportRejectedModules} className={classes.resultModuleRejectModalDownloadBtn}>
                  Download
                </CustomButton>
                <ExcelCustomComponent
                  filename={`rejected-modules-${new Date()}.csv`}
                  id="rejected-modules-data-sheet"
                  data={rejectedModulesDataSheet}
                />
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={moduleApprovalDetails}
        onClose={() => setModuleApprovalDetails(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={moduleApprovalDetails}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton onClick={() => setModuleApprovalDetails(false)}>
                <CloseOutlinedIcon fontSize="small" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text style={{ marginTop: '-40px', fontWeight: 500 }} size="h3">
              Product Types
            </Text>
            {!loadingApprovalDetails && (
              <div className={classes.tableWrapper}>
                <Table size="small" className={`${classes.table} ${classes.productTypesModal}`}>
                  <TableRow className="th">
                    <TableCell style={{ width: '30px' }}>
                      <div>#</div>
                    </TableCell>
                    <TableCell style={{ width: '250px' }}>
                      <div>Product Type</div>
                    </TableCell>
                    <TableCell style={{ width: '150px' }}>
                      <div>Q.POINT</div>
                    </TableCell>
                    <TableCell style={{ width: '150px' }}>
                      <div>Quantity (pcs)</div>
                    </TableCell>
                  </TableRow>
                  {approvalDetails.map((a, idx) => (
                    <TableRow key={idx} className={classes.productTypesModalRows}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{a.productName}</TableCell>
                      <TableCell>
                        <Price>{calculatePrice(a)}</Price>
                      </TableCell>
                      <TableCell>{a.moduleQuantity}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow className={classes.productTypesModalLastRow}>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <Text weight="bold">Total</Text>
                    </TableCell>
                    <TableCell>
                      <Price color="primaryLight">
                        {approvalDetails.reduce(
                          (a, b) => +a + +calculatePrice(b),
                          0
                        )}
                      </Price>
                    </TableCell>
                    <TableCell>
                      <Text color="primaryLight">
                        {approvalDetails.reduce((a, b) => a + +b.moduleQuantity, 0)}
                      </Text>
                    </TableCell>
                  </TableRow>
                </Table>
              </div>
            )}
            {loadingApprovalDetails && (
              <div className="wr_loading_approval_modules">
                <CustomLoading size={36} color="#00adef"/>
              </div>
            )}
          </Alert>
        </Fade>
      </Modal>
    </>
  );
}
