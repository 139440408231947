import { useState } from 'react';
import _ from 'lodash';
import { notiFailed } from './../../../utils/helpers';

const useSSOLoginForm = callback => {
  const [inputs, setInputs] = useState({});

  const { firstName, lastName, email, password } = inputs;

  const isValidLoginFormInputs = () => {
    if (email === undefined && password == undefined) {
      return true;
    }
    return email && password;
  };

  const isValidRegisterFormInputs = () => {
    return firstName && lastName && email && password;
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    if (_.isEmpty(inputs.email) && _.isEmpty(inputs.password)) {
      notiFailed('Email and Password is required!');
      return;
    } else if (_.isEmpty(inputs.email)) {
      return;
      notiFailed('Email is required!');
    } else if (_.isEmpty(inputs.password)) {
      return;
      notiFailed('Email is required!');
    }

    callback(inputs);
  };

  const handleLoginInputChange = event => {
    event.persist();

    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const handleRegisterInputChange = event => {
    event.persist();

    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  return {
    handleSubmit,
    handleRegisterInputChange,
    handleLoginInputChange,
    inputs,
    isValidLoginFormInputs,
    isValidRegisterFormInputs
  };
};

export default useSSOLoginForm;
