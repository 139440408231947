import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import BackButton from '../../common/components/button/BackButton';
import { Link, useHistory } from 'react-router-dom';
import Text from '../../common/components/text';
import DownloadIcon from '../../config/images/download-icon.png';
import DownloadIconOn from '../../config/images/download-icon-on.png';
import axios from '../../utils/axios';
import { baseUrl } from '../../config';
import _ from 'lodash';
import { APPROVAL_STEP } from '../../utils/constants';


const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  tableHeader: {
    background: '#f4f5f7',
    fontWeight: '500',
    fontSize : '15px'
  },
  table: {
    borderTop: '1px solid #e4e4e4',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  noteTableHeader: {
    textAlign: 'left',
    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px'
  },
  noteTable: {
    borderTop: '1px solid #e4e4e4',
    marginTop: '10px',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  emptyNoteList: {
    '& td': {
      padding: '81px 15px'
    }
  },
  emailText: {
    margin: '8px 12px 8px 0'
  },
  multiSelect: {
    '& .select__control': {
      borderRadius: 0,
      padding: '4px'
    },
    '& .select__multi-value__remove:hover': {
      backgroundColor: '#00adef',
      color: '#fff'
    }
  },
  w9ImageInput: {
    display: 'none'
  },
  w9ImageBtn: {
    border: '1px solid gray',
    height: '56px',
    marginLeft: '10px'
  },
  textArea: {
    width: '100%',
    padding: '5px'
  },
  newLetterText: {
    lineHeight: '40px',
    marginTop: 0,
    marginBottom: 0
  },
  newLetterRadio: {
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#019ee3'
    }
  },
  w9Download: {
    display: 'inline-block',
    position: 'relative',
    transition: 'color 0.4s',
    paddingLeft: '20px',
    background: `url(${DownloadIcon}) left 1px no-repeat`,
    '&:after': {
      position: 'absolute',
      left: '19px',
      bottom: 0,
      width: 0,
      height: '1px',
      background: '#019ee3',
      content: '""',
      transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#019ee3',
      background: `url(${DownloadIconOn}) left 1px no-repeat`,
    },
    '&:hover:after': {
      width: 'calc(100% - 20px)'
    }
  }
}));

function Survey() {

  const classes = useStyles();

  function renderTitle(index) {
    const actions = {
      7 : 'Through event',
      2: ' Through web ads',
      3 : 'Through social media',
      4 : 'Through other installers',
      6 : 'Through Q CELLS',
      1 : 'Through distribution',
      5 : 'Other',
    }

    return actions[index];
  }

  const [survey, setSurvey] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    getAnalytic();
  }, [])


  function sum(obj) {
    return Object.keys(obj).reduce((sum,key)=>sum+parseFloat(obj[key]||0),0);
  }


  async function getAnalytic() {
    const { data } = await axios.get(`${baseUrl}/users/registration/survey/analytic`);

    const total = sum(data);
    setTotal(total);

    setSurvey(data);
  }

  return(
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          marginBottom: 10,
          marginTop: 10
        }}
      >
        <BackButton component={Link} to="/qpartners/registration/list/1">
          List
        </BackButton>
      </Grid>

      <Paper className={classes.container}>
        <Grid style={{ padding: '40px 30px 15px' }} container>
          <Text className={"text-survey"} size="h3" style={{ lineHeight: '0px' }}>
            How did you hear about the Q.PARTNER Program?
          </Text>
        </Grid>

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableBody>
              {[7,2,3,4,6,1,5]?.map((item, index) => {
                return <TableRow key={index}>
                  <TableCell style={{width: '50%'}} align='center' className={classes.tableHeader}>{renderTitle(item)}</TableCell>
                  <TableCell align='left'>
                    {survey[item]}
                  </TableCell>
                  <TableCell align='left'>
                    {Math.round((survey[item] / total) * 100)} %
                  </TableCell>

                </TableRow>
              })}

            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default Survey;
