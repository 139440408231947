import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OfflineTraining from '../../pages/offline-training';
import FormAddProduct from '../../pages/offline-training/components/FormAddProduct';
import FormEditProduct from '../../pages/offline-training/components/FormEditProduct';

export default function Offline() {
  return(
    <Switch>
      <Route
        exact
        path="/admincontrols/configurations/list"
        component={OfflineTraining}
      />

      <Route
        exact
        path="/admincontrols/configurations/add"
        component={FormAddProduct}
      />

      <Route
        exact
        path="/admincontrols/configurations/edit/:id"
        component={FormEditProduct}
      />

    </Switch>
  )
}
