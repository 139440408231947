import React from 'react';
import styled from 'styled-components';
import Text from '../text';

const SmallText = styled.span`
  font-size: calc(${({ size, theme }) => theme.fontSizes[size]} - 5px);
`;

const Price = ({
  children,
  currency = 'Q',
  size = 'h5',
  color = 'secondaryDark',
  ...props
}) => (
  <Text color={color} size={size} {...props}>
    {Number(children).toLocaleString()}
    {` `}
    {currency}
  </Text>
);

export default Price;
