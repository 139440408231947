import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '50rem',
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 20px 56px 20px',
    borderRadius: '0px',
    [theme.breakpoints.down('xs')]: {
      width: '85%'
    }
  }
}));

export default function Alert(props) {
  const classes = useStyles();

  return (
    <div className={`${classes.paper} ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
}
