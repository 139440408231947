import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {UserAdminProvider} from '../../contexts/admin';
import AdminManagementList from '../../pages/adminManagement';
import FormAddUserAdmin from '../../pages/adminManagement/components/FormAddUserAdmin';
import FormEditUserAdmin from '../../pages/adminManagement/components/FormEditUserAdmin';

export default function AdminManagement() {
  return(
    <UserAdminProvider>
      <Switch>
        <Route
          exact
          path="/admincontrols/management"
          component={AdminManagementList}
        />

        <Route
          exact
          path="/admincontrols/management/add"
          component={FormAddUserAdmin}
        />

        <Route
          exact
          path="/admincontrols/management/:id"
          component={FormEditUserAdmin}
        />
      </Switch>
    </UserAdminProvider>

  )
}
