import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Text from '../components/text';

import { List, ListItem, ListItemText, Button, Popover } from '@material-ui/core';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';

const StyledButton = styled(Button)(
  props => `
  height: 60px;
  border-radius: 0;

  &:hover {
    background-color: '#FFF';
  }

  ${props.open &&
    `
    color: #00ADEF;
    border-bottom: 4px solid #00ADEF;
    font-weight: 700; 
    border-bottom: solid;
    border-bottom-color: #00ADEF;
  `}
`
);

const CustomPopover = styled(Popover)`
  & .MuiPaper-rounded {
    border-radius: 0px;
  }

  & .MuiTypography-body2 {
    font-size: 15px;
  }

  & .MuiTypography-body2:hover {
    color: #00adef;
  }

  & .MuiListItem-button:hover {
    background-color: #fff;
  }
`;

const useStyles = makeStyles(theme => ({
  buttonStyle: { borderBottom: 'solid', borderBottomColor: '#00ADEF' }
}));

export default function NavButton({ buttonText, links, icon }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <StyledButton
        aria-describedby={id}
        color="primary"
        onClick={handleClick}
        className={open ? classes.buttonStyle : ''}
        // style={{ width: '264px', textTransform: 'uppercase', textAlign: 'left' }}
      >
        <Text
          color="dark"
          weight="medium"
          style={{ letterSpacing: 'normal', fontSize: '14px', fontWeight: 600 }}
          className="navbar_item"
        >
          <span dangerouslySetInnerHTML={{__html: buttonText}} />
        </Text>
      </StyledButton>
      {icon ? (
        open ? (
          <KeyboardArrowUpOutlinedIcon />
        ) : (
          <KeyboardArrowDownOutlinedIcon />
        )
      ) : (
        false
      )}
      <CustomPopover
        elevation={2}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        style={{ borderRadius: 0 }}
      >
        <List dense style={{ width: '200px', textTransform: 'uppercase' }}>
          {links.map((link, index) => {
            return (
              <ListItem
                button
                key={index}
                href={link.href}
                component={link.href ? 'a' :Link}
                to={link.path}
                onClick={link.onClick}
                style={{
                  // border: '1px solid #DFDFDF',
                  color: '#9A9A9A',
                  height: '51px'
                }}
              >
                <ListItemText
                  disableTypography
                  primary={<span className="navbar_item">{link.link}</span>} 
                />
              </ListItem>
            );
          })}
        </List>
      </CustomPopover>
    </>
  );
}
