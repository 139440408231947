import React, { useCallback } from 'react';
import { profileService } from '../../services';



export async function getProfile(dispatch) {
  try {

    await profileService.getProfile().then((res) => {
      dispatch({
        type : 'GET_PROFILE_SUCCESS',
        payload : res.data
      })
    });


  }catch (e) {

  }
}




