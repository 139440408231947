import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

const StyledButton = styled(ButtonBase)(
  ({ theme: { colors }, color, minWidth }) => `
  && {
    min-width: ${ minWidth ? minWidth : '11.5rem' };
    font-size: 1rem;
    line-height: 1rem;
    padding: .5rem 2rem;
    border-radius: 100px;
    transition: background-color 175ms ease-out;
    background-color: ${
      color === 'primary'
        ? colors.primaryLight
        : color === 'alert'
        ? '#eb4034'
        : colors.light
    };
    color: ${
      color === 'primary'
        ? colors.light
        : color === 'alert'
        ? '#ffffff'
        : colors.secondaryDark
    };
    border: 1px solid
      ${
        color === 'primary'
          ? 'transparent'
          : color === 'alert'
          ? 'transparent'
          : colors.secondaryDark
      };

    &:hover {
      background-color: ${
        color === 'primary'
          ? colors.primaryDark
          : color === 'alert'
          ? '#e66565'
          : colors.secondaryLight
      };
    }
  }
`
);

/**
 * @name Button
 * @description Rounded Button component
 * @param {string} color The color theme of the button. Can either be "primary" or "secondary"
 * @param {node} children The content inside the button. ie "Add to Cart"
 * @extends {ButtonBase}
 */
const Button = ({ color = 'primary', children, ...props }) => {
  return (
    <StyledButton color={color} {...props}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'alert']),
  children: PropTypes.node.isRequired,
  ...ButtonBase.propTypes
};

export default Button;
