import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import CustomButton from '../../common/components/button';
import DatePicketCustom from '../../utils/components/datePicket';
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TableContainer,
  IconButton,
  TextField,
  InputAdornment,
  Fade,
  Modal,
  Backdrop, 
  Badge,
  ListItem,
  ListItemText,
  Menu,
  MenuItem
} from '@material-ui/core';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import axios from '../../utils/axios';
import { notiSuccess, notiFailed, decodeHtml } from './../../utils/helpers';
import { baseUrl } from '../../config';
import CustomLoading from './../../common/components/CustomLoading';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import Pagination from '@material-ui/lab/Pagination';
import { convertHTMLEntity } from '../../utils/helpers';
import _ from 'lodash';
import Text from '../../common/components/text';
import Alert from './Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { ExcelCustomComponent } from '../../utils/components';
import { Link } from 'react-router-dom';
import useUpdatedToast from '../../common/customHooks/useUpdatedToast';
import BackButton from '../../common/components/button/BackButton';

const useStyles = makeStyles(theme => ({
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '40px'
  },
  iconButton: {
    padding: 0,
    position: 'absolute',
    top: '13px',
    right: '15px'
  },
  datePickerContainer: {
    position: 'relative'
  },
  tableHeaderCell: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1rem'
  },
  approveRejectContainer: {
    margin: 'auto',
    marginTop: '20px'
  },
  paginationContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    '& .Mui-selected': {
      backgroundColor: '#00ADEF',
      color: '#fff'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  approveRejectModuleModalCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  },
  approveRejectModuleModalTitle: {
    textAlign: 'center',
    marginTop: '15px',
    fontSize: '40px',
    lineHeight: '45px'
  },
  approveRejectModuleModalSubtitle: {
    marginTop: '-32px',
    marginBottom: '20px',
    fontSize: '20px !important'
  },
  approveRejectModuleModalCancelBtn: {
    padding: '.75rem 2rem !important'
  },
  approveRejectModuleModalApproveBtn: {
    padding: '.75rem 2rem !important'
  },
  resultModuleRejectModalCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  },
  resultModuleRejectedModalTitle: {
    fontSize: '40px',
    textAlign: 'center',
    marginBottom: '40px',
    marginTop: '15px'
  },
  resultModuleRejectedModalSubtitle: {
    marginTop: '-40px',
    fontSize: '20px !important'
  },
  resultModuleRejectModalDownloadBtn: {
    padding: '.75rem 2rem !important'
  },
  noModuleContainer: {
    width: '95%',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: '#e5f3fb',
    paddingTop: '50px',
    paddingBottom: '50px',
    marginTop: '50px',
    marginBottom: '50px'
  },
  tableContainer: {
    marginBottom: '20px'
  },

  wrapSearchModule: {
    marginBottom: 10
  },
  reasonUpdateField: {
    width: '200px',
    marginLeft: '5px',
    borderRadius: '4px',
    "& .MuiListItemText-root": {
        marginTop: 0,
        marginBottom: 0
    },
    "& .purposeAction": {
        display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0
    }
},
  addNewReason: {
    "& .MuiInputBase-root": {
        fontSize: '13px'
    },
    "& .MuiInput-underline:after": {
        borderBottom: 0
    },
    "& .MuiInput-underline:before": {
        borderBottom: 0
    },
    "& .MuiInput-underline:hover:before": {
        borderBottom: 0
    },
    padding: '8px 12px'
},
  reasonListItem: {
    paddingRight: '6px',
    "& .MuiListItemText-root": {
        marginTop: 0,
        marginBottom: 0
    },
    "&:hover": {
        backgroundColor: '#c9e7f7',
        cursor: 'pointer'
    },
    "&:hover .MuiListItemText-root": {
        color: '#00adef'
    },
    "&:hover.Mui-selected": {
        backgroundColor: '#c9e7f7'
    }
},
reasonUpdateFieldHelperText: {
  position: 'absolute',
  top: '-30px',
  left: '8px',
  fontSize: '12px',
  fontFamily: 'Actual_Light',
  color: '#C8C8C8'
},
}));

function ReasonListItem({
  reason,
  handleClickReasonActionIcon,
  handleRenameReasonType,
  ...rest
}) {
  const classes = useStyles();

  return reason.isEdit ? (
    <div className={classes.addNewReason}>
      <TextField
        autoFocus={true}
        onClick={e => e.stopPropagation()}
        fullWidth
        type="text"
        defaultValue={reason.name}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && e.target.value !== '') {
              handleRenameReasonType(reason.id, e.target.value);
          }
        }}
      />
    </div>
  ) : (
    <ListItem className={classes.reasonListItem} {...rest}>
      <ListItemText primary={reason.name}></ListItemText>
      <MoreHorizIcon
        className="purposeAction"
        onClick={handleClickReasonActionIcon}
        value={reason.id}
      />
    </ListItem>
  );
}

export default function UpdateModules() {
  const classes = useStyles();
  const [modulesAwaitingUpdate, setModulesAwaitingUpdate] = useState([]);
  const [countModuleAwaitingUpdate, setCountModuleAwaitingUpdate] = useState(0);
  const [totalCompany, setTotalCompany] = useState(0);
  const [checked, setChecked] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [loadingModuleAwaitingUpdate, setLoadingModuleAwaitingUpdate] = useState(false);
  const [filters, setFilters] = useState({ limit, offset });
  const [betweenDates, setBetweenDates] = useState({
    startDate: null,
    endDate: null
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [isApproving, setIsApproving] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [resultsModalOpen, setResultsModalOpen] = useState(false);
  const [rejectedModules, setRejectedModules] = useState(null);
  const [rejectedModulesDataSheet, setRejectedModulesDataSheet] = useState([]);
  const [reasonTypes, setReasonTypes] = useState([]);
  const isOpenReasonSelect = useRef(null);
  const [newReason, setNewReason] = useState('');
  const [selectedReasonType, setSelectedReasonType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openReasonActionMenu = Boolean(anchorEl);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    modulesAwaitingApprovalDataSheet,
    setModulesAwaitingApprovalDataSheet
  ] = useState([]);

  const getReasonTypes = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/reason-type/search`);
      setReasonTypes(data.map(reasonType => ({ ...reasonType, isEdit: false })));
    } catch (err) {
      notiFailed(`There was a problem getting reason types: ${err.message}`);
    }
  };

  const handleResetReasonTypes = () => {
    const newReasonTypes = reasonTypes.map(reasonType => ({ ...reasonType, isEdit: false }));
    setReasonTypes(newReasonTypes);
    setNewReason('');
  };

  const handleCreateReasonType = async () => {
    if (newReason === '') return;

    try {
      const { data } = await axios.post(`${baseUrl}/reason-type`, {
        name: newReason
      });
      setReasonTypes([...data, ...reasonTypes]);
      setNewReason('');
    } catch (err) {
      notiFailed(`There was a problem creating purpose types: ${err.message}`);
    }
  };

  const getFilterReasonTypes = () => {
    if (newReason === '') return reasonTypes;

    return reasonTypes.filter(reasonType => reasonType.name.toLowerCase().includes(newReason.toLowerCase()));
  };

  const handleClickReasonActionIcon = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleRenameReasonType = async (id, name) => {
    try {
      const { data } = await axios.patch(`${baseUrl}/reason-type`, {
        id,
        name
      });
      setReasonTypes(reasonTypes.map(reasonType => {
        if (reasonType.id === id) {
          return {
            ...reasonType,
            name,
            isEdit: false
          }
        }

      return reasonType;
      }));
    } catch (err) {
      notiFailed(
        `There was a problem updating the reason type name: ${err.message}`
      );
    }
  };

  const handleCloseReasonActionMenu = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeToRename = e => {
    const id = anchorEl.getAttribute('value');
    const newReasonTypes = reasonTypes.map(reasonType => {
      if (reasonType.id === +id) {
        return {
          ...reasonType,
          isEdit: true
        };
      }

      return {
        ...reasonType,
        isEdit: false
      };
    });
    setReasonTypes(newReasonTypes);
    handleCloseReasonActionMenu(e);
  };

  const handleCloseDeleteModal = () => {
    setAnchorEl(null);
    setOpenDeleteModal(false);
  };

  const handleDeleteReasonType = async () => {
    const id = anchorEl.getAttribute('value');
    setAnchorEl(null);

    try {
      const { data } = await axios.delete(`${baseUrl}/reason-type`, {
        params: { id }
    });
      setReasonTypes(reasonTypes.filter(reasonType => reasonType.id !== +id));
      setSelectedReasonType(null);
    } catch (err) {
      notiFailed(
        `There was a problem deleting the reason type name: ${err.message}`
      );
    } finally {
      setOpenDeleteModal(false);
    }
  };

  const [
    notifyInitDownloadList,
    notifySuccessDownloadList,
    notifyFailedDownloadList,
    notifyUpdateProgressDownloadList,
    notifyDismissDownloadList
  ] = useUpdatedToast();

  const selectAll = () => {
    let currentChecked = [];
    if (!isSelectAllChecked) {
      currentChecked = [...modulesAwaitingUpdate];
    }
    setChecked(currentChecked);
    setIsSelectAllChecked(currentChecked.length === modulesAwaitingUpdate.length);
  };

  const handleCheck = value => () => {
    const doesExist = checked.some(c => c.id === value.id);
    let newCheck = [...checked];
    if (doesExist) {
      newCheck = checked.filter(c => c.id !== value.id);
    } else {
      newCheck.push(value);
    }
    setChecked(newCheck);
    setIsSelectAllChecked(newCheck.length === modulesAwaitingUpdate.length);
  };

  const getUpdateModules = async (filters = {}) => {
    try {
      setLoadingModuleAwaitingUpdate(true);
      const { data } = await axios.get(`${baseUrl}/modules/awaiting-approval/search`, { params: filters });
      setModulesAwaitingUpdate(data.data);
      setCountModuleAwaitingUpdate(data.total);
      setTotalCompany(data.totalCompany);
      const numberPage = Math.ceil(data.total / limit);
      setCountPage(numberPage);
      setLoadingModuleAwaitingUpdate(false);
    } catch (err) {
      setLoadingModuleAwaitingUpdate(false);
      notiFailed(`There was a problem getting the products ${err.message}`);
    }
  };

  const handleSearch = async () => {
    let filter = {
      ...filters,
      keyword: searchField,
      offset: 0
    };

    setOffset(0);
    setCurrentPage(1);
    setFilters(filter);
    getUpdateModules(filter);
  };

  const handleStartDateChange = date => {
    const startDate = moment(date)
      .startOf('day')
      .format();
    setBetweenDates({ ...betweenDates, startDate });
    const filter = {
      ...filters,
      startDate,
      offset: 0
    };
    setOffset(0);
    setCurrentPage(1);
    setFilters(filter);
    getUpdateModules(filter);
  };

  const handleEndDateChange = date => {
    const endDate = moment(date)
      .endOf('day')
      .format();
    setBetweenDates({ ...betweenDates, endDate });
    const filter = {
      ...filters,
      endDate,
      offset: 0
    };
    setOffset(0);
    setCurrentPage(1);
    setFilters(filter);
    getUpdateModules(filter);
  };

  const handleResetDate = type => {
    setBetweenDates({ ...betweenDates, [type]: null });
    const filter = { ...filters, offset: 0 };
    delete filter[type];
    setOffset(0);
    setCurrentPage(1);
    setFilters(filter);
    getUpdateModules(filter);
  };

  const handleChangePage = (event, currentPage) => {
    setCurrentPage(currentPage);
    const newOffset = limit * currentPage - limit;
    setOffset(newOffset);
    const filter = {
      ...filters,
      offset: newOffset
    };
    getUpdateModules(filter);
  };

  const approveUpdateModule = async () => {
    try {
      setIsApproving(true);
      let body = {
        types: ['modules-awaiting'],
        moduleIds: checked.map(m => m.id)
      };
      const { data } = await axios.post(`${baseUrl}/approvals/approve`, body);
      setOffset(0);
      setCurrentPage(1);
      setChecked([]);
      setIsSelectAllChecked(false);
      const filter = { ...filters, offset: 0 };
      getUpdateModules(filter);
      let sumAmount = 0;
      data.approved.forEach(o => {
        sumAmount += _.get(o, 'amount', 0);
      });
      notiSuccess(
        `Successfully approved all modules for the amount of ${Number(
          sumAmount
        ).toLocaleString()}Q qpoints.`
      );
    } catch (err) {
      notiFailed('Failed to approve updated modules');
    } finally {
      setIsApproving(false);
    }
  };

  const rejectUpdatedModules = async () => {
    setOpenRejectModal(false);
    try {
      setIsRejecting(true);
      let body = {
        rejected: true,
        types: ['modules-awaiting'],
        moduleIds: checked.map(c => c.id),
        rejectionNote: selectedReasonType
      };
      const { data } = await axios.post(`${baseUrl}/approvals/approve`, body);
      setResultsModalOpen(true);
      setRejectedModules(data);
      setChecked([]);
      setIsSelectAllChecked(false);
      setOffset(0);
      setCurrentPage(1);
      const filter = { ...filters, offset: 0 };
      getUpdateModules(filter);
    } catch (err) {
      notiFailed('Failed to reject updated modules');
    } finally {
      setIsRejecting(false);
    }
  };

  const exportRejectedModules = async () => {
    setResultsModalOpen(false);
    const filtered = await rejectedModules.approved.map(r => {
      let { rate, id, userId, companyId, approvedBy, ...rest } = r;

      return {
        ...rest,
        registrationDate : moment(r.registrationDate).format('YYYY-MM-DD'),
        approvedBy: `${approvedBy.firstName} ${approvedBy.lastName}`
      };
    });

    setRejectedModulesDataSheet(filtered);
    document.getElementById('update-rejected-modules-data-sheet').click();
  };

  async function exportCsvModulesAwaitingApproval() {
    notifyInitDownloadList('Downloading...');
    try {
        const { data } = filters.keyword || filters.startDate || filters.endDate ?  
        await axios.get(`${baseUrl}/modules/awaiting-approval/filtered-search`, { params: filters }) :
        await axios.get(`${baseUrl}/approvals/download`, {
          onDownloadProgress: function(progressEvent) {
            const progress = progressEvent.loaded / progressEvent.total;
            notifyUpdateProgressDownloadList(progress);
          }
        });
      setModulesAwaitingApprovalDataSheet(
        data.map(
          ({
             registrationDate,
             serialNumber,
             installationZipCode,
             configurationType,
             firstName,
             lastName,
             companyName,
             nameOfPurchasingChannel,
             purchasingChannel,
             source,
             email,
             mobileNumber
           }) => ({
            registrationDate,
            serialNumber: serialNumber,
            configurationType,
            nameOfPurchasingChannel: nameOfPurchasingChannel
              ? nameOfPurchasingChannel
              : purchasingChannel,
            installationZipCode,
            firstName: decodeHtml(firstName),
            lastName: decodeHtml(lastName),
            companyName: decodeHtml(companyName),
            purchasingChannel,
            source,
            email,
            mobileNumber
          })
        )
      );

      document.getElementById('modules-awaiting-approval-data-sheet').click();
      notifyDismissDownloadList();
    } catch (err) {
      notifyFailedDownloadList(
        `There was a problem getting modules that needs attention ${err.message}`
      );
    }
  }

  useEffect(() => {
    getUpdateModules(filters);
    getReasonTypes();
  }, []);
  
  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          marginBottom: 10
        }}
      >
        <div>
          <BackButton component={Link} to="/rewards/module-approvals">
            Approvals
          </BackButton>
          <Text size="h2" style={{ lineHeight: '0px' }}>
            Update Modules
          </Text>
        </div>
      </Grid>


      <Paper>
        <Grid style={{ padding: '40px 10px 0px 10px' }} className={classes.wrapSearchModule} container spacing={1}>
          <Grid item xs={12} md={8} lg={8} sm={8}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Search"
              value={searchField}
              onChange={e => setSearchField(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon style={{ color: '#BDBDBD' }}/>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2} sm={2} className={classes.datePickerContainer}>
            <DatePicketCustom
              fullWidth
              size="small"
              variant="inline"
              format="MM/dd/yyyy"
              placeholder="Start Date"
              inputVariant="outlined"
              value={betweenDates.startDate}
              onChange={handleStartDateChange}
              maxDate={betweenDates.endDate || new Date()}
              views={['year', 'month', 'date']}
              disablePast={false}
            />
            <IconButton
              className={classes.iconButton}
              edge="end"
              size="small"
              disabled={!betweenDates.startDate}
              onClick={() => handleResetDate('startDate')}
            >
              <ClearIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={12} md={2} lg={2} sm={2} className={classes.datePickerContainer}>
            <DatePicketCustom
              fullWidth
              size="small"
              variant="inline"
              format="MM/dd/yyyy"
              placeholder="End Date"
              inputVariant="outlined"
              value={betweenDates.endDate}
              onChange={handleEndDateChange}
              minDate={betweenDates.startDate}
              maxDate={new Date()}
              views={['year', 'month', 'date']}
              disablePast={false}
            />
            <IconButton
              className={classes.iconButton}
              edge="end"
              size="small"
              disabled={!betweenDates.endDate}
              onClick={() => handleResetDate('endDate')}
            >
              <ClearIcon/>
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{
            padding: 15,
            marginBottom: 10,
            borderBottom: '0.5px solid rgb(224 224 224)'
          }}
        >
          <div>
            <Text size="h3" className="page_title">
              <span style={{ color: '#00ADEF' }}>
                {`${countModuleAwaitingUpdate}`}
              </span>{' '}
              Module Awaiting Approval
            </Text>
            <Text size="h3" className="page_title">
              <span style={{ color: '#00ADEF' }}>
                {`${totalCompany}`}
              </span>{' '}
              Number of Companies
            </Text>
          </div>
          <Grid item component={Link} to={'/rewards/module-awaiting-update'} style={{
            textDecoration: 'none'
          }}>
            <Badge badgeContent={countModuleAwaitingUpdate} color="primary">
              <CustomButton
                className={classes.downloadListBtn}
                color="secondary"
                onClick={exportCsvModulesAwaitingApproval}
              >
                Download
              </CustomButton>
              <ExcelCustomComponent
                filename={`modules-awaiting-approval-${new Date()}.csv`}
                id="modules-awaiting-approval-data-sheet"
                data={modulesAwaitingApprovalDataSheet}
              />
            </Badge>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  Company Name
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Username
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Registration Date
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Configuration Type
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Serial Number
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    onChange={selectAll}
                    style={{ color: '#9ca4ab' }}
                    checked={isSelectAllChecked}
                    disabled={modulesAwaitingUpdate.length === 0}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingModuleAwaitingUpdate ? (
                <TableRow>
                  <TableCell colSpan="6">
                    <div className={classes.loadingIndicator}>
                      <CustomLoading size={40} color="#00adef"></CustomLoading>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {
                    modulesAwaitingUpdate.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan="6">
                          <div className={classes.noModuleContainer}>
                            <Text color="primaryLight" weight="bold">
                              No modules to reject.
                            </Text>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : modulesAwaitingUpdate.map((module, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {convertHTMLEntity(module.name)}
                          </TableCell>
                          <TableCell>
                            {`${module.firstName} ${module.lastName}`}
                          </TableCell>
                          <TableCell>
                            {moment(module.createdAt).format('MM[-]DD[-]YYYY')}
                          </TableCell>
                          <TableCell>
                            {module.configurationType}
                          </TableCell>
                          <TableCell>
                            {module.serialNumber}
                          </TableCell>
                          <TableCell align="right">
                            <Checkbox
                              onChange={handleCheck(module)}
                              checked={checked.some(
                                c => c.id === module.id
                              )}
                              style={{ color: '#9ca4ab' }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>


      {checked.length > 0 ? (
        <div className={classes.approveRejectContainer}>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <CustomButton
                color="secondary"
                onClick={() => setOpenRejectModal(true)}
                disabled={isRejecting}
              >
                {isRejecting ? (
                  <CustomLoading color="#00adef"/>
                ) : (
                  'Reject'
                )}
              </CustomButton>
            </Grid>
          </Grid>
        </div>
      ) : (
        false
      )}

      {!loadingModuleAwaitingUpdate && countPage > 1 && <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.paginationContainer}
      >
        <Pagination count={countPage} page={currentPage} onChange={handleChangePage}/>
      </Grid>}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openRejectModal}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={() => setOpenRejectModal(false)}
                className={classes.approveRejectModuleModalCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }}/>
              </IconButton>
            </Grid>
            <Text className={classes.approveRejectModuleModalTitle}>
              Are you sure you want to reject{' '}
              <span style={{ color: '#00ADEF' }}>{checked.length}</span>{' '}
              modules?
            </Text>
            <Text
              size="h4"
              color="secondaryDark"
              className={classes.approveRejectModuleModalSubtitle}
            >
              Once you do this, it can't be undone.
            </Text>
            <Text
              size="h4"
              color="secondaryDark"
              className={classes.approveRejectModuleModalSubtitle}
            >
              Create new or choose exsisting reason for rejecting these modules.
            </Text>
            <div style={{paddingBottom: '20px'}}>
              <Text className={classes.reasonUpdateFieldHelperText}>Reason</Text>
              <TextField
                select
                className={classes.reasonUpdateField}
                size="small"
                variant="outlined"
                placeholder="Select or Create New Reason"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  },
                  onOpen: () => {
                    handleResetReasonTypes();
                    isOpenReasonSelect.current = true;
                  },
                  onClose: () => isOpenReasonSelect.current = false
                }}
                defaultValue={selectedReasonType}
                onChange={e => setSelectedReasonType(e.target.value)}
                // disabled={isDisablePointAmount()}
              >
              <div className={classes.addNewReason}>
                <TextField
                  autoFocus
                  onClick={e => e.stopPropagation()}
                  fullWidth
                  type="text"
                  placeholder="Search or Create New"
                  value={newReason}
                  onChange={e => setNewReason(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleCreateReasonType();
                    }
                  }}
                  onBlur={e => {
                    e.stopPropagation();

                    if (!reasonTypes && e.target && isOpenReasonSelect.current) {
                        e.target.focus();
                    }
                  }}
                />
              </div>
                {getFilterReasonTypes().map(reasonType => (
                  <ReasonListItem
                      key={reasonType.id}
                      reason={reasonType}
                      value={reasonType.id}
                      handleClickReasonActionIcon={handleClickReasonActionIcon}
                      handleRenameReasonType={handleRenameReasonType}
                  >
                      {reasonType.name}
                  </ReasonListItem>
                ))}
                  <Menu
                    id="reasonActionMenu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={openReasonActionMenu}
                    onClose={handleCloseReasonActionMenu}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDeleteModal(true);
                      }}
                    >
                      Delete
                    </MenuItem>
                    <MenuItem onClick={handleChangeToRename}>Rename</MenuItem>
                  </Menu>
              </TextField>
          </div>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton color="secondary" onClick={() => setOpenRejectModal(false)}
                              className={classes.approveRejectModuleModalCancelBtn}>
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton disabled={selectedReasonType == null} color="alert" onClick={rejectUpdatedModules}
                              className={classes.approveRejectModuleModalApproveBtn}>
                  Reject Modules
                </CustomButton>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={resultsModalOpen}
        onClose={() => setResultsModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={resultsModalOpen}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={() => setResultsModalOpen(false)}
                className={classes.resultModuleRejectModalCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }}/>
              </IconButton>
            </Grid>
            <Text className={classes.resultModuleRejectedModalTitle}>
              <span style={{ color: '#00ADEF' }}>{rejectedModules ? rejectedModules.approved.length : ''}</span>{' '}
              Modules Rejected
            </Text>
            <Text size="h4" color="secondaryDark" className={classes.resultModuleRejectedModalSubtitle}>
              Download reject list?
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton 
                  color="secondary" 
                  onClick={exportRejectedModules}
                  className={classes.resultModuleRejectModalDownloadBtn}
                >
                  Download
                </CustomButton>
                <ExcelCustomComponent
                  filename={`rejected-modules-${new Date()}.csv`}
                  id="update-rejected-modules-data-sheet"
                  data={rejectedModulesDataSheet}
                />
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openDeleteModal}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={handleCloseDeleteModal}
                className={classes.deletePurposeModuleModalCloseBtn}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.deletePurposeModalTitle}>
              Are you sure you want to delete ?
            </Text>
            <Text
              color="secondaryDark"
              className={classes.deletePurposeModalSubtitle}
            >
              Once you do this, it can't be undone.
            </Text>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={handleCloseDeleteModal}
                  className={classes.deletePurposeModalCancelBtn}
                >
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  color="alert"
                  onClick={handleDeleteReasonType}
                  className={classes.deletePurposeModalDeleteBtn}
                >
                  Delete
                </CustomButton>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
    </>
  );
};
