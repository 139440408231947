import React, { useState, useEffect, useMemo } from 'react';
import BackButton from '../../common/components/button/BackButton';
import Text from '../../common/components/text';
import { Link } from 'react-router-dom';
import { Grid, Paper, TextField, MenuItem, Button, makeStyles, Select } from '@material-ui/core';
import { baseUrl } from '../../config';
import { notiFailed, notiSuccess } from './../../utils/helpers';
import axios from '../../utils/axios';
import _ from 'lodash';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import JoditEditor from 'jodit-react';
import { useHistory } from 'react-router-dom';
import CustomLoading from './../../common/components/CustomLoading';
import CustomButton from '../../common/components/button';

const useStyles = makeStyles(them => ({
    buttonSubmit: {
        minWidth: '11.5rem',
        fontSize: '1rem',
        lineHeight: '1rem',
        padding: '.5rem 2rem',
        borderRadius: '100px',
        transition: 'background-color 175ms ease-out',
        backgroundColor: '#00ADEF',
        color: '#FFFFFF',
        border: '1px solid transparent',
        '&:hover': {
            backgroundColor: '#002D56',
            cursor: 'pointer'
        }
    },
    contentImageInput: {
        display: 'none'
    },
    contentImageBtn: {
        border: '1px solid gray',
        height: '56px',
        marginLeft: '10px'
    },
    txtPoint: {
        color: '#ed7100'
    },
    paper: {
        paddingTop: '30px',
        paddingBottom: '10px'
    },
    errorMsg: {
        color: '#f44336',
        marginLeft: '14px',
        marginRight: '14px',
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: '0.03333em'
    }
}));

const Editor = ({ value, config, onChange, tabIndex }) => {
    return useMemo(() => ( 
        <JoditEditor value={value} config={config} onChange={onChange} tabIndex={tabIndex} /> 
    ), [])
}

const schema = yup.object().shape({
    type: yup.number().required(),
    title: yup.string().required(),
    content: yup.string().required()
});

export default function AddFaq() {
    const history = useHistory();
    const classes = useStyles();
    const [faqTypes, setFaqTypes] = useState([]);
    const [selectedFaqType, setSelectedFaqType] = useState(null);
    const [selectedContentImage, setSelectedContentImage] = useState('');
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, watch, errors, getValues, trigger, control } = useForm({
        resolver : yupResolver(schema),
        defaultValues: {
            type: null
        }
    });
    const onSubmit = async data => {
        let formData = new FormData();
        formData.append('faqTypeId', data.type);
        formData.append('title', data.title);
        formData.append('content', data.content);
        if (data.video) {
            formData.append('video', data.video);
        }
        if (data.contentImage.length > 0) {
            formData.append('image', data.contentImage[0]);
        }
        try {
            setLoading(true);
            await axios.post(`${baseUrl}/faq`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            notiSuccess('Add faq successfully');
            history.push('/admincontrols/faq');
        } catch (err) {
            notiFailed(`There was a problem create new faq ${err.message}`);
        } finally {
            setLoading(false);
        }
    }

    const onSubmitInvalid = () => {
        const title = getValues('title');
        const type = getValues('type'); 
        const content = getValues('content');

        if (!type) {
            return notiFailed(`Type can't be blank`); 
        }

        if (_.trim(title) === '') {
            return notiFailed(`Title can't be blank`);
        }

        if (_.trim(content) === '') {
            return notiFailed(`Content can't be blank`);
        }
    };
    
    const getFaqTypes = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/faq/types`);
            setFaqTypes(data);
            setSelectedFaqType(_.get(data, '[0].id', null));
        } catch (err) {
            notiFailed(`There was a problem getting faq ${err.message}`);
        }
    };
    
    useEffect(() => {
        getFaqTypes();
    }, [])

    return (
        <> 
            <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{
                    marginBottom: 10
                }}
            >
                <div>
                    <BackButton component={Link} to="/admincontrols/faq">
                        List faqs
                    </BackButton>
                    <Text size="h2" style={{ lineHeight: '0px' }}>
                        FAQ
                    </Text>
                </div>
            </Grid>

            <Paper className={classes.paper}>
                <Grid
                    container
                    style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
                >
                    <Grid container item xs={4} style={{ marginBottom: '20px' }}>
                        <Text 
                            weight="medium"
                            style={{ color: '#8c8c8c', marginBottom: '1px' }}
                        >
                            Types
                            <em className={classes.txtPoint}>*</em>
                        </Text>
                        <Controller
                            name="type"
                            control={control}
                            as={
                                <Select
                                    select
                                    fullWidth
                                    variant="outlined"
                                    error={errors.type}
                                >
                                    {faqTypes.map(ft => (
                                        <MenuItem key={ft.id} value={ft.id}>{ft.name}</MenuItem>
                                    ))}
                                </Select>
                            }
                        />
                        {errors.type && <p className={classes.errorMsg}>Type can't be blank</p>}
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <Text 
                            weight="medium"
                            style={{ color: '#8c8c8c', marginBottom: '1px' }}
                        >
                            Title
                            <em className={classes.txtPoint}>*</em>
                        </Text>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Title"
                            inputRef={register}
                            name="title"
                            error={errors.title}
                            helperText={errors.title ? "Title can't be blank" : ''}           
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <Text 
                            weight="medium"
                            style={{ color: '#8c8c8c', marginBottom: '1px' }}
                        >
                            Content image
                        </Text>
                        <div>
                            <TextField
                                disabled
                                variant="outlined"
                                placeholder="Please choose file"
                                value={selectedContentImage}
                            />
                            <input
                                ref={register}
                                name={"contentImage"}
                                accept="image/*"
                                className={classes.contentImageInput}
                                id="raised-button-file"
                                type="file"
                                onChange={e => setSelectedContentImage(e.target.value)}
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="raised" component="span" className={classes.contentImageBtn}>
                                    Upload
                                </Button>
                            </label>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <Text 
                            weight="medium"
                            style={{ color: '#8c8c8c', marginBottom: '1px' }}
                        >
                            Video
                        </Text>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Please enter the URL of the video"
                            inputRef={register}
                            name="video"              
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <Text
                            weight="medium"
                            style={{ color: '#8c8c8c', marginBottom: '1px' }}
                        >
                            Content
                            <em className={classes.txtPoint}>*</em>
                        </Text>
                        <Controller
                            name="content"
                            control={control}
                            render={(
                                { onChange, onBlur, value, name, ref },
                                { invalid, isTouched, isDirty }
                              ) => (
                                <Editor
                                    config={{
                                        readonly: false,
                                        askBeforePasteHTML: false,
                                        askBeforePasteFromWord: false
                                    }}
                                    tabIndex={1}
                                    onChange={onChange}
                                    value={value}
                                />
                              )    
                            }
                        />
                        {errors.content && <p className={classes.errorMsg}>Content can't be blank</p>}
                    </Grid>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{
                            marginBottom: 10
                        }}
                    >
                        <CustomButton style={{ height: '40px' }} disabled={loading} onClick={handleSubmit(onSubmit, onSubmitInvalid)}>
                            { loading ? <CustomLoading color="#fff" size={18} /> : 'Register' }
                        </CustomButton>
                        <CustomButton 
                            style={{ marginLeft: '10px', height: '40px' }} 
                            color="gray"
                            component={Link}
                            to="/admincontrols/faq"
                        >
                            Cancel
                        </CustomButton>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}