import React from 'react';
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
const useStyles = makeStyles(theme => ({
  tableHeader: {
    background: '#f4f5f7',
    fontWeight: '500'
  },
  table: {
    borderTop: '1px solid #e4e4e4',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  noteTableHeader: {
    textAlign: 'center',
    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px'
  },
  textArea: {
    width: '100%',
    padding: '5px'
  },
}));


function ContactHistory({histories}) {

  const classes = useStyles();
    return(
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Status change date</TableCell>
              <TableCell align='center'>Membership Type</TableCell>
              <TableCell align='center'>Status Changer</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center' style={{ width: '60%' }}>Contents</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            {histories?.map((item, index) => {
              return(
                <TableRow key={index}>
                  <TableCell align='center'>{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                  <TableCell align='center'>{_.get(item, 'user.role', '')}</TableCell>
                  <TableCell align='center'>{_.get(item, 'user.fullName', '')}</TableCell>
                  <TableCell align='center'>{item.contactStatus}</TableCell>
                  <TableCell align='left'>
                    <span dangerouslySetInnerHTML={{ __html: `${item.content}` }} />
                  </TableCell>
                </TableRow>
              )
            })}


          </TableBody>

        </Table>
      </TableContainer>
    )

}

export default React.memo(ContactHistory);
