import React from 'react';
import Text from '../../common/components/text';
import 'react-dropzone-uploader/dist/styles.css';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Button from '../../common/components/button';
import { TextField, Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { notiFailed } from './../../utils/helpers';

const useStyles = makeStyles({
  chip: {
    borderRadius: '3px'
  }
});

const CustomChipRenderer = (
  { text, isDisabled, isReadOnly, handleClick, className },
  key
) => (
  <Chip
    key={key}
    className={className}
    style={{
      pointerEvents: isDisabled || isReadOnly ? 'none' : undefined
    }}
    onClick={handleClick}
    label={text}
  />
);

export default function ProductOptionEdit({
  sizes,
  colors,
  customs,
  editOptions,
  allOptions,
  onChangeEditOptions,
  onAddNewColorVariants,
  onAddNewSizeVariants,
  onAddNewCustomVariants,
  onRemoveSizeVariant,
  onRemoveColorVariant,
  onRemoveCustomVariant
}) {
  const classes = useStyles();

  const makeid = length => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleAddSize = v => {
    onAddNewSizeVariants(v);
  };

  const handleDeleteSize = (v, index) => {
    onRemoveSizeVariant(v);
  };

  const handleAddColor = v => {
    onAddNewColorVariants(v);
  };

  const handleDeleteColor = (v, index) => {
    onRemoveColorVariant(v);
  };

  const handleAddCustom = v => {
    onAddNewCustomVariants(v);
  };

  const handleDeleteCustom = (v, index) => {
    onRemoveCustomVariant(v);
  };

  const addOption = () => {
    const isChooseCustom = !!editOptions.find(o => o.option === 'Custom');
    if (isChooseCustom) {
      return notiFailed(`You can't add other option with custom`);
    }
    let remainOptions = [...allOptions];
    for (let option of editOptions) {
      remainOptions = remainOptions.filter(o => o.option !== option.option);
    }
    if (!isChooseCustom) {
      remainOptions = remainOptions.filter(o => o.option !== 'Custom');
    }
    if (!remainOptions.length) return;
    onChangeEditOptions([
      ...editOptions,
      {
        id: makeid(6),
        option: remainOptions[0].option,
        value: remainOptions[0].value,
        options: remainOptions
      }
    ]);
  };

  const handleChangeOption = (index, value) => {
    const newEditOptions = [...editOptions];
    newEditOptions[index].option = value;
    newEditOptions[index].value = value;
    onChangeEditOptions(newEditOptions);
  };
  
  return (
    <Grid container>
      {editOptions.map((option, index) => {
        if (option.value === 'Color') {
          return (
            <Grid item xs={12} key={option.id}>
              <Grid container justify="space-between">
                <Text>Option</Text>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item md={2} xs={4}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}
                    style={{ marginBottom: '20px' }}
                    value={'Color'}
                    onChange={e => handleChangeOption(index, e.target.value)}
                  >
                    {option.options.map(o => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={10} xs={8}>
                  {colors.length > 1 ? (
                    <ChipInput
                      fullWidth
                      variant="outlined"
                      placeholder="Separate options with a comma"
                      value={colors.map(c => c.name)}
                      newChipKeys={['Enter', ',']}
                      classes={{
                        chip: classes.chip
                      }}
                      onAdd={v => handleAddColor(v)}
                      onDelete={(v, index) => handleDeleteColor(v, index)}
                    />
                  ) : (
                    <ChipInput
                      fullWidth
                      variant="outlined"
                      placeholder="Separate options with a comma"
                      value={colors.map(c => c.name)}
                      newChipKeys={['Enter', ',']}
                      classes={{
                        chip: classes.chip
                      }}
                      onAdd={v => handleAddColor(v)}
                      chipRenderer={CustomChipRenderer}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        } else if (option.value === 'Size') {
          return (
            <Grid item xs={12} key={option.id}>
              <Grid container justify="space-between">
                <Text>Option</Text>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item md={2} xs={4}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}
                    style={{ marginBottom: '20px' }}
                    value={'Size'}
                    onChange={e => handleChangeOption(index, e.target.value)}
                  >
                    {option.options.map(o => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={10} xs={8}>
                  {sizes.length > 1 ? (
                    <ChipInput
                      fullWidth
                      variant="outlined"
                      placeholder="Separate options with a comma"
                      value={sizes}
                      newChipKeys={['Enter', ',']}
                      classes={{
                        chip: classes.chip
                      }}
                      onAdd={v => handleAddSize(v)}
                      onDelete={(v, index) => handleDeleteSize(v, index)}
                    />
                  ) : (
                    <ChipInput
                      fullWidth
                      variant="outlined"
                      placeholder="Separate options with a comma"
                      value={sizes}
                      newChipKeys={['Enter', ',']}
                      classes={{
                        chip: classes.chip
                      }}
                      onAdd={v => handleAddSize(v)}
                      onDelete={(v, index) => handleDeleteSize(v, index)}
                      chipRenderer={CustomChipRenderer}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        } else if (option.value === 'Custom') {
          return (
            <Grid item xs={12} key={option.id}>
              <Grid container justify="space-between">
                <Text>Option</Text>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item md={2} xs={4}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}
                    style={{ marginBottom: '20px' }}
                    value={'Custom'}
                    onChange={e => handleChangeOption(index, e.target.value)}
                  >
                    {option.options.map(o => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={10} xs={8}>
                  {customs.length > 1 ? (
                    <ChipInput
                      fullWidth
                      variant="outlined"
                      placeholder="Separate options with a comma"
                      value={customs}
                      newChipKeys={['Enter', ',']}
                      classes={{
                        chip: classes.chip
                      }}
                      onAdd={v => handleAddCustom(v)}
                      onDelete={(v, index) => handleDeleteCustom(v, index)}
                    />
                  ) : (
                    <ChipInput
                      fullWidth
                      variant="outlined"
                      placeholder="Separate options with a comma"
                      value={customs}
                      newChipKeys={['Enter', ',']}
                      classes={{
                        chip: classes.chip
                      }}
                      onAdd={v => handleAddCustom(v)}
                      onDelete={(v, index) => handleDeleteCustom(v, index)}
                      chipRenderer={CustomChipRenderer}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        }
      })}
      {editOptions.length < 2 && (
        <Button color="secondary" onClick={addOption} style={{ marginTop: '20px' }}>
          Add another option
        </Button>
      )}
    </Grid>
  );
}
