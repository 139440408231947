import React from 'react';
import Text from '../text';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { convertHTMLEntity } from '../../../utils/helpers';
const useStyles = makeStyles(theme => ({

  companyInfoContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: 'rgb(247, 247, 247)',
    padding: '30px 50px',
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(100%, 1fr))',
      padding: '10px 20px',
      marginTop: '15px'
    }
  },
  companyNameColumn: {
    gridRowStart: 1,
    gridRowEnd: 4
  },

  wrapItem : {
    display: 'flex',
    flexDirection : 'row',
    width: 214,
    justifyContent : 'space-between'
  },
  textOrderDetail : {
    fontFamily: 'Actual',
    color: '#8C8C8C',
    marginTop: '0 !important',
    marginBottom: '0 !important',
    fontSize: 18,
    fontWeight: 100,
  },
  textTitleOrder : {
    fontFamily: 'Actual',
    color: 'black',
    marginTop: '0 !important',
    marginBottom: '0 !important',
    fontSize: 18,
    fontWeight: 500,
  }
}));

function CompanyInformation({name , address, website, officeNumber, mobileNumber}) {

  const classes = useStyles();

  return(
    <div style={{marginTop : 50}}>
      <Text size="h4" weight="medium">Company Information</Text>
      <Grid
        container
        item
        justify="space-around"
        alignItems="flex-start"
        className={classes.companyInfoContainer}
      >
        <div className={classes.companyNameColumn}>
          <p
            className={classes.textTitleOrder}
          >
            Company Name:
          </p>
          <p
            className={classes.textOrderDetail}
          >
            {convertHTMLEntity(name)}
          </p>

          <div style={{marginTop: 35}}>
            <p
              className={classes.textTitleOrder}
            >
              Address:
            </p>
            <p
              className={classes.textOrderDetail}
            >
              {address}
            </p>
          </div>
        </div>

        <div>
          <p
            className={classes.textTitleOrder}
          >
            Website:
          </p>
          <p
            className={classes.textOrderDetail}
          >
            {website ? website : 'N/A'}
          </p>
        </div>

        <div style={{marginTop: 35}}>
          <p
            className={classes.textTitleOrder}
          >
            Contact Number:
          </p>
          <div className={classes.wrapItem}>
            <p
              className={classes.textOrderDetail}
            >
              Office
            </p>

            <p
              className={classes.textOrderDetail}
            >
              {officeNumber}
            </p>
          </div>

          <div className={classes.wrapItem}>
            <p
              className={classes.textOrderDetail}
            >
              Mobile
            </p>

            <p
              className={classes.textOrderDetail}
            >
              {mobileNumber}
            </p>
          </div>

        </div>

      </Grid>
    </div>
  )
}

export default CompanyInformation;
