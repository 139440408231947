import React, { useEffect, useState } from 'react';
import Text from '../text';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import axios from '../../../utils/axios';
import { baseUrl } from '../../../config';
import { notiFailed } from '../../../utils/helpers';
import CustomButton from '../button';
import CustomLoading from '../CustomLoading';


const useStyles = makeStyles(theme => ({

  companyInfoContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr',
    backgroundColor: 'rgb(247, 247, 247)',
    padding: '30px 50px',
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(100%, 1fr))',
      padding: '10px 20px',
      marginTop: '15px'
    }
  },
  companyNameColumn: {
    gridRowStart: 1,
    gridRowEnd: 4
  },

  wrapItem : {
    display: 'flex',
    flexDirection : 'row',
    width: 214,
    justifyContent : 'space-between'
  },
  wrapName : {
    display: 'flex',
    flexDirection : 'row',
    justifyContent : 'space-between'
  },
  viewMore : {
    display: 'flex',
    flexDirection : 'row',
    justifyContent : 'center',
    marginTop : 100
  },
  textOrderDetail : {
    fontFamily: 'Actual',
    color: '#8C8C8C',
    marginTop: '0 !important',
    marginBottom: '0 !important',
    fontSize: 18,
    fontWeight: 100,
  }
}));

function UserInformation({firstName, lastName, email, companyId }) {
  const classes = useStyles();
  const [subUsers, setSubUsers] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getSubUsers();
  }, [companyId, page])

  const getSubUsers = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${baseUrl}/users/sub-user/by-company`, {
        params: {
          companyId: companyId,
          page: page
        }
      });

      setSubUsers([...subUsers, ...data.data]);
      setTotalRecord(data.total);
    } catch (err) {
      notiFailed(
        `There was a problem get sub users ${err.message}`
      );
    }
    setLoading(false);
  };

  function viewMoreSubUser() {
    setPage(page + 1);
  }

  return(
    <div style={{marginTop : 50}}>
      <Text size="h4" weight="medium">User Information</Text>
      <Grid
        container
        item
        justify="space-around"
        alignItems="flex-start"
        className={classes.companyInfoContainer}
      >
        <div>
          <Text
            size="h4"
            weight="medium"
          >
            Primary User:
          </Text>
          <p
            className={classes.textOrderDetail}
          >
            {_.trim(`${firstName} ${lastName}`)}
          </p>
        </div>
        <div style={{marginTop: 35}}>

          <p
            className={classes.textOrderDetail}
          >
            {email}
          </p>

        </div>
        <div>
          <Text
            size="h4"
            weight="medium"
          >
            Sub user(s):
          </Text>
          {subUsers.length > 0 ?
            subUsers.map(su => (
              <div className={classes.wrapName}>
                <p
                  className={classes.textOrderDetail}
                >
                  {_.trim(`${su.firstName} ${su.lastName}`)}
                </p>
                <p
                  className={classes.textOrderDetail}
                >
                  {su.email}
                </p>
              </div>

            )) : (
              <Text
                size="h4"
                color="secondaryDark"
                weight="light"
              >
                N/A
              </Text>
            )}

          {subUsers.length < totalRecord && <Grid
                container
                justify="center"
                style={{ marginBottom: '10px', marginTop: 50 }}
              >
                <Grid item>
                  <CustomButton
                    color="secondary"
                    onClick={viewMoreSubUser}
                    className="RewardHistory_ViewMoreButton"
                  >
                    {loading ? <CustomLoading size={18} color="#00adef"/> : 'View More'}
                  </CustomButton>
                </Grid>
              </Grid>}
        </div>

      </Grid>
    </div>
  )
}

export default UserInformation;
