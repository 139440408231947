import React, { useReducer, useEffect, useMemo } from 'react';
import { initialState, ContactReducer } from './reducer';
import {getListContact} from './actions';


const Context = React.createContext();

export default Context;


const ContactStateContext = React.createContext();
const ContactDispatchContext = React.createContext();

export function useContactState() {
  const context = React.useContext(ContactStateContext);
  if (context === undefined) {
    throw new Error('useContactState must be used within a ContactProvider');
  }

  return context;
}

export function useContactDispatch() {
  const context = React.useContext(ContactDispatchContext);
  if (context === undefined) {
    throw new Error('useContactDispatch must be used within a ContactProvider');
  }

  return context;
}

export const ContactProvider = ({ children }) => {
  const [contacts, dispatch] = useReducer(ContactReducer, initialState);

  return (
    <ContactStateContext.Provider value={contacts}>
      <ContactDispatchContext.Provider value={dispatch}>
        {children}
      </ContactDispatchContext.Provider>
    </ContactStateContext.Provider>
  );
};

