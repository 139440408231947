import React, { useEffect, useState } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CustomLoading from '../../../../common/components/CustomLoading';
import Text from '../../../../common/components/text';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import { newsService } from '../../../../services';
import moment from 'moment';


const useStyleDesign = makeStyles(theme => ({
  loadingNews : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '40px'
  },
  headerNew : {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1rem'
  },
  wrapContainerLoading : {
    display: 'flex',
    flexDirection : 'row',
    justifyContent : 'center',
    marginTop: 10
  },
  itemNew : {
    cursor: 'pointer',
    height: 55,
    '&:hover': {
      backgroundColor : 'rgba(0, 0, 0, 0.04)'
    }
  },

  paginationContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    paddingBottom: 20,
    '& .Mui-selected': {
      backgroundColor: '#00ADEF',
      color: '#fff'
    }
  },
  tableWrapper: {
    maxWidth: '100%',
    margin: 'auto',
    '& td, & th': {
      fontFamily: 'Actual'
    }
  },
  noItem : {
    backgroundColor: '#E5F3FB',
    height: 100,
    color :'#00ADEF',
    display: 'flex',
    flexDirection : 'row',
    justifyContent : 'center',
    alignItems : 'center',
  }
}));

function ListNew() {

  const classes = useStyleDesign();
  const history = useHistory();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [record, setTotalRecord] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const total = Math.ceil(record / 10);
  function handleNavigateNewDetail(info) {
    history.push(`/admincontrols/news/${info.id}`)
  }

  useEffect(() => {
    getListNews();
  },[currentPage])

  async function getListNews() {
    setLoading(true);
    const listNews = await newsService.getListNew({page : currentPage});
    if (listNews && listNews.data) {
      setLoading(false);
      setNews(listNews?.data.data || []);
      setTotalRecord(listNews?.data.total || 0);
    }

  }

  function handleChangePage(event, page) {
    setCurrentPage(page)
  }

  return(

    <div>
      <Paper elevation={0}>
        <Grid
          container
          justify="flex-start"
          className={classes.tableWrapper}
          spacing={2}
        >
          <TableContainer style={{borderBottom: '1px solid #80808038', borderRight: '1px solid #80808038', overflowX : 'unset' ,borderLeft: '1px solid #80808038'}}>
            <Table size="small">
              <TableHead>
                <TableRow style={{height: 55}}>
                  <TableCell className={classes.headerNew} align={"center"}>
                    No.
                  </TableCell>
                  <TableCell style={{width: '60%'}} className={classes.headerNew}  align={"center"}>
                    Title
                  </TableCell>
                  <TableCell align={"center"} className={classes.headerNew} >
                    Date
                  </TableCell>
                  <TableCell className={classes.headerNew}  align={"center"}>
                    Views
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan="6">
                      <div className={classes.wrapContainerLoading}>
                        <CustomLoading className={classes.loadingNews} size={40} color="#00adef"/>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <React.Fragment>
                    {
                      news.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan="6">
                            <div className={classes.noItem}>
                              <Text weight="bold">
                                No news.
                              </Text>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : news.map((item, index) => {
                        return (
                          <TableRow className={classes.itemNew} key={index} onClick={() => handleNavigateNewDetail(item)}>
                            <TableCell align={"center"}>
                              {record - (currentPage - 1) * 10 - index}
                            </TableCell>
                            <TableCell>
                              <div dangerouslySetInnerHTML={{__html: `${item.title}`}} />
                            </TableCell>
                            <TableCell align={"center"}>
                              {moment(`${item.createdAt}`).format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell align={"center"}>
                              {item.views}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </React.Fragment>
                )}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>

      </Paper>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        className={classes.paginationContainer}
      >
        {total !== 0 && <Pagination size={'small'} count={total} page={currentPage} onChange={handleChangePage}/>}
      </Grid>

    </div>




  )
}



export default ListNew;
