
import React, { useState, useReducer } from 'react';

export const initialState = {
  loading: true,
  users : [],
  total_record : 0,
  condition : {}
};

export const adminReducer = (initialState, action) => {
  switch (action.type) {
    case 'GET_LIST_ADMIN_PROGRESS':
      return {
        ...initialState,
        loading: true,
        condition: {...action.payload}
      }
    case 'GET_LIST_ADMIN_SUCCESS':
      return {
        ...initialState,
        loading: false,
        users : [...action.payload.users],
        total_record: action.payload.total_record
      }
    case 'SET_CONDITION_SEARCH':
      return {
        ...initialState,
        condition : action.payload
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
