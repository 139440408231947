import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  makeStyles, MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow, TextField
} from '@material-ui/core';
import Text from '../../common/components/text';
import SearchUserAdmin from './components/SearchUserAdmin';
import CustomLoading from '../../common/components/CustomLoading';
import { decodeStringHtml, notiSuccess } from '../../utils/helpers';
import moment from 'moment';

import _ from 'lodash';
import { getListAdmin, useUserAdminDispatch, useUserAdminState } from '../../contexts/admin';
import { getListContact, useContactDispatch } from '../../contexts/support';
import Button from '../../common/components/button';
import Pagination from '@material-ui/lab/Pagination';
import { adminService, contactService } from '../../services';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  tableHeaderCell: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1rem'
  },
  tableContainer: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    marginTop: '20px',
    borderRadius: 0,
    '& td': {
      fontFamily: 'Actual'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    }
  },
  container: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  paginationContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    '& .Mui-selected': {
      backgroundColor: '#00ADEF',
      color: '#fff'
    }
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer'
    }
  },
  selectCheckbox: {
    '& .Mui-checked .MuiSvgIcon-root': {
      fill: '#009ee3'
    }
  },
  tabs: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& .MuiTabs-root': {
      maxWidth: '75vw',
      margin: 'auto',
      padding: '0'
    },
    '& .MuiTab-root': {
      minWidth: '60px',
      borderBottom: '3px solid transparent'
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Actual',
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: '400'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#00ADEF',
      borderBottom: '3px solid #00ADEF'
    },
    '& span.MuiTabs-indicator': {
      backgroundColor: 'transparent'
    }
  },
  wrapContainerLoading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },

  noRecordContainer: {
    backgroundColor: '#e5f3fb',
    width: '95%',
    margin: 'auto',
    marginTop: 50,
    textAlign: 'center',
    paddingTop: 50,
    marginBottom: 50,
    paddingBottom: 50
  }
}));

function AdminManagementList() {


  const classes = useStyles();
  const history = useHistory();
  const { users, total_record, loading, condition } = useUserAdminState();
  const [currentPage, setCurrentPage] = useState(1);
  const total = Math.ceil(total_record / 10);
  const dispatch = useUserAdminDispatch();
  const [listIds, setListIds] = useState([]);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [status, setStatus] = useState(true)

  useEffect(() => {
    getListAdmin(dispatch, {page : 1, size : 10})
  }, []);


  function handleChangeStatus(id) {


    if (listIds.includes(id)) {
      const makeArr = _.remove(listIds, function(el) {
        return el !== id;
      });
      setListIds(makeArr);
    } else {
      setListIds(prevState => [
        ...prevState,
        id
      ]);
    }
  }

  function handleChangePage(event, page) {
    setCurrentPage(page);
    setListIds([]);
    getListAdmin(dispatch, {
      size: 10,
      ...condition,
      page: page
    });

  }

  function handleSelectStatus(event) {
    setStatus(event.target.value);
  }

  async function handleConfirmUser() {

    setLoadingConfirm(true);
    const makePayload = {
      disable: status,
      userIds : listIds
    }

    await adminService.updateStatus(makePayload).then(() => {
      notiSuccess('Change status success')
      getListAdmin(dispatch, {
        size: 10,
        ...condition,
        page: currentPage
      });
    }).catch(() => {

    })

    setLoadingConfirm(false)

  }



  return(
    <>
      <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 10 }}>
        <Text size="h2" style={{ lineHeight: '0px' }}>
          Admin
        </Text>
      </Grid>

      <Paper className={classes.container}>
        <SearchUserAdmin/>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell} align='center'>No.</TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>Registration date</TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>Type</TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>ID(E-Mail)</TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>Name</TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>Main Contact Number</TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>Account Status</TableCell>
                <TableCell className={classes.tableHeaderCell} align='center'>Status Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan="12">
                    <div className={classes.wrapContainerLoading}>
                      <CustomLoading className={classes.loadingNews} size={40} color="#00adef"/>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {users.length === 0 &&
                  <TableRow>
                    <TableCell colSpan="12">
                      <div className={classes.noRecordContainer}>
                        <Text color="primaryLight" weight="bold">
                          No registered article.
                        </Text>
                      </div>
                    </TableCell>
                  </TableRow>}
                  {users?.map((user, index) => (
                    <TableRow
                      key={user.id}
                      className={classes.tableRow}
                      onClick={() => history.push(`/admincontrols/management/${user.id}`)}
                    >
                      <TableCell align='center'>
                        {total_record - (currentPage - 1) * 10 - index}
                      </TableCell>
                      <TableCell align='center'>{moment(user.createdAt).format('YYYY-MM-DD')}</TableCell>
                      <TableCell align='center'>{user.role}</TableCell>
                      <TableCell align='center'>{user.email}</TableCell>
                      <TableCell align='center'>{user.fullName}</TableCell>
                      <TableCell align='center'>{user.officeNumber ? user.officeNumber : user.mobileNumber}</TableCell>
                      <TableCell align='center'>{user.disabled ? 'Disabled' : 'In Use'}</TableCell>
                      <TableCell align='center'>
                        <IconButton size="small" className={classes.selectCheckbox} onClick={e => e.stopPropagation()}>
                          <Checkbox
                            color="primary"
                            onChange={() => handleChangeStatus(user.id)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              )}

            </TableBody>
          </Table>
        </TableContainer>
        <Grid style={{ padding: '40px 30px 15px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }} container
              justify="flex-end">
          <Grid item sm={12}>
            <Box className={'wrap-box-website'}>
              <Text className={'text-website'} size="p" style={{ lineHeight: '37px', margin: 0, marginRight: '10px' }}>
                * Changes the status of the selected item to
              </Text>
              <TextField
                className={'select-user'}
                select
                size="small"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                defaultValue={status}
                onChange={handleSelectStatus}
              >
                <MenuItem key={'disabled'} value={true}>Disabled</MenuItem>
                <MenuItem key={'in'} value={false}>In Use</MenuItem>


              </TextField>
              <Button
                onClick={handleConfirmUser}
                className={'btn-website'}
                style={{
                  height: '40px',
                  borderRadius: '5px',
                  marginLeft: '15px',
                  padding: '.5rem 1rem'
                }}
              >
                {loadingConfirm ? <CustomLoading size={20} color="#00adef"/> : 'Confirm'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.paginationContainer}

      >
        {total !== 0 && <Pagination size={'small'} count={total} page={currentPage} onChange={handleChangePage}/>}
      </Grid>
    </>
  )
}

export default AdminManagementList;
