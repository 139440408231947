import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@material-ui/core';
import Button from '../../../../common/components/button';
import CustomButton from '../../../../common/components/button';
import Alert from '../../../../routes/rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Text from '../../../../common/components/text';
import CustomLoading from '../../../../common/components/CustomLoading';
import * as yup from 'yup';
import { useFormik } from 'formik';
import BackButton from '../../../../common/components/button/BackButton';
import { Link } from 'react-router-dom';
import { adminService } from '../../../../services';
import { notiFailed, notiSuccess } from '../../../../utils/helpers';
import {useHistory, useParams} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  container: {
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  tableHeader: {
    background: '#f4f5f7',
    fontWeight: '500',
    fontSize: '15px'
  },
  table: {
    borderTop: '1px solid #e4e4e4',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  noteTableHeader: {
    textAlign: 'center',
    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px'
  },
  noteTable: {
    borderTop: '1px solid #e4e4e4',
    marginTop: '10px',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  emptyNoteList: {
    '& td': {
      padding: '81px 15px'
    }
  },
  emailText: {
    margin: '8px 12px 8px 0'
  },
  multiSelect: {
    '& .select__control': {
      borderRadius: 0,
      padding: '4px'
    },
    '& .select__multi-value__remove:hover': {
      backgroundColor: '#00adef',
      color: '#fff'
    }
  },
  w9ImageInput: {
    display: 'none'
  },
  w9ImageBtn: {
    border: '1px solid gray',
    height: '56px',
    marginLeft: '10px'
  },
  textArea: {
    width: '100%',
    padding: '5px',
    fontSize: '14px',
    fontFamily: 'Actual'
  },
  textAreaError : {
    width: '100%',
    padding: '5px',
    borderColor: 'red'
  },
  newLetterText: {
    lineHeight: '40px',
    marginTop: 0,
    marginBottom: 0
  },
  newLetterRadio: {
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#019ee3'
    }
  },
  textEmail : {
    borderColor: 'red'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textConfirm : {
    fontSize: 36
  },
  textInput : {
    fontSize: 14
  },
  w9Download: {
    display: 'inline-block',
    position: 'relative',
    transition: 'color 0.4s',
    paddingLeft: '20px',
    // background: `url(${DownloadIcon}) left 1px no-repeat`,
    '&:after': {
      position: 'absolute',
      left: '19px',
      bottom: 0,
      width: 0,
      height: '1px',
      background: '#019ee3',
      content: '""',
      transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#019ee3'
      // background: `url(${DownloadIconOn}) left 1px no-repeat`,
    },
    '&:hover:after': {
      width: 'calc(100% - 20px)'
    }
  }
}));



const validateSchemaFormEmail = yup.object({
  email : yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  firstName : yup.string().required('firstName is required'),
  lastName : yup.string().required('lastName is required'),
  textNumber : yup.number().required('Number is required'),
})




function FormEditUserAdmin() {

  const [initValue, setInitValue] = useState({
    role: 'Admin',
    email : '',
    firstName : '',
    lastName: '',
    mobileNumber : '',
    officeNumber : '',
    textNumber : '',
    typeNumber : '2',
    disabled : 'false'
  })

  const classes = useStyles();
  const history = useHistory();
  const {id} = useParams();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [infoUser, setInfoUser] = useState({});
  const [openFormUpdate, setOpenFormUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValue,
    validationSchema: validateSchemaFormEmail,
    onSubmit:  (values, {resetForm}) => {
        setOpenFormUpdate(true);
    }
  });



  useEffect(() => {
    getInfoDetailUser()
  }, [id])

  async function handleUpdateUser() {

    setLoadingUpdate(true)
    const makeFormUser = {
      firstName : formik.values.firstName.trim(),
      lastName : formik.values.lastName.trim(),
      mobileNumber : formik.values.typeNumber === '1' ? formik.values.textNumber :  formik.values.mobileNumber,
      disabled : formik.values.disabled,
      role : formik.values.role,
      fullName : `${formik.values.firstName} ${formik.values.lastName}`.trim(),
      officeNumber : formik.values.typeNumber === '2' ? formik.values.textNumber :  formik.values.officeNumber
    }

    const makeFormBranch = {
      officeNumber : formik.values.typeNumber === '2' ? formik.values.textNumber :  formik.values.officeNumber
    }
    await adminService.updateUserAdmin(infoUser.id, makeFormUser, makeFormBranch).then(() => {
      notiSuccess('Update user success')
      history.push('/admincontrols/management')
    }).catch(() => {
      notiFailed('Update Failed')
    })

    setLoadingUpdate(false);
  }

  async function getInfoDetailUser() {
    const user = await adminService.getUserDetail(id);
    setInfoUser(user.data);

    const initState = {
      ...user.data,
      typeNumber : user?.data.officeNumber ? '2' : '1',
      textNumber : user?.data.officeNumber ? user.data.officeNumber : user.data.mobileNumber,
      disabled : user?.data.disabled ? 'true' : 'false'
    }

    setInitValue(initState);
  }


  async function handleResetPassword() {
    await adminService.resetPassword(infoUser.id).then(() => {
      notiSuccess('Reset password success')
    }).catch(() => {
      notiFailed('Reset password Failed')
    })
  }

  async function handleDeleteUser() {
    setLoading(true)
    await adminService.deleteUser(infoUser.id).then(() => {
      notiSuccess('Deleted.');
      history.push('/admincontrols/management')
    }).catch(() => {
      notiFailed('Delete Failed');
    })

    setLoading(false);
  }



  return(
    <>
      <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 10}}>
        <BackButton component={Link} to="/admincontrols/management">List</BackButton>
      </Grid>
      <Grid container>
        <Grid
          style={{paddingRight: 10, marginTop: 20}}
          container
          direction="row"
          alignItems="center"
          xs={12} md={12} lg={12} sm={12}
        >
          <sm style={{color : 'red'}}>*</sm> Required
        </Grid>

      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <TableContainer style={{ marginTop: 20, marginBottom: 20 }} component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Type <sm style={{color : 'red'}}>*</sm></TableCell>
              <TableCell align='left'>
                <Grid container xs={12} className={classes.newLetterRadio}>
                  <Grid item>
                    <RadioGroup id="type" value={formik.values.role} onChange={formik.handleChange} row aria-label="position" name="role" defaultValue={`admin`}>
                      <FormControlLabel classes={{label : classes.textInput}} value="Admin" control={<Radio color="primary"/>} label="Admin"/>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>ID(E-mail)<sm style={{color : 'red'}}>*</sm></TableCell>
              <TableCell align='left' style={{ minWidth: '300px' }}>
                {formik.values.email}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Password</TableCell>
              <TableCell align='left' style={{ minWidth: '300px' }}>
                A temporary password will be sent to the registered email address. Please make sure to change it after login. <br/>
                <Button onClick={handleResetPassword} style={{borderRadius : 0}}>Reset password</Button>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Name <sm style={{color : 'red'}}>*</sm></TableCell>
              <TableCell align='left' colSpan={3}>
                <TextField
                  id="firstName"
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    }
                  }}
                  placeholder={'First Name'}
                  name="firstName"
                  className={classes.textEmail}
                  value={formik.values.firstName}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  onChange={formik.handleChange}
                  style={{ minWidth: 300 }}
                  variant="outlined"
                  size="small"
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />

                <TextField
                  id="lastName"
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    }
                  }}
                  placeholder={'Last Name'}
                  name="lastName"
                  className={classes.textEmail}
                  value={formik.values.lastName}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  onChange={formik.handleChange}
                  style={{ minWidth: 300, marginLeft : 20 }}
                  variant="outlined"
                  size="small"
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Main Contact Number <sm style={{color : 'red'}}>*</sm></TableCell>
              <TableCell align='left' colSpan={3}>
                <Grid container xs={12} spacing={2} className={classes.newLetterRadio}>
                  <Grid item>
                    <TextField
                      id="textNumber"
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        },

                      }}
                      name="textNumber"
                      type="number"
                      className={classes.textEmail}
                      value={formik.values.textNumber}
                      error={formik.touched.textNumber && Boolean(formik.errors.textNumber)}
                      onChange={formik.handleChange}
                      style={{ minWidth: 300 }}
                      variant="outlined"
                      placeholder="ex) 070-1234-1234"
                      size="small"
                      helperText={formik.touched.textNumber && formik.errors.textNumber}
                    />
                  </Grid>
                  <Grid item>
                    <RadioGroup id="typeNumber" value={parseInt(formik.values.typeNumber)} onChange={formik.handleChange} row aria-label="typeNumber" name="typeNumber" defaultValue={2}>
                      <FormControlLabel classes={{label : classes.textInput}} value={2} control={<Radio color="primary"/>} label="Office Number"/>
                      <FormControlLabel classes={{label : classes.textInput}} value={1} control={<Radio color="primary"/>} label="Mobile Number"/>
                    </RadioGroup>
                  </Grid>
                </Grid>

              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' className={classes.tableHeader}>Account Status <sm style={{color : 'red'}}>*</sm></TableCell>
              <TableCell align='left' colSpan={3}>
                <Grid container xs={12} className={classes.newLetterRadio}>
                  <Grid item>
                    <RadioGroup id="type" value={formik.values.disabled} onChange={formik.handleChange} row aria-label="position" name="disabled" defaultValue={`false`}>
                      <FormControlLabel classes={{label : classes.textInput}} value={'false'} control={<Radio color="primary"/>} label="In use"/>
                      <FormControlLabel classes={{label : classes.textInput}} value={'true'} control={<Radio color="primary"/>} label="Disabled"/>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
        <Grid style={{marginBottom: 20}} container  alignItems={'center'} justify={'center'}>
          <Button type="submit" style={{marginRight: 5}}>Update</Button>
          <Button onClick={() => setOpen(true)} color={"alert"}>Delete</Button>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title-new"
          aria-describedby="transition-modal-description-new"
          className={classes.modal}
          open={openFormUpdate}
          onClose={() => setOpenFormUpdate(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={openFormUpdate}>
            <Alert>
              <Grid container justify="flex-end">
                <IconButton>
                  <CloseOutlinedIcon onClick={() => setOpenFormUpdate(false)} fontSize="large" style={{ color: '#8C8C8C' }} />
                </IconButton>
              </Grid>
              <Text className={classes.textConfirm}>
                Do you want to update ?
              </Text>
              <Grid container justify="center" spacing={2} style={{ marginBottom: '20px' }}>
                <Grid item>
                  <CustomButton onClick={() => setOpenFormUpdate(false)} color="secondary">
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid item>
                  <Button onClick={handleUpdateUser}  type="submit">{loadingUpdate ?  <CustomLoading size={18}/> : 'Confirm'}</Button>
                </Grid>
              </Grid>
            </Alert>
          </Fade>
        </Modal>


        <Modal
          aria-labelledby="transition-modal-title-new"
          aria-describedby="transition-modal-description-new"
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <Alert>
              <Grid container justify="flex-end">
                <IconButton>
                  <CloseOutlinedIcon onClick={() => setOpen(false)} fontSize="large" style={{ color: '#8C8C8C' }} />
                </IconButton>
              </Grid>
              <Text className={classes.textConfirm}>
                Do you want to delete ?
              </Text>
              <Grid container justify="center" spacing={2} style={{ marginBottom: '20px' }}>
                <Grid item>
                  <CustomButton onClick={()=> setOpen(false)} color="secondary">
                    Cancel
                  </CustomButton>
                </Grid>
                <Grid item>
                  <Button onClick={handleDeleteUser}  type="submit">{loading ?  <CustomLoading size={18}/> : 'Confirm'}</Button>
                </Grid>
              </Grid>
            </Alert>
          </Fade>
        </Modal>

      </form>
    </>
  )
}

export default FormEditUserAdmin;
