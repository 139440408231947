import React from 'react';
import ListAcademy from './components/ListAcademy';
import { Grid } from '@material-ui/core';
import Text from '../../common/components/text';

function OfflineTraining() {
  return(
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          marginBottom: 10
        }}
      >
        <Text size="h2" style={{ lineHeight: '0px' }}>
          ONLINE
        </Text>
      </Grid>
      <ListAcademy/>
    </>
  )
}

export default OfflineTraining;


