import React, { useReducer, useEffect, useMemo } from 'react';
import { initialState, adminReducer } from './reducer';
import {getListAdmin} from './actions';


const Context = React.createContext();

export default Context;


const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

export function useUserAdminState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useContactState must be used within a UserProvider');
  }

  return context;
}

export function useUserAdminDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useContactDispatch must be used within a UserProvider');
  }

  return context;
}

export const UserAdminProvider = ({ children }) => {
  const [users, dispatch] = useReducer(adminReducer, initialState);

  return (
    <UserStateContext.Provider value={users}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

