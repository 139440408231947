import React, { useState, useEffect } from 'react';
import { find, get } from 'lodash';
import moment from 'moment';
import {
  TableContainer,
  Grid,
  Paper,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  MenuItem,
  Tabs,
  Tab
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CheckIcon from '@material-ui/icons/Check';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import { notiSuccess, notiFailed, warningError } from './../../utils/helpers';
import Text from '../../common/components/text';
import config from '../../config';
import axios from '../../utils/axios';
import Alert from './Alert';
import CustomButton from '../../common/components/button';
import CustomLoading from '../../common/components/CustomLoading';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionItemContainer: {
    minWidth: 100
  },
  contentDeleteConfigurationPopup: {
    fontSize: '3.373rem',
    textAlign: 'center',
    marginTop: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem'
    }
  },
  descriptionDeleteConfigurationPopup: {
    marginTop: '-50px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  actionItemContainerEdit: {
    minWidth: 135
  },
  titleEndPromotionDialog: {
    fontSize: '3.375rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem'
    }
  },
  descriptionEndPromotionDialog: {
    marginTop: -50,
    fontSize: '1.5rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      marginTop: -30
    }
  },
  addButton: {
    background: '#002D56',
    color: '#fff',
    marginTop: 40
  },
  searchAreaContainer: {
    maxWidth: '75vw',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  headerItem: {
    color: '#9ca4ab',
    fontWeight: '400'
  },
  tableContainer: {
    borderTop: 'solid 1px #e7e7e7',
    marginBottom: '30px'
  },
  tableWrapper: {
    maxWidth: '75vw',
    margin: 'auto',
    '& td, & th': {
      fontFamily: 'Actual'
    }
  },
  promotionTypeItem: {
    color: '#002D56'
  },
  naItem: {
    color: '#9ca4ab'
  },
  configTable: {
    border: 'solid #E6E6E6 1px'
  },
  tabs: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '&.MuiPaper-elevation1': {
      boxShadow: 'none'
    },
    '& .MuiTabs-root': {
        maxWidth: '75vw',
        margin: 'auto',
        padding: '0'
    },
    '& .MuiTab-root': {
        minWidth: '60px',
        borderBottom: '3px solid transparent'
    },
    '& .MuiTab-wrapper': {
        fontFamily: 'Actual',
        textTransform: 'none',
        fontSize: '18px',
        fontWeight: '400'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        color: '#00ADEF',
        borderBottom: '3px solid #00ADEF'
    },
    '& span.MuiTabs-indicator': {
        backgroundColor: 'transparent'
    }
  }
}));

const CustomCircularProgress = withStyles(theme => ({
  root: {
    '&.MuiCircularProgress-colorPrimary': {
      color: '#FFFFFF'
    }
  }
}))(CircularProgress);

const { baseUrl } = config;

const PROMOTION_TYPE = {
  date: {
    code: 'date',
    label: 'Date'
  },
  quantity: {
    code: 'quantity',
    label: 'Quantity'
  }
};

const PROMOTION_STATUS = {
  active: {
    code: 'active',
    label: 'Active'
  },
  notStarted: {
    code: 'notStarted',
    label: 'Not Started'
  },
  expired: {
    code: 'expired',
    label: 'Expired'
  }
};

const initSelectedPromotionConfig = {
  id: null,
  productName: '',
  rate: 0,
  startDate: '',
  endDate: '',
  quantity: '',
  promotionType: PROMOTION_TYPE.date.code
};

const initInputChanges = {
  configType: 'default',
  configurationType: 'modules',
  productName: '',
  quantity: 0,
  promotionType: PROMOTION_TYPE.date.code,
  qualified: true,
  rate: 0,
  startDate: moment()
    .startOf('day')
    .format(),
  endDate: moment()
    .endOf('day')
    .format()
};

const initSelectedDefaultConfig = {
  id: null,
  productName: '',
  rate: ''
};

export default function ConfigurationsModules() {
  const classes = useStyles();
  const [configTypes, setConfigTypes] = useState([]);
  const [nonQualifyConfigTypes, setNonQualifyConfigTypes] = useState([]);
  const [configTypesDeleted, setConfigTypesDeleted] = useState([]);
  const [promotionConfigs, setPromotionConfigs] = useState([]);
  const [modules, setModules] = useState([]);
  const [ess, setEss] = useState([]);
  const [isPromotionSelected, setIsPromotionSelected] = useState(false);
  const [inputChanges, setInputChanges] = useState(initInputChanges);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [selectedDeletePromotionConfig, setSelectedDeletePromotionConfig] = useState(
    {}
  );
  const [selectedDefaultConfig, setSelectedDefaultConfig] = useState(
    initSelectedDefaultConfig
  );
  const [selectedPromotionConfig, setSelectedPromotionConfig] = useState(
    initSelectedPromotionConfig
  );
  const [open, setOpen] = useState(false);
  const [isLoadingDeletePromotion, setIsLoadingDeletePromotion] = useState(false);
  const [isLoadingDeleteDefault, setIsLoadingDeleteDefault] = useState(false);
  const [openDefaultModalRemove, setOpenDefaultModalRemove] = useState(false);
  const [isLoadingAddButton, setIsLoadingAddButton] = useState(false);
  const [loadingConfigTypes, setLoadingConfigTypes] = useState(false);
  const [loadingPromotionConfigs, setLoadingPromotionConfigs] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleOpen = promotion => {
    setSelectedDeletePromotionConfig(promotion);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedDeletePromotionConfig({});
    setOpen(false);
  };

  const handleDefaultRemove = ct => {
    setSelectedConfig(ct);
    setOpenDefaultModalRemove(true);
  };

  const handleConfigModalClose = () => {
    setSelectedConfig({});
    setOpenDefaultModalRemove(false);
  };

  const removeConfig = async () => {
    try {
      setIsLoadingDeleteDefault(true);
      await axios.delete(`${baseUrl}/configurations/${selectedConfig.id}`, {
        id: selectedConfig.id,
        rate: 0
      });
      setSelectedConfig({});
      notiSuccess(`You have successfully set the default config to 0.`);
      getConfigTypes();
    } catch (err) {
      warningError(err);
    } finally {
      setOpenDefaultModalRemove(false);
      setIsLoadingDeleteDefault(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsLoadingDeletePromotion(true);
      const { data } = await axios.patch(
        `${baseUrl}/promotions/${selectedDeletePromotionConfig.configurationId}`,
        transferDeletePromotion(),
        {
          params: {
            httpReq: 'delete'
          }
        }
      );
      setSelectedDeletePromotionConfig({});
      notiSuccess(`You have successfully cancelled the promotion for ${data.id}`);
      getPromotionConfigs();
    } catch (err) {
      warningError(err, 'Oh no something went wrong cancelling the promotion');
    } finally {
      setOpen(false);
      setIsLoadingDeletePromotion(false);
    }
  };

  const transferDeletePromotion = () => {
    const data = {
      configurationId: selectedDeletePromotionConfig.configurationId,
      id: selectedDeletePromotionConfig.id,
      startDate: selectedDeletePromotionConfig.startDate,
      configurationType: 'modules'
    };
    if (selectedDeletePromotionConfig.promotionType === PROMOTION_TYPE.date.code) {
      return {
        ...data,
        endDate: moment()._d
      };
    }
    return {
      ...data,
      quantityAvailable: 0
    };
  };

  const handleDelete = async () => {
    try {
      setIsLoadingDeletePromotion(true);
      const { data } = await axios.delete(
        `${baseUrl}/promotions/${selectedDeletePromotionConfig.id}`
      );
      setSelectedDeletePromotionConfig({});
      notiSuccess(`You have successfully removed the promotion ${data.id}`);
      getPromotionConfigs();
    } catch (err) {
      warningError(err, 'Oh no something went wrong removing the promotion');
    } finally {
      setOpen(false);
      setIsLoadingDeletePromotion(false);
    }
  };

  const createNewDefaultConfig = async () => {
    let { productName, rate, qualified, configurationType } = inputChanges;
    productName = productName.toUpperCase();

    if (productName.length > 255) {
      notiFailed('Product name should not be longer than 255 characters!');
      return;
    }

    try {
      let find1 = find(configTypesDeleted, function(o) {
        return get(o, 'productName', '').toUpperCase() === productName;
      });
      if (find1 && productName && qualified) {
        setIsLoadingAddButton(true);
        const { data } = await axios.patch(`${baseUrl}/configurations/${find1.id}`, {
          id: find1.id,
          configurationType: configurationType,
          productName: productName,
          rate: qualified ? rate : 0,
          qualified
        });
        notiSuccess(
          `Successfully updated default configuration: ${data.productName}`
        );
      } else {
        setIsLoadingAddButton(true);
        const { data } = await axios.post(`${baseUrl}/configurations`, {
          configurationType: configurationType,
          productName: productName.toUpperCase(),
          rate: qualified ? rate : 0,
          qualified
        });
        notiSuccess(
          `Successfully created default configuration: ${data.productName}`
        );
        getConfigTypes();
        setInputChanges(initInputChanges);
      }
    } catch (err) {
      if (
        err.status === 422 &&
        err.data &&
        err.data.errors &&
        err.data.errors.length > 0
      ) {
        err.data.errors.forEach(v => {
          notiFailed(`${v.msg}`);
        });
      } else if (err.status === 422) {
        notiFailed(`${err.data.message}`);
      } else {
        warningError(err, `Something happened when creating default config.`);
      }
    } finally {
      setIsLoadingAddButton(false);
    }
  };

  const createNewPromotionConfig = async () => {
    try {
      setIsLoadingAddButton(true);
      await axios.post(`${baseUrl}/promotions`, transferCreatePromotion());
      setInputChanges(initInputChanges);
      setIsPromotionSelected(false);
      getPromotionConfigs();
      notiSuccess(`Successfully saved promotion`);
    } catch (err) {
      if (
        err.status === 422 &&
        err.data &&
        err.data.errors &&
        err.data.errors.length > 0
      ) {
        err.data.errors.forEach(v => {
          notiFailed(`${v.msg}`);
        });
      } else if (err.status === 422) {
        notiFailed(`${err.data.message}`);
      } else {
        warningError(err, `Something happened when creating promotion config.`);
      }
    } finally {
      setIsLoadingAddButton(false);
    }
  };

  const transferCreatePromotion = () => {
    const {
      configurationType,
      rate,
      startDate,
      endDate,
      configurationId,
      quantity,
      promotionType
    } = inputChanges;

    const formatStartDate = moment(startDate).format('YYYY-MM-DD 00:00:01');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD 23:59:59');
    if (promotionType === PROMOTION_TYPE.quantity.code) {
      return {
        configurationType,
        rate,
        startDate: formatStartDate,
        quantity,
        configurationId
      };
    }
    return {
      configurationType,
      rate,
      startDate: formatStartDate,
      endDate: formatEndDate,
      configurationId
    };
  };

  const togglePromotionFields = event => {
    setIsPromotionSelected(event.target.value === 'promotion');

    setInputChanges({
      ...initInputChanges,
      configType: event.target.value
    });
  };

  const filterPromotionStatus = data => {
    let today = new Date();

    const filterOutPastPromo = data.map(promo => {
      switch (promo.promotionType) {
        case PROMOTION_TYPE.date.code:
          if (
            moment(promo.endDate).isAfter(today) &&
            moment(promo.startDate).isBefore(today)
          ) {
            return { ...promo, status: PROMOTION_STATUS.active.code };
          } else if (moment(promo.startDate).isAfter(today)) {
            return {
              ...promo,
              status: PROMOTION_STATUS.notStarted.code
            };
          } else {
            return {
              ...promo,
              status: PROMOTION_STATUS.expired.code
            };
          }
        case PROMOTION_TYPE.quantity.code:
          if (moment(promo.startDate).isAfter(today)) {
            return {
              ...promo,
              status: PROMOTION_STATUS.notStarted.code
            };
          } else if (promo.quantityAvailable === 0) {
            return {
              ...promo,
              status: PROMOTION_STATUS.expired.code
            };
          } else {
            return { ...promo, status: PROMOTION_STATUS.active.code };
          }
        default:
          break;
      }
    });

    setPromotionConfigs(filterOutPastPromo);
  };

  const getPromotionConfigs = async () => {
    try {
      setLoadingPromotionConfigs(true);
      const { data } = await axios.get(
        `${baseUrl}/configurationTypes/all/promotions`
      );
      filterPromotionStatus(
        data
          .filter(o => o.configurationType !== 'forecasts')
          .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
      );
    } catch (err) {
      warningError(err, `There was a problem getting the promotions ${err.message}`);
    } finally {
      setLoadingPromotionConfigs(false);
    }
  };

  const getConfigTypes = async () => {
    try {
      setLoadingConfigTypes(true);
      const { data } = await axios.get(
        `${baseUrl}/configurationTypes/all/configurations`
      );

      setEss(data.filter(d => d.configurationType === 'ess' && d.qualified));
      setModules(data.filter(d => d.configurationType === 'modules' && d.qualified));
      setConfigTypes(
        data
          .filter(d => d.rate !== 0 && d.configurationType !== 'forecasts' && d.qualified)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      );
      setConfigTypesDeleted(data.filter(d => d.rate == 0 && d.qualified));
      setNonQualifyConfigTypes(
        data
          .filter(d => !d.qualified)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      );
    } catch (err) {
      warningError(
        err,
        `There was an error getting the configuration types ${err.message}`
      );
    } finally {
      setLoadingConfigTypes(false);
    }
  };

  const handleInputChange = event => {
    setInputChanges({ ...inputChanges, [event.target.name]: event.target.value });
  };

  const handleStartDateChange = date => {
    // Handle auto fill end date.
    if (moment(date) > moment(inputChanges.endDate)) {
      setInputChanges({
        ...inputChanges,
        startDate: moment(date)
          .startOf('day')
          .format(),
        endDate: moment(date)
          .startOf('day')
          .format()
      });
      return;
    }
    setInputChanges({
      ...inputChanges,
      startDate: moment(date)
        .startOf('day')
        .format()
    });
  };

  const handleEndDateChange = date => {
    setInputChanges({
      ...inputChanges,
      endDate: moment(date)
        .endOf('day')
        .format()
    });
  };

  const handleProductTypeChange = e => {
    setInputChanges({
      ...inputChanges,
      configurationType: e.target.value
    });
  };

  const handleQualifiedChange = e => {
    setInputChanges({
      ...inputChanges,
      qualified: e.target.value
    });
  };

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  const getRenderConfigTypes = () => {
    if (tabValue === 0) {
      return configTypes;
    }

    return nonQualifyConfigTypes;
  };

  const editDefaultConfig = (id, productName, rate) => {
    if (productName) {
      productName = productName.toUpperCase();
    }
    setSelectedDefaultConfig({ id, productName, rate });
  };

  const saveDefaultConfigChanges = async () => {
    try {
      const { data } = await axios.patch(
        `${baseUrl}/configurations/${selectedDefaultConfig.id}`,
        {
          id: selectedDefaultConfig.id,
          productName: selectedDefaultConfig.productName,
          rate: selectedDefaultConfig.rate
        }
      );
      getConfigTypes();
      getPromotionConfigs();
      setSelectedDefaultConfig(initSelectedDefaultConfig);
      notiSuccess(`Successfully edited default configuration: ${data.productName}`);
    } catch (err) {
      warningError(err);
    }
  };

  const editPromotionStartDate = date => {
    setSelectedPromotionConfig({
      ...selectedPromotionConfig,
      startDate: moment(date).format('MM-DD-YYYY 00:00:01')
    });
  };

  const editPromotionEndDate = date => {
    setSelectedPromotionConfig({
      ...selectedPromotionConfig,
      endDate: moment(date).format('MM-DD-YYYY 23:59:59')
    });
  };

  const savePromotionConfigChanges = async () => {
    try {
      await axios.patch(
        `${baseUrl}/promotions/${selectedPromotionConfig.configurationId}`,
        transferEditPromotion()
      );

      getPromotionConfigs();
      setSelectedPromotionConfig(initSelectedPromotionConfig);
      notiSuccess(`Successfully edited promotion configuration.`);
    } catch (err) {
      if (
        err.status === 422 &&
        err.data &&
        err.data.errors &&
        err.data.errors.length > 0
      ) {
        err.data.errors.forEach(v => {
          notiFailed(`${v.msg}`);
        });
      } else if (err.status === 422) {
        notiFailed(`${err.data.message}`);
      } else {
        warningError(err);
      }
    }
  };

  const transferEditPromotion = () => {
    const data = {
      configurationId: selectedPromotionConfig.configurationId,
      id: selectedPromotionConfig.id,
      configurationType: 'modules',
      rate: selectedPromotionConfig.rate,
      startDate: moment(selectedPromotionConfig.startDate).format(
        'YYYY-MM-DD 00:00:01'
      )
    };
    if (selectedPromotionConfig.promotionType === PROMOTION_TYPE.date.code) {
      return {
        ...data,
        endDate: moment(selectedPromotionConfig.endDate).format(
          'YYYY-MM-DD 23:59:59'
        )
      };
    }
    return {
      ...data,
      quantity: selectedPromotionConfig.quantity
    };
  };

  const handleColorPromotionStatus = promotionStatus => {
    switch (promotionStatus) {
      case PROMOTION_STATUS.active.code:
        return 'primaryLight';
      case PROMOTION_STATUS.notStarted.code:
        return 'dark';
      case PROMOTION_STATUS.expired.code:
        return 'secondaryDark';
      default:
        break;
    }
  };

  useEffect(() => {
    getConfigTypes();
    getPromotionConfigs();
  }, []);

  const addModule = () => {
    if (isPromotionSelected) {
      createNewPromotionConfig();
      return;
    }
    createNewDefaultConfig();
  };

  const NAComponent = () => {
    return <span className={classes.naItem}>N/A</span>;
  };

  const AddButtonComponent = () => {
    return (
      <Grid item md={1} xs={12}>
        <Grid container direction="column" justify="center" alignItems="center">
          {isLoadingAddButton ? (
            <Button className={classes.addButton} variant="contained">
              <CustomCircularProgress size={24} />
            </Button>
          ) : (
            <Button
              onClick={addModule}
              className={classes.addButton}
              variant="contained"
            >
              + Add
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const DateComponent = props => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disablePast
          fullWidth
          size="small"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          inputVariant="outlined"
          {...props}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const StartDateComponent = () => {
    return (
      <Grid item xs={6}>
        <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
          Start Date
        </Text>
        <DateComponent
          value={inputChanges.startDate}
          onChange={handleStartDateChange}
        />
      </Grid>
    );
  };

  const EndDateComponent = () => {
    return (
      <Grid item xs={6}>
        <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
          End Date
        </Text>
        <DateComponent
          minDate={inputChanges.startDate}
          value={inputChanges.endDate}
          onChange={handleEndDateChange}
        />
      </Grid>
    );
  };

  const ProductTypeComponent = () => {
    return (
      <Grid item md={2} xs={12}>
        <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
          Product Type
        </Text>
        <TextField
          select
          fullWidth
          variant="outlined"
          size="small"
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}
          onChange={handleProductTypeChange}
          value={inputChanges.configurationType}
        >
          <MenuItem value="modules">Modules</MenuItem>
          <MenuItem value="ess">ESS</MenuItem>
        </TextField>
      </Grid>
    );
  };

  const PromotionTypeComponent = () => {
    return (
      <Grid item md={2} xs={12}>
        <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
          Promotion Type
        </Text>
        <TextField
          select
          fullWidth
          variant="outlined"
          size="small"
          name="promotionType"
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}
          onChange={handleInputChange}
          value={inputChanges.promotionType}
        >
          {Object.keys(PROMOTION_TYPE).map(key => {
            return (
              <MenuItem
                key={PROMOTION_TYPE[key].code}
                value={PROMOTION_TYPE[key].code}
              >
                {PROMOTION_TYPE[key].label}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    );
  };

  const ProductNameAndRateComponent = () => {
    return (
      <React.Fragment>
        <Grid item md={3} xs={12}>
          <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
            Product Name
          </Text>
          {isPromotionSelected ? (
            <TextField
              fullWidth
              size="small"
              id="outlined-select-currency"
              select
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }
              }}
              placeholder="Product Name"
              name="configurationId"
              onChange={handleInputChange}
              variant="outlined"
              value={inputChanges.configurationId || ''}
            >
              {inputChanges.configurationType === 'ess'
                ? ess.map(data => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.productName}
                    </MenuItem>
                  ))
                : modules.map(data => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.productName}
                    </MenuItem>
                  ))}
            </TextField>
          ) : (
            <TextField
              fullWidth
              size="small"
              placeholder="Product Name"
              name="productName"
              variant="outlined"
              value={inputChanges.productName}
              className={classes.textField}
              onChange={handleInputChange}
            ></TextField>
          )}
        </Grid>
        {inputChanges.qualified && (
          <Grid item md={2} xs={12}>
            <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
              Rate
            </Text>
            <TextField
              fullWidth
              size="small"
              placeholder="Rate"
              name="rate"
              variant="outlined"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              onKeyPress={e => {
                if (isNaN(e.key)) {
                  e.preventDefault();
                }
              }}
              value={inputChanges.rate}
              onChange={handleInputChange}
            ></TextField>
          </Grid>
        )}
      </React.Fragment>
    );
  };

  const InputQuantityComponent = props => {
    return (
      <TextField
        fullWidth
        size="small"
        placeholder="Quantity"
        name="quantity"
        variant="outlined"
        type="number"
        InputProps={{
          inputProps: {
            min: 1
          }
        }}
        onKeyPress={e => {
          if (isNaN(e.key)) {
            e.preventDefault();
          }
        }}
        value={inputChanges.quantity}
        onChange={handleInputChange}
        {...props}
      ></TextField>
    );
  };

  const QuantityComponent = () => {
    return (
      <Grid item xs={6}>
        <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
          Quantity
        </Text>
        {InputQuantityComponent()}
      </Grid>
    );
  };

  const VolumeComponent = () => {
    return (
      <Grid item md={2} xs={12}>
        <Text color="secondaryDark">Volume (W)</Text>
        <Text
          style={{
            marginBottom: '-5px',
            marginTop: '-5px'
          }}
        >
          {inputChanges.quantity * 100}
        </Text>
      </Grid>
    );
  };

  const QualifiedComponent = () => {
    return (
      <Grid item md={2} xs={12}>
        <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
          Qualified Type
        </Text>
        <TextField
          select
          fullWidth
          variant="outlined"
          size="small"
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }
          }}
          onChange={handleQualifiedChange}
          value={inputChanges.qualified}
        >
          <MenuItem value={true}>Qualifying</MenuItem>
          <MenuItem value={false}>Non-qualifying</MenuItem>
        </TextField>
      </Grid>
    )
  };

  return (
    <>
      <Text size="h2" style={{ lineHeight: '0px' }}>
        Configurations
      </Text>

      <Paper elevation={0} style={{ marginBottom: '20px' }} className={classes.configTable}>
        <div
          style={{
            borderBottom: 'solid 1px #E6E6E6'
          }}
        >
          <Grid
            container
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              height: '55px'
            }}
          >
            <Grid
              item
              style={{
                display: 'inline-block',
                cursor: 'pointer',
                marginTop: '-5px',
                paddingRight: '7px',
                fontWeight: '100'
              }}
            >
              <Text
                color="primaryLight"
                size="h4"
                weight="regular"
                style={{
                  borderBottom: 'solid #00ADEF',
                  paddingBottom: '10px'
                }}
              >
                Modules
              </Text>
            </Grid>
            <Grid
              item
              component={Link}
              to="/rewards/configurations/forecasts"
              style={{
                display: 'inline-block',
                cursor: 'pointer',
                marginLeft: '25px',
                marginTop: '-5px',
                paddingRight: '7px',
                fontWeight: '100',
                textDecoration: 'none'
              }}
            >
              <Text size="h4" color="dark" weight="regular">
                Forecasts
              </Text>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            paddingTop: '15px',
            paddingBottom: '20px'
          }}
        >
          <Grid
            container
            wrap="nowrap"
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto'
            }}
            spacing={1}
          >
            <Text
              size="h3"
              style={{
                width: '100%',
                marginBottom: '2px',
                fontFamily: 'Actual'
              }}
              className="page_title2"
            >
              Add a Module Type or Promotion
            </Text>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            justify="flex-start"
            spacing={1}
            className={classes.searchAreaContainer}
          >
            <Grid item md={2} xs={12}>
              <Text color="secondaryDark" style={{ marginBottom: '2px' }}>
                Rate Type
              </Text>
              <TextField
                select
                fullWidth
                variant="outlined"
                size="small"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                onChange={togglePromotionFields}
                value={inputChanges.configType}
              >
                <MenuItem value="default">Default</MenuItem>
                <MenuItem value="promotion">Promotion</MenuItem>
              </TextField>
            </Grid>
            {isPromotionSelected && PromotionTypeComponent()}
            <ProductTypeComponent />
            {!isPromotionSelected && QualifiedComponent()}
            {ProductNameAndRateComponent()}
            {!isPromotionSelected && <Grid item md={3} xs={12}></Grid>}
            {isPromotionSelected && (
              <React.Fragment>
                <Grid item md={3} xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <StartDateComponent />
                    {inputChanges.promotionType === PROMOTION_TYPE.date.code &&
                      EndDateComponent()}
                    {inputChanges.promotionType === PROMOTION_TYPE.quantity.code &&
                      QuantityComponent()}
                  </Grid>
                </Grid>
                {inputChanges.promotionType === PROMOTION_TYPE.quantity.code &&
                  VolumeComponent()}
              </React.Fragment>
            )}
            {AddButtonComponent()}
          </Grid>
        </div>
      </Paper>

      {/* Defaults Table START */}
      <Paper elevation={0} style={{ marginBottom: '20px' }} className={classes.configTable}>
        <Paper className={classes.tabs} square>
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
          >
              <Tab label="Qualifying" />
              <Tab label="Non-qualifying" />
          </Tabs>
        </Paper>
        <Grid
          container
          justify="flex-start"
          className={classes.tableWrapper}
          spacing={2}
        >
          <Grid container justify="space-between" alignItems="center">
            <Text size="h3">Defaults</Text>
          </Grid>
          <TableContainer>
            <Table size="small" className={classes.tableContainer}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerItem}>Product Name</TableCell>
                  <TableCell className={classes.headerItem}>Product Type</TableCell>
                  {tabValue === 0 && <TableCell className={classes.headerItem}>Rate</TableCell>}
                  <TableCell className={classes.headerItem}>Updated</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getRenderConfigTypes().map(ct => (
                  <TableRow key={ct.id}>
                    {selectedDefaultConfig.id === ct.id ? (
                      <>
                        <TableCell>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={selectedDefaultConfig.productName}
                            onChange={e =>
                              setSelectedDefaultConfig({
                                ...selectedDefaultConfig,
                                productName: e.target.value
                              })
                            }
                          ></TextField>
                        </TableCell>
                        <TableCell>
                        {ct.configurationType}    
                          </TableCell>
                        {tabValue === 0 && (
                          <TableCell>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              value={selectedDefaultConfig.rate}
                              onChange={e =>
                                setSelectedDefaultConfig({
                                  ...selectedDefaultConfig,
                                  rate: e.target.value
                                })
                              }
                            ></TextField>
                          </TableCell>
                        )}
                        <TableCell>
                          <Moment format="L">{ct.updatedAt}</Moment>
                        </TableCell>
                        <TableCell align="right">
                          {selectedDefaultConfig.id ? (
                            <IconButton
                              size="small"
                              onClick={saveDefaultConfigChanges}
                            >
                              <CheckIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              size="small"
                              style={{ marginRight: '20px' }}
                              onClick={() => editDefaultConfig(ct.id)}
                            >
                              <CreateOutlinedIcon />
                            </IconButton>
                          )}

                          <Button
                            onClick={() =>
                              setSelectedDefaultConfig(initSelectedDefaultConfig)
                            }
                          >
                            Cancel
                          </Button>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell className="td_product_name">
                          {ct.productName}
                        </TableCell>
                        <TableCell className="td_product_name">
                          {ct.configurationType}
                        </TableCell>
                        {tabValue === 0 && <TableCell>{ct.rate}</TableCell>}
                        <TableCell>
                          <Moment format="L">{ct.updatedAt}</Moment>
                        </TableCell>
                        <TableCell align="right">
                          <div className={classes.actionItemContainer}>
                            <IconButton
                              size="small"
                              style={{ marginRight: '20px' }}
                              onClick={() =>
                                editDefaultConfig(ct.id, ct.productName, ct.rate)
                              }
                            >
                              <CreateOutlinedIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => handleDefaultRemove(ct)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!loadingConfigTypes && (!configTypes || !configTypes.length) && tabValue === 0 ? (
            <div className="wr_loading">List empty</div>
          ) : null}

          {!loadingConfigTypes && (!nonQualifyConfigTypes || !nonQualifyConfigTypes.length) && tabValue === 1 ? (
            <div className="wr_loading">List empty</div>
          ) : null}

          {loadingConfigTypes && (
            <div className="wr_loading">
              <CustomLoading size={40} color="#00adef"></CustomLoading>
            </div>
          )}
        </Grid>
      </Paper>

      {/* Promotions Table START */}
      <Paper elevation={0} style={{ marginBottom: '50px' }} className={classes.configTable}>
        <Grid
          container
          justify="flex-start"
          className={classes.tableWrapper}
          spacing={2}
        >
          <Grid container justify="space-between" alignItems="center">
            <Text size="h3">Promotions</Text>
          </Grid>
          <TableContainer>
            <Table size="small" className={classes.tableContainer}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerItem}>Module</TableCell>
                  <TableCell className={classes.headerItem}>
                    Promotion Type
                  </TableCell>
                  <TableCell className={classes.headerItem}>Rate {' (Q)'}</TableCell>
                  <TableCell className={classes.headerItem}>Start Date</TableCell>
                  <TableCell className={classes.headerItem}>End Date</TableCell>
                  <TableCell className={classes.headerItem}>
                    Quantity {' (pcs)'}
                  </TableCell>
                  <TableCell className={classes.headerItem}>
                    Progress {' (pcs)'}
                  </TableCell>
                  <TableCell className={classes.headerItem}>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loadingPromotionConfigs &&
                  promotionConfigs.map(pc => (
                    <TableRow hover key={pc.id}>
                      {selectedPromotionConfig.id === pc.id ? (
                        <>
                          <TableCell className="td_product_name">
                            {pc.productName}
                          </TableCell>
                          <TableCell className={classes.promotionTypeItem}>
                            {PROMOTION_TYPE[pc.promotionType].label}
                          </TableCell>
                          <TableCell>
                            <TextField
                              disabled={pc.status === PROMOTION_STATUS.active.code}
                              fullWidth
                              size="small"
                              variant="outlined"
                              value={selectedPromotionConfig.rate}
                              onChange={e =>
                                setSelectedPromotionConfig({
                                  ...selectedPromotionConfig,
                                  rate: e.target.value
                                })
                              }
                            ></TextField>
                          </TableCell>
                          <TableCell>
                            <DateComponent
                              disabled={pc.status === PROMOTION_STATUS.active.code}
                              value={selectedPromotionConfig.startDate}
                              onChange={editPromotionStartDate}
                            />
                          </TableCell>
                          <TableCell>
                            {pc.promotionType === PROMOTION_TYPE.date.code && (
                              <DateComponent
                                minDate={selectedPromotionConfig.startDate}
                                value={selectedPromotionConfig.endDate}
                                onChange={editPromotionEndDate}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {pc.promotionType === PROMOTION_TYPE.quantity.code &&
                              InputQuantityComponent({
                                value: selectedPromotionConfig.quantity,
                                onChange: e => {
                                  setSelectedPromotionConfig({
                                    ...selectedPromotionConfig,
                                    quantity: e.target.value
                                  });
                                }
                              })}
                          </TableCell>
                          <TableCell>
                            {pc.quantityScanned !== null ? (
                              pc.quantityScanned
                            ) : (
                              <NAComponent />
                            )}
                          </TableCell>
                          <TableCell>
                            <Text
                              style={{
                                whiteSpace: 'nowrap'
                              }}
                              color={handleColorPromotionStatus(pc.status)}
                            >
                              {PROMOTION_STATUS[pc.status].label}
                            </Text>
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.actionItemContainerEdit}
                          >
                            {selectedPromotionConfig.id ? (
                              <IconButton
                                size="small"
                                onClick={savePromotionConfigChanges}
                              >
                                <CheckIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                size="small"
                                style={{ marginRight: '20px' }}
                                onClick={() => editDefaultConfig(pc.id)}
                              >
                                <CreateOutlinedIcon />
                              </IconButton>
                            )}

                            <Button
                              onClick={() =>
                                setSelectedPromotionConfig(
                                  initSelectedPromotionConfig
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell className="td_product_name">
                            {pc.productName}
                          </TableCell>
                          <TableCell className={classes.promotionTypeItem}>
                            {PROMOTION_TYPE[pc.promotionType].label}
                          </TableCell>
                          <TableCell>{pc.rate.toLocaleString()}</TableCell>
                          <TableCell>
                            {moment(pc.startDate)
                              .utc()
                              .subtract(7, 'hours')
                              .format('MM/DD/YYYY')}
                          </TableCell>
                          <TableCell>
                            {pc.endDate ? (
                              moment(pc.endDate)
                                .utc()
                                .subtract(7, 'hours')
                                .format('MM/DD/YYYY')
                            ) : (
                              <NAComponent />
                            )}
                          </TableCell>
                          <TableCell>
                            {pc.quantity !== null ? pc.quantity : <NAComponent />}
                          </TableCell>
                          <TableCell>
                            {pc.quantityScanned !== null ? (
                              pc.quantityScanned
                            ) : (
                              <NAComponent />
                            )}
                          </TableCell>
                          <TableCell>
                            <Text
                              style={{
                                whiteSpace: 'nowrap'
                              }}
                              color={handleColorPromotionStatus(pc.status)}
                            >
                              {PROMOTION_STATUS[pc.status].label}
                            </Text>
                          </TableCell>
                          <TableCell align="right">
                            {pc.status === PROMOTION_STATUS.notStarted.code ||
                            pc.status === PROMOTION_STATUS.active.code ? (
                              <div className={classes.actionItemContainer}>
                                {' '}
                                <IconButton
                                  disabled={
                                    pc.status === PROMOTION_STATUS.active.code &&
                                    pc.promotionType === PROMOTION_TYPE.quantity.code
                                  }
                                  size="small"
                                  style={{ marginRight: '20px' }}
                                  onClick={() => setSelectedPromotionConfig(pc)}
                                >
                                  <CreateOutlinedIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handleOpen(pc)}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </div>
                            ) : (
                              false
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!loadingPromotionConfigs &&
          (!promotionConfigs || !promotionConfigs.length) ? (
            <div className="wr_loading">List empty</div>
          ) : null}

          {loadingPromotionConfigs && (
            <div className="wr_loading">
              <CustomLoading size={40} color="#00adef"></CustomLoading>
            </div>
          )}
        </Grid>
      </Paper>

      {/* Popup START */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton onClick={handleClose}>
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.titleEndPromotionDialog}>
              Are you sure you want to end this promotion?
            </Text>
            <Text
              color="secondaryDark"
              className={classes.descriptionEndPromotionDialog}
            >
              {moment(selectedPromotionConfig.startDate)
                .startOf('day')
                .isAfter()
                ? `This promotion has not started. This will delete the promotion immediately and remove it from the list.`
                : `This will end the promotion immediately and change the status to expired.`}
            </Text>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={handleClose}
                  style={{ height: '40px' }}
                >
                  Nevermind
                </CustomButton>
              </Grid>
              <Grid item>
                {isLoadingDeletePromotion ? (
                  <CustomButton color="alert" style={{ height: '40px' }}>
                    <CustomCircularProgress size={24} />
                  </CustomButton>
                ) : (
                  <CustomButton
                    color="alert"
                    style={{ height: '40px' }}
                    onClick={
                      moment(selectedDeletePromotionConfig.startDate).isAfter()
                        ? handleDelete
                        : handleUpdate
                    }
                  >
                    Delete
                  </CustomButton>
                )}
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDefaultModalRemove}
        onClose={handleConfigModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openDefaultModalRemove}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton
                onClick={handleConfigModalClose}
                style={{ marginTop: '20px' }}
              >
                <CloseOutlinedIcon fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.contentDeleteConfigurationPopup}>
              Are you sure you want to delete this configuration?
            </Text>
            <Text
              size="h3"
              color="secondaryDark"
              className={classes.descriptionDeleteConfigurationPopup}
            >
              This will set the configuration rate to 0.
            </Text>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <CustomButton
                  color="secondary"
                  onClick={handleConfigModalClose}
                  style={{ height: '40px' }}
                >
                  Nevermind
                </CustomButton>
              </Grid>
              <Grid item>
                {isLoadingDeleteDefault ? (
                  <CustomButton color="alert" style={{ height: '40px' }}>
                    <CustomCircularProgress size={24} />
                  </CustomButton>
                ) : (
                  <CustomButton
                    color="alert"
                    onClick={removeConfig}
                    style={{ height: '40px' }}
                  >
                    Delete
                  </CustomButton>
                )}
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>
    </>
  );
}
