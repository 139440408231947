import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Backdrop, Fade,
  FormControlLabel,
  Grid, IconButton,
  makeStyles, Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableCell, TableContainer,
  TableRow, TextareaAutosize,
  TextField
} from '@material-ui/core';

import Button from '../../../../common/components/button';
import CustomLoading from '../../../../common/components/CustomLoading';
import Alert from '../../../../routes/rewards/Alert';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Text from '../../../../common/components/text';
import CustomButton from '../../../../common/components/button';

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  tableHeader: {
    background: '#f4f5f7',
    fontWeight: '500',
    fontSize: '15px'
  },
  table: {
    borderTop: '1px solid #e4e4e4',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  noteTableHeader: {
    textAlign: 'center',
    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px'
  },
  noteTable: {
    borderTop: '1px solid #e4e4e4',
    marginTop: '10px',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  emptyNoteList: {
    '& td': {
      padding: '81px 15px'
    }
  },
  emailText: {
    margin: '8px 12px 8px 0'
  },
  multiSelect: {
    '& .select__control': {
      borderRadius: 0,
      padding: '4px'
    },
    '& .select__multi-value__remove:hover': {
      backgroundColor: '#00adef',
      color: '#fff'
    }
  },
  w9ImageInput: {
    display: 'none'
  },
  w9ImageBtn: {
    border: '1px solid gray',
    height: '56px',
    marginLeft: '10px'
  },
  textArea: {
    width: '100%',
    padding: '5px',
    fontSize: '14px',
    fontFamily: 'Actual'
  },
  textAreaError : {
    width: '100%',
    padding: '5px',
    borderColor: 'red'
  },
  newLetterText: {
    lineHeight: '40px',
    marginTop: 0,
    marginBottom: 0
  },
  newLetterRadio: {
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#019ee3'
    }
  },
  textEmail : {
    borderColor: 'red'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textConfirm : {
    fontSize: 36
  },
  textInput : {
    fontSize: 14
  },
  w9Download: {
    display: 'inline-block',
    position: 'relative',
    transition: 'color 0.4s',
    paddingLeft: '20px',
    // background: `url(${DownloadIcon}) left 1px no-repeat`,
    '&:after': {
      position: 'absolute',
      left: '19px',
      bottom: 0,
      width: 0,
      height: '1px',
      background: '#019ee3',
      content: '""',
      transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#019ee3'
      // background: `url(${DownloadIconOn}) left 1px no-repeat`,
    },
    '&:hover:after': {
      width: 'calc(100% - 20px)'
    }
  }
}));



const validateSchemaFormEmail = yup.object({
  email : yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  title : yup.string().required('Title is required'),
  content : yup.string().required('Content is required')
})


const validateSchemaFormPhone = yup.object({
  content : yup.string().required('Content is required')
})



const FormSubmitResponse = ({handleSubmit, email, loading}) => {

  const classes = useStyles();

  const [validate, setValidate] = useState(validateSchemaFormEmail);
  const [open, setOpen] = useState(false);
  const [initValue, setInitValue] = useState({
    email: email,
    title: '',
    content : '',
    type: 'email'
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValue,
    validationSchema: validate,
    onSubmit: (values, {resetForm}) => {
      setOpen(true);
    }
  });

  function handleRegister() {
    handleSubmit(formik.values);
    formik.resetForm({
      ...initValue,
      email
    });
  }


  useEffect(() => {
    setInitValue(prevState => ({
      ...prevState,
      email
    }))
  }, [email])

  useEffect(() => {
    if(formik.values.type === 'phone') {
      setValidate(validateSchemaFormPhone);
    }

    if(formik.values.type === 'email') {
      setValidate(validateSchemaFormEmail);
    }
  }, [formik.values.type])



  return(
    <form onSubmit={formik.handleSubmit}>
      <TableContainer style={{ marginTop: 20, marginBottom: 20 }} component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableRow align="center">
            <TableCell align='center' className={classes.tableHeader} colSpan={5}>Status</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='center' className={classes.tableHeader}>* How to Respond</TableCell>
            <TableCell align='left'>
              <Grid container xs={12} className={classes.newLetterRadio}>
                <Grid item>
                  <RadioGroup id="type" value={formik.values.type} onChange={formik.handleChange} row aria-label="position" name="type" defaultValue={`email`}>
                    <FormControlLabel classes={{label : classes.textInput}} value="email" control={<Radio color="primary"/>} label="Sending email"/>
                    <FormControlLabel classes={{label : classes.textInput}} value="phone" control={<Radio color="primary"/>} label="Phone call"/>
                  </RadioGroup>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>

          {formik.values.type === 'email' && <TableRow>
            <TableCell align='center' className={classes.tableHeader}>* Mail to</TableCell>
            <TableCell align='left' style={{ minWidth: '300px' }}>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    input: classes.textInput
                  }
                }}
                name="email"
                className={classes.textEmail}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                onChange={formik.handleChange}
                style={{ minWidth: 300 }}
                variant="outlined"
                size="small"
                helperText={formik.touched.email && formik.errors.email}
              />
            </TableCell>
          </TableRow>}

          {formik.values.type === 'email' && <TableRow>
            <TableCell align='center' className={classes.tableHeader}>* Title</TableCell>
            <TableCell align='left' style={{ minWidth: '300px' }}>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.textInput
                  }
                }}
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                helperText={formik.touched.title && formik.errors.title}
                fullWidth
                error={formik.touched.title && Boolean(formik.errors.title)}
                variant="outlined"
                size="small"
              />
            </TableCell>
          </TableRow>}
          <TableRow>
            <TableCell align='center' className={classes.tableHeader}>* Contents</TableCell>
            <TableCell align='left' colSpan={3}>
              <TextareaAutosize
                id="content"
                name="content"
                size={"small"}
                onChange={formik.handleChange}
                className={formik.touched.title && formik.errors.content ? classes.textAreaError : classes.textArea}
                rowsMin={6}
                value={formik.values.content}
                helperText={formik.touched.title && formik.errors.title}
              />
              <p style={{color : 'red', fontSize: '0.75rem', marginLeft: 15 ,marginTop: 0}}>{formik.touched.title && formik.errors.content}</p>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
      <Grid style={{marginBottom: 20}} container alignItems={'center'} justify={'center'}>
        <Button type="submit">Confirm</Button>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title-new"
        aria-describedby="transition-modal-description-new"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Alert>
            <Grid container justify="flex-end">
              <IconButton>
                <CloseOutlinedIcon onClick={() => setOpen(false)} fontSize="large" style={{ color: '#8C8C8C' }} />
              </IconButton>
            </Grid>
            <Text className={classes.textConfirm}>
              Do you want to register ?
            </Text>
            <Grid container justify="center" spacing={2} style={{ marginBottom: '20px' }}>
              <Grid item>
                <CustomButton color="secondary">
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item>
                <Button onClick={handleRegister} type="submit">{loading ?  <CustomLoading size={18}/> : 'Confirm'}</Button>
              </Grid>
            </Grid>
          </Alert>
        </Fade>
      </Modal>

    </form>
  )
}


export default FormSubmitResponse;
