import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { contactService } from '../../../../services';
import InformationContact from '../InfomationContact';
import ContactHistory from '../ContactHistory';
import _ from 'lodash';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import Text from '../../../../common/components/text';
import BackButton from '../../../../common/components/button/BackButton';
import FormSubmitResponse from '../FormSubmitResponse';
import { notiFailed, notiSuccess } from '../../../../utils/helpers';
import Button from '../../../../common/components/button';
import CustomLoading from '../../../../common/components/CustomLoading';
import CustomButton from '../../../../common/components/button';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  tableHeader: {
    background: '#f4f5f7',
    fontWeight: '500'
  },
  table: {
    borderTop: '1px solid #e4e4e4',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  noteTableHeader: {
    textAlign: 'center',
    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '16px',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '10px'
  },
  noteTable: {
    borderTop: '1px solid #e4e4e4',
    marginTop: '10px',
    '& td': {
      fontFamily: 'Actual'
    }
  },
  emptyNoteList: {
    '& td': {
      padding: '81px 15px'
    }
  },
  emailText: {
    margin: '8px 12px 8px 0'
  },
  multiSelect: {
    '& .select__control': {
      borderRadius: 0,
      padding: '4px'
    },
    '& .select__multi-value__remove:hover': {
      backgroundColor: '#00adef',
      color: '#fff'
    }
  },
  w9ImageInput: {
    display: 'none'
  },
  w9ImageBtn: {
    border: '1px solid gray',
    height: '56px',
    marginLeft: '10px'
  },
  textArea: {
    width: '100%',
    padding: '5px'
  },
  newLetterText: {
    lineHeight: '40px',
    marginTop: 0,
    marginBottom: 0
  },
  newLetterRadio: {
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#019ee3'
    }
  },
  w9Download: {
    display: 'inline-block',
    position: 'relative',
    transition: 'color 0.4s',
    paddingLeft: '20px',
    // background: `url(${DownloadIcon}) left 1px no-repeat`,
    '&:after': {
      position: 'absolute',
      left: '19px',
      bottom: 0,
      width: 0,
      height: '1px',
      background: '#019ee3',
      content: '""',
      transition: 'width 0.6s 0.2s'
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#019ee3'
      // background: `url(${DownloadIconOn}) left 1px no-repeat`,
    },
    '&:hover:after': {
      width: 'calc(100% - 20px)'
    }
  }
}));


function FormEditSupportCenter() {

  const { id, type } = useParams();
  const [detailContact, setDetailContact] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    getDetail();
  }, [id]);

  async function getDetail() {
    setLoadingDetail(true);
    const detail = await contactService.getDetailContactSupportCenter(id);
    setLoadingDetail(false);
    setDetailContact(detail.data);
  }

  async function submitValueResponse(value) {


    const makePayLoadFormContactEmail = {
      contactId : detailContact.id,
      content : value.content,
      contactStatusId : value.type === 'email' ? 2 : 3,
      mailTitle : value.title,
      email : value.email || _.get(detailContact, 'email')
    }

    const makePayloadFormContactPhone = {
      contactId : detailContact.id,
      content : value.content,
      contactStatusId : value.type === 'email' ? 2 : 3,
    }



    setLoading(true);

    await contactService.sendContact(value.type === 'email' ? makePayLoadFormContactEmail : makePayloadFormContactPhone).then(() => {
      setLoading(false);
      notiSuccess('Registered !');
      getDetail();
    }).catch(() => {
      setLoading(false);
      notiFailed('Failed Registered !');
    })

  }

  return (
    <>
      <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 10}}>
        {
          type === '1' ?
            <BackButton component={Link} to="/supportcenter/qcells">
              List
            </BackButton> :
            <BackButton component={Link} to="/supportcenter/partner">
              List
            </BackButton>
        }

      </Grid>
      <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 10 }}>
        <Text size="h2" style={{ lineHeight: '0px' }}>
          {type === '2' ?  'Q.PARTNER Portal' : 'Product Website'}
        </Text>
      </Grid>

      {loadingDetail ? <Grid style={{marginBottom: 20}} container alignItems={'center'} justify={'center'}>
         <CustomLoading color={'#2badef'} size={40}/>
      </Grid> : <Paper className={classes.container}>
        <InformationContact type={type} info={detailContact}/>
        <Grid container>
          <Grid
            style={{paddingRight: 10, marginTop: 20}}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            xs={12} md={12} lg={12} sm={12}
          >
              *Required
          </Grid>

        </Grid>
        <FormSubmitResponse loading={loading} email={_.get(detailContact, 'email')} handleSubmit={submitValueResponse}/>
        <ContactHistory histories={_.get(detailContact, 'contactHistories', [])}/>
      </Paper>}



    </>
  );
}

export default FormEditSupportCenter;
