import React, { useState } from 'react';
import axios from '../../utils/axios';
import { baseUrl } from '../../config';
import { Link } from 'react-router-dom';
import { Grid, Paper, TextField, InputAdornment, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../../common/components/text';
import SearchIcon from '@material-ui/icons/Search';
import CustomLoading from './../../common/components/CustomLoading';

const useStyles = makeStyles(theme => ({
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '40px'
  },
  iconButton: {
    padding: 0,
    position: 'absolute',
    top: '13px',
    right: '15px'
  },
  datePickerContainer: {
    position: 'relative'
  },
  tableHeaderCell: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1rem',
    paddingLeft: '55px'
  },
  tableBodyCell: {
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '.9rem',
    paddingLeft: '55px'
  },
  approveRejectContainer: {
    margin: 'auto',
    marginTop: '20px'
  },
  paginationContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    '& .Mui-selected': {
      backgroundColor: '#00ADEF',
      color: '#fff'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  approveRejectModuleModalCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  },
  approveRejectModuleModalTitle: {
    textAlign: 'center',
    marginTop: '15px',
    fontSize: '40px',
    lineHeight: '45px'
  },
  approveRejectModuleModalSubtitle: {
    marginTop: '-32px',
    marginBottom: '20px',
    fontSize: '20px !important'
  },
  approveRejectModuleModalCancelBtn: {
    padding: '.75rem 2rem !important'
  },
  approveRejectModuleModalApproveBtn: {
    padding: '.75rem 2rem !important'
  },
  resultModuleRejectModalCloseBtn: {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '-20px'
  },
  resultModuleRejectedModalTitle: {
    fontSize: '40px',
    textAlign: 'center',
    marginBottom: '40px',
    marginTop: '15px'
  },
  resultModuleRejectedModalSubtitle: {
    marginTop: '-40px',
    fontSize: '20px !important'
  },
  resultModuleRejectModalDownloadBtn: {
    padding: '.75rem 2rem !important'
  },
  noModuleContainer: {
    width: '95%',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: '#e5f3fb',
    paddingTop: '50px',
    paddingBottom: '50px',
    marginTop: '50px',
    marginBottom: '50px'
  },
  tableContainer: {
    marginBottom: '20px'
  },

  wrapSearchModule: {
    marginBottom: 10
  }
}));

export default function UploadList() {
  const classes = useStyles();
  const [modules, setModules] = useState([]);
  const [keyword, setKeyword] = useState();
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    setSearched(true);
    try {
      const { data } = await axios.get(`${baseUrl}/modules/awaiting-approval/filter-search-uploads`, { params: {keyword} });
      setModules(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Text size="h2" style={{ lineHeight: '0px' }}>
        Uploads
      </Text>
      <Paper>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="flex-start"
            style={{
              maxWidth: '75vw',
              margin: 'auto'
            }}
            spacing={2}
          >
            <Grid
              item
              component={Link}
              to="/rewards/articlecode-upload"
              style={{ textDecoration: 'none' }}
            >
              <Text color="dark" size="h4">
                Article Code
              </Text>
            </Grid>
            <Grid
              item
              component={Link}
              to="/rewards/serialnumber-upload"
              style={{ textDecoration: 'none' }}
            >
              <Text size="h4" color="dark">
                Serial Number
              </Text>
            </Grid>
            <Grid
              item
              to="/rewards/upload-list"
              style={{
                borderBottom: 'solid',
                borderColor: '#00ADEF'
              }}
            >
              <Text size="h4" color="primaryLight">
                Upload List
              </Text>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6'
          }}
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              maxWidth: '75vw',
              margin: '5px auto'
            }}
          >
            <Text size="h2" style={{ margin: 0 }} className="page_title">
              Upload List
            </Text>
          </Grid>
        </div>
        <div
          style={{
            borderBottom: 'solid',
            borderWidth: '1px',
            borderColor: '#E6E6E6',
            width: '100%'
          }}
        >
          <Grid
            container
            spacing={1}
            style={{
              maxWidth: '75vw',
              margin: 'auto',
              paddingTop: '20px',
              paddingBottom: '20px'
            }}
          >
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </Grid>
          </Grid>
        </div>
          <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
              <TableCell className={classes.tableHeaderCell}>
                  Product Type
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Article Code
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Serial Number
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan="6">
                    <div className={classes.loadingIndicator}>
                      <CustomLoading size={40} color="#00adef"></CustomLoading>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {
                    modules.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan="6">
                          <div className={classes.noModuleContainer}>
                            <Text color="primaryLight" weight="bold">
                              { searched ? 'No modules or article codes match your search.' : 'Input a article code or serial number above.'}
                            </Text>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : modules.map((module, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={classes.tableBodyCell}>
                            {module.configurationType.toUpperCase()}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {module.code}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {module.serialNumber}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {module.approved ? 'Accrued' : 'Unaccrued'}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
