import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../components/text';
import styled from 'styled-components';
import classNames from 'classnames';

import { List, ListItem, ListItemText, Collapse, Button } from '@material-ui/core';
import { ACCESS_CONTROL_LIST_TYPES, PTI_ADMIN_URL } from './../../utils/constants';
import { RoleContext } from './../../contexts/roles';

const useStyles = makeStyles(theme => ({
  toolbar: {
    position: 'fixed',
    minHeight: '95vh',
    minWidth: '230px',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    padding: theme.spacing(0, 1),
    paddingLeft: 0,
    borderRight: '1px solid #e6e6e6',
    zIndex: 999,
  },
  buttonStyle: { borderBottom: 'solid', borderBottomColor: '#00ADEF' },
  addEditProductToolBar: {
    width: '220px',
    zIndex: '999'
  }
}));

const MainButton = styled(Button)(
  props => `
  height: 60px;
  border-radius: 0;
  font-family: Actual;

  &:hover {
    background-color: '#FFF';
  }
  & .MuiPaper-rounded {
    border-radius: 0px;
  }

  & .MuiTypography-body2 {
    font-size: 15px;
  }

  & .MuiTypography-body2:hover {
    color: #00adef;
  }

  & .MuiListItem-button:hover {
    background-color: #fff;
  }

  ${props.open &&
    `
    color: #00ADEF;
    border-bottom: 4px solid #00ADEF;
    font-weight: 700;
    border-bottom: solid;
    border-bottom-color: #00ADEF;
  `}
  
`
);

const SecondaryButton = styled(List)`
  & .MuiPaper-rounded {
    border-radius: 0px;
  }

  & .MuiTypography-body2 {
    font-size: 15px;
  }

  & .MuiTypography-body2:hover {
    color: #00adef;
  }

  & .MuiListItem-button:hover {
    background-color: #fff;
  }
`;

const qpartnerLegacyBaseUrl =
  process.env.REACT_APP_QPARTNER_LEGACY_BASE_URL || 'https://qcellspartner.us';

export default function CenteredTabs(props) {
  const { checkShowLegacyMenuItem, checkShowMenuItem } = useContext(RoleContext);
  const location = useLocation();
  const classes = useStyles();

  const [isQpartnerOpen, setIsQpartnerOpen] = useState(
    location.pathname.includes('qpartners')
  );

  const [isRewardsOpen, setIsRewardsOpen] = useState(
    location.pathname.includes('rewards')
  );

  const [isMarketingShopOpen, setIsMarketingShopOpen] = useState(
    location.pathname.includes('marketing-shop')
  );

  const [isSupportCenterOpen, setIsSupportCenterOpen] = useState(
    location.pathname.includes('supportcenter')
  );

  const [isAdminControlsOpen, setIsAdminControlsOpen] = useState(
    location.pathname.includes('admincontrols')
  );

  const [isApprovalsSelected, setIsApprovalsSelected] = useState(
    location.pathname.includes('approvals')
  );
  const [isUploadsSelected, setIsUploadsSelected] = useState(
    location.pathname.includes('upload')
  );
  const [isConfigurationsSelected, setIsConfigurationsSelected] = useState(
    location.pathname.includes('configurations')
  );
  const [isProductsSelected, setIsProductsSelected] = useState(
    location.pathname.includes('products')
  );
  const [isOrdersSelected, setIsOrdersSelected] = useState(
    location.pathname.includes('orders')
  );

  useEffect(() => {
    if (location) {
      setIsRewardsOpen(location.pathname.includes('rewards'));
      setIsMarketingShopOpen(location.pathname.includes('marketing-shop'));
      setIsApprovalsSelected(location.pathname.includes('approvals'));
      setIsUploadsSelected(location.pathname.includes('upload'));
      setIsConfigurationsSelected(location.pathname.includes('configurations'));
      setIsProductsSelected(location.pathname.includes('products'));
      setIsOrdersSelected(location.pathname.includes('orders'));
      setIsQpartnerOpen(location.pathname.includes('qpartners'));
      setIsSupportCenterOpen(location.pathname.includes('supportcenter'));
      setIsAdminControlsOpen(location.pathname.includes('admincontrols'));
    }
  }, [location]);

  return (
    <div className={classNames([classes.toolbar], { [classes.addEditProductToolBar]: props.isAddEditProductPage })}>
      <List dense style={{ width: '200px', textTransform: 'uppercase' }}>
        {checkShowLegacyMenuItem() &&
        <>
          <MainButton
            component="div"
            onClick={() => setIsQpartnerOpen(!isQpartnerOpen)}
            selected={location.pathname.includes('qpartners')}
            style={
              isQpartnerOpen
                ? {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#00ADEF',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
                : {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderWidth: '1px',
                    borderColor: '#E6E6E6',
                    height: '50px'
                  }
            }
          >
            <ListItem key={'Rewards'}>
              <Text size="h5" color="dark" weight="bold">
                Q.Partners
              </Text>
            </ListItem>
          </MainButton>
          <Collapse in={isQpartnerOpen} timeout="auto" unmountOnExit>
            <SecondaryButton
              component="div"
              // style={{ width: '264px', textTransform: 'uppercase' }}
            >
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/qpartners/registration/list/0"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('registration')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Registration
                  </Text>
                </ListItem>
              </MainButton>
            </SecondaryButton>
          </Collapse>
        </>}

        {checkShowMenuItem([
          ACCESS_CONTROL_LIST_TYPES.APPROVAL_MANAGEMENT,
          ACCESS_CONTROL_LIST_TYPES.UPLOAD_MANAGEMENT,
          ACCESS_CONTROL_LIST_TYPES.CONFIGURATIONS_MANAGEMENT,
          ACCESS_CONTROL_LIST_TYPES.QPOINT_MANAGEMENT
        ]) && (
        <>
          <MainButton
            component="div"
            onClick={() => setIsRewardsOpen(!isRewardsOpen)}
            selected={location.pathname.includes('rewards')}
            style={
              isRewardsOpen
                ? {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#00ADEF',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
                : {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#e6e6e6',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
            }
          >
            <ListItem key={'Rewards'}>
              <Text size="h5" color="dark" weight="bold">
                REWARDS
              </Text>
            </ListItem>
          </MainButton>
          <Collapse in={isRewardsOpen} timeout="auto" unmountOnExit>
            <SecondaryButton
              component="div"
              style={{ width: '264px', textTransform: 'uppercase' }}
            >
              {checkShowMenuItem([ACCESS_CONTROL_LIST_TYPES.APPROVAL_MANAGEMENT]) &&
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/rewards/module-approvals"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('approvals')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Approvals
                  </Text>
                </ListItem>
              </MainButton>}
              {checkShowMenuItem([ACCESS_CONTROL_LIST_TYPES.UPLOAD_MANAGEMENT]) &&
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/rewards/articlecode-upload"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('upload')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Uploads
                  </Text>
                </ListItem>
              </MainButton>}
              {checkShowMenuItem([ACCESS_CONTROL_LIST_TYPES.CONFIGURATIONS_MANAGEMENT]) &&
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/rewards/configurations/modules"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('configurations')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Configurations
                  </Text>
                </ListItem>
              </MainButton>}
              {checkShowMenuItem([ACCESS_CONTROL_LIST_TYPES.QPOINT_MANAGEMENT]) &&
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/rewards/qpoint-management"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('qpoint-management')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Q.Point Management
                  </Text>
                </ListItem>
              </MainButton>}
            </SecondaryButton>
          </Collapse>
        </>)}

        {checkShowMenuItem([
          ACCESS_CONTROL_LIST_TYPES.PRODUCTS_MANAGEMENT,
          ACCESS_CONTROL_LIST_TYPES.ORDERS_MANAGEMENT
        ]) && (
        <>
          <MainButton
            component="div"
            onClick={() => setIsMarketingShopOpen(!isMarketingShopOpen)}
            selected={location.pathname.includes('marketing-shop')}
            style={
              isMarketingShopOpen
                ? {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#00ADEF',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
                : {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#e6e6e6',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
            }
          >
            <ListItem key={'Rewards'}>
              <Text size="h5" color="dark" weight="bold">
                Shop
              </Text>
            </ListItem>
          </MainButton>
          <Collapse in={isMarketingShopOpen} timeout="auto" unmountOnExit>
            <SecondaryButton
              component="div"
              style={{ width: '264px', textTransform: 'uppercase' }}
            >
              {checkShowMenuItem([ACCESS_CONTROL_LIST_TYPES.PRODUCTS_MANAGEMENT]) &&
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/marketing-shop/products/1"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('products')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Products
                  </Text>
                </ListItem>
              </MainButton>}
              {checkShowMenuItem([ACCESS_CONTROL_LIST_TYPES.ORDERS_MANAGEMENT]) &&
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/marketing-shop/orders/all/1"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('orders')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Orders
                  </Text>
                </ListItem>
              </MainButton>}
            </SecondaryButton>
          </Collapse>
        </>)}

        {checkShowLegacyMenuItem() &&
        <>
          <MainButton
            component="div"
            onClick={() => setIsSupportCenterOpen(!isSupportCenterOpen)}
            selected={location.pathname.includes('supportcenter')}
            style={
              isSupportCenterOpen
                ? {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#00ADEF',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
                : {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#e6e6e6',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
            }
          >
            <ListItem key={'Rewards'}>
              <Text size="h5" color="dark" weight="bold">
                Support Center
              </Text>
            </ListItem>
          </MainButton>
          <Collapse in={isSupportCenterOpen} timeout="auto" unmountOnExit>
            <SecondaryButton
              component="div"
              style={{ width: '264px', textTransform: 'uppercase' }}
            >
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/supportcenter/qcells"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('/supportcenter/qcells')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Qcells Website
                  </Text>
                </ListItem>
              </MainButton>
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/supportcenter/partner"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('/supportcenter/partner')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Q.PARTNER Website
                  </Text>
                </ListItem>
              </MainButton>
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
               <ListItem
                  component={Link}
                  to="/supportcenter/claim-management"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('/supportcenter/claim-management')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Claim Management
                  </Text>
                </ListItem>
              </MainButton>
            </SecondaryButton>
          </Collapse>
        </>}

        {checkShowLegacyMenuItem() &&
        <>
          <MainButton
            component="div"
            onClick={() => setIsAdminControlsOpen(!isAdminControlsOpen)}
            selected={location.pathname.includes('admincontrols')}
            style={
              isAdminControlsOpen
                ? {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#00ADEF',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
                : {
                    width: '264px',
                    textTransform: 'uppercase',
                    borderBottom: 'solid',
                    borderColor: '#e6e6e6',
                    borderWidth: '2px',
                    fontWeight: '700',
                    height: '50px'
                  }
            }
          >
            <ListItem key={'Rewards'}>
              <Text size="h5" color="dark" weight="bold">
                Admin Controls
              </Text>
            </ListItem>
          </MainButton>
          <Collapse in={isAdminControlsOpen} timeout="auto" unmountOnExit>
            <SecondaryButton
              component="div"
              style={{ width: '264px', textTransform: 'uppercase' }}
            >
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to={'/admincontrols/management'}
                  className="link1"
                >
                  <Text
                    color={
                      location.pathname.includes('management')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Admin Management
                  </Text>
                </ListItem>
              </MainButton>
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to={'/admincontrols/news'}
                  className="link1"
                >
                  <Text
                    color={
                      location.pathname.includes('news')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    News
                  </Text>
                </ListItem>

              </MainButton>
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/admincontrols/data-hub"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('data-hub')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Download Center
                  </Text>
                </ListItem>
              </MainButton>
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to={'/admincontrols/configurations/list'}
                  className="link1"
                >
                  <Text
                    color={
                      location.pathname.includes('configurations')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    Video Content
                  </Text>
                </ListItem>
              </MainButton>
              <MainButton
                component="div"
                style={{
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: '#E6E6E6',
                  height: '35px'
                }}
              >
                <ListItem
                  component={Link}
                  to="/admincontrols/faq"
                  style={{
                    height: '51px'
                  }}
                >
                  <Text
                    color={
                      location.pathname.includes('faq')
                        ? 'primaryLight'
                        : 'secondaryDark'
                    }
                    weight="medium"
                    style={{ fontSize: '13px' }}
                  >
                    FAQ
                  </Text>
                </ListItem>
              </MainButton>
            </SecondaryButton>
          </Collapse>
        </>}

        <MainButton
          component="div"
          selected={location.pathname.includes('projects')}
          style={
            location.pathname.includes('projects')
              ? {
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderColor: '#00ADEF',
                  borderWidth: '2px',
                  fontWeight: '700',
                  height: '50px'
                }
              : {
                  width: '264px',
                  textTransform: 'uppercase',
                  borderBottom: 'solid',
                  borderColor: '#e6e6e6',
                  borderWidth: '2px',
                  fontWeight: '700',
                  height: '50px'
                }
          }
        >
          <ListItem
            component={Link}
            to="/projects/projects-management"
            className="link1"
          >
            <Text 
              size="h5" 
              color="dark" 
              weight="bold"
            >
              PROJECT STATUS
            </Text>
          </ListItem>
        </MainButton>
      </List>
    </div>
  );
}
