import React, { useCallback } from 'react';
import { contactService } from '../../services';



export async function getListContact(dispatch, condition) {
  try {

    dispatch({
      type : 'GET_LIST_CONTACT_PROGRESS',
      payload : condition
    })

    await contactService.getListContact({...condition}).then((res) => {

      dispatch({
        type : 'GET_LIST_CONTACT_SUCCESS',
        payload : {
          contacts : res.data.data,
          total_record: res.data.total
        }
      })
    });


  }catch (e) {

  }
}




