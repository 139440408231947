import axios from './../utils/axios';
import config  from "../config";

function getListNew(condition) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/notice/search`,
    params : condition
  }

  return axios(request);
}

function getNewById(id) {
  const request = {
    method : 'GET',
    url : `${config.baseUrl}/notice/detail/${id}`
  }

  return axios(request);
}


function createNew(formData) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/notice`,
    header: {'content-type': 'multipart/form-data'},
    data : formData
  }

  return axios(request);
}

function updateNew(formData) {
  const request = {
    method : 'POST',
    url : `${config.baseUrl}/notice`,
    header: {'content-type': 'multipart/form-data'},
    data : formData
  }

  return axios(request);
}

function deleteNew(id) {
  const request = {
    method : 'DELETE',
    url : `${config.baseUrl}/notice/delete`,
    data : [id]
  }

  return axios(request);
}

export {getListNew, createNew, getNewById, updateNew, deleteNew};


