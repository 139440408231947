import React, { useState, useEffect, useCallback } from 'react';
import {
  TableContainer,
  Grid,
  Paper,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  makeStyles,
  Checkbox,
  MenuItem,
  Box,
  Tabs,
  Tab
} from '@material-ui/core';
import Text from '../../../../common/components/text';
import Button from '../../../../common/components/button';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import SearchSupportCenter from '../../../../common/components/SearchSupportCenter';
import { contactService } from '../../../../services';
import {
  useContactState,
  getListContact,
  useContactDispatch
} from '../../../../contexts/support';
import moment from 'moment';
import CustomLoading from '../../../../common/components/CustomLoading';
import _ from 'lodash';
import { decodeStringHtml, notiFailed } from '../../../../utils/helpers';

const useStyles = makeStyles(theme => ({
  tableHeaderCell: {
    color: '#9ca4ab',
    fontFamily: 'Actual',
    fontWeight: '400',
    fontSize: '1rem'
  },
  tableContainer: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    marginTop: '20px',
    borderRadius: 0,
    '& td': {
      fontFamily: 'Actual'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    }
  },
  container: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 0,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '30px',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  paginationContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    '& .Mui-selected': {
      backgroundColor: '#00ADEF',
      color: '#fff'
    }
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer'
    }
  },
  selectCheckbox: {
    '& .Mui-checked .MuiSvgIcon-root': {
      fill: '#009ee3'
    }
  },
  tabs: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& .MuiTabs-root': {
      maxWidth: '75vw',
      margin: 'auto',
      padding: '0'
    },
    '& .MuiTab-root': {
      minWidth: '60px',
      borderBottom: '3px solid transparent'
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Actual',
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: '400'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#00ADEF',
      borderBottom: '3px solid #00ADEF'
    },
    '& span.MuiTabs-indicator': {
      backgroundColor: 'transparent'
    }
  },
  wrapContainerLoading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },

  noRecordContainer: {
    backgroundColor: '#e5f3fb',
    width: '95%',
    margin: 'auto',
    marginTop: 50,
    textAlign: 'center',
    paddingTop: 50,
    marginBottom: 50,
    paddingBottom: 50
  }
}));

function SupportCenterQcell({ type }) {
  const history = useHistory();
  const classes = useStyles();
  const { contacts, total_record, loading, condition } = useContactState();
  const dispatch = useContactDispatch();
  const [listUser, setListUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userAssign, setUserAssign] = useState('select');
  const total = Math.ceil(total_record / 10);
  const [listIds, setListIds] = useState([]);
  const getListUserAdminCallBack = useCallback(() => {
    getListUserAdmin();
  }, []);

  useEffect(() => {
    getListUserAdminCallBack();
    getListContact(dispatch, { page: 1, size: 10, contactTypeId: type });
  }, []);

  async function getListUserAdmin() {
    const users = await contactService.getListUserAdmin(type);
    setListUser(users.data);
  }

  function handleAssignRecord(id) {
    if (listIds.includes(id)) {
      const makeArr = _.remove(listIds, function(el) {
        return el !== id;
      });
      setListIds(makeArr);
    } else {
      setListIds(prevState => [...prevState, id]);
    }
  }

  function handleChangePage(event, page) {
    setCurrentPage(page);
    setListIds([]);
    getListContact(dispatch, {
      size: 10,
      ...condition,
      page: page
    });
  }

  function handleChangeListUserAssign(event) {
    const { value } = event.target;
    setUserAssign(value);
  }

  async function handleConfirmAssign() {
    if (userAssign === 'select') {
      notiFailed('Please select a target to change');
      return;
    }

    await contactService.assignUser(listIds, userAssign);
    getListContact(dispatch, {
      size: 10,
      ...condition,
      page: currentPage
    });
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [total_record]);

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Text size="h2" style={{ lineHeight: '0px' }}>
          {type !== 1 ? 'Q.PARTNER Website' : 'Qcells Website'}
        </Text>
      </Grid>

      <Paper className={classes.container}>
        <SearchSupportCenter type={type} />

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell} align="center">
                  No.
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Category
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Name
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Company/ Organization
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Contact Number
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Source
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Registration date
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Account Manager
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Status
                </TableCell>
                <TableCell className={classes.tableHeaderCell} align="center">
                  Status Change
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan="12">
                    <div className={classes.wrapContainerLoading}>
                      <CustomLoading
                        className={classes.loadingNews}
                        size={40}
                        color="#00adef"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {contacts.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="12">
                        <div className={classes.noRecordContainer}>
                          <Text color="primaryLight" weight="bold">
                            No registered article.
                          </Text>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {contacts?.map((contact, index) => (
                    <TableRow
                      key={contact.id}
                      className={classes.tableRow}
                      onClick={() =>
                        history.push(`/supportcenter/${type}/edit/${contact.id}`)
                      }
                    >
                      <TableCell align="center">
                        {total_record - (currentPage - 1) * 10 - index}
                      </TableCell>
                      <TableCell align="center">
                        {contact.contactCategoryName}
                      </TableCell>
                      <TableCell align="center">
                        {decodeStringHtml(contact.name)}
                      </TableCell>
                      <TableCell align="center">
                        {decodeStringHtml(contact.companyName)}
                      </TableCell>
                      <TableCell align="center">{contact.mobileNumber}</TableCell>
                      <TableCell align="center">{contact.utmSource}</TableCell>
                      <TableCell align="center">
                        {moment(contact.createdAt).format('YYYY-MM-DD')}
                      </TableCell>
                      <TableCell align="center">
                        {_.get(contact.assign, 'fullName', 'Not assigned')}
                      </TableCell>
                      <TableCell align="center">{contact.contactStatus}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          className={classes.selectCheckbox}
                          onClick={e => e.stopPropagation()}
                        >
                          <Checkbox
                            color="primary"
                            onChange={() => handleAssignRecord(contact.id)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          style={{
            padding: '40px 30px 15px',
            borderBottom: '1px solid rgba(224, 224, 224, 1)'
          }}
          container
          justify="flex-end"
        >
          <Grid item sm={12}>
            <Box className={'wrap-box-website'}>
              <Text
                className={'text-website'}
                size="p"
                style={{ lineHeight: '37px', margin: 0, marginRight: '10px' }}
              >
                * Changes the account manager of the selected item to
              </Text>
              <TextField
                className={'select-user'}
                select
                size="small"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                value={userAssign}
                onChange={handleChangeListUserAssign}
              >
                <MenuItem key={'select'} value={'select'}>
                  Select
                </MenuItem>
                {listUser?.map((user, index) => {
                  return (
                    <MenuItem key={index} value={user.id}>
                      {user.fullName}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Button
                className={'btn-website'}
                onClick={handleConfirmAssign}
                style={{
                  height: '40px',
                  borderRadius: '5px',
                  marginLeft: '15px',
                  padding: '.5rem 1rem'
                }}
              >
                Confirm
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.paginationContainer}
      >
        {total !== 0 && (
          <Pagination
            size={'small'}
            count={total}
            page={currentPage}
            onChange={handleChangePage}
          />
        )}
      </Grid>
    </>
  );
}

export default React.memo(SupportCenterQcell);
